// Import necessary modules and components
import React, { useEffect } from 'react'
import { RouteComponentProps, RouteProps } from 'react-router'
import { Redirect, Route, Switch } from 'react-router-dom'
import { log } from '../../logger'
import { Auth0CallbackFunc } from '../../pages/authentication/auth0-callback-page'
import { Auth0LoginPage } from '../../pages/authentication/auth0-login-page'
import { DeploymentPage } from '../../pages/authentication/deployment-dashboard-base-page'
import { DashboardPage } from '../../pages/dashboard/dashboard-page'

import { useAppState } from '../../state'
import { Links, Paths } from './paths'
import { PanelOverviewPage } from '../../pages/panel-overview/panel-overview-page'
import { TenantsPage } from '../../pages/tenants/tenants-page'
import { TemplatePage } from '../../pages/template'
import { ChargerModelsPage } from '../../pages/chargerModels'

// Define the path for the default logged-in state
export const LoggedInPath = Links.auth0login()

// Component that handles routing within the application
export const Routes: React.FC = () => {
  const { hydrateSession } = useAppState()
  useEffect(() => {
    log('hydrating session')
    hydrateSession()
  }, [])

  // Define authenticated routes and their accessibility
  const authenticatedRoutes: {
    [path: string]: {
      component: React.ComponentType<any>
      accessibility: {
        admin: boolean
        manager: boolean
        user: boolean
      }
    }
  } = {
    [Paths.onboard]: { component: DeploymentPage, accessibility: { user: true, admin: true, manager: true } },
    [Paths.dashboard]: { component: DashboardPage, accessibility: { user: true, admin: true, manager: true } },
    [Paths.panelOverview]: { component: PanelOverviewPage, accessibility: { user: true, admin: true, manager: true } },
    [Paths.tenant]: { component: TenantsPage, accessibility: { user: true, admin: true, manager: true } },
    [Paths.newTenant]: { component: TenantsPage, accessibility: { user: true, admin: true, manager: true } },
    [Paths.template]: { component: TemplatePage, accessibility: { user: true, admin: true, manager: true } },
    [Paths.chargerModels]: { component: ChargerModelsPage, accessibility: { user: true, admin: true, manager: true } },
  }

  return (
    <>
      <Switch>
        {/* Authenticated-only routes */}
        {Object.keys(authenticatedRoutes).map((path) => {
          return (
            <AuthenticatedRoute
              exact={true}
              key={`authenticated-route${path.replace('/', '-')}`}
              path={path}
              component={authenticatedRoutes[path].component}
              accessibility={authenticatedRoutes[path].accessibility}
            />
          )
        })}
        {/* Unauthenticated-only routes */}
        <UnauthenticatedRoute exact={true} path={Paths.auth0login} component={Auth0LoginPage} />
        <Route path={Paths.auth0Callback} exact={true} component={Auth0CallbackFunc} />
        <Redirect to={Paths.auth0login} />
      </Switch>
    </>
  )
}

/**
 * Returns a ReactRouter.Route that can only be accessed with a valid
 * session. Accessing it with an invalid or null session with redirect
 * the user to Auth0. Upon authentication, the user will be redirect
 * to it's original route.
 */
function AuthenticatedRoute(
  props: RouteProps & {
    accessibility: {
      admin: boolean
      manager: boolean
      user: boolean
    }
  },
) {
  const { isAuthenticated, currentUser } = useAppState()
  const Component = props.component as React.ComponentType<any>
  const rest = Object.assign({}, props)
  // const accessibility = props.accessibility
  delete rest.component
  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps): React.ReactNode => {
        // If the user is not authenticated, redirect to signup
        if (!isAuthenticated()) {
          return <Redirect to={Links.auth0login()} />
        }

        // is the user is authenticated and he wants to go
        // to the onboarding flow send him there
        if (props.location.pathname === '/onboard') {
          return <Component {...props} />
        }

        // is the current user has not been resolved (i.e. GetSelf was NOT called on the onboarding flow)
        // send him back to onboarding to attempt to call GetSelf
        if (!currentUser) {
          log(
            'attempted to load an authentication page, current user is not set redirecting to onboard to resolve current user',
            { desiredPath: props.location.pathname },
          )
          return <Redirect to={`${Links.onboard()}?redirect_path=${props.location.pathname}`} />
        }

        // if the current user is found, a tenant is selected and he is trying to access a route
        // that is reserved to admins
        // if (currentUser.role === 'user' && !accessibility.user) {
        //   return <Redirect to={Links.onboard()} />
        // }

        return <Component {...props} />
      }}
    />
  )
}

/**
 * Returns a ReactRouter.Route that can only be accessed with a valid
 * session. Accessing it with an invalid or null session with redirect
 * the user to Auth0. Upon authentication, the user will be redirect
 * to it's original route.
 */
function UnauthenticatedRoute(props: RouteProps) {
  const { isAuthenticated } = useAppState()
  const Component = props.component as React.ComponentType<any>
  const rest = Object.assign({}, props)
  delete rest.component
  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps) => {
        if (isAuthenticated()) {
          // If the user is authenticated and this is an auth page, redirect to onboarding
          return <Redirect to={Links.onboard()} />
        }
        return <Component {...props} />
      }}
    />
  )
}
