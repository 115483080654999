import { message } from 'antd'
import { useEffect, useState } from 'react'

import { AllCircuitData, AllLoadManagements, SessionsResponse } from '../models/circuit'
import { LoadManagementTenantRef } from '../models/tenant'
import { ApiError } from '../models/error'
import { GetLoadManagementTenants } from '../services/data-provider/tenants'
import { GetAllCircuitInfo } from '../services/data-provider/circuit'
import { TenantResponse } from '../models/http'

export const useSessions = () => {
  const [loading, setLoading] = useState(false)
  const [loadManagements, setLoadManagements] = useState<AllLoadManagements[]>([])
  const [originalLoadManagements, setOriginalLoadManagements] = useState<AllLoadManagements[]>([])
  const [tenants, setTenants] = useState<LoadManagementTenantRef[]>([])
  const [allTenants, setAllTenants] = useState<LoadManagementTenantRef[]>([])
  const [error, setError] = useState<ApiError>()
  const [searchTenantId, setSearchTenantId] = useState('')
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    getAllCircuits()
    getAllTenants()
  }, [])

  useEffect(() => {
    const tempLoadManagementArr: any = []
    tenants.map((tenant: LoadManagementTenantRef) => {
      return originalLoadManagements.map((circuit) => {
        if (circuit.tenant_id === tenant.tenant_id) tempLoadManagementArr.push(circuit)
        return tempLoadManagementArr
      })
    })
    setLoadManagements([...tempLoadManagementArr])
    if (tenants.length === 0) {
      setLoadManagements([...originalLoadManagements])
    }
  }, [tenants])

  const getAllTenants = () => {
    setLoading(true)
    GetLoadManagementTenants()
      .then((resp: TenantResponse) => {
        const allTenantsArr: any = resp.data[0].tenants
        setAllTenants([...allTenantsArr])
      })
      .catch((error) => {
        setAllTenants([])
        message.error(error.description)
        setError(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getAllCircuits = () => {
    setLoading(true)
    const allLoadManagementsArr: AllLoadManagements[] = []
    GetAllCircuitInfo(currentPage)
      .then((resp: SessionsResponse) => {
        resp.data[0].map((panel: AllCircuitData) => {
          return panel.circuits.map((circuit) => {
            let totalEvse = 0
            let instanceNumber = ''
            let instanceIp = ''
            let instancePort = ''
            circuit.branches.map((branch) => {
              if (circuit.type === 'Mixed' && circuit.session) {
                instanceNumber = circuit.session.ssh_instance
                instanceIp = circuit.session.evidect_ip
                instancePort = circuit.session.evidect_port
              } else {
                if (branch.session) {
                  instanceNumber = branch.session.ssh_instance
                  instanceIp = branch.session.evidect_ip
                  instancePort = branch.session.evidect_port
                }
              }
              return (totalEvse = totalEvse + branch.chargers.length)
            })

            const circuitData = {
              tenant_id: panel.tenant_id,
              tenant_name: panel.tenant_name,
              status: circuit.load_management,
              panel_type: circuit.type,
              panel: {
                id: circuit.circuit_id,
                name: circuit.name,
              },
              existing_load: circuit.existing_load,
              total_evse: totalEvse,
              ct_clamp: {
                ip_address: circuit.ct_clamp?.ip_address,
                port: circuit.ct_clamp.port,
                id: circuit.ct_clamp.id,
              },
              instance: {
                instanceNumber: instanceNumber,
                instanceIp: instanceIp,
                instancePort: instancePort,
              },
              updated_by: {
                name: panel.updated_by,
                date: panel.updated_at,
              },
            }
            return allLoadManagementsArr.push(circuitData)
          })
        })
        setLoadManagements([...allLoadManagementsArr])
        setOriginalLoadManagements([...allLoadManagementsArr])
      })
      .catch((error) => {
        message.error(error.description)
        setError(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const searchForTenants = (tenantId: string, tenants: LoadManagementTenantRef[]) => {
    if (tenantId === '' && tenants.length === 0) {
      setLoadManagements([...originalLoadManagements])
    } else {
      let allTenantsIdArr = [tenantId]
      tenants.map((tenant) => {
        if (tenant.tenant_id !== tenantId) allTenantsIdArr.push(tenant.tenant_id)
        return allTenantsIdArr
      })
      const allLoadManagementsArr: any = []
      allTenantsIdArr.map((singleTenantId) => {
        originalLoadManagements.map((circuit) => {
          if (circuit.tenant_id === singleTenantId) allLoadManagementsArr.push(circuit)
          return setLoadManagements([...allLoadManagementsArr])
        })
        return allLoadManagementsArr
      })
    }
  }

  const onChange = (e: any) => setSearchTenantId(e.target.value)

  const onPaginationChange = (page: number) => setCurrentPage(page)

  return {
    allTenants,
    searchTenantId,
    loading,
    error,
    loadManagements,
    setTenants,
    searchForTenants,
    onChange,
    onPaginationChange,
  }
}
