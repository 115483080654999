import { httpClient } from './client'
import configProvider from '../../config'
import {
  GeocodingResult,
  GoogleGeocodeResponse,
  GoogleGeocodeResult,
  SwtchAddress,
  googleGeocodeBaseUrl,
} from '../../models/geocoding'

const getLocationFromAddress = async (address: SwtchAddress): Promise<GeocodingResult> => {
  const requestUrl = buildGeocodeUrl(address)
  const response = await callGeocodeUrl(requestUrl)
  return handleGeocodingResponse(response, address)
}

// Main function to perform geocoding
const buildGeocodeUrl = (address: SwtchAddress): string => {
  const addressComponents = [
    address.numberAndStreet,
    address.city,
    address.province,
    address.postalCode,
    address.country,
  ]
  const urlEncodedComponents = addressComponents.map((c) => encodeURIComponent(c ?? '')).join(',+')
  return `${googleGeocodeBaseUrl}?address=${urlEncodedComponents}&key=${configProvider.config.googleMapsApiKey}`
}

const callGeocodeUrl = async (url: string): Promise<GoogleGeocodeResponse> => {
  try {
    const response = await httpClient<GoogleGeocodeResponse>(url, {})
    return response
  } catch (error: unknown) {
    let errorMessage = 'An unknown error occurred.'

    if (error instanceof Error) {
      errorMessage = error.message
    }

    return { results: [], status: 'REQUEST_FAILED', error_message: errorMessage }
  }
}

// Handles the geocoding API response
const handleGeocodingResponse = (response: GoogleGeocodeResponse, address: SwtchAddress): GeocodingResult => {
  if (!response.results || response.results.length === 0) {
    return { found: false, message: 'No results found.', code: response.status }
  }

  let result = selectBestGeocodeResult(response, address)
  const { lat, lng } = result.geometry.location

  let country = extractAddressComponent(result, 'country')
  let province = extractAddressComponent(result, 'administrative_area_level_1')

  const numberAndStreet = formatNumberAndStreet(result, country)

  // Handle Puerto Rico case
  if (country === 'Puerto Rico') {
    country = 'United States'
    province = 'Puerto Rico'
  }

  return {
    found: true,
    lat,
    lng,
    numberAndStreet,
    city: extractCity(result),
    postalCode: extractAddressComponent(result, 'postal_code'),
    country,
    province,
  }
}

const selectBestGeocodeResult = (response: GoogleGeocodeResponse, address: SwtchAddress) => {
  let result = response.results[0] // Default to the first result
  if (response.results.length > 1) {
    result = response.results.find((r) => r.formatted_address.includes(address.numberAndStreet ?? '')) || result
  }
  return result
}

const formatNumberAndStreet = (result: GoogleGeocodeResult, country?: string) => {
  if (country === 'United States' || country === 'Canada') {
    return `${extractAddressComponent(result, 'street_number') || ''} ${
      extractAddressComponent(result, 'route') || ''
    }`.trim()
  }
  return result.formatted_address
    .replace(new RegExp(`, ${extractAddressComponent(result, 'postal_code')}`), '')
    .replace(new RegExp(`, ${extractAddressComponent(result, 'sublocality')}`), '')
    .replace(new RegExp(`, ${extractAddressComponent(result, 'locality')}`), '')
    .replace(new RegExp(`, ${extractAddressComponent(result, 'country')}`), '')
    .replace(new RegExp(`, ${extractAddressComponent(result, 'administrative_area_level_1')}`), '')
    .replace(/,\s*$/, '')
}

// Utility to extract long name of an address component by type
const extractAddressComponent = (result: GoogleGeocodeResult, type: string): string | undefined => {
  return result.address_components.find((ac) => ac.types.includes(type))?.long_name
}

const extractCity = (result: GoogleGeocodeResult): string | undefined => {
  return (
    result.address_components.find((ac) => ac.types.includes('locality'))?.short_name ||
    result.address_components.find((ac) => ac.types.includes('neighborhood'))?.short_name ||
    result.address_components.find((ac) => ac.types.includes('sublocality'))?.short_name ||
    result.address_components.find((ac) => ac.types.includes('postal_town'))?.short_name
  )
}

export { getLocationFromAddress }
