// Import necessary components and libraries
import { Col, Layout, Menu, MenuProps, Row, Space } from 'antd'
import { useHistory, useLocation } from 'react-router'

import { useAppState } from '../../../state'
import { styled, theme } from '../../../theme'
import { Links } from '../../routes/paths'
import { panelOverviewSVG } from '../../../assets/svg/panel'
import { pieChartSVG } from '../../../assets/svg/pieChart'
import { logoutSVG } from '../../../assets/svg/logout'
import { AppHeaderContainer, HeadingWrapper } from '../../../atom/app-header'
import { FileTextFilled, ProfileFilled } from '@ant-design/icons'

// Destructure components from antd library
const { Header } = Layout

// Styled component for menu spacing
const MenuSpacing = styled.div`
  .ant-layout-header {
    padding: 0 21px 0 15px;
    height: 55px;
  }
`

// Styled component for logout text
const LogoutTextWrapper = styled.span`
  font-family: ${(props) => props.theme.fontFamily.regular};
  color: #242e2c;
`

// Styled component for logout wrapper
const LogoutWrapper = styled.span`
  display: flex;
  justify-content: end;
  font-family: ${(props) => props.theme.fontFamily.regular};

  .ant-menu-item {
    display: flex;
    align-items: center;
    background-color: white;
  }
`

// Props interface for the component
interface props {
  collapsed: boolean
  handleToggleMenu(): void
}

// AppHeader component
export const AppHeader: React.FC<props> = ({ collapsed, handleToggleMenu }) => {
  const { currentUser, logout, IsMobile, IsTablet } = useAppState()

  // Check if the device is mobile or tablet
  const isMobile = IsMobile()
  const isTablet = IsTablet()
  const history = useHistory()
  const location = useLocation()

  // Handle the logout action
  const handleLogout: MenuProps['onClick'] = (e) => {
    if (e.key === 'logout') {
      logout()
      history.push(Links.auth0login())
    }
  }

  // If there's no current user, return an empty div
  if (!currentUser) {
    return <div />
  }

  // Function to render the title and icon in the header
  const renderTitle = () => {
    const splitPathName = location.pathname.split('/')

    let title = ''
    let icon = pieChartSVG

    switch (splitPathName[1]) {
      case 'panel-overview':
        icon = panelOverviewSVG
        title = 'Panel Overview'
        break
      case 'template':
        title = 'Template'
        icon = <FileTextFilled style={{ color: theme.colors.primary }} />
        break
      case 'charger-models':
        title = 'Charger Models'
        icon = <ProfileFilled style={{ color: theme.colors.primary }} />
        break
      default:
        icon = pieChartSVG
        title = 'Deployment Dashboard'
        break
    }

    return (
      <HeadingWrapper>
        <span style={{ position: 'absolute', left: '5px', top: '16px' }}>{icon}</span>
        <div className="heading-04-regular">{title}</div>
      </HeadingWrapper>
    )
  }

  // Return the JSX for the AppHeader component
  return (
    <AppHeaderContainer>
      <MenuSpacing style={{ zIndex: 1 }}>
        <Header>
          <Row justify="space-between">
            <Col xs={22} sm={23} md={17} lg={18} xl={20} xxl={20}>
              <Space>
                <h1
                  className="heading-01-regular dashboard-title"
                  style={{ marginTop: '15px', marginBottom: 0, marginLeft: '33px' }}
                >
                  {renderTitle()}
                </h1>
              </Space>
            </Col>
            <Col xs={2} sm={1} md={7} lg={6} xl={4} xxl={4}>
              <LogoutWrapper>
                <Menu theme="light" mode="horizontal" style={{ float: isMobile || isTablet ? 'none' : 'right' }}>
                  <Menu.Item key={'logout'} icon={logoutSVG} onClick={handleLogout}>
                    <LogoutTextWrapper className="regular-cap">Log Out</LogoutTextWrapper>
                  </Menu.Item>
                </Menu>
              </LogoutWrapper>
            </Col>
          </Row>
        </Header>
      </MenuSpacing>
    </AppHeaderContainer>
  )
}
