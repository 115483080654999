// Search icon with grey stroke
export const searchSVG = (
    <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="usersSelectorIcon"
  >
    <path
      d="M10.9951 11.08L13.6 13.6M12.76 6.88C12.76 10.1274 10.1274 12.76 6.88 12.76C3.63257 12.76 1 10.1274 1 6.88C1 3.63257 3.63257 1 6.88 1C10.1274 1 12.76 3.63257 12.76 6.88Z"
      stroke="#7D838A"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
  )
  
