import { TenantResponse } from '../models/http'

export const mockTenantResponse: TenantResponse = {
  data: [
    {
      id: '43',
      name: '1-vancouver-street-barrie-on',
      displayName: '1 Vancouver Street, Barrie, ON',
    },
    {
      id: '46',
      name: '1engineering',
      displayName: undefined,
    },
  ],
  pagination: { currentPage: 1, totalEntries: 25, perPage: 20 },
}

// export const mockTenantCreatedResponse: TenantCreatedResponse = {
//   code: 200,
//   message: 'Tenant created successfully',
// }
