import { ChargersListingsLoadMgmt } from '../models/charger'
import {
  LoadManagementAllResponse,
  LoadManagementCreateNewTenantResponse,
  LoadManagementEndPointResponse,
  LoadManagementResponse,
  LoadManagementUpdatedResponse,
} from '../models/http'

export const mockAllPanels = [
  {
    tenant_id: '479',
    tenant_name: 'engineering',
    circuits: [
      {
        id: '1',
        circuit_id: '1001',
        name: 'Lan`s First Circuit Panel',
        load_management: true,
        type: 'Dedicated',
        ct_clamp: {
          id: '1',
          enabled: false,
          ip_address: '127.0.0.1',
          port: 2000,
        },
        existing_load: 12,
        circuit_size: 100,
        note: 'note for circuit 1',
        branches: [
          {
            id: '1',
            name: 'Branch_1',
            load_management: false,
            breaker_size: 40,
            end_point: null,
            chargers: [
              {
                id: '1',
                cp_serial_number: '1EC0-1-1823-00061',
                phases: 'AB',
                ampere_max: 32,
                ampere_min: 12,
                ampere_fallback: 8,
              },
            ],
          },
        ],
      },
      {
        id: '2',
        circuit_id: '1002',
        name: 'Jing`s First Circuit Panel',
        load_management: true,
        type: 'Mixed',
        ct_clamp: {
          id: '1',
          enabled: true,
          ip_address: '127.0.0.1',
          port: 2000,
        },
        existing_load: 12,
        circuit_size: 100,
        note: 'note for circuit 1',
        branches: [
          {
            id: '1',
            name: 'Branch_1',
            load_management: false,
            breaker_size: 40,
            end_point: null,
            chargers: [
              {
                id: '1',
                cp_serial_number: '1EC0-1-1823-00061',
                phases: 'AB',
                ampere_max: 32,
                ampere_min: 12,
                ampere_fallback: 8,
              },
            ],
          },
        ],
      },
    ],
  },
]

export const mockLoadMgmtResp = {
  data: [
    {
      tenant_id: '479',
      tenant_name: 'engineering',
      circuits: [
        {
          id: '1',
          circuit_id: '1001',
          name: 'Lan`s First Circuit Panel',
          load_management: true,
          type: 'Dedicated',
          ct_clamp: {
            id: '1',
            enabled: false,
            ip_address: '127.0.0.1',
            port: 2000,
          },
          existing_load: 12,
          circuit_size: 100,
          note: 'note for circuit 1',
          branches: [
            {
              id: '1',
              name: 'Branch_1',
              load_management: false,
              breaker_size: 40,
              end_point: null,
              chargers: [
                {
                  id: '1',
                  cp_serial_number: '1EC0-1-1823-00061',
                  phases: 'AB',
                  ampere_max: 32,
                  ampere_min: 12,
                  ampere_fallback: 8,
                },
              ],
            },
          ],
        },
        {
          id: '2',
          circuit_id: '1002',
          name: 'Jing`s First Circuit Panel',
          load_management: true,
          type: 'Mixed',
          ct_clamp: {
            id: '1',
            enabled: false,
            ip_address: '127.0.0.1',
            port: 2000,
          },
          existing_load: 12,
          circuit_size: 100,
          note: 'note for circuit 1',
          branches: [
            {
              id: '1',
              name: 'Branch_1',
              load_management: false,
              breaker_size: 40,
              end_point: null,
              chargers: [
                {
                  id: '1',
                  cp_serial_number: '1EC0-1-1823-00061',
                  phases: 'AB',
                  ampere_max: 32,
                  ampere_min: 12,
                  ampere_fallback: 8,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  code: 200,
  message: 'Panel data retrieved successfully',
} as LoadManagementResponse

export const mockLoadMgmtAllResp = {
  data: [
    [
      {
        tenant_id: '479',
        tenant_name: 'engineering',
        circuits: [
          {
            id: '1',
            circuit_id: '1001',
            name: 'Lan`s First Circuit Panel',
            load_management: true,
            type: 'Dedicated',
            ct_clamp: {
              id: '1',
              enabled: false,
              ip_address: '127.0.0.1',
              port: 2000,
            },
            existing_load: 12,
            circuit_size: 100,
            note: 'note for circuit 1',
            branches: [
              {
                id: '1',
                name: 'Branch_1',
                load_management: false,
                breaker_size: 40,
                end_point: null,
                chargers: [
                  {
                    id: '1',
                    cp_serial_number: '1EC0-1-1823-00061',
                    phases: 'AB',
                    ampere_max: 32,
                    ampere_min: 12,
                    ampere_fallback: 8,
                  },
                ],
              },
            ],
          },
          {
            id: '2',
            circuit_id: '1002',
            name: 'Jing`s First Circuit Panel',
            load_management: true,
            type: 'Mixed',
            ct_clamp: {
              id: '1',
              enabled: false,
              ip_address: '127.0.0.1',
              port: 2000,
            },
            existing_load: 12,
            circuit_size: 100,
            note: 'note for circuit 1',
            branches: [
              {
                id: '1',
                name: 'Branch_1',
                load_management: false,
                breaker_size: 40,
                end_point: null,
                chargers: [
                  {
                    id: '1',
                    cp_serial_number: '1EC0-1-1823-00061',
                    phases: 'AB',
                    ampere_max: 32,
                    ampere_min: 12,
                    ampere_fallback: 8,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  ],
  code: 200,
  message: 'Panel data retrieved successfully',
} as LoadManagementAllResponse

export const mockLoadMgmtUpdatedResp = {
  data: ['Panel: 4 update is successful'],
  code: 200,
  message: 'Panel updated succesfully',
} as LoadManagementUpdatedResponse

export const test = {
  data: [],
}
export const mockLoadMgmtChargerResp = {
  '479': {
    tenant_id: 79,
    tenant_name: '1644-Hillside-Ave-Victoria-BC',
    chargers: {
      '250': {
        charger_id: 250,
        chargepoint_serial_number: '1EC4-1-1927-00005',
        charging_type: 'Level 2',
        service_status: 'service_mode',
        connectors: {
          '1': {
            connector_id: 1,
            listing_id: null,
            listing_title: null,
          },
        },
      },
      '251': {
        charger_id: 251,
        chargepoint_serial_number: '1EC4-1-1927-00046',
        charging_type: 'Level 2',
        service_status: 'service_mode',
        connectors: {
          '1': {
            connector_id: 1,
            listing_id: null,
            listing_title: null,
          },
        },
      },
      '252': {
        charger_id: 252,
        chargepoint_serial_number: '1EC4-1-2001-00003',
        charging_type: 'Level 2',
        service_status: 'service_mode',
        connectors: {
          '1': {
            connector_id: 1,
            listing_id: null,
            listing_title: null,
          },
        },
      },
      '253': {
        charger_id: 253,
        chargepoint_serial_number: '1EC4-1-2001-00005',
        charging_type: 'Level 2',
        service_status: 'service_mode',
        connectors: {
          '1': {
            connector_id: 1,
            listing_id: null,
            listing_title: null,
          },
        },
      },
      '254': {
        charger_id: 254,
        chargepoint_serial_number: '1EC4-1-2001-00009',
        charging_type: 'Level 2',
        service_status: 'service_mode',
        connectors: {
          '1': {
            connector_id: 1,
            listing_id: 218,
            listing_title: 'HA06',
          },
        },
      },
      '255': {
        charger_id: 255,
        chargepoint_serial_number: '1EC4-1-2001-00011',
        charging_type: 'Level 2',
        service_status: 'service_mode',
        connectors: {
          '1': {
            connector_id: 1,
            listing_id: 219,
            listing_title: 'HA07',
          },
        },
      },
      '256': {
        charger_id: 256,
        chargepoint_serial_number: '1EC4-1-2001-00020',
        charging_type: 'Level 2',
        service_status: 'service_mode',
        connectors: {
          '1': {
            connector_id: 1,
            listing_id: 220,
            listing_title: 'HA08',
          },
        },
      },
      '257': {
        charger_id: 257,
        chargepoint_serial_number: '1EC4-1-2001-00023',
        charging_type: 'Level 2',
        service_status: 'service_mode',
        connectors: {
          '1': {
            connector_id: 1,
            listing_id: 221,
            listing_title: 'HA09',
          },
        },
      },
      '258': {
        charger_id: 258,
        chargepoint_serial_number: '1EC4-1-2001-00026',
        charging_type: 'Level 2',
        service_status: 'service_mode',
        connectors: {
          '1': {
            connector_id: 1,
            listing_id: 222,
            listing_title: 'HA10',
          },
        },
      },
    },
  },
} as ChargersListingsLoadMgmt

export const mockLoadMgmtEndPointResp = {
  data: [
    {
      1: {
        circuit: '1',
        branch: '1',
        url: 'ws://evidect.swtchenergy.com/tenant_479_1/engineering',
      },
      2: {
        circuit: '2',
        branch: '',
        url: 'ws://evidect.swtchenergy.com/tenant_479_2/engineering',
      },
    },
  ],
  code: 200,
  message: 'Endpoint updated succesfully',
} as LoadManagementEndPointResponse

export const mockLoadMgmtUpdatedTenant = {
  data: [
    {
      tenant_id: '80',
      tenant_name: '1644-Hillside-Ave-Victoria-BC',
    },
  ],
  code: 200,
  message: 'Tenant updated succesfully',
} as LoadManagementCreateNewTenantResponse
