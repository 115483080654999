import { useEffect, useState } from 'react'
import { ColumnsType } from 'antd/lib/table'
import { Badge, Button, Drawer, message, PageHeader, Space, Spin, Table, Tooltip } from 'antd'
import { ExclamationCircleOutlined, FileTextOutlined, SettingFilled } from '@ant-design/icons'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'

import { AlertError } from '../../components/error'
import { withAuthenticatedLayout } from '../../components/layouts/layout'

import { Charger } from '../../models/charger'
import { Environment } from '../../models/environment'
import { Template, UpdateBase } from '../../models/template'
import { ApiError } from '../../models/error'

import {
  CommissionCharger,
  DeleteCharger,
  GetAllChargers,
  UpdateAndRestartCharger,
  VerifyCharger,
} from '../../services/data-provider/charger'
import { GetAllChargerEnvironments } from '../../services/data-provider/environment'
import { GetAllChargerConfigTemplates, ModifyChargerConfigTemplate } from '../../services/data-provider/template'

import { EditChargerConfigurationTemplateModal } from './charger/edit-charger-configuration-template-modal'

import { AlertWarning } from '../../components/warning'
import { ChargerErrorsModal } from './charger/charger-errors-modal'
import { UpdateAndRestartChargerModal } from './charger/update_and_restart_charger_modal'
import { DeleteModal } from './delete-modal'
import { clearSession } from '../../state/session-store'
import { useDashboardPermissions } from './useDashboardPermissions'
import auth0Obj from '../../services/data-provider/auth0Obj'
import { Links } from '../../components/routes/paths'
import { log } from '../../logger'
import UnauthorizedResult from '../../components/result/UnauthorizedResult'
import SessionExpiredResult from '../../components/result/SessionExpiredResult'
import CreateTenantModal from '../../feature/modal/CreateTenantModal'
import { networkSVG } from '../../assets/svg/network'
import { handleConfigurationStatus, handleConnectivityBadge } from '../../helpers/connectivity'
import CreateChargerModelModal from '../../feature/modal/CreateChargerModelModal'
import ShowChargerConfigModal from '../../feature/modal/ShowChargerConfigModal'
import { DateTimeV2 } from '../../components/date-time'
import { HomeFilter } from '../../models/filter'
import { BackgroundContainer } from '../../atom/grey-background'

import { useAppState } from '../../state'
import ChargerCardView from '../../feature/home/ChargerCardView'
import ChargerSelectors from '../../feature/home/ChargerSelectors'
import HomeSelectors from '../../feature/home/HomeSelectors'
import MobileExtraOptions from '../../feature/home/MobileExtraOptions'
import { LineBreak } from '../../atom/line-break'
import CommissionNewListingsModal from '../../feature/commission-new-listings/CommissionNewListingsModal'
import InfoMessage from '../../components/message/InfoMessage'
import { theme } from '../../theme'

const DashboardBasePage: React.FC = () => {
  const { IsMonitor, IsDesktop, IsMobile, IsTablet, IsLaptop } = useAppState()

  const isMonitor = IsMonitor()
  const isDesktop = IsDesktop()
  const isMobile = IsMobile()
  const isTablet = IsTablet()
  const isLaptop = IsLaptop()

  const [chargers, setChargers] = useState<Charger[]>([])
  const [environments, setEnvironments] = useState<Environment[]>([])
  const [templates, setTemplates] = useState<Template[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ApiError>()

  const [showChargerConfig, setShowChargerConfig] = useState<Charger>()

  const [chargerEditInfoModalVisible, setChargerEditInfoModalVisible] = useState(false)
  const [chargerUpdateAndRestartModalVisible, setChargerUpdateAndRestartModalVisible] = useState(false)

  const [updateAndRestartBtnEnabled, setUpdateAndRestartBtnEnabled] = useState(false)
  const [updateAndRestartBtnClicked, setUpdateAndRestartBtnClicked] = useState(false)

  const [verifyBtnEnabled, setVerifyBtnEnabled] = useState(false)
  const [verifyBtnEnabledClicked, setVerifyBtnEnabledClicked] = useState(false)

  const [commissionBtnEnabled, setCommissionBtnEnabled] = useState(false)
  const [commissionBtnEnabledClicked, setCommissionBtnEnabledClicked] = useState(false)

  const [charger, setCharger] = useState<Charger>()
  const [serialNumbers, setSerialNumbers] = useState<UpdateBase[]>([])

  const [chargerErrorsModalVisible, setChargerErrorsModalVisible] = useState(false)
  const [deleteChargerModalVisible, setDeleteChargerModalVisible] = useState(false)

  const [term, setTerm] = useState('')

  const signatureExpired = localStorage.getItem('signatureExpired')

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [isCreateTenantModalOpen, setIsCreateTenantModalOpen] = useState(false)
  const [isCreateChargerModelOpen, setIsCreateChargerModelOpen] = useState(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  // const [expandableRowKeys, setExpandableRowKeys] = useState<{ [dataKey: string]: boolean }>({})
  const [showCommissionNewListingsModal, setShowCommissionNewListingsModal] = useState(false)
  const [selectedChargers, setSelectedChargers] = useState<Charger[]>([])

  const [filter, setFilter] = useState<HomeFilter>({
    charger_serial_numbers: [],
    status: undefined,
  })

  const [showSearchQuery, setShowSearchQuery] = useState(false)

  const { canReadChargerEntries, canReadTemplates, canReadEnvironments } = useDashboardPermissions()

  const serialNumberIndices: {
    [key: number]: string
  }[] = []

  const getData = () => {
    setError(undefined)
    setLoading(true)
    setShowSearchQuery(true)
    GetAllChargers(filter)
      .then((resp) => setChargers(resp))
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    setLoading(true)
    const promises = []

    if (canReadChargerEntries) {
      const getAllChargersPromise = GetAllChargers(filter).then((resp) => {
        setChargers(resp)
      })
      promises.push(getAllChargersPromise)
    }

    if (canReadTemplates) {
      const getAllChargerConfigTemplatesPromise = GetAllChargerConfigTemplates().then((resp: Template[]) => {
        Array.isArray(resp) && setTemplates(resp)
      })
      promises.push(getAllChargerConfigTemplatesPromise)
    }

    if (canReadEnvironments) {
      const getAllChargerEnvironmentsPromise = GetAllChargerEnvironments().then((resp: Environment[]) => {
        Array.isArray(resp) && setEnvironments(resp)
      })
      promises.push(getAllChargerEnvironmentsPromise)
    }

    Promise.all(promises)
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }, [term])

  useEffect(() => {
    verifyBtnEnabled &&
      verifyBtnEnabledClicked &&
      serialNumbers &&
      serialNumbers.length > 0 &&
      VerifyCharger(serialNumbers)
        .then((resp) => {
          message.success(resp.message)
          if (
            (Array.isArray(resp.errors) && resp.errors.length > 0) ||
            (Array.isArray(resp.warnings) && resp.warnings.length > 0)
          ) {
            setError(
              new ApiError(
                'Something went wrong with this request. Please contact customer support if this issue persists.',
                resp.errors,
                resp.warnings,
              ),
            )
          } else {
            getData()
          }
        })
        .catch((err) => setError(err))
        .finally(() => {
          setLoading(false)
          setVerifyBtnEnabledClicked(!verifyBtnEnabledClicked)
        })

    updateAndRestartBtnEnabled &&
      updateAndRestartBtnClicked &&
      serialNumbers &&
      serialNumbers.length > 0 &&
      UpdateAndRestartCharger(serialNumbers)
        .then((resp) => {
          message.success(resp.message)
          if (
            (Array.isArray(resp.errors) && resp.errors.length > 0) ||
            (Array.isArray(resp.warnings) && resp.warnings.length > 0)
          ) {
            setError(
              new ApiError(
                'Something went wrong with this request. Please contact customer support if this issue persists.',
                resp.errors,
                resp.warnings,
              ),
            )
          } else {
            getData()
          }
        })
        .catch((err) => {
          setError(err)
        })
        .finally(() => {
          setLoading(false)
          setUpdateAndRestartBtnClicked(!updateAndRestartBtnClicked)
        })

    commissionBtnEnabled &&
      commissionBtnEnabledClicked &&
      serialNumbers &&
      serialNumbers.length > 0 &&
      CommissionCharger(serialNumbers)
        .then((resp) => {
          message.success(resp.message)
          if (
            (Array.isArray(resp.errors) && resp.errors.length > 0) ||
            (Array.isArray(resp.warnings) && resp.warnings.length > 0)
          ) {
            setError(
              new ApiError(
                'Something went wrong with this request. Please contact customer support if this issue persists.',
                resp.errors,
                resp.warnings,
              ),
            )
          } else {
            getData()
          }
        })
        .catch((err) => {
          setError(err)
        })
        .finally(() => {
          setLoading(false)
          setCommissionBtnEnabledClicked(!commissionBtnEnabledClicked)
        })
  }, [serialNumbers])

  const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: Charger[]) => {
    if (selectedRows.length > 0) {
      const states = selectedRows.map((ch) => ch.configurationState.state)

      const hasSameMakeAndModel = selectedRows.every(
        (charger) =>
          charger.info.vendor === selectedRows[0].info.vendor && charger.info.model === selectedRows[0].info.model,
      )

      const isOnline = selectedRows.every((charger) => charger.connectionStatus.status === 'connected')

      const isUnconfiguredOrRejected = states.some((state) => state === 'Unconfigured' || state === 'Rejected')

      const canUpdateAndRestart = states.every(
        (state) => state === 'Unknown' || state === 'Unconfigured' || state === 'Rejected',
      )

      const canVerify = states.every((state) => state === 'Rebooted')

      const canCommission = isUnconfiguredOrRejected && hasSameMakeAndModel && isOnline

      const otherStates = states.every(
        (state) => state === 'Rebooting' || state === 'Updating' || state === 'Commissioned',
      )

      canUpdateAndRestart ? setUpdateAndRestartBtnEnabled(true) : setUpdateAndRestartBtnEnabled(false)
      canVerify ? setVerifyBtnEnabled(true) : setVerifyBtnEnabled(false)
      canCommission ? setCommissionBtnEnabled(true) : setCommissionBtnEnabled(false)

      if (otherStates) {
        setUpdateAndRestartBtnEnabled(false)
        setVerifyBtnEnabled(false)
        setCommissionBtnEnabled(false)
      }
    } else {
      setUpdateAndRestartBtnEnabled(false)
      setVerifyBtnEnabled(false)
      setCommissionBtnEnabled(false)
    }

    setSelectedRowKeys(newSelectedRowKeys)
    setSelectedChargers(selectedRows)
  }

  const handleSelectedChargers = (newCharger: Charger) => {
    const isSelected = selectedChargers.some((selectedCharger) => selectedCharger._id === newCharger._id)

    if (isSelected) {
      setSelectedChargers(selectedChargers.filter((selectedCharger) => selectedCharger._id !== newCharger._id))
      handleCommissionEnabled(selectedChargers.filter((selectedCharger) => selectedCharger._id !== newCharger._id))
    } else {
      setSelectedChargers([...selectedChargers, newCharger])
      handleCommissionEnabled([...selectedChargers, newCharger])
    }
  }

  const handleCommissionEnabled = (selectedChargerList: Charger[]) => {
    const states = selectedChargerList.map((ch) => ch.configurationState.state)
    const hasSameMakeAndModel = selectedChargerList.every(
      (charger) =>
        charger.info.vendor === selectedChargerList[0].info.vendor &&
        charger.info.model === selectedChargerList[0].info.model,
    )

    const isOnline = selectedChargerList.every((charger) => charger.connectionStatus.status === 'connected')

    const isUnconfiguredOrRejected = states.some((state) => state === 'Unconfigured' || state === 'Rejected')

    const canCommission = isUnconfiguredOrRejected && hasSameMakeAndModel && isOnline

    const otherStates = states.every(
      (state) => state === 'Rebooting' || state === 'Updating' || state === 'Commissioned',
    )
    if (otherStates) {
      setCommissionBtnEnabled(false)
    } else {
      setCommissionBtnEnabled(canCommission)
    }
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const handleDeleteCharger = ({ serialNumber }: Charger) => {
    DeleteCharger(serialNumber)
      .then((resp) => {
        if (resp.errors.length === 0) {
          message.success(resp.message)
          const updateChargers = chargers.filter((charger) => charger.serialNumber !== serialNumber)
          setChargers(updateChargers)
        }
        if (
          (Array.isArray(resp.errors) && resp.errors.length > 0) ||
          (Array.isArray(resp.warnings) && resp.warnings.length > 0)
        ) {
          setError(
            new ApiError(
              'Something went wrong with this request. Please contact customer support if this issue persists.',
              resp.errors,
              resp.warnings,
            ),
          )
        }
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }

  const handleCopyAboveBtn = (index: number, serialNumber: string) => {
    const currentIndex = serialNumberIndices.findIndex((element) => element === serialNumber)
    const previousRecordName = serialNumberIndices[currentIndex - 1]

    const [previousRecord] = chargers.filter((charger) => charger.serialNumber === previousRecordName)
    const { configurationTemplate } = previousRecord
    setLoading(true)
    ModifyChargerConfigTemplate(configurationTemplate, serialNumber)
      .then((resp) => {
        message.success(resp.message)
        if (
          (Array.isArray(resp.errors) && resp.errors.length > 0) ||
          (Array.isArray(resp.warnings) && resp.warnings.length > 0)
        ) {
          setError(
            new ApiError(
              'Something went wrong with this request. Please contact customer support if this issue persists.',
              resp.errors,
              resp.warnings,
            ),
          )
        }
        setTerm(`${_.random(Number.MIN_VALUE, Number.MAX_VALUE)}`)
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const updateSelectedSerialNumbers = () => {
    let obj: UpdateBase[] = []

    for (const element of selectedRowKeys) {
      obj.push({ id: String(element) })
    }

    setSerialNumbers(obj)
  }

  const handleUpdateAndRestartBtn = () => {
    updateSelectedSerialNumbers()
    setUpdateAndRestartBtnClicked(!updateAndRestartBtnClicked)
  }

  const handleVerifyBtn = () => {
    updateSelectedSerialNumbers()
    setVerifyBtnEnabledClicked(!verifyBtnEnabledClicked)
  }

  const handleCommissionBtn = () => {
    updateSelectedSerialNumbers()
    setCommissionBtnEnabledClicked(!commissionBtnEnabledClicked)
  }

  const showChargerErrors = (charger: Charger) => {
    setChargerErrorsModalVisible(!chargerErrorsModalVisible)
  }

  // const onExpand = (expanded: boolean, record: Charger) => {
  //   setExpandableRowKeys({ ...expandableRowKeys, [record.serialNumber]: expanded })
  // }

  // const rowExpandable = (record: Charger) => {
  //   if (Object.keys(expandableRowKeys).includes(`${record.serialNumber}`)) return true
  //   const rowElement = document.querySelector(`[data-row-key="${record.serialNumber}"]`)
  //   const currentRowHeight = (rowElement as HTMLElement)?.offsetHeight
  //   const childElements = rowElement?.querySelectorAll('.custom-row-collapse > td > *')

  //   let expandable = false

  //   childElements?.forEach((el: Element) => {
  //     expandable = expandable || el.scrollHeight > currentRowHeight
  //   })

  //   return expandable
  // }

  const columns: ColumnsType<Charger> = [
    {
      title: <Tooltip title="Connectivity">{networkSVG}</Tooltip>,
      key: 'connectivity',
      align: 'right' as 'right',
      width: 50,
      render: ({ connectionStatus }: Charger) => handleConnectivityBadge(connectionStatus.status),
    },
    {
      title: 'Status',
      dataIndex: ['configurationState', 'state'],
      render: (state: string, record: Charger) => {
        return (
          <div className="flex-row">
            {handleConfigurationStatus(state)}
            {record.errors.length !== 0 ? (
              <span
                onClick={() => {
                  setCharger(record)
                  showChargerErrors(record)
                }}
              >
                <Tooltip title="Error">
                  {' '}
                  <Badge
                    count={<ExclamationCircleOutlined className="clickable" />}
                    showZero
                    style={{ color: theme.colors.red, marginBottom: '0.2rem', fontSize: '1.5rem' }}
                    className="clickable"
                  />
                </Tooltip>
              </span>
            ) : null}
          </div>
        )
      },
    },
    {
      title: 'Charger Serial #',
      key: 'serialNumber',
      render: (charger: Charger) =>
        // <Typography.Text style={{ width: 125 }} ellipsis={{ tooltip: charger.serialNumber }}>
        //   {charger.serialNumber}
        // </Typography.Text>
        charger.serialNumber,
    },
    {
      title: 'Make & Model',
      key: 'makeAndModel',
      render: (charger: Charger) => {
        return (
          <div className="flex-row">
            <div className="flex-and-gap-below">
              <div>
                {charger.info.vendor}{' '}
                {charger.configurationTemplate.template === null ? null : (
                  <span
                    onClick={() => {
                      setCharger(charger)
                      setChargerEditInfoModalVisible(!chargerEditInfoModalVisible)
                    }}
                  >
                    <Tooltip title="Configuration Template">
                      {' '}
                      <Badge
                        count={<FileTextOutlined />}
                        showZero
                        style={{ color: theme.colors.gray, marginBottom: '0.2rem', fontSize: '1.5rem' }}
                        className="clickable"
                      />
                    </Tooltip>
                  </span>
                )}
              </div>
              <span className="paragraph-04-light opacity-06">{charger.info.model}</span>
              <div></div>
            </div>
          </div>
        )
      },
    },
    {
      title: 'Connected Date',
      key: 'connectedDate',
      sorter: (a: Charger, b: Charger) => a.info.updatedAt.localeCompare(b.info.updatedAt),
      render: (charger: Charger) => <DateTimeV2 date={charger.info.updatedAt} timezone="America/Toronto" />,
    },
    {
      title: 'Updated On',
      dataIndex: ['configurationTemplate', 'updatedAt'],
      sorter: (a: Charger, b: Charger) =>
        a.configurationTemplate.updatedAt.localeCompare(b.configurationTemplate.updatedAt),
      render: (date: string) => <DateTimeV2 date={date} />,
    },
    { title: 'Firmware', dataIndex: ['info', 'firmwareVersion'] },
    {
      title: 'Site',
      key: 'tenant',
      render: (charger: Charger) =>
        // <Typography.Text style={{ width: 175 }} ellipsis={{ tooltip: charger.configurationTemplate.tenant }}>
        //   {charger.configurationTemplate.tenant}
        // </Typography.Text>
        charger.configurationTemplate.tenant,
    },
    // ! This is commented out because it is not used in the current implementation
    // {
    //   title: 'Listing',
    //   key: 'listing',
    //   width: '8%',
    //   render: (charger: Charger) => (
    //     <Space wrap>
    //       {charger.listings?.map((listing) => (
    //         <CoreBadge key={listing.id} title={listing.title} id={listing.id} />
    //       ))}
    //     </Space>
    //   ),
    // },
    // Table.EXPAND_COLUMN,
    {
      title: 'Commissioned By',
      dataIndex: ['configurationTemplate', 'updatedBy'],
    },
    // ! This is commented out because it is not used in the current implementation
    // {
    //   title: '',
    //   key: 'action',
    //   render: (charger: Charger) => <Button onClick={() => setIsDrawerOpen(true)}>Action</Button>,
    // },
    {
      title: 'Actions',
      key: 'action',
      align: 'right',
      fixed: 'right',
      // width: '7%',
      //   render: (item: any, charger: Charger, chargerIndex: number) => (
      //     <Actions
      //       charger={charger}
      //       chargerIndex={chargerIndex}
      //       onSerialNumberIndices={(index: number, record: Charger) => handleTableChange(index, record)}
      //       onClickGetConfigurationBtn={(record: Charger) => setShowChargerConfig(record)}
      //       onClickEditBtn={(record: Charger) => {
      //         setCharger(record)
      //         setChargerEditInfoModalVisible(!chargerEditInfoModalVisible)
      //       }}
      //       onClickCopyAboveBtn={(index: number, record: Charger) => {
      //         // handleTableChange(index, record)
      //         handleCopyAboveBtn(index, record.serialNumber)
      //       }}
      //       onClickDeleteBtn={(record: Charger) => {
      //         setCharger(record)
      //         setDeleteChargerModalVisible(!deleteChargerModalVisible)
      //       }}
      //     />
      //   ),
      // },
      // {
      //   title: 'Actions',
      render: (item: any, record: Charger, index: number) => {
        handleTableChange(index, record)
        return (
          <>
            <Space>
              <Tooltip title="Get Configuration">
                <Button
                  shape="circle"
                  className="config-btn"
                  icon={<SettingFilled />}
                  disabled={record.connectionStatus.status === 'disconnected'}
                  onClick={() => setShowChargerConfig(record)}
                />
              </Tooltip>
              <Tooltip title="Delete Charger">
                <Button
                  shape="circle"
                  className="delete-btn"
                  icon={<FontAwesomeIcon icon={faTrash} size="lg" color={theme.colors.red} />}
                  onClick={() => {
                    setCharger(record)
                    setDeleteChargerModalVisible(!deleteChargerModalVisible)
                  }}
                />
              </Tooltip>
            </Space>
          </>
        )
      },
    },
  ]

  const handleTableChange = (index: number, record: Charger) => {
    serialNumberIndices[index] = record.serialNumber
  }

  const handleOk = () => {
    setIsCreateTenantModalOpen(false)
  }

  const handleCancel = () => {
    setIsCreateTenantModalOpen(false)
  }

  const operations = (
    <Space>
      <Button type="primary" onClick={() => setIsCreateTenantModalOpen(!isCreateTenantModalOpen)}>
        Create Site
      </Button>
      <Button type="primary" onClick={() => setIsCreateChargerModelOpen(!isCreateTenantModalOpen)}>
        Create Charger Model
      </Button>
      <LineBreak />
      <Button
        type="primary"
        block
        disabled={!commissionBtnEnabled}
        onClick={() => setShowCommissionNewListingsModal(true)}
      >
        Commission
      </Button>
    </Space>
  )

  const handleMaxTagPlaceholder = () => {
    return filter.charger_serial_numbers.length === 1
      ? filter.charger_serial_numbers[0]
      : `${filter.charger_serial_numbers.length} serial numbers inputted`
  }

  return (
    <>
      {signatureExpired === 'true' ? (
        <>
          <SessionExpiredResult />
          <Spin size="large">
            {log('Session Expired. Logging out the user and redirecting to the auth login page')}
            {setTimeout(() => {
              clearSession()
              auth0Obj.logout({})
              window.location.replace(Links.auth0login())
            }, 5000)}
          </Spin>
        </>
      ) : (
        <>
          <AlertError error={error} closable={true} />
          <AlertWarning error={error} closable={true} />

          {canReadChargerEntries ? (
            <BackgroundContainer>
              <PageHeader
                style={{ position: 'sticky', top: 0, zIndex: 6 }}
                title={
                  isMonitor || isDesktop || isLaptop ? (
                    <ChargerSelectors
                      loading={loading}
                      filter={filter}
                      setFilter={setFilter}
                      getData={getData}
                      handleMaxTagPlaceholder={handleMaxTagPlaceholder}
                    />
                  ) : (
                    <HomeSelectors
                      loading={loading}
                      filter={filter}
                      setFilter={setFilter}
                      getData={getData}
                      handleMaxTagPlaceholder={handleMaxTagPlaceholder}
                    />
                  )
                }
                extra={
                  isMonitor || isDesktop || isLaptop ? (
                    operations
                  ) : (
                    <MobileExtraOptions
                      onCreateTenantModalOpen={() => setIsCreateTenantModalOpen(true)}
                      onCreateChargerModelTemplateOpen={() => setIsCreateChargerModelOpen(true)}
                      commissionBtnEnabled={commissionBtnEnabled}
                      onCommissionChargers={() => setShowCommissionNewListingsModal(true)}
                    />
                  )
                }
              />
              <InfoMessage
                text="charger"
                loading={loading}
                totalEntries={chargers.length}
                showSearchQuery={showSearchQuery}
              />
              {(isMonitor || isDesktop || isLaptop || isMobile) && (
                <Table
                  sticky
                  loading={loading}
                  dataSource={chargers}
                  columns={columns}
                  rowKey="serialNumber"
                  pagination={false}
                  rowSelection={rowSelection}
                  scroll={{ x: 1300 }}
                />
              )}
              {isTablet && (
                <ChargerCardView
                  chargers={chargers}
                  selectedChargers={selectedChargers}
                  onSerialNumberIndices={(index: number, record: Charger) =>
                    (serialNumberIndices[index] = record.serialNumber)
                  }
                  onClickErrorsBtn={(record: Charger) => {
                    setCharger(record)
                    showChargerErrors(record)
                  }}
                  onClickGetConfigurationBtn={(record: Charger) => setShowChargerConfig(record)}
                  onClickEditBtn={(record: Charger) => {
                    setCharger(record)
                    setChargerEditInfoModalVisible(!chargerEditInfoModalVisible)
                  }}
                  onClickDeleteBtn={(record: Charger) => {
                    setCharger(record)
                    setDeleteChargerModalVisible(!deleteChargerModalVisible)
                  }}
                  onSelectedChargers={(newChargers) => handleSelectedChargers(newChargers)}
                />
              )}
              {/*
              {(isMobile || isTablet) && (
                <ChargerMobileView
                  chargers={chargers}
                  environments={environments}
                  templates={templates}
                  loading={loading}
                  onSerialNumberIndices={(index: number, record: Charger) =>
                    (serialNumberIndices[index] = record.serialNumber)
                  }
                  onClickErrorsBtn={(record: Charger) => {
                    setCharger(record)
                    showChargerErrors(record)
                  }}
                  onClickGetConfigurationBtn={(record: Charger) => setShowChargerConfig(record)}
                  onCopyAboveBtn={(index: number, record: Charger) => {
                    handleCopyAboveBtn(index, record.serialNumber)
                  }}
                  onClickEditBtn={(record: Charger) => {
                    setCharger(record)
                    setChargerEditInfoModalVisible(!chargerEditInfoModalVisible)
                  }}
                  onClickDeleteBtn={(record: Charger) => {
                    setCharger(record)
                    setDeleteChargerModalVisible(!deleteChargerModalVisible)
                  }}
                />
              )} */}

              {charger && (
                <EditChargerConfigurationTemplateModal
                  visible={chargerEditInfoModalVisible}
                  onCancel={() => setChargerEditInfoModalVisible(!chargerEditInfoModalVisible)}
                  onSubmit={() => {
                    setChargerEditInfoModalVisible(!chargerEditInfoModalVisible)
                    setTerm(`${_.random(Number.MIN_VALUE, Number.MAX_VALUE)}`)
                  }}
                  record={charger}
                  environments={environments}
                  templates={templates}
                />
              )}
              {chargerUpdateAndRestartModalVisible && (
                <UpdateAndRestartChargerModal
                  visible={chargerUpdateAndRestartModalVisible}
                  onCancel={() => setChargerUpdateAndRestartModalVisible(!chargerUpdateAndRestartModalVisible)}
                  onSubmit={() => {
                    setChargerUpdateAndRestartModalVisible(!chargerUpdateAndRestartModalVisible)
                    setTerm(`${_.random(Number.MIN_VALUE, Number.MAX_VALUE)}`)
                  }}
                  chargers={chargers}
                />
              )}
              {charger && (
                <ChargerErrorsModal
                  visible={chargerErrorsModalVisible}
                  onCancel={() => setChargerErrorsModalVisible(!chargerErrorsModalVisible)}
                  charger={charger}
                />
              )}
              {charger && deleteChargerModalVisible && (
                <DeleteModal
                  visible={deleteChargerModalVisible}
                  onCancel={() => setDeleteChargerModalVisible(!deleteChargerModalVisible)}
                  record={charger}
                  onOk={() => {
                    handleDeleteCharger(charger)
                    setDeleteChargerModalVisible(!deleteChargerModalVisible)
                  }}
                />
              )}
            </BackgroundContainer>
          ) : (
            <UnauthorizedResult />
          )}

          {isCreateTenantModalOpen && (
            <CreateTenantModal onOk={handleOk} onCancel={handleCancel} isModalOpen={isCreateTenantModalOpen} />
          )}
          {isCreateChargerModelOpen && (
            <CreateChargerModelModal
              isModalOpen={isCreateChargerModelOpen}
              onCancel={() => setIsCreateChargerModelOpen(!isCreateChargerModelOpen)}
              onOk={() => setIsCreateChargerModelOpen(!isCreateChargerModelOpen)}
            />
          )}
          {showCommissionNewListingsModal && (
            <CommissionNewListingsModal
              chargers={selectedChargers}
              environments={environments}
              onCancel={() => setShowCommissionNewListingsModal(false)}
              isModalOpen={showCommissionNewListingsModal}
              onOk={() => setShowCommissionNewListingsModal(false)}
            />
          )}
          {showChargerConfig && (
            <ShowChargerConfigModal charger={showChargerConfig} onClose={() => setShowChargerConfig(undefined)} />
          )}
          <Drawer title="Basic Drawer" placement="right" onClose={() => setIsDrawerOpen(false)} visible={isDrawerOpen}>
            <p>Delete charger button and info here</p>
            <p>Copy charger to another charger here</p>
            <p>Edit charger's configuration</p>
            <p>See errors</p>
          </Drawer>
        </>
      )}
    </>
  )
}

export const DashboardPage = withAuthenticatedLayout(DashboardBasePage)
