// Import the 'theme' object from the specified location
import { theme } from '../../theme'

// Defines an SVG component for a pie chart icon
export const pieChartSVG = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.16699 1.70835V10.8333H18.292C17.8745 15.0442 14.3212 18.3333 10.0003 18.3333C5.39783 18.3333 1.66699 14.6025 1.66699 10C1.66699 5.67918 4.95616 2.12585 9.16699 1.70835ZM10.8337 0.452515C15.4612 0.850015 19.1495 4.53918 19.5478 9.16668H10.8337V0.452515Z"
      fill={theme.colors.primary}
    />
  </svg>
)
