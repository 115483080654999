import { ChargerRef } from '../models/charger'
import { ListingRef } from '../models/listing'

export const mockListingsRef: ListingRef[] = [
  { id: 1, title: 'FR01', timezone: 'America/New_York' },
  { id: 2, title: 'FR02', timezone: 'Asia/Tokyo' },
  { id: 3, title: 'DN01', timezone: 'Europe/Vienna' },
  { id: 4, title: 'DN02', timezone: 'Europe/Monaco' },
  { id: 5, title: 'DN03', timezone: 'Europe/Paris' },
  { id: 6, title: 'DN04', timezone: 'Europe/Berlin' },
  { id: 7, title: 'DN05', timezone: 'Europe/London' },
  { id: 8, title: 'DN06', timezone: 'Europe/Madrid' },
  { id: 9, title: 'DN07', timezone: 'Europe/Amsterdam' },
  { id: 10, title: 'DN08', timezone: 'Europe/Brussels' },
  { id: 11, title: 'DN09', timezone: 'Australia/Victoria' },
]

export const mockChargerRef: { [serialNumber: string]: ChargerRef } = {
  '5SW1-1-2147-00081': {
    id: 1,
    chargePointSerialNumber: '5SW1-1-2147-00081',
  },
  'SWTCH-0001': {
    id: 2,
    chargePointSerialNumber: 'SWTCH-0001',
  },
}
