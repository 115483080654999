import { addQueryString } from '../../helpers/url'
import { log } from '../../logger'
import { TenantResponse } from '../../models/http'
import { apiLoadMgmtClient, apiRailsClient } from './client'
import configProvider from '../../config'
import { mockTenantResponse } from '../../mock/tenants'
import { CreateTenantRequest, TenantRef } from '../../models/tenant'
import { mockTenantsRef, returnMock } from './mock'
import CaseConverter from './case-converter'

export async function GetTenants(): Promise<TenantResponse> {
  let url = '/tenants'

  url = addQueryString(url, {})

  log('fetching tenants', { url: url })
  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockTenantResponse)
  }
  return apiRailsClient(url, {})
}

export async function FindTenants(term?: string): Promise<TenantRef[]> {
  let url = '/tenants/find'

  if (term) {
    let queryParams: { [key: string]: string } = {
      term: `${term}`,
    }
    url = addQueryString(url, queryParams)
  }

  log('fetching tenants', { url: url })
  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockTenantsRef)
  }
  return apiRailsClient(url, {})
}

export async function GetLoadManagementTenants(): Promise<TenantResponse> {
  let url = '/tenant/'

  log('fetching tenants', { url: url })
  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockTenantResponse)
  }
  return apiLoadMgmtClient(url, {
    method: 'GET',
    headers: {
      'api-key': configProvider.config.loadManagementApiKey,
      'Access-Control-Allow-Origin': configProvider.config.loadManagement,
      Referer: configProvider.config.loadManagement,
      Origin: configProvider.config.loadManagement,
    },
  })
}

export async function CreateTenant(data: CreateTenantRequest): Promise<TenantRef> {
  console.log('🚀 ~ CreateTenant ~ data:', data)
  const url = '/tenants'

  const snakeCasedTenantModel = CaseConverter.camelToSnakeCase(data)
  const newKeys = { location: 'location_attributes', weekly_schedules: 'weekly_schedules_attributes' }
  const updatedTenantModel = CaseConverter.renameKeys(snakeCasedTenantModel, newKeys)

  log('creating tenant', { url, data })
  if (configProvider.config.mockEnabled) {
    return returnMock({ ...updatedTenantModel, id: 100 })
  }
  return apiRailsClient(url, {
    method: 'POST',
    body: JSON.stringify(updatedTenantModel),
  })
}
