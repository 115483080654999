import { JWTIssue, JWTIssuev2 } from '../../models/auth'
import { User } from '../../models/user'

export const returnMock = <T>(obj: T): Promise<T> => {
  return new Promise<T>((resolve) =>
    setTimeout(() => {
      return resolve(obj)
    }, 2000),
  )
}

export const mockAdminJwt = {
  token:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiIxIiwiaXNzIjoic3d0Y2giLCJhIjp0cnVlLCJleHAiOjE1MTYyMzkwMjJ9.6K-fLr5P-tBn2Ksm4kyuDh0JIX0VvwoovsfqkPjSrrk',
  expiresAt: 1516239022,
} as JWTIssue

export const mockAdminJwt2 = {
  accessToken:
    'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ik16QkRSRE16UVVRMk1UazNRVGRDUTBVNU1UQXhSVFE0UVRZNVFrUTFNRFU1TVVReE1qUTJOUSJ9.eyJpc3MiOiJodHRwczovL2xvZ2luLnN3dGNoZW5lcmd5LmNvbS8iLCJzdWIiOiJnb29nbGUtb2F1dGgyfDEwODc5NjkyMDk2NDYxMjMyMTk4NSIsImF1ZCI6WyJodHRwczovL3N0YWdpbmcuY29tbWlzc2lvbmluZy5zd3RjaGVuZXJneS5jb20vIiwiaHR0cHM6Ly9zd3RjaC1wcm9kLmF1dGgwLmNvbS91c2VyaW5mbyJdLCJpYXQiOjE2ODEwMDQ1MzksImV4cCI6MTY4MTAxMTczOSwiYXpwIjoib1paM0x4YzFGMWs3WG5IYVJlelNFN1N3Q2diR0RxRmkiLCJzY29wZSI6Im9wZW5pZCBlbWFpbCIsInBlcm1pc3Npb25zIjpbImRlcGxveW1lbnQ6Y2hhcmdlci5jb21taXNzaW9uIiwiZGVwbG95bWVudDpjaGFyZ2VyX2VudHJpZXMuY3JlYXRlIiwiZGVwbG95bWVudDpjaGFyZ2VyX2VudHJpZXMuZGVsZXRlIiwiZGVwbG95bWVudDpjaGFyZ2VyX2VudHJpZXMucmVhZCIsImRlcGxveW1lbnQ6Y2hhcmdlcl9lbnRyaWVzLnVwZGF0ZSIsImRlcGxveW1lbnQ6Y2hhcmdlcl9pbmZvLmNyZWF0ZSIsImRlcGxveW1lbnQ6Y2hhcmdlcl9pbmZvLmRlbGV0ZSIsImRlcGxveW1lbnQ6Y2hhcmdlcl9pbmZvLnJlYWQiLCJkZXBsb3ltZW50OmNoYXJnZXJfaW5mby51cGRhdGUiLCJkZXBsb3ltZW50OmNoYXJnZXIudXBkYXRlIiwiZGVwbG95bWVudDpjaGFyZ2VyLnZlcmlmeSIsImRlcGxveW1lbnQ6Y29uZmlnX2tleV9tYXBwaW5ncy5jcmVhdGUiLCJkZXBsb3ltZW50OmNvbmZpZ19rZXlfbWFwcGluZ3MuZGVsZXRlIiwiZGVwbG95bWVudDpjb25maWdfa2V5X21hcHBpbmdzLnJlYWQiLCJkZXBsb3ltZW50OmNvbmZpZ19rZXlfbWFwcGluZ3MudXBkYXRlIiwiZGVwbG95bWVudDplbnZpcm9ubWVudHMuY3JlYXRlIiwiZGVwbG95bWVudDplbnZpcm9ubWVudHMuZGVsZXRlIiwiZGVwbG95bWVudDplbnZpcm9ubWVudHMucmVhZCIsImRlcGxveW1lbnQ6ZW52aXJvbm1lbnRzLnVwZGF0ZSIsImRlcGxveW1lbnQ6dGVtcGxhdGVzLmNyZWF0ZSIsImRlcGxveW1lbnQ6dGVtcGxhdGVzLmRlbGV0ZSIsImRlcGxveW1lbnQ6dGVtcGxhdGVzLnJlYWQiLCJkZXBsb3ltZW50OnRlbXBsYXRlcy51cGRhdGUiXX0.cGOZ2ftwGL5g2LdrOnlrDLkJ7fN0ZfR6t68zNHB1RHO31bV8Jiz_cnI6SAplfu8JRmkFbNTYvcbpiACpo7ejVdkPY7I2OqPefY2_MF2q0xusWzjE8il4Qy6pkRasnQ6mOndDPhsfpZZnnt6i76NH5BX-caLwTrI376FnZg4S10FiH94P5bJuoOTKkC1odb-ih6dqyIdlkIMp6V8GjGxYAUN0WCz9xLUiUAt1vKuhVltT35SLBZGLD4iUlr3bXaOv0k2HuMkKINc1917p5VkGzZWEr3Grro7UAVT1nGw51kJOdeaaZMD4H7YPLLrOLZ7fuw9imlyGn62Z-1pSa4P38w',
  token:
    'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ik16QkRSRE16UVVRMk1UazNRVGRDUTBVNU1UQXhSVFE0UVRZNVFrUTFNRFU1TVVReE1qUTJOUSJ9.eyJlbWFpbCI6InN1bGVtYW4ucmFpQHN3dGNoZW5lcmd5LmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJpc3MiOiJodHRwczovL2xvZ2luLnN3dGNoZW5lcmd5LmNvbS8iLCJhdWQiOiJvWlozTHhjMUYxazdYbkhhUmV6U0U3U3dDZ2JHRHFGaSIsImlhdCI6MTY4MTAwNDUzOSwiZXhwIjoxNjgxMDQwNTM5LCJzdWIiOiJnb29nbGUtb2F1dGgyfDEwODc5NjkyMDk2NDYxMjMyMTk4NSIsImF0X2hhc2giOiJYSjdLS1VpN1M3cWZ0bXFGTmU2T01BIiwic2lkIjoiVnhrQm5Xbmo5b1I5djB3Y1c3U2FzZTc1cGhvYkFWbUgiLCJub25jZSI6InVPZm9RazVGMUdQMnRNbkR3bUR6Z1JpT0xVSXJpNmh4In0.f6tZFh2TAd0E-Nqop38VmBBQQXPeCALZqha4fdcaq1yKH7xUY1wius85WEst2y4VzZNr_Dh0Gw9mfqTGlihaT_vIz5T798Xy8SJrDN8NT48yAlxEPDfRExNAq9PeF2ZIVW15jWeEYpcpqNJziL_fBDLwmMmXkCQU1k-xaCGsReJrHeeR-yOQC-IOKhHcZ5VaSgYMxp3xE6ZfVikRXNCuTQSFjQQMn-5bxPFhqmXUqPa5yFa1ZXUAaDhs4k0RRIGzASu5m_GAv4czY0BBZTtoKs6KAXfDm9_ZWQPytah8deVGqD7YJUZdOtti_63sb_3ZpD-xZY8ntf_rnd2yzheZhg',
  expiresAt: 7200,
} as JWTIssuev2

export const mockSelf = {
  id: 1,
  name: 'Lan Phan',
  email: 'lan.phan@gmail.com',
  role: 'admin',
  auth0_uid: 'google-oauth2|108796920964612321985',
} as User

export const mockTenantsRef = [
  {
    id: '8',
    name: 'swtchhq',
    displayName: 'test_swtch_hq',
  },
  { id: '2', name: 'lan-fake-tenant', displayName: 'lan-fake-tenant-display-name', chargerCount: 15 },
  { id: '3', name: '123-lake-street', displayName: '', chargerCount: 28 },
  { id: '4', name: '83-beaver-hall', displayName: 'beaver hall', chargerCount: 73 },
  { id: '5', name: '9-GreatNorth-Rd-ParrySound-ON-P2A2X8', displayName: 'Green North Road', chargerCount: 7 },
]
