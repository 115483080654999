// Defines an SVG component for a magnify icon with a grey stroke
export const magnifySVG = (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="mangifyIcon"
  >
    <path
      d="M10.9951 11.08L13.6 13.6M12.76 6.88C12.76 10.1274 10.1274 12.76 6.88 12.76C3.63257 12.76 1 10.1274 1 6.88C1 3.63257 3.63257 1 6.88 1C10.1274 1 12.76 3.63257 12.76 6.88Z"
      stroke='#7D838A'
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)

// Defines an SVG component for a magnify icon with a grey stroke (alternative)
export const magnifyBlackSVG = (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.9951 11.08L13.6 13.6M12.76 6.88C12.76 10.1274 10.1274 12.76 6.88 12.76C3.63257 12.76 1 10.1274 1 6.88C1 3.63257 3.63257 1 6.88 1C10.1274 1 12.76 3.63257 12.76 6.88Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)
