import { Modal, Typography } from 'antd'

import commissionChargerTechnicianImage from '../../assets/images/commission_charger_technican.png'

import { useEffect, useState } from 'react'

interface props {
  isModalVisible: boolean
  onOk: () => void
}

const CommissionLoading: React.FC<props> = ({ isModalVisible, onOk }) => {
  const [dotsCount, setDotsCount] = useState(1)

  useEffect(() => {
    const interval = setInterval(() => {
      setDotsCount((prevCount) => (prevCount === 3 ? 1 : prevCount + 1))
    }, 500) // Adjust the interval as needed (500ms here for half-second intervals)

    return () => clearInterval(interval)
  }, [])

  return (
    <>
      <Modal visible={isModalVisible} onOk={onOk} onCancel={onOk} footer={null} closable={false} style={{ top: 10 }}>
        <div>
          <Typography.Title
            level={4}
            style={{
              textAlign: 'center',
              display: 'inline-block',
              minWidth: '200px', // Adjust this width as needed to fit your text
              marginLeft: '8rem', // Adjust this margin as needed to center your text
            }}
          >
            Preparing to commission listing(s){Array(dotsCount).fill('.').join('')}
          </Typography.Title>

          <img
            src={commissionChargerTechnicianImage}
            alt="Technician commissioning charger"
            style={{ width: '50%', display: 'block', margin: 'auto' }}
          />
        </div>
      </Modal>
    </>
  )
}

export default CommissionLoading
