import { Image, Skeleton, Space } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'

import { ChargerModelTemplate, ChargerModelMetadata } from '../../../models/charger-model-template'

import CoreBadge from '../../../components/badge/Badge'
import { theme } from '../../../theme'

const { green, red, underRepair, dangerPrimary, magicMint, viridian } = theme.colors

export const ChargerModelsColumn = (metadata?: ChargerModelMetadata) => {
  if (!metadata) return []

  const columns: ColumnsType<ChargerModelTemplate> = [
    {
      title: 'Charger Model Template Name',
      dataIndex: 'name',
      key: 'name',
    },

    {
      title: 'Make & Model',
      key: 'makeAndModel',
      render: ({ make, model }: ChargerModelTemplate) => {
        return (
          <div className="flex-and-gap-below">
            <div>{make}</div>
            <span className="paragraph-04-light opacity-06">{model}</span>
          </div>
        )
      },
    },
    {
      title: 'Charging & Connector Type',
      key: 'charging_and_connector_type',
      render: ({ charging_type, connector_type_id, total_connectors }: ChargerModelTemplate) => (
        <div className="flex-and-gap-below">
          <div>{charging_type}</div>
          <span className="paragraph-04-light opacity-06">
            {total_connectors} x {metadata?.connector_type.find((type) => type.id === connector_type_id)?.name}
          </span>
        </div>
      ),
    },
    {
      title: 'Cable Length',
      key: 'cable_length',
      render: ({ cable_length, cable_length_unit }: ChargerModelTemplate) => (
        <>
          {cable_length} {cable_length_unit}
        </>
      ),
    },
    {
      title: 'Power & Max. Output',
      key: 'power_and_max_output',
      render: ({ rated_power, rated_power_unit, rated_current, rated_current_unit }: ChargerModelTemplate) => (
        <div className="flex-and-gap-below">
          <div>
            {rated_power} {rated_power_unit}
          </div>
          <span className="paragraph-04-light opacity-06">
            {rated_current} {rated_current_unit}
          </span>
        </div>
      ),
    },
    {
      title: 'Display',
      dataIndex: 'display_size',
    },
    { title: 'Support POS', dataIndex: 'support_pos' },
    {
      title: 'Holster',
      dataIndex: 'has_built_in_holster',
      align: 'center',
      render: (has_built_in_holster: boolean) =>
        has_built_in_holster ? (
          <CheckCircleFilled style={{ color: green }} />
        ) : (
          <CloseCircleFilled style={{ color: red }} />
        ),
    },
    {
      title: 'RFID',
      key: 'support_RFID',
      align: 'center',
      render: ({ support_RFID }: ChargerModelTemplate) =>
        support_RFID ? <CheckCircleFilled style={{ color: green }} /> : <CloseCircleFilled style={{ color: red }} />,
    },
    {
      title: 'ISO 15118 Standard',
      key: 'iso15118Standard',
      render: ({ support_ISO15118_2, support_ISO15118_20 }: ChargerModelTemplate) => {
        const badges = [
          { support: support_ISO15118_2, text: 'ISO 15118-2' },
          { support: support_ISO15118_20, text: 'ISO 15118-20' },
        ]

        return (
          <div className="flex-and-gap-below">
            <Space wrap>
              {badges.map(({ support, text }) => (
                <CoreBadge
                  key={text}
                  text={text}
                  backgroundColor={support ? magicMint : underRepair}
                  color={support ? viridian : dangerPrimary}
                />
              ))}
            </Space>
          </div>
        )
      },
    },
    {
      title: 'Connectivity ',
      key: 'connectivity',
      render: ({ support_wifi, support_4G, support_WiSun }: ChargerModelTemplate) => {
        const connectivity = [
          { support: support_wifi, text: 'WiFi' },
          { support: support_4G, text: '4G' },
          { support: support_WiSun, text: 'WiSun' },
        ]

        return (
          <div className="flex-and-gap-below">
            <Space wrap>
              {connectivity.map(({ support, text }) => (
                <CoreBadge
                  key={text}
                  text={text}
                  backgroundColor={support ? magicMint : underRepair}
                  color={support ? viridian : dangerPrimary}
                />
              ))}
            </Space>
          </div>
        )
      },
    },
    {
      title: 'Image',
      dataIndex: 'image_url',
      align: 'center',
      render: (image_url: string) => (
        <Image
          src={image_url}
          alt="Charger Model Template Image"
          style={{ width: 50, height: 50, objectFit: 'cover' }} // Adjust the size as needed
          onError={(e) => {
            if (e) {
              e.currentTarget.alt = 'Image not found'
              return <Skeleton.Image style={{ width: 50, height: 50, objectFit: 'cover' }} /> // Fallback image if there's an error
            }
          }}
        />
      ),
    },
  ]
  return columns
}
