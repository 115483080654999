import { Col, List, Modal, Row } from 'antd'

import Item from 'antd/lib/list/Item'
import { Template } from '../../../models/template'

interface props {
  visible: boolean
  templateObj: Template
  onCancel(): void
}

export const ViewTemplatesModal: React.FC<props> = ({ visible, templateObj, onCancel }) => {
  const { template } = templateObj

  const renderTemplate = () => {
    const result: any = []
    for (const key in template) {
      result.push(
        <Item key={key}>
          <Item.Meta description={`value : ${template[key]}`} title={`key : ${key}`}></Item.Meta>
        </Item>,
      )
    }
    return result
  }

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col md={24} lg={24} xl={24} xxl={24} style={{ textAlign: 'end' }}>
          <Modal title={`${templateObj.name}`} visible={visible} footer={null} onCancel={onCancel}>
            <List grid={{ column: 2 }}>{renderTemplate()}</List>
          </Modal>
        </Col>
      </Row>
    </>
  )
}
