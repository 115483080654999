import { Alert } from 'antd'
import React from 'react'
import { AlertBoxprops } from '../models/error'

// AlertWarning component
export const AlertWarning: React.FC<AlertBoxprops> = ({ error, closable }) => {
  if (!error) {
    return <></>
  }

  // Function to generate the description element
  const description = () => {
    return (
      <ul>
        {error.warnings.map((warning, index) => {
          return <li key={index}>{warning}</li>
        })}
      </ul>
    )
  }

  // Render Alert component with warnings if there are any
  return (
    <>
      {error && error.warnings && error.warnings.length > 0 && (
        <Alert closable={closable} showIcon description={description()} type="warning" />
      )}
    </>
  )
}
