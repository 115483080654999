import React from 'react'
import moment from 'moment' // Library for working with dates and times
import { Col, Layout, Row, Typography } from 'antd' // Ant Design components

const { Footer } = Layout

export const AppFooter: React.FC = () => {
  return (
    <Footer
      style={{
        padding: '10px',
      }}
    >
      <Row justify="end">
        <Col>
          <Typography.Text type={'secondary'}>
            <small>
              Powered by <strong>SWTCH</strong> &copy; {moment().format('YYYY')}
            </small>
          </Typography.Text>
        </Col>
      </Row>
    </Footer>
  )
}
