import React, { useState } from 'react'
import { Dropdown, Switch, Table } from 'antd'
import { Circuit, PanelType } from '../../../../models/circuit'
import {
  MoreButtonWrapper,
  MoreDropdownWrapper,
  TableHeadingNoteWrapper,
  TableHeadingWrapper,
  TableWrapper,
  TitleWrapper,
} from '../../../../atom/load-management/load-management'
import { greyPlusSVG } from '../../../../assets/svg/plus'
import { greyEditSVG } from '../../../../assets/svg/edit'
import { trashCanSVG } from '../../../../assets/svg/trashCan'
import { useCircuits } from '../../../../hooks/useCircuits'
import { EditPanelModal } from '../modal/edit-panel'
import { ServiceStatusBadge } from '../../../../components/status-badge'
import { useSwtchControlPermissions } from '../../../panel-overview/useSwtchControlPermissions'

interface props {
  circuit: Circuit
  circuits: Circuit[]
  initialBreakerSizeLimit: boolean
  panelIndexKey: number
  setCircuits: (circuits: Circuit[]) => void
  removePanel: (type: string, circuit: Circuit) => void
  setBreakerSizeLimit: (breakerSizeLimit: number) => void
  setBreakerLimitWarning: (breakerLimitWarning: boolean) => void
  addBranch: (circuit: Circuit, type: string, breakerLimitWarning: boolean) => void
}

export const PanelHeading: React.FC<props> = ({
  circuit,
  circuits,
  panelIndexKey,
  initialBreakerSizeLimit,
  setCircuits,
  removePanel,
  setBreakerSizeLimit,
  setBreakerLimitWarning,
  addBranch,
}) => {
  const [verticalMoreButton, setVerticalMoreButton] = useState(false)
  const ref = React.createRef()
  const {
    panelTypeModal,
    editPanelModalVisible,
    modalData,
    handleEditPanelSubmit,
    editPanel,
    cancelEdit,
    setPanelTypeModal,
    limitBreakerSizeDedicated,
  } = useCircuits()
  const { canUpdateSwtchControl } = useSwtchControlPermissions()

  const addingBranch = (circuit: Circuit, type: string) => {
    const breakerLimit = limitBreakerSizeDedicated(circuit)
    setBreakerSizeLimit(breakerLimit)
    let breakerLimitWarning = false

    if (initialBreakerSizeLimit) {
      if (breakerLimit <= 0) {
        setBreakerLimitWarning(true)
        breakerLimitWarning = true
      } else {
        setBreakerLimitWarning(false)
      }
    }

    addBranch(circuit, type, breakerLimitWarning)
  }

  const togglePanelSharing = (value: boolean) => {
    const singleCircuit = { ...circuit, two_stage: value }
    const tempCircuitsArr = circuits.map((panel) => {
      if (panel.id === singleCircuit.id) return singleCircuit
      return panel
    })
    setCircuits(tempCircuitsArr)
  }

  const moreDropDown = (
    <MoreDropdownWrapper>
      <div
        ref={ref as React.RefObject<HTMLDivElement>}
        className="dropdown-list-item"
        onClick={() => addingBranch(circuit, circuit.type)}
      >
        {greyPlusSVG} Add Circuit
      </div>
      <div
        className="dropdown-list-item"
        onClick={() => {
          editPanel(circuit.type, circuit)
        }}
      >
        {greyEditSVG} Edit Panel
      </div>
      <div
        className="last-item"
        onClick={() => {
          removePanel(PanelType.mixed, circuit)
        }}
      >
        {trashCanSVG} Remove Panel
      </div>
    </MoreDropdownWrapper>
  )

  const columns = [
    {
      title: (
        <TableHeadingWrapper>
          <div>
            Panel Name <span className="divider">|</span> <span className="data">{circuit.name}</span>
          </div>
        </TableHeadingWrapper>
      ),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: (
        <TableHeadingWrapper>
          <div>
            Panel Sharing <span className="divider">|</span>{' '}
            <Switch checked={circuit.two_stage} onChange={(value) => togglePanelSharing(value)} />
          </div>
        </TableHeadingWrapper>
      ),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: (
        <TableHeadingWrapper>
          <div>
            Panel Size <span className="divider"> | </span> <span className="data">{circuit.circuit_size}</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {circuit.load_management ? <ServiceStatusBadge state="Enabled" /> : <ServiceStatusBadge state="Disabled" />}
            {circuit.note === '' && circuit.type === PanelType.dedicated && canUpdateSwtchControl && (
              <MoreButtonWrapper
                onClick={() => setVerticalMoreButton(!verticalMoreButton)}
                vertical={verticalMoreButton}
              >
                <Dropdown.Button className="opened" trigger={['click']} overlay={moreDropDown}></Dropdown.Button>
              </MoreButtonWrapper>
            )}
          </div>
        </TableHeadingWrapper>
      ),
      dataIndex: 'size',
      key: 'size',
    },
  ]

  if (circuit.type === PanelType.mixed) {
    columns.push(
      {
        title: (
          <TableHeadingWrapper>
            <div>
              CT Clamp ID <span className="divider"> | </span> <span className="data">{circuit.ct_clamp.id}</span>
            </div>
          </TableHeadingWrapper>
        ),
        dataIndex: 'ct_clamp_id',
        key: 'ct_clamp_id',
      },
      {
        title: (
          <TableHeadingWrapper>
            <div>
              CT Clamp IP <span className="divider"> | </span>{' '}
              <span className="data">{circuit.ct_clamp.ip_address}</span>
            </div>
          </TableHeadingWrapper>
        ),
        dataIndex: 'ip_address',
        key: 'ip_address',
      },
      {
        title: (
          <TableHeadingWrapper>
            <div>
              Existing Load <span className="divider"> | </span>{' '}
              <span className="data">{circuit.existing_load} amp</span>
            </div>
            {circuit.note === '' && circuit.type === PanelType.mixed && canUpdateSwtchControl && (
              <MoreButtonWrapper
                onClick={() => setVerticalMoreButton(!verticalMoreButton)}
                vertical={verticalMoreButton}
              >
                <Dropdown.Button className="opened" trigger={['click']} overlay={moreDropDown}></Dropdown.Button>
              </MoreButtonWrapper>
            )}
          </TableHeadingWrapper>
        ),
        dataIndex: 'type',
        key: 'type',
      },
    )
  }

  if (circuit.note !== '') {
    columns.push({
      title: (
        <TableHeadingNoteWrapper>
          <span>
            Panel Note <span className="note">({circuit.note})</span>
          </span>
          {canUpdateSwtchControl ? (
            <MoreButtonWrapper onClick={() => setVerticalMoreButton(!verticalMoreButton)} vertical={verticalMoreButton}>
              <Dropdown.Button className="opened" trigger={['click']} overlay={moreDropDown}></Dropdown.Button>
            </MoreButtonWrapper>
          ) : (
            <></>
          )}
        </TableHeadingNoteWrapper>
      ),
      dataIndex: 'note',
      key: 'note',
    })
  }

  const data = [{}]

  return (
    <>
      {circuit.type === PanelType.dedicated && (
        <TitleWrapper>
          <span className="bold">Dedicated EV Panel:</span> {circuit.circuit_id}
        </TitleWrapper>
      )}
      {circuit.type === PanelType.mixed && (
        <TitleWrapper>
          <span className="bold">Mixed EV Panel:</span> {circuit.circuit_id}
        </TitleWrapper>
      )}
      <TableWrapper>
        <Table columns={columns} dataSource={data} pagination={false} bordered={false} />
      </TableWrapper>
      {editPanelModalVisible && (
        <EditPanelModal
          visible={editPanelModalVisible}
          onCancel={() => cancelEdit()}
          onSubmit={handleEditPanelSubmit}
          panelTypeModal={panelTypeModal}
          setPanelTypeModal={setPanelTypeModal}
          circuitData={modalData}
          circuits={circuits}
          setCircuits={setCircuits}
        />
      )}
    </>
  )
}
