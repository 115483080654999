import { useEffect, useState } from 'react'
import { Button, Col, PageHeader, Result, Row, Space, Spin, Table, Tooltip, message } from 'antd'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { LoadingOutlined } from '@ant-design/icons'

import { AlertError } from '../../components/error'
import { Links } from '../../components/routes/paths'
import { AlertWarning } from '../../components/warning'
import { withAuthenticatedLayout } from '../../components/layouts/layout'
import UnauthorizedResult from '../../components/result/UnauthorizedResult'

import { useAppState } from '../../state'

import { Message } from '../../models/message'
import { ApiError } from '../../models/error'
import { Template } from '../../models/template'
import { formatDate } from '../../helpers/charger'
import { theme } from '../../theme'
import { log } from '../../logger'

import { useDashboardPermissions } from '../dashboard/useDashboardPermissions'
import { DeleteChargerTemplate, GetAllChargerConfigTemplates } from '../../services/data-provider/template'
import { clearSession } from '../../state/session-store'
import auth0Obj from '../../services/data-provider/auth0Obj'

import { DeleteModal } from '../dashboard/delete-modal'
import { TemplatesMobileView } from '../dashboard/template/template-list-view'
import { ViewTemplatesModal } from '../dashboard/template/view-templates-modal'
import { EditTemplateModal } from '../dashboard/template/edit-template-modal'
import { CreateNewTemplateModal } from '../dashboard/template/create-new-template-modal'

const TemplateBasedPage: React.FC = () => {
  const { IsMonitor, IsLaptop, IsDesktop, IsTablet, IsMobile } = useAppState()

  const [editTemplateModalVisible, setEditTemplateModalVisible] = useState(false)
  const [term, setTerm] = useState('')
  const [error, setError] = useState<ApiError>()
  const [loading, setLoading] = useState(false)
  const [templates, setTemplates] = useState<Template[]>([])
  const [viewTemplate, setViewTemplate] = useState(false)
  const [template, setTemplate] = useState<Template>()
  const [templateRecord, setTemplateRecord] = useState<Template>()
  const [templateID, setTemplateID] = useState('')
  const isMonitor = IsMonitor()
  const isDesktop = IsDesktop()
  const isLaptop = IsLaptop()
  const isTablet = IsTablet()
  const isMobile = IsMobile()
  const [deleteTemplateModalVisible, setDeleteTemplateModalVisible] = useState(false)
  const [templateModalVisible, setTemplateModalVisible] = useState(false)
  const [templateRecordToDelete, setTemplateRecordToDelete] = useState<Template>()
  const signatureExpired = localStorage.getItem('signatureExpired')

  const { canDeleteTemplates, canUpdateTemplates, canReadTemplates } = useDashboardPermissions()

  useEffect(() => {
    if (canReadTemplates) {
      setLoading(true)
      GetAllChargerConfigTemplates()
        .then((resp) => Array.isArray(resp) && setTemplates(resp))
        .catch((err) => setError(err))
        .finally(() => setLoading(false))
    }
  }, [term])

  const handleViewTemplatesBtn = (record: Template) => {
    setTemplate(record)
    setViewTemplate(true)
  }

  const handleDeleteTemplate = (record: Template) => {
    setLoading(true)
    DeleteChargerTemplate(record._id)
      .then((resp: Message) => {
        message.success(resp.message)
        if (
          (Array.isArray(resp.errors) && resp.errors.length > 0) ||
          (Array.isArray(resp.warnings) && resp.warnings.length > 0)
        ) {
          setError(
            new ApiError(
              'Something went wrong with this request. Please contact customer support if this issue persists.',
              resp.errors,
              resp.warnings,
            ),
          )
        }
        setTerm(`${_.random(Number.MIN_VALUE, Number.MAX_VALUE)}`)
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }

  const columns = [
    { title: 'Name', dataIndex: 'name' },
    { title: 'Model', dataIndex: 'model' },
    { title: 'Vendor', dataIndex: 'vendor' },
    { title: 'Updated At', dataIndex: 'updatedAt', key: 'updatedAt', render: (date: string) => formatDate(date) },
    { title: 'Updated By', dataIndex: 'updatedBy' },
    {
      title: 'Templates',
      render: (record: Template) => {
        return (
          <Button type="primary" onClick={() => handleViewTemplatesBtn(record)}>
            View Template Info
          </Button>
        )
      },
    },
    {
      title: 'Actions',
      align: 'center' as 'center',
      render: (record: any) => {
        return (
          <Space size="large">
            {canUpdateTemplates && (
              <Tooltip title="Edit Template">
                <Button
                  shape="circle"
                  className="edit-btn"
                  color={theme.colors.red}
                  icon={<FontAwesomeIcon icon={faPen} size="lg" color={theme.colors.yellow} />}
                  onClick={() => {
                    setTemplateRecord(record)
                    setTemplateID(record._id)
                    setEditTemplateModalVisible(true)
                  }}
                />
              </Tooltip>
            )}
            {canDeleteTemplates && (
              <Tooltip title="Delete Template">
                <Button
                  shape="circle"
                  className="delete-btn"
                  color={theme.colors.red}
                  icon={<FontAwesomeIcon icon={faTrash} size="lg" color={theme.colors.red} />}
                  onClick={() => {
                    setTemplateRecordToDelete(record)
                    setDeleteTemplateModalVisible(!deleteTemplateModalVisible)
                  }}
                />
              </Tooltip>
            )}
          </Space>
        )
      },
    },
  ]

  return (
    <>
      {signatureExpired === 'true' ? (
        <>
          <Result
            status="403"
            icon={<LoadingOutlined />}
            title="Session Expired"
            subTitle={`Redirecting back to the login page!`}
          />
          <Spin size="large">
            {log('Session Expired. Logging out the user and redirecting to the auth login page')}
            {setTimeout(() => {
              clearSession()
              auth0Obj.logout({})
              window.location.replace(Links.auth0login())
            }, 5000)}
          </Spin>
        </>
      ) : (
        <>
          <AlertError error={error} />
          <AlertWarning error={error} />

          <Row gutter={[8, 8]}>
            <Col span={24}>
              {canReadTemplates ? (
                <>
                  <PageHeader
                    extra={
                      <Button type="primary" onClick={() => setTemplateModalVisible(!templateModalVisible)}>
                        Create New Template
                      </Button>
                    }
                  />
                  {(isDesktop || isMonitor || isLaptop) && (
                    <Table columns={columns} dataSource={templates} rowKey="_id" pagination={false} />
                  )}
                  {(isMobile || isTablet) && templates && (
                    <TemplatesMobileView
                      templates={templates}
                      loading={loading}
                      onClickViewTemplatesBtn={(record: Template) => handleViewTemplatesBtn(record)}
                      onClickEditBtn={(record: Template) => {
                        setTemplateRecord(record)
                        setTemplateID(record._id)
                        setEditTemplateModalVisible(true)
                      }}
                      onClickDeleteBtn={(record: Template) => {
                        handleDeleteTemplate(record)
                      }}
                    />
                  )}
                  {template && (
                    <ViewTemplatesModal
                      visible={viewTemplate}
                      templateObj={template}
                      onCancel={() => setViewTemplate(false)}
                    />
                  )}
                  <CreateNewTemplateModal
                    visible={templateModalVisible}
                    onCancel={() => setTemplateModalVisible(!templateModalVisible)}
                    onSubmit={() => {
                      setTemplateModalVisible(!templateModalVisible)
                      setTerm(`${_.random(Number.MIN_VALUE, Number.MAX_VALUE)}`)
                    }}
                  />
                  {templateRecord && (
                    <EditTemplateModal
                      visible={editTemplateModalVisible}
                      onCancel={() => setEditTemplateModalVisible(!editTemplateModalVisible)}
                      onSubmit={() => {
                        setEditTemplateModalVisible(!editTemplateModalVisible)
                        setTerm(`${_.random(Number.MIN_VALUE, Number.MAX_VALUE)}`)
                      }}
                      record={templateRecord}
                      templateID={templateID}
                    />
                  )}
                  {templateRecordToDelete && deleteTemplateModalVisible && (
                    <DeleteModal
                      visible={deleteTemplateModalVisible}
                      onCancel={() => setDeleteTemplateModalVisible(!deleteTemplateModalVisible)}
                      record={templateRecordToDelete}
                      onOk={() => {
                        handleDeleteTemplate(templateRecordToDelete)
                        setDeleteTemplateModalVisible(!deleteTemplateModalVisible)
                      }}
                    />
                  )}
                </>
              ) : (
                <UnauthorizedResult />
              )}
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

export const TemplatePage = withAuthenticatedLayout(TemplateBasedPage)
