import { BrowserRouter } from 'react-router-dom'
import './app.less'
import ErrorBoundary from './components/layouts/authenticated/error_boundary'
import { Routes } from './components/routes/routes'
import { NetworkProvider } from './context/network'
import AppStateProvider from './state'
import { GlobalStyle } from './theme'

function App() {
  return (
    <>
      <GlobalStyle />
      <ErrorBoundary>
        <NetworkProvider>
          <AppStateProvider>
            <BrowserRouter>
              <Routes />
            </BrowserRouter>
          </AppStateProvider>
        </NetworkProvider>
      </ErrorBoundary>
    </>
  )
}

export default App
