import { Form } from 'antd'

const CreatorForm: React.FC = () => {
  return (
    <>
      <Form.Item name="creator" label="Created by" hidden></Form.Item>
      <Form.Item name="creator_auth0_uid" label="Creator_auth0_uid" hidden></Form.Item>
      <Form.Item name="creator_email" label="Creator Email" hidden></Form.Item>
    </>
  )
}

export default CreatorForm
