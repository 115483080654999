import { useEffect, useRef } from 'react'
import { LatLng } from 'leaflet'
import { Button, Cascader, Col, Form, Input, Modal, Row, Spin, Switch } from 'antd'

import { requiredRule } from '../../helpers/rules'
import { locations } from '../../helpers/location'
import { AlertError } from '../../components/error'

import { LocationView } from '../../components/map'
import { TenantRef } from '../../models/tenant'
import { theme } from '../../theme'
import { useCreateTenant } from '../../hooks/useCreateTenant'

interface CreateTenantModalProps {
  onOk: () => void
  onCancel: () => void
  isModalOpen: boolean
  onTenantCreated?: (tenant: TenantRef) => void
}

const CreateTenantModal: React.FC<CreateTenantModalProps> = ({ onOk, onCancel, isModalOpen, onTenantCreated }) => {
  const defaultMapCenter: LatLng = new LatLng(43.657768, -79.3561481)

  const {
    addressForGeocoding,
    geocodedLocation,
    timeZone,
    geocodingError,
    loading,
    tenantsNames,
    searchingAddress,
    error,
    form,
    checkAndSubmit,
    handleAddressChange,
    handleCityChange,
  } = useCreateTenant(onOk, onTenantCreated)

  const nameInputRef = useRef<any>(null)

  useEffect(() => {
    if (isModalOpen && nameInputRef.current) {
      nameInputRef.current.focus()
    }
  }, [isModalOpen])

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <Modal
      title="Create New Site"
      visible={isModalOpen}
      onCancel={onCancel}
      width={1000}
      footer={[
        <Row justify="space-between">
          <Button key="back" loading={loading} onClick={onCancel} style={{ width: '49%' }}>
            Cancel
          </Button>
          <Button key="submit" loading={loading} type="primary" onClick={checkAndSubmit} style={{ width: '49%' }}>
            Create New Site
          </Button>
        </Row>,
      ]}
    >
      <AlertError error={error} />

      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12} lg={12}>
          <h4>Enter full address</h4>
          <Input
            placeholder="11 River St, Sleepy Hollow, NY 10591, USA"
            onChange={handleAddressChange}
            ref={nameInputRef}
            disabled={loading}
          />
          <br />
          <br />
          <Form
            form={form}
            layout="vertical"
            name="create_tenant_form"
            requiredMark={false}
            onFinishFailed={onFinishFailed}
            scrollToFirstError
            initialValues={{
              timezone: timeZone,
              accessCode: 'xxxxxx',
              twentyFourSeven: true,
              publishToOcpi: false,
              displayName: '',
            }}
          >
            <Form.Item
              name="name"
              label="OCPP Name"
              rules={[
                requiredRule('OCPP name'),
                { max: 255 },
                () => ({
                  validator(_, value) {
                    if (tenantsNames.has(value)) {
                      return Promise.reject(new Error('OCPP Name already exists'))
                    }
                    return Promise.resolve()
                  },
                }),
              ]}
            >
              <Input placeholder="1-yonge-street" disabled={loading} />
            </Form.Item>
            <Form.Item name="displayName" label="Display Name" hidden>
              <Input placeholder="Enter tenant's display name" disabled={loading} />
            </Form.Item>
            <Form.Item
              name="address"
              label={
                <span>
                  Address{' '}
                  <small style={{ color: theme.colors.subtitle, marginLeft: '0.25rem' }}>(Modify if necessary)</small>
                </span>
              }
              rules={[requiredRule('address')]}
            >
              <Input placeholder="11 River St" disabled={loading} />
            </Form.Item>
            <Row justify="space-between">
              <Form.Item
                name="city"
                label="City"
                rules={[requiredRule('city')]}
                style={{ display: 'inline-block', width: '49%' }}
              >
                <Input placeholder="Toronto" onChange={handleCityChange} value={addressForGeocoding?.city} disabled />
              </Form.Item>
              <Form.Item
                name="postalCode"
                label="Postal Code"
                rules={[requiredRule('postal code')]}
                style={{ display: 'inline-block', width: '49%' }}
              >
                <Input placeholder="B1A 4QT" disabled />
              </Form.Item>
            </Row>
            <Row justify="space-between">
              <Form.Item
                name="province"
                label="Country and Province"
                rules={[requiredRule('country and province')]}
                style={{ display: 'inline-block', width: '49%' }}
              >
                <Cascader
                  options={locations}
                  placeholder="Canada / Ontario"
                  allowClear={false}
                  placement="topLeft"
                  disabled
                />
              </Form.Item>
              <Form.Item
                name="timezone"
                label="Time zone"
                rules={[requiredRule('time zone')]}
                style={{ display: 'inline-block', width: '49%' }}
              >
                <Input placeholder="America/Toronto" disabled />
              </Form.Item>
            </Row>
            <Row justify="space-between">
              <Form.Item label="Access Code" name="accessCode" hidden>
                <Input placeholder="123456" disabled />
              </Form.Item>

              <Form.Item label="Open 24/7" name="twentyFourSeven" hidden>
                <Switch defaultChecked checked={true} disabled />
              </Form.Item>
              <Form.Item label="OCPI Sharing" name="publishToOcpi" hidden>
                <Switch checked={false} disabled />
              </Form.Item>
            </Row>
          </Form>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          {searchingAddress && (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
              <Spin size="large" tip="Retrieving the inputted address information..." />
            </div>
          )}
          {geocodingError && <>{console.log('geocodingError:', geocodingError)}</>}
          {!searchingAddress && (
            <LocationView
              value={geocodedLocation}
              defaultCenter={defaultMapCenter}
              defaultZoom={18}
              style={{ height: '50vh' }}
            />
          )}
        </Col>
      </Row>
    </Modal>
  )
}

export default CreateTenantModal
