import { useState } from 'react'
import { Button, PageHeader, Table } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'

import { useGetChargerModelMetadata } from '../../hooks/useGetChargerModelMetadata'
import { useGetChargerModelTemplate } from '../../hooks/useGetChargerModelTemplate'
import { withAuthenticatedLayout } from '../../components/layouts/layout'

import { Box } from '../../atom/box'
import { BackgroundContainer } from '../../atom/grey-background'

import { ChargerModelsColumn } from '../../feature/charger-models/table/ChargerModelsTable'
import CreateChargerModelModal from '../../feature/modal/CreateChargerModelModal'

const ChargerModelsBasePage: React.FC = () => {
  const [isCreateChargerModelOpen, setIsCreateChargerModelOpen] = useState(false)

  const {
    chargerModelTemplateLoading,
    chargerModelTemplates,
    pagination,
    handlePaginationChange,
    getChargerModelTemplateData,
  } = useGetChargerModelTemplate(true)
  const { metadata } = useGetChargerModelMetadata()

  return (
    <>
      <BackgroundContainer>
        <PageHeader
          extra={
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              size="large"
              onClick={() => setIsCreateChargerModelOpen(true)}
            >
              <span className="regular-cap">Create Charger Model Template</span>
            </Button>
          }
        />
        <Box>
          <Table
            dataSource={chargerModelTemplates}
            rowKey="name"
            columns={ChargerModelsColumn(metadata)}
            loading={chargerModelTemplateLoading}
            scroll={{ x: 1300 }}
            pagination={{
              pageSize: pagination.per_page || 20,
              // showSizeChanger: true,
              // pageSizeOptions: ['10', '20', '50', '100'],
              // showTotal: (total) => `Total ${total} charger models`,
              position: ['bottomCenter'],
              total: pagination?.total_entries,
              current: pagination?.current_page,
              showSizeChanger: false,
              onChange: handlePaginationChange,
            }}
          />
        </Box>
      </BackgroundContainer>

      {isCreateChargerModelOpen && (
        <CreateChargerModelModal
          isModalOpen={isCreateChargerModelOpen}
          onCancel={() => setIsCreateChargerModelOpen(!isCreateChargerModelOpen)}
          onOk={() => {
            setIsCreateChargerModelOpen(!isCreateChargerModelOpen)
            getChargerModelTemplateData()
          }}
        />
      )}
    </>
  )
}

export const ChargerModelsPage = withAuthenticatedLayout(ChargerModelsBasePage)
