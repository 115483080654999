// Define an SVG component for our charger icon
export const chargerSVG = (
  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_502_3996)">
      <path
        d="M2.65096 17.9948C2.15501 18.0234 1.66204 17.9007 1.23738 17.643C0.812727 17.3852 0.476412 17.0044 0.272996 16.5512C0.0515426 16.1333 -0.040247 15.6589 0.00931125 15.1885C0.0588695 14.7182 0.24754 14.2733 0.551227 13.9108C0.949477 13.4771 1.37936 13.0735 1.83732 12.7034L2.62472 13.6378L1.79006 14.3465C1.54956 14.5204 1.37203 14.7677 1.28419 15.0512C1.19636 15.3347 1.20298 15.6391 1.30303 15.9185C1.40308 16.198 1.59116 16.4374 1.83899 16.6007C2.08681 16.764 2.38096 16.8424 2.6772 16.8242L2.65096 17.9948Z"
        fill="#7D838A"
      />
      <path
        d="M15.3859 6.90812C15.3859 7.62728 15.3859 8.34646 15.3859 9.06562C15.3859 9.66405 15.1234 9.895 14.5197 9.92649C13.9161 9.95799 13.3019 9.92652 13.0342 9.20211C13.0027 9.12337 12.8189 9.06562 12.7087 9.06037C12.1838 9.06037 11.6588 9.06037 11.1339 9.06037C10.945 9.08179 10.7644 9.15039 10.609 9.25985C9.03416 10.3097 7.45935 11.3596 5.91605 12.4095C5.39111 12.7769 5.13391 12.7402 4.71921 12.2415C4.30451 11.7428 3.92656 11.2966 3.53811 10.8189C3.46334 10.756 3.40437 10.6765 3.36593 10.5866C3.32749 10.4968 3.31065 10.3992 3.31678 10.3017C3.32291 10.2042 3.35183 10.1095 3.4012 10.0252C3.45058 9.94087 3.51905 9.86932 3.6011 9.81628C5.2074 8.24147 6.81371 6.66667 8.44101 5.12336C9.27914 4.32095 10.3989 3.87982 11.5591 3.895C12.546 3.895 13.5276 3.895 14.5145 3.895C15.1444 3.895 15.3859 4.14172 15.3911 4.77689L15.3859 6.90812Z"
        fill="#7D838A"
      />
      <path
        d="M15.895 9.04987V4.39896C16.5302 4.39896 17.1391 4.39896 17.7481 4.39896C17.9028 4.41617 18.0445 4.4935 18.1427 4.61429C18.2409 4.73508 18.2877 4.88964 18.273 5.04462C18.273 6.14698 18.273 7.24933 18.273 8.3517C18.2802 8.43759 18.2696 8.52408 18.2418 8.60567C18.214 8.68727 18.1696 8.76221 18.1114 8.82582C18.0533 8.88942 17.9825 8.9403 17.9037 8.97525C17.8249 9.01019 17.7398 9.02846 17.6536 9.02888C17.0919 9.07613 16.5039 9.04987 15.895 9.04987Z"
        fill="#7D838A"
      />
      <path
        d="M3.03941 10.9134L4.61421 12.8346C4.25302 13.0486 3.87968 13.2414 3.4961 13.4121C3.34912 13.4698 3.1339 13.3543 2.97117 13.3123C2.97117 13.3123 2.93443 13.2703 2.91343 13.2493C1.84781 11.9002 1.74282 12.3359 3.03941 10.9134Z"
        fill="#7D838A"
      />
    </g>
    <defs>
      <clipPath id="clip0_502_3996">
        <rect width="18.2992" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
