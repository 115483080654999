import configProvider from '../../config'
import { log } from '../../logger'
import { mockChargerConfigResponse } from '../../mock/configuration'
import { ConfigurationItem } from '../../models/charger-config-template'
import { apiCihClient } from './client'

export async function GetChargerConfig(serialNumber: string): Promise<ConfigurationItem> {
  let url = `/commissioning/api/v1/chargers/get_config/${serialNumber}`
  const productionURL = configProvider.config.cihApiDomain
  if (productionURL.includes('commissioning.swtchenergy.com')) {
    url = `/api/v1/chargers/get_config/${serialNumber}`
  }
  log('Fetching charger configuration', { url })

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockChargerConfigResponse)
  }
  return apiCihClient(url, {})
}
