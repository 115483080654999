import { useState } from 'react'
import { Drawer, Button, Space, Divider } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'

interface props {
  onCreateTenantModalOpen: () => void
  onCreateChargerModelTemplateOpen: () => void
  commissionBtnEnabled: boolean
  onCommissionChargers: () => void
}

const MobileExtraOptions: React.FC<props> = ({
  onCreateTenantModalOpen,
  onCreateChargerModelTemplateOpen,
  commissionBtnEnabled,
  onCommissionChargers,
}) => {
  const [visible, setVisible] = useState(false)

  const showDrawer = () => {
    setVisible(true)
  }

  const closeDrawer = () => {
    setVisible(false)
  }

  return (
    <div>
      <Button type="primary" icon={<PlusCircleOutlined />} onClick={showDrawer}>
        Actions
      </Button>
      <Drawer title="Create" placement="right" onClose={closeDrawer} visible={visible}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Button
            block
            type="primary"
            onClick={() => {
              closeDrawer()
              onCreateTenantModalOpen()
            }}
          >
            Create Site
          </Button>
          <Button
            block
            type="primary"
            onClick={() => {
              closeDrawer()
              onCreateChargerModelTemplateOpen()
            }}
          >
            Create Charger Model Template
          </Button>
          <Divider />
          <Button
            block
            type="primary"
            disabled={!commissionBtnEnabled}
            onClick={() => {
              closeDrawer()
              onCommissionChargers()
            }}
          >
            Commission
          </Button>
        </Space>
      </Drawer>
    </div>
  )
}

export default MobileExtraOptions
