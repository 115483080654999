import { Image } from '../models/image'

export const mockImages = [
  {
    imagePath: 'https://swtch-garden-bucket-dev.s3.amazonaws.com/test/1715532892057',
    imageFileName: 'ic3_80ab05_426057.png',
    imageUrl: 'https://swtch-garden-bucket-dev.s3.amazonaws.com/test/1715532892056/original/ic3_80ab05_426057.png',
    thumbUrl: 'https://swtch-garden-bucket-dev.s3.amazonaws.com/test/1715532892056/thumb/ic3_80ab05_426057.png',
  },
  {
    imagePath: 'https://swtch-garden-bucket-dev.s3.amazonaws.com/test/1715532892056',
    imageFileName: 'ic3_80ab05_426056.png',
    imageUrl: 'https://swtch-garden-bucket-dev.s3.amazonaws.com/test/1715532892056/original/ic3_80ab05_426056.png',
    thumbUrl: 'https://swtch-garden-bucket-dev.s3.amazonaws.com/test/1715532892056/thumb/ic3_80ab05_426056.png',
  },
] as Image[]

export const mockImageResponse = () => {
  return {
    data: mockImages[0],
    status: 'success',
  }
}

export const mockGetImagesResponse = () => {
  return {
    data: mockImages,
    status: 'success',
  }
}
