import { Result } from 'antd'

// UnauthorizedResult component
const UnauthorizedResult: React.FC = () => {
  // Renders a Result component with status "error", title, and subTitle
  return (
    <Result status="error" title="401 Unauthorized" subTitle="Please contact the administator for authorization." />
  )
}

export default UnauthorizedResult
