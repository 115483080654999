import { Button, Modal } from 'antd'

import { Charger } from '../../models/charger'
import { Environment } from '../../models/environment'
import { Template } from '../../models/template'

interface props {
  visible: boolean
  record: Charger | Template | Environment
  onCancel(): void
  onOk(): void
}

const instanceOfCharger = (data: any): data is Charger => {
  return 'serialNumber' in data
}

const instanceOfTemplate = (data: any): data is Template => {
  return 'name' in data && !('ocppUrl' in data)
}

const instanceOfEnvironment = (data: any): data is Environment => {
  return 'ocppUrl' in data
}

export const DeleteModal: React.FC<props> = ({ visible, record, onCancel, onOk }) => {
  var output: [string, string] = ['', '']

  const renderMessage = (record: Charger | Template | Environment) => {
    let str = 'Are you sure you want to delete '

    if (instanceOfCharger(record)) {
      str += `charger ${record.serialNumber}?`
      output = ['Charger', str]
    }

    if (instanceOfTemplate(record)) {
      str += `template ${record.name}?`
      output = ['Template', str]
    }

    if (instanceOfEnvironment(record)) {
      str += `environment ${record.name}?`
      output = ['Environment', str]
    }

    return output
  }

  return (
    <Modal
      title={`Delete ${renderMessage(record)[0]}`}
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button onClick={onCancel}>Cancel</Button>,
        <Button danger onClick={onOk}>
          {`Delete ${renderMessage(record)[0]}`}
        </Button>,
      ]}
    >
      {renderMessage(record)[1]}
    </Modal>
  )
}
