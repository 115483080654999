import configProvider from '../../config'
import { log } from '../../logger'
import {
  mockAllChargerEnvironments,
  mockCreateEnvironment,
  mockDeleteEnvironment,
  mockEditChargerEnvironment,
} from '../../mock/enviroment'
import { Environment, EnvironmentParams } from '../../models/environment'
import { Message } from '../../models/message'
import { CaseConverter } from './case-converter'
import { apiClient } from './client'

export async function GetAllChargerEnvironments(): Promise<Environment[]> {
  const url = '/environments'
  log('fetching all charger environments', { url })

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockAllChargerEnvironments)
  }

  return apiClient(url, {})
}

export async function CreateNewChargerEnvironment(createNewChargerEnvironment: EnvironmentParams): Promise<Message> {
  const url = `/environments`
  log('creating new environment', { url: url })

  let caseConverter = new CaseConverter()
  const snakeCasedCreateNewChargerEnvironment = caseConverter.camelToSnakeCase(createNewChargerEnvironment)

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockCreateEnvironment)
  }

  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      ...snakeCasedCreateNewChargerEnvironment,
    }),
  })
}

export async function DeleteChargerEnvironment(environmentID: String): Promise<Message> {
  const url = `/environments/${environmentID}`
  log('deleting environment', { url: url })

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockDeleteEnvironment)
  }

  return apiClient(url, {
    method: 'DELETE',
  })
}

export async function EditChargerEnvironment(environmentID: String, environment: EnvironmentParams): Promise<Message> {
  const url = `/environments/${environmentID}`
  log('editing environment', { url: url })

  let caseConverter = new CaseConverter()
  const snakeEditChargerEnvironment = caseConverter.camelToSnakeCase(environment)

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockEditChargerEnvironment)
  }

  return apiClient(url, {
    method: 'PUT',
    body: JSON.stringify({
      ...snakeEditChargerEnvironment,
    }),
  })
}
