import { Col, message, Modal, Row, Select, Spin } from 'antd'
import { ReactElement, useState } from 'react'
import { AlertError } from '../../../components/error'
import { Charger } from '../../../models/charger'
import { ApiError } from '../../../models/error'
import { UpdateBase } from '../../../models/template'
import { UpdateAndRestartCharger } from '../../../services/data-provider/charger'

interface props {
  visible: boolean
  onCancel(): void
  onSubmit(): void
  chargers: Charger[]
}

const { Option } = Select

const renderOption = (option: string): ReactElement => {
  return (
    <Option key={option} value={option}>
      {option}
    </Option>
  )
}

export const UpdateAndRestartChargerModal: React.FC<props> = ({ visible, onCancel, onSubmit, chargers }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ApiError>()
  const [serialNumbers, setSerialNumbers] = useState<UpdateBase[]>([])

  const handleChange = (value: string) => {
    let obj: UpdateBase[] = []

    for (const element of value) {
      obj.push({ id: element })
    }

    setSerialNumbers(obj)
  }

  const handleSubmit = () => {
    serialNumbers &&
      serialNumbers.length > 0 &&
      UpdateAndRestartCharger(serialNumbers)
        .then((resp) => {
          message.success(resp.message)
          if (
            (Array.isArray(resp.errors) && resp.errors.length > 0) ||
            (Array.isArray(resp.warnings) && resp.warnings.length > 0)
          ) {
            setError(
              new ApiError(
                'Something went wrong with this request. Please contact customer support if this issue persists.',
                resp.errors,
                resp.warnings,
              ),
            )
          }
          onSubmit()
        })
        .catch((err) => {
          setError(err)
        })
        .finally(() => {
          setLoading(false)
        })
  }

  return (
    <>
      <AlertError error={error} />
      <Spin spinning={loading}>
        <Row gutter={[8, 8]}>
          <Col md={24} lg={24} xl={24} xxl={24} style={{ textAlign: 'end' }}>
            <Modal
              title="Update & Restart Charger(s)"
              visible={visible}
              confirmLoading={loading}
              onCancel={onCancel}
              okText="Update & Restart"
              onOk={handleSubmit}
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Select Charger(s)"
                onChange={handleChange}
              >
                {chargers.map((charger) => renderOption(charger.serialNumber))}
              </Select>
            </Modal>
          </Col>
        </Row>
      </Spin>
    </>
  )
}
