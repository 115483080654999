import { log } from '../../logger'
import { CommissionNewListingResponse } from '../../models/http'
import { apiCihClient } from './client'
import configProvider from '../../config'
import {
  // mockCommissionNewListingSuccess,
  mockCommissionNewListingFailed,
  // mockCommissionNewListingWarning,
  // mockCommissionNewListingPartialFailed,
} from '../../mock/commission-new-listing-message'
import { CommissionListingSetup } from '../../models/commission-listing'

export async function CreateNewListingsViaCommissioning(
  data: CommissionListingSetup,
): Promise<CommissionNewListingResponse> {
  console.log('🚀 ~ post data:', data)
  const url = '/commissioning/api/v1/charger_deployment/commission'

  log('commissioning charger(s)', { url })
  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockCommissionNewListingFailed)
  }
  return apiCihClient(url, {
    method: 'POST',
    body: JSON.stringify(data),
  })
}
