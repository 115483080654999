import { useState } from 'react'
import { Button, Col, Form, Input, Row, Select, SelectProps } from 'antd'

import { requiredRule } from '../../helpers/rules'
import { useAppState } from '../../state'
import { FindTenants } from '../../services/data-provider/tenants'
import { TenantRef } from '../../models/tenant'

interface SearchOrCreateTenantProps {
  onCreateTenantModalOpen: () => void
  onTenantSelected: (tenant: TenantRef) => void
}
let timeout: ReturnType<typeof setTimeout> | null
let currentValue: string

const fetch = (value: string, callback: Function) => {
  if (timeout) {
    clearTimeout(timeout)
    timeout = null
  }
  currentValue = value

  const searchTenant = () =>
    FindTenants(value).then((response) => {
      if (currentValue === value) {
        const data = response.map((item) => ({
          value: item.id,
          text: item.name,
        }))
        callback(data)
      }
    })

  timeout = setTimeout(searchTenant, 300)
}

const SearchOrCreateTenant: React.FC<SearchOrCreateTenantProps> = ({ onCreateTenantModalOpen, onTenantSelected }) => {
  const { IsMobile } = useAppState()
  const isMobile = IsMobile()

  const [data, setData] = useState<SelectProps['options']>([])
  const [value, setValue] = useState<string>()

  const handleSearch = (newValue: string) => {
    if (newValue) {
      fetch(newValue, setData)
    } else {
      setData([])
    }
  }

  const handleChange = (newValue: any) => {
    setValue(newValue)
    const selectedTenant = { id: newValue.value, name: newValue.label }
    onTenantSelected(selectedTenant)
  }

  return (
    <Row wrap={false}>
      <Col flex="auto">
        <Form.Item name="tenant_name" label="Site" rules={[requiredRule('tenant')]} style={{ marginRight: '1rem' }}>
          {/* <Select showSearch placeholder="Search for a site" optionFilterProp="children" /> */}
          <Select
            showSearch
            value={value}
            placeholder="Search for a site"
            // style={props.style}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={handleSearch}
            onChange={handleChange}
            labelInValue
            notFoundContent={null}
            options={(data || []).map((d) => ({
              value: d.value,
              label: d.text,
            }))}
          />
        </Form.Item>
      </Col>
      <Form.Item name="tenant_id" hidden rules={[requiredRule('tenant_id')]}>
        <Input />
      </Form.Item>
      <Col flex="none">
        <Button type="primary" onClick={onCreateTenantModalOpen} style={{ marginTop: isMobile ? '4rem' : 0 }}>
          Create New Site
        </Button>
      </Col>
    </Row>
  )
}

export default SearchOrCreateTenant
