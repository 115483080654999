import { styled } from '../../theme'

interface styleProps {
  isDedicated?: boolean
  isLast?: boolean
  vertical?: boolean
}

export const MainPageWrapper = styled.div`
  padding: 0;
  background: #fafafa;
  margin: -8px;
`

export const TitleWrapper = styled.div`
  font-size: 16px;
  line-height: 19px;
  margin-top: 22px;
  padding-top: 4px;
  font-family: ${(props) => props.theme.fontFamily.regular};
  .bold {
    font-weight: 700;
  }
`

export const Padding15Wrapper = styled.div`
  padding: 20px;
  padding-top: 32.5px;

  .ant-page-header {
    padding: 0px;
    background: #fafafa;
    margin-bottom: 20px;
  }
`

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`

export const ListingLabelColourWrapper = styled.span`
  color: #30d7ba;
  font-family: ${(props) => props.theme.fontFamily.regular};
`

export const ChargerOptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const MoreButtonWrapper = styled.span<styleProps>`
  font-family: ${(props) => props.theme.fontFamily.regular};

  .ant-btn-group > .ant-btn-icon-only {
    border-radius: 40px !important;
  }

  .ant-btn:hover {
    border-color: #c1c3d0;
    color: #242e2c;
  }
  .ant-btn:focus {
    border-color: #30d7ba;
    color: #242e2c;
  }
  .ant-btn > span {
    transform: rotate(0deg);
  }

  .ant-btn:focus > span {
    transform: ${(props) => (props.vertical ? 'rotate(90deg)' : 'rotate(0deg)')};
  }
`

export const MoreDropdownWrapper = styled.div`
  background: #ffffff;
  border: 1px solid #c1c3d0;
  border-radius: 5px;
  padding: 16px;
  width: 197px;

  .dropdown-list-item {
    padding-bottom: 22px;
    display: flex;
    align-items: center;
    gap: 14px;
    font-size: 13px;
    line-height: 16px;
    color: #242e2c;
    cursor: pointer;
    font-family: ${(props) => props.theme.fontFamily.regular};
  }

  .last-item {
    padding-bottom: 0;
    display: flex;
    align-items: center;
    gap: 14px;
    font-size: 13px;
    line-height: 16px;
    color: #242e2c;
    cursor: pointer;
    font-family: ${(props) => props.theme.fontFamily.regular};
  }
`

export const TimelineWrapper = styled.div<styleProps>`
  display: flex;

  .ant-timeline {
    width: 100%;
  }

  .ant-timeline > .ant-timeline-item > .ant-timeline-item-tail {
    border-left: ${(props) => (props.isDedicated ? '2px solid #30D7BA' : '2px solid #FF9900')};
    margin-top: 9px;
  }

  .ant-timeline > .ant-timeline-item > .ant-timeline-item-head {
    padding: 0px 1px;
    background: transparent;
    padding-top: 33px;
  }

  .ant-timeline > .ant-timeline-item:first-child > .ant-timeline-item-head {
    padding-top: 0px;
    height: 23px;
  }

  .ant-timeline > .ant-timeline-item {
    padding-bottom: 70px;
  }
`

export const TableHeadingWrapper = styled.div`
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: ${(props) => props.theme.fontFamily.regular};

  .divider {
    color: #c1c3d0;
    margin-left: 2px;
    margin-right: 2px;
  }
  .data {
    color: #30d7ba;
  }
  .note {
    color: #7d838a;
    margin-left: 2px;
  }
  .ant-badge-count {
    box-shadow: none;
  }
`
export const EditBranchButtonWrapper = styled.span`
  button {
    border: 1px solid #c1c3d0;
    box-shadow: none;
  }

  button:hover {
    border: 1px solid #30d7ba;
  }

  button:active {
    border: 1px solid #30d7ba;
  }

  button:focus {
    border: 1px solid #30d7ba;
  }

  .add-button > svg {
    margin-top: 3px;
  }
`

export const BranchHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const TableHeadingNoteWrapper = styled.div`
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: ${(props) => props.theme.fontFamily.regular};

  .note {
    color: #7d838a;
    margin-left: 2px;
  }
`

export const TableWrapper = styled.div`
  margin-top: 16px;

  .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th:first-child {
    border: 1px solid #c1c3d0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 10px 20px;
    max-width: 185px;
  }

  .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th {
    background: #f1f1f1;
    padding: 21px 20px;
  }

  .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th:last-child {
    border: 1px solid #c1c3d0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: none;
    padding: 21px 20px;
  }

  .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th {
    border-right: 1px solid #c1c3d0;
    border-top: 1px solid #c1c3d0;
    border-bottom: 1px solid #c1c3d0;
  }

  .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody {
    display: none;
  }

  .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }
`
