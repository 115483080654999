import { Charger, ChargerResponseV2 } from '../models/charger'
import { Message } from '../models/message'
import { mockListingsRef } from './listing-mock'

export const mockAllChargers: Charger[] = [
  {
    _id: '64ec30018e9e94b1c373975b',
    serialNumber: '5SW1-1-2147-00081',
    createdAt: '2022-11-02T20:38:43.356000',
    deletedAt: null,
    chargePointStatus: {
      status: 'Faulted',
      previousStatus: 'Unavailable',
      updatedAt: '2022-11-02T20:49:21.718000',
    },
    connectionStatus: {
      status: 'disconnected',
      environment: 'deployment',
      updatedAt: '2022-11-02T20:38:43.356000',
    },
    configurationState: {
      state: 'Unconfigured',
      updatedAt: '2022-11-02T20:38:43.356000',
    },
    configurationTemplate: {
      tenant: '140-Jensen-Ave-W-Parksville',
      environment: null,
      template: null,
      autoRun: null,
      updatedBy: 'Faraz',
      updatedAt: '2022-11-02T20:38:43.356000',
      timezone: 'America/Toronto',
    },
    info: {
      vendor: 'LITE-ON',
      model: 'EX-1762-5SW1',
      firmwareVersion: 'V1.00.89SWTCH (BBOX: V1.33)',
      ocppVersion: 'ocpp1.6',
      ocppEnvironment: 'development',
      updatedAt: '2022-11-02T20:49:20.363000',
      connectors: [
        { id: 1, connectorId: 1, listing: null },
        { id: 2, connectorId: 2, listing: null },
      ],
    },
    chargerConfigurations: [],
    errors: [],
    listings: [],
  },
  {
    _id: '64ed8d718e9e94b1c373978c',
    serialNumber: 'OCPIT3',
    createdAt: '2024-07-02T14:37:31.223000',
    deletedAt: null,
    chargePointStatus: {
      status: 'Available',
      previousStatus: 'Unknown',
      updatedAt: '2022-11-03T14:23:36.241000',
    },
    connectionStatus: {
      status: 'unknown',
      environment: 'production',
      updatedAt: '2022-11-02T20:38:48.651000',
    },
    configurationState: {
      state: 'Unknown',
      updatedAt: '2022-11-02T20:38:48.651000',
    },
    configurationTemplate: {
      tenant: '1 Yonge St Toronto, ON M5E 1E5 Canada',
      environment: null,
      template: '230fjsdlfj230fjiodsf',
      autoRun: null,
      updatedBy: 'Lan',
      updatedAt: '2024-07-02T16:00:53.949000',
      timezone: 'America/Vancouver',
    },
    info: {
      vendor: 'Atom Power',
      model: 'AS3P100',
      firmwareVersion: 'V1.00.89SWTCH (BBOX: V1.33)',
      ocppVersion: 'ocpp1.6',
      ocppEnvironment: 'production',
      updatedAt: '2022-11-03T14:23:36.019000',
      connectors: [{ id: 1, connectorId: 1, listing: { id: 3, title: 'FR03' } }],
    },
    chargerConfigurations: [],
    errors: [
      {
        updatedAt: '2022-11-02T20:49:20.363000',
        deletedAt: null,
        status: 'Verified',
        message: 'Timeout during configuration update. Timeout is 600 seconds.',
      },
      {
        updatedAt: '2022-11-02T21:51:20.363000',
        deletedAt: null,
        status: 'Rebooting',
        message:
          "fail to update configurations to charger, diff_config={'MeterValueAlignedData': 'Current.Import,Current.Offered,Energy.Active.Import.Register,Energy.Active.Import.Interval,Power.Offered,Temperature,Voltage'}",
      },
    ],
    listings: [
      mockListingsRef[1],
      mockListingsRef[2],
      mockListingsRef[3],
      mockListingsRef[4],
      mockListingsRef[5],
      mockListingsRef[6],
      mockListingsRef[7],
    ],
  },
  {
    _id: '64ed8d718e9e94b1c373978d',
    serialNumber: '5SW2-1-2141-00091',
    createdAt: '2022-11-02T20:40:09.728000',
    deletedAt: null,
    chargePointStatus: {
      status: 'Available',
      previousStatus: 'Unknown',
      updatedAt: '2022-11-03T14:23:35.946000',
    },
    connectionStatus: {
      status: 'connected',
      environment: 'development',
      updatedAt: '2022-11-02T20:40:09.728000',
    },
    configurationState: {
      state: 'Unconfigured',
      updatedAt: '2022-11-02T20:40:09.728000',
    },
    configurationTemplate: {
      tenant: '631 Yew Wood Rd, Tofino, BC V0R 0A4',
      environment: null,
      template: null,
      autoRun: null,
      updatedBy: 'Dylan',
      updatedAt: '2022-11-02T20:40:09.728000',
      timezone: 'America/Toronto',
    },
    info: {
      // vendor: 'LGE',
      // model: 'EVW011SK-SN',
      vendor: 'LITE-ON',
      model: 'EX-1762-5SW1',
      firmwareVersion: 'V2.02.22SWTCH (BBOX: V1.33a)',
      ocppVersion: 'ocpp1.6',
      ocppEnvironment: 'staging',
      updatedAt: '2022-11-03T14:23:35.807000',
      connectors: [
        { id: 1, connectorId: 1, listing: { id: 4, title: 'FR04' } },
        // { id: 2, connectorId: 2, listing: { id: 5, title: 'FR05' } },
      ],
    },
    chargerConfigurations: [],
    errors: [
      {
        updatedAt: '2022-11-02T20:52:20.363000',
        deletedAt: null,
        status: 'Updating',
        message: 'Error Message 1',
      },
      {
        updatedAt: '2022-11-02T21:53:20.363000',
        deletedAt: null,
        status: 'Rebooting',
        message: 'Error Message 2',
      },
    ],
    listings: [mockListingsRef[2]],
  },
  {
    _id: '64ed8d718e9e94b1c373978e',
    serialNumber: 'SWT00000101',
    createdAt: '2022-11-03T14:23:35.893000',
    deletedAt: null,
    chargePointStatus: {
      status: 'Available',
      previousStatus: 'Unknown',
      updatedAt: '2022-11-03T14:23:36.088000',
    },
    connectionStatus: {
      status: 'connected',
      environment: 'production',
      updatedAt: '2022-11-03T14:23:35.893000',
    },
    configurationState: {
      state: 'Updating',
      updatedAt: '2022-11-03T14:23:35.893000',
    },
    configurationTemplate: {
      tenant: '550 Bayview Ave, Toronto, ON M4W 3X8',
      environment: null,
      template: null,
      autoRun: null,
      updatedBy: 'Mike',
      updatedAt: '2022-11-03T14:23:35.893000',
      timezone: 'America/New_York',
    },
    info: {
      vendor: 'Phihong 48',
      model: 'AXLU111001W1P1',
      firmwareVersion: 'V2.02.22SWTCH (BBOX: V1.33a)',
      ocppVersion: 'ocpp1.6',
      ocppEnvironment: 'production',
      updatedAt: '2022-11-03T14:23:35.894000',
      connectors: [
        { id: 1, connectorId: 1, listing: { id: 6, title: 'FR06' } },
        { id: 2, connectorId: 2, listing: { id: 7, title: 'FR07' } },
      ],
    },
    chargerConfigurations: [],
    errors: [],
    listings: [mockListingsRef[3]],
  },
  {
    _id: 'f4ed8d718e9e94b1c373978f',
    serialNumber: 'AX48LM',
    createdAt: '2022-11-03T14:23:35.994000',
    deletedAt: null,
    chargePointStatus: {
      status: 'Available',
      previousStatus: 'Unknown',
      updatedAt: '2022-11-03T14:23:36.236000',
    },
    connectionStatus: {
      status: 'connected',
      environment: 'deployment',
      updatedAt: '2022-11-03T14:23:35.995000',
    },
    configurationState: {
      state: 'Verified',
      updatedAt: '2022-11-03T14:23:35.995000',
    },
    configurationTemplate: {
      tenant: '7 Queen Elizabeth Blvd, Toronto, ON M8Z 1L9',
      environment: null,
      template: null,
      autoRun: null,
      updatedBy: null,
      updatedAt: '2022-11-03T14:23:35.995000',
      timezone: 'Australia/Lindeman',
    },
    info: {
      vendor: 'LITE-ON',
      model: 'EX-1762-5SW1',
      firmwareVersion: 'V2.02.22SWTCH (BBOX: V1.33a)',
      ocppVersion: 'ocpp1.6',
      ocppEnvironment: 'production',
      updatedAt: '2022-11-03T14:23:35.996000',
      connectors: [
        { id: 1, connectorId: 1, listing: { id: 8, title: 'FR08' } },
        { id: 2, connectorId: 2, listing: { id: 9, title: 'FR09' } },
      ],
    },
    chargerConfigurations: [],
    errors: [],
    listings: [mockListingsRef[4]],
  },
  {
    _id: '64ed8d718e9e94b1c373978g',
    serialNumber: '1EC1-1-1824-00021',
    createdAt: '2022-11-03T14:23:36.033000',
    deletedAt: null,
    chargePointStatus: {
      status: 'Available',
      previousStatus: 'Unknown',
      updatedAt: '2022-11-03T14:23:36.230000',
    },
    connectionStatus: {
      status: 'connected',
      environment: 'deployment',
      updatedAt: '2022-11-03T14:23:36.033000',
    },
    configurationState: {
      state: 'Commissioned',
      updatedAt: '2022-11-03T14:23:36.033000',
    },
    configurationTemplate: {
      tenant: '122 Judge Rd, Etobicoke, ON M8Z 5B7',
      environment: null,
      template: null,
      autoRun: null,
      updatedBy: 'Jing',
      updatedAt: '2022-11-03T14:23:36.033000',
      timezone: 'Asia/Jakarta',
    },
    info: {
      vendor: 'CDT_TACW7::NET_WIFI',
      model: 'ABB',
      firmwareVersion: 'V2.02.22SWTCH (BBOX: V1.33a)',
      ocppVersion: 'ocpp1.6',
      ocppEnvironment: 'production',
      updatedAt: '2022-11-03T14:23:36.034000',
      connectors: [
        { id: 1, connectorId: 1, listing: { id: 10, title: 'FR10' } },
        { id: 2, connectorId: 2, listing: { id: 11, title: 'FR11' } },
      ],
    },
    chargerConfigurations: [],
    errors: [],
    listings: [mockListingsRef[5]],
  },
  {
    _id: '64ed8d718e9e94b1c373978h',
    serialNumber: '5SW1-1-2142-00204',
    createdAt: '2022-11-03T14:33:12.741000',
    deletedAt: null,
    chargePointStatus: {
      status: 'Available',
      previousStatus: 'Unknown',
      updatedAt: '2022-11-03T14:54:45.319000',
    },
    connectionStatus: {
      status: 'connected',
      environment: 'production',
      updatedAt: '2022-11-03T14:33:12.741000',
    },
    configurationState: {
      state: 'Verified',
      updatedAt: '2022-11-03T14:33:12.741000',
    },
    configurationTemplate: {
      tenant: '999 Van Ness Ave, San Francisco, CA 94109, United States',
      environment: null,
      template: null,
      autoRun: null,
      updatedBy: 'Aileen',
      updatedAt: '2022-11-03T14:33:12.741000',
      timezone: 'Asia/Tokyo',
    },
    info: {
      vendor: 'BREVEM2040',
      model: 'Eaton',
      firmwareVersion: 'V1.00.89SWTCH (BBOX: V1.33)',
      ocppVersion: 'ocpp1.6',
      ocppEnvironment: 'production',
      updatedAt: '2022-11-03T14:54:45.139000',
      connectors: [
        { id: 1, connectorId: 1, listing: { id: 12, title: 'FR12' } },
        { id: 2, connectorId: 2, listing: { id: 13, title: 'FR13' } },
      ],
    },
    chargerConfigurations: [],
    errors: [],
    listings: [mockListingsRef[6]],
  },
  {
    _id: '64ed8d718e9e94b1c373978h2',
    serialNumber: '5SW1-1-2142-00205',
    createdAt: '2022-11-03T14:33:12.741000',
    deletedAt: null,
    chargePointStatus: {
      status: 'Available',
      previousStatus: 'Unknown',
      updatedAt: '2022-11-03T14:54:45.319000',
    },
    connectionStatus: {
      status: 'connected',
      environment: 'production',
      updatedAt: '2022-11-03T14:33:12.741000',
    },
    configurationState: {
      state: 'Failed Commissioning',
      updatedAt: '2022-11-03T14:33:12.741000',
    },
    configurationTemplate: {
      tenant: '999 Van Ness Ave, San Francisco, CA 94109, United States',
      environment: null,
      template: null,
      autoRun: null,
      updatedBy: 'Aileen',
      updatedAt: '2022-11-03T14:33:12.741000',
      timezone: 'Asia/Tokyo',
    },
    info: {
      vendor: 'BREVEM2040',
      model: 'Eaton',
      firmwareVersion: 'V1.00.89SWTCH (BBOX: V1.33)',
      ocppVersion: 'ocpp1.6',
      ocppEnvironment: 'production',
      updatedAt: '2022-11-03T14:54:45.139000',
      connectors: [
        { id: 1, connectorId: 1, listing: { id: 14, title: 'FR14' } },
        { id: 2, connectorId: 2, listing: { id: 15, title: 'FR15' } },
      ],
    },
    chargerConfigurations: [],
    errors: [],
    listings: [mockListingsRef[6]],
  },
  {
    _id: '64ed8d718e9e94b1c373978i',
    serialNumber: 'AXLU111001D1P1D2203A015A0',
    createdAt: '2022-11-03T14:33:12.754000',
    deletedAt: null,
    chargePointStatus: {
      status: 'Available',
      previousStatus: 'Unknown',
      updatedAt: '2022-11-03T14:54:45.332000',
    },
    connectionStatus: {
      status: 'connected',
      environment: 'production',
      updatedAt: '2022-11-03T14:33:12.754000',
    },
    configurationState: {
      state: 'Failed Config',
      updatedAt: '2022-11-03T14:33:12.754000',
    },
    configurationTemplate: {
      tenant: '181 Fremont St, San Francisco, CA 94105, USA',
      environment: null,
      template: null,
      autoRun: null,
      updatedBy: 'Michael',
      updatedAt: '2022-11-03T14:33:12.754000',
      timezone: 'Europe/Monaco',
    },
    info: {
      vendor: 'UnitedChargers',
      model: 'rizzl-E Smart',
      firmwareVersion: 'V1.00.89SWTCH (BBOX: V1.33)',
      ocppVersion: 'ocpp1.6',
      ocppEnvironment: 'production',
      updatedAt: '2022-11-03T14:54:45.130000',
      connectors: [],
    },
    chargerConfigurations: [],
    errors: [],
    listings: [mockListingsRef[7]],
  },
  {
    _id: '64ed8d718e9e94b1c373978j',
    serialNumber: '5SW1-1-2142-00250',
    createdAt: '2022-11-03T14:33:12.760000',
    deletedAt: null,
    chargePointStatus: {
      status: 'Available',
      previousStatus: 'Unknown',
      updatedAt: '2022-11-03T14:54:45.319000',
    },
    connectionStatus: {
      status: 'connected',
      environment: 'production',
      updatedAt: '2022-11-03T14:33:12.760000',
    },
    configurationState: {
      state: 'Failed Verifying',
      updatedAt: '2022-11-03T14:33:12.760000',
    },
    configurationTemplate: {
      tenant: 'One Microsoft Way, Redmond, WA 98052, United States',
      environment: null,
      template: null,
      autoRun: null,
      updatedBy: 'Victoria',
      updatedAt: '2022-11-03T14:33:12.760000',
      timezone: 'Europe/Vienna',
    },
    info: {
      vendor: 'Tritium',
      model: 'TRI153-RTM-01',
      firmwareVersion: 'V1.00.89SWTCH (BBOX: V1.33)',
      ocppVersion: 'ocpp1.6',
      ocppEnvironment: 'preprod',
      updatedAt: '2022-11-03T14:54:45.152000',
      connectors: [
        { id: 1, connectorId: 1, listing: { id: 16, title: 'FR16' } },
        { id: 2, connectorId: 2, listing: { id: 17, title: 'FR17' } },
      ],
    },
    chargerConfigurations: [],
    errors: [],
    listings: [mockListingsRef[8]],
  },
  {
    _id: '64ed8d718e9e94b1c373978k',
    serialNumber: '120920060',
    createdAt: '2022-11-03T14:33:12.774000',
    deletedAt: null,
    chargePointStatus: {
      status: 'Available',
      previousStatus: 'Unknown',
      updatedAt: '2022-11-03T14:54:45.320000',
    },
    connectionStatus: {
      status: 'connected',
      environment: 'deployment',
      updatedAt: '2022-11-03T14:33:12.774000',
    },
    configurationState: {
      state: 'Rejected',
      updatedAt: '2022-11-03T14:33:12.774000',
    },
    configurationTemplate: {
      tenant: '1355 Market Street, Downtown San Francisco, California, United States',
      environment: null,
      template: null,
      autoRun: null,
      updatedBy: 'Reesha',
      updatedAt: '2022-11-03T14:33:12.774000',
      timezone: 'Asia/Tokyo',
    },
    info: {
      vendor: 'XCHARGE',
      model: 'C6EU',
      firmwareVersion: 'V1.00.89SWTCH (BBOX: V1.33)',
      ocppVersion: 'ocpp1.6',
      ocppEnvironment: 'development',
      updatedAt: '2022-11-03T14:54:45.156000',
      connectors: [
        { id: 1, connectorId: 1, listing: { id: 18, title: 'FR18' } },
        { id: 2, connectorId: 2, listing: { id: 19, title: 'FR19' } },
      ],
    },
    chargerConfigurations: [],
    errors: [],
    listings: [mockListingsRef[9]],
  },
  {
    _id: '64ed8d718e9e94b1c373978l',
    serialNumber: 'W1-1962-5SW1-6-2312-00030',
    createdAt: '2022-11-03T14:33:12.784000',
    deletedAt: null,
    chargePointStatus: {
      status: 'Available',
      previousStatus: 'Unknown',
      updatedAt: '2022-11-03T14:54:45.298000',
    },
    connectionStatus: {
      status: 'disconnected',
      environment: 'production',
      updatedAt: '2022-11-03T14:33:12.784000',
    },
    configurationState: {
      state: 'Configuring',
      updatedAt: '2022-11-03T14:33:12.784000',
    },
    configurationTemplate: {
      tenant: '290 Bremner Blvd, Toronto, ON M5V 3L9',
      environment: null,
      template: null,
      autoRun: null,
      updatedBy: 'Randy',
      updatedAt: '2022-11-03T14:33:12.784000',
      timezone: 'Asia/Tokyo',
    },
    info: {
      vendor: 'Zerova AX48',
      model: 'AXLU111001W1S1',
      firmwareVersion: 'V1.00.89SWTCH (BBOX: V1.33)',
      ocppVersion: 'ocpp1.6',
      ocppEnvironment: 'deployment',
      updatedAt: '2022-11-03T14:54:45.160000',
      connectors: [
        { id: 1, connectorId: 1, listing: { id: 20, title: 'FR20' } },
        { id: 2, connectorId: 2, listing: { id: 21, title: 'FR21' } },
      ],
    },
    chargerConfigurations: [],
    errors: [],
    listings: [mockListingsRef[10]],
  },
  {
    _id: '64ed8d718e9e94b1c373978op',
    serialNumber: 'W1-2312-00030',
    createdAt: '2022-11-03T14:33:12.784000',
    deletedAt: null,
    chargePointStatus: {
      status: 'Available',
      previousStatus: 'Unknown',
      updatedAt: '2022-11-03T14:54:45.298000',
    },
    connectionStatus: {
      status: 'disconnected',
      environment: 'production',
      updatedAt: '2022-11-03T14:33:12.784000',
    },
    configurationState: {
      state: 'Rebooted',
      updatedAt: '2022-11-03T14:33:12.784000',
    },
    configurationTemplate: {
      tenant: '290 Bremner Blvd, Toronto, ON M5V 3L9',
      environment: null,
      template: null,
      autoRun: null,
      updatedBy: 'Randy',
      updatedAt: '2022-11-03T14:33:12.784000',
      timezone: 'Asia/Tokyo',
    },
    info: {
      vendor: 'Zerova AX48',
      model: 'AXLU111001W1S1',
      firmwareVersion: 'V1.00.89SWTCH (BBOX: V1.33)',
      ocppVersion: 'ocpp1.6',
      ocppEnvironment: 'deployment',
      updatedAt: '2022-11-03T14:54:45.160000',
      connectors: [
        { id: 1, connectorId: 1, listing: { id: 20, title: 'FR20' } },
        { id: 2, connectorId: 2, listing: { id: 21, title: 'FR21' } },
      ],
    },
    chargerConfigurations: [],
    errors: [],
    listings: [mockListingsRef[10]],
  },
]

export const mockDeleteChargerResponse = (serialNumber: string): Message => {
  return {
    message: `Charger ${serialNumber} deleted`,
    errors: [],
    warnings: [],
  }
}

export const mockChargerUpdatedResponse = (serialNumber: string): Message => {
  return {
    message: `Charger ${serialNumber} updated`,
    errors: [],
    warnings: [],
  }
}

export const mockUpdateAndRestartChargerResponse: Message = {
  message: 'Charger 5SW1-1-2147-00081 updated',
  errors: [],
  warnings: ['Warning 1', 'Warning 2'],
}

export const mockCommissioningChargerResponse: Message = {
  message: 'Charger commissioning verify process started',
  errors: [
    'Charger with serial number TEST-001 does not have a configuration key assisnged',
    'Charger with serial number TEST-002 does not have a configuration key assisnged',
  ],
  warnings: ['Warning 1', 'Warning 2'],
}

export const mockChargerV2 = [
  {
    _id: '659dbdc3a16d514d80dc64fd',
    charger: {
      id: 1,
      serialNumber: '5SW1-1-2147-00081',
      vendor: 'LITE-ON', // --> it could be make as well
      model: 'EX-1762-5SW1',
      firmwareVersion: 'V1.00.89SWTCH (BBOX: V1.33)',
      ocppVersion: 'ocpp1.6',
    },
    connection: {
      status: 'connected',
      updatedAt: '2022-11-02T20:38:43.356000',
    },
    listings: [
      {
        id: 1,
        title: 'FR02',
      },
    ],
    tenant: {
      id: 1,
      name: '140-Jensen-Ave-W-Parksville',
      displayName: '140 Jensen Ave W, Parksville, BC V9P 2G6',
      timezone: 'America/Toronto', // --> tenant&.location&.listing_timezone
    },
    configuration: {
      autoRun: null,
      environment: '6487bea061dfd24a73dc7129',
      template: '6578957ea16d514d80dc641d',
      updatedAt: '2024-01-09T21:42:27.212000',
      updatedBy: 'Faraz',
      state: 'Failed Commissioning',
    },
    errors: [
      {
        updatedAt: '2022-11-02T20:49:20.363000',
        deletedAt: null,
        status: 'Verified',
        message: 'Error Message 1',
      },
      {
        updatedAt: '2022-11-02T21:51:20.363000',
        deletedAt: null,
        status: 'Rebooting',
        message: 'Error Message 2',
      },
    ],
  },
] as ChargerResponseV2[]

export const mockChargerResponse = (serialNumber: string[], status?: string): Charger[] => {
  let filteredChargers = mockAllChargers

  if (serialNumber.length > 0) {
    filteredChargers = mockAllChargers.filter((charger) => serialNumber.includes(charger.serialNumber))
  }

  if (status) {
    filteredChargers = mockAllChargers.filter((charger) => charger.configurationState.state === status)
  }

  return filteredChargers
}
