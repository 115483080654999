import { Badge, Tooltip } from 'antd'

import { theme } from '../theme'
import { ConfigurationStateType } from '../models/configuration'
import { ChargerInfo, ConnectionStatus } from '../models/charger'

export const connectionStatus = [
  { label: 'Unconfigured', value: 'Unconfigured' },
  { label: 'Updating', value: 'Updating' },
  { label: 'Rebooting', value: 'Rebooting' },
  { label: 'Rebooted', value: 'Rebooted' },
  { label: 'Verified', value: 'Verified' },
  { label: 'Commissioned', value: 'Commissioned' },
  { label: 'Rejected', value: 'Rejected' },
  { label: 'Failed', value: 'Failed' },
  { label: 'Commissioning', value: 'Commissioning' },
  // { label: 'Commissioning', value: 'Commissioning' },
  // { label: 'Failed Config', value: 'Failed Config' },
  // { label: 'Failed Verifying', value: 'Failed Verifying' },
  // { label: 'Failed Commissioning', value: 'Failed Commissioning' },
  // { label: 'Unknown', value: 'Unknown' },
  // { label: 'Verifying', value: 'Verifying' },
]

const {
  black05,
  black,
  green,
  magicMint,
  danger,
  darkGray,
  darkBlue,
  viridian,
  orangePeel15,
  warningPrimary,
  underRepair,
  dangerPrimary,
  lightBlue,
} = theme.colors

type StatusConfig = {
  title: string
  badgeCount: string
  backgroundColor: string
}

const statusColors: {
  [key in ConfigurationStateType]?: { fontColor: string; backgroundColor: string }
} & {
  [key: string]: { fontColor: string; backgroundColor: string } | undefined
} = {
  Unconfigured: { fontColor: warningPrimary, backgroundColor: orangePeel15 },
  Rejected: { fontColor: dangerPrimary, backgroundColor: underRepair },
  Configuring: { fontColor: darkBlue, backgroundColor: lightBlue },
  Rebooting: { fontColor: darkBlue, backgroundColor: lightBlue },
  Verifying: { fontColor: darkBlue, backgroundColor: lightBlue },
  Commissioning: { fontColor: darkBlue, backgroundColor: lightBlue },
  Updating: { fontColor: darkBlue, backgroundColor: lightBlue },
  Rebooted: { fontColor: viridian, backgroundColor: magicMint },
  Commissioned: { fontColor: viridian, backgroundColor: magicMint },
  Verified: { fontColor: viridian, backgroundColor: magicMint },
  Unknown: { fontColor: black, backgroundColor: black05 },
  'Failed Config': { fontColor: dangerPrimary, backgroundColor: underRepair },
  'Failed Verifying': { fontColor: dangerPrimary, backgroundColor: underRepair },
  'Failed Commissioning': { fontColor: dangerPrimary, backgroundColor: underRepair },
}

const statusConfig: Record<string, StatusConfig> = {
  production_connected: {
    title: 'Online on Production Server',
    badgeCount: 'P',
    backgroundColor: green,
  },
  production_disconnected: {
    title: 'Offline on Production Server',
    badgeCount: 'P',
    backgroundColor: danger,
  },
  development_connected: {
    title: 'Online on development Server',
    badgeCount: 'D',
    backgroundColor: green,
  },
  development_disconnected: {
    title: 'Offline on development Server',
    badgeCount: 'D',
    backgroundColor: danger,
  },
  preprod_connected: {
    title: 'Online on Preprod Server',
    badgeCount: 'D',
    backgroundColor: green,
  },
  preprod_disconnected: {
    title: 'Offline on Preprod Server',
    badgeCount: 'D',
    backgroundColor: danger,
  },
  deployment_connected: {
    title: 'Online on Deployment Server',
    badgeCount: 'D',
    backgroundColor: green,
  },
  deployment_disconnected: {
    title: 'Offline on Deployment Server',
    badgeCount: 'D',
    backgroundColor: danger,
  },
  staging_connected: {
    title: 'Online on Staging Server',
    badgeCount: 'S',
    backgroundColor: green,
  },
  staging_disconnected: {
    title: 'Offline on Staging Server',
    badgeCount: 'S',
    backgroundColor: danger,
  },
}

export const handleConnectivity = (
  connectionStatus: ConnectionStatus,
  info: ChargerInfo,
  noBadge: boolean,
  isMobile: boolean,
) => {
  const combinedCondition = `${info.ocppEnvironment}_${connectionStatus.status}`
  const config = statusConfig[combinedCondition] || {
    title: `${connectionStatus.status} on ${info.ocppEnvironment} Server`,
    badgeCount: 'U',
    backgroundColor: darkGray,
  }

  if (isMobile && noBadge) {
    return `Charger is ${connectionStatus.status} on ${info.ocppEnvironment} Server`
  }

  return (
    <Tooltip title={config.title}>
      {noBadge ? (
        <div style={{ backgroundColor: config.backgroundColor, height: '32px', width: '32px' }}>
          {config.badgeCount}
        </div>
      ) : (
        <Badge count={config.badgeCount} style={{ backgroundColor: config.backgroundColor }} />
      )}
    </Tooltip>
  )
}

export const handleConfigurationStatus = (status: string): JSX.Element => {
  const { backgroundColor, fontColor } = statusColors[status] || {
    fontColor: black,
    backgroundColor: black05,
  }

  return <Badge key={status} count={status} style={{ backgroundColor, color: fontColor }} />
}

export const handleConnectivityBadge = (connectivity: string): JSX.Element => {
  switch (connectivity) {
    case 'disconnected':
      return <Badge status="error" />
    case 'connected':
      return <Badge status="success" />
    default:
      return <Badge status="default" color={theme.colors.grey4} /> // deployed to production server
  }
}
