import configProvider from '../../config'
import { log } from '../../logger'
import { mockGetImagesResponse, mockImageResponse } from '../../mock/image'

import { ChargerModelTemplateImageResponse, GetImagesResponse } from '../../models/http'

import { apiCihClient, apiClient } from './client'

export async function UploadChargerModelTemplateImage(image: FormData): Promise<ChargerModelTemplateImageResponse> {
  const url = '/commissioning/api/v1/upload/charger-model-image'
  log('Creating charger model template', { url })

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockImageResponse())
  }

  // ! need to change the apiCihClient to use 'Api_key' instead of 'api_key'
  return apiCihClient(url, {
    method: 'POST',
    body: image,
  })
}

export async function GetImages(): Promise<GetImagesResponse> {
  const url = '/charger-model-template/images'
  log('Getting images', { url })

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockGetImagesResponse())
  }

  return apiClient(url, {})
}
