import { useEffect, useState } from 'react'

import { ApiError } from '../models/error'
import { GetChargerModelTemplate } from '../services/data-provider/template'
import { ChargerModelTemplate } from '../models/charger-model-template'
import { PaginationMetaSnakeCase } from '../models/pagination'

const initialPagination = { current_page: 1, per_page: 20, total_entries: 31 }

export const useGetChargerModelTemplate = (usePagination = true) => {
  const [chargerModelTemplateLoading, setLoading] = useState(false)
  const [chargerModelTemplateError, setError] = useState<ApiError>()
  const [chargerModelTemplates, setChargerModelTemplates] = useState<ChargerModelTemplate[]>([])
  const [pagination, setPagination] = useState<PaginationMetaSnakeCase>(initialPagination)
  const [status, setStatus] = useState('success')

  useEffect(() => {
    getChargerModelTemplateData(usePagination)
  }, [])

  const getChargerModelTemplateData = (usePagination = false, page = 1) => {
    setLoading(true)
    const pageNumber = usePagination ? page : undefined
    GetChargerModelTemplate(pageNumber)
      .then(({ data, status, pagination }) => {
        console.log('🚀 ~ .then ~ pagination:', pagination)
        console.log('🚀 ~ .then ~ status:', status)
        console.log('🚀 ~ .then ~ data:', data)
        setChargerModelTemplates(data)
        setStatus(status)
        setPagination(pagination)
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }

  const handlePaginationChange = (page: number) => {
    setPagination({ ...pagination, current_page: page })
    getChargerModelTemplateData(true, page)
    return
  }

  return {
    chargerModelTemplateLoading,
    chargerModelTemplateError,
    status,
    chargerModelTemplates,
    pagination,
    handlePaginationChange,
    getChargerModelTemplateData,
  }
}
