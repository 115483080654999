import React from 'react'
import { Form, InputNumber, Row } from 'antd'
import { validateNumberWithMaxLimit } from '../../helpers/rules'

interface RatedVoltageFieldsProps {
  styleDisplay: (width: number) => React.CSSProperties
  styleFullWidth: React.CSSProperties
}

const RatedVoltagePowerForm: React.FC<RatedVoltageFieldsProps> = ({ styleDisplay, styleFullWidth }) => {
  return (
    <Row justify="space-between">
      <Form.Item
        name="rated_current"
        label="Power (A)"
        rules={[
          { type: 'number', min: 0, max: 300, required: true, message: 'Please input the power!' },
          ({ getFieldValue }) => ({
            validator: validateNumberWithMaxLimit(getFieldValue, 300),
          }),
          { pattern: /^\d+$/, message: `Please enter a valid number` },
        ]}
        style={styleDisplay(24)}
      >
        <InputNumber placeholder="32" style={styleFullWidth} />
      </Form.Item>
      <Form.Item
        name="rated_power"
        label="Max Output (kW)"
        rules={[{ type: 'number', min: 0, max: 300, required: true, message: 'Please input the max output!' }]}
        style={styleDisplay(24)}
      >
        <InputNumber placeholder="7.2" style={styleFullWidth} />
      </Form.Item>
      <Form.Item
        name="min_input_voltage"
        label="Min. Voltage (V)"
        rules={[{ type: 'number', min: 0, required: true, message: 'Please input the min voltage!' }]}
        style={styleDisplay(24)}
      >
        <InputNumber placeholder="1" style={styleFullWidth} />
      </Form.Item>
      <Form.Item
        name="max_input_voltage"
        label="Max. Voltage (V)"
        rules={[{ type: 'number', min: 0, required: true, message: 'Please input the max voltage!' }]}
        style={styleDisplay(24)}
      >
        <InputNumber placeholder="1" style={styleFullWidth} />
      </Form.Item>
    </Row>
  )
}

export default RatedVoltagePowerForm
