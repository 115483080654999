import { ConfigurationItem } from '../models/charger-config-template'

export const mockChargerConfigResponse: ConfigurationItem = {
  ChargePointVendor: 'LITE-ON',
  ChargePointModel: 'EX-1762-1A31',
  ChargePointSerialNumber: '1A31-1-YYWW-XXXXX',
  HardwareVersion: 'XX-XX',
  FirmwareVersion: 'V1.00.90SWTCH (BBOX: V1.33)',
  ProductionDate: 'YY-KK',
  EvrangeBasicAuthId: '',
  EvrangeBasicAuthPwd: '',
  ConnectorType: 'SAE J1772',
  MaxAmperageConnector: '32.0',
  MeterType: 'Built-in meter',
  ReaderType: 'Built-in RFID',
  DSMRType: 'Not Support',
  Connectivity: 'Wi-Fi',
  BasicAuthId: '',
  BasicAuthPwd: '',
  FTPUserName: '',
  FTPPassword: '',
  MNC: '',
  ICCID: '',
  IMSI: '',
  IMEI: '',
  MEID: '',
  CellularDialNumber: '*99#',
  CellularApnName: '',
  CellularApnPdpType: '1',
  CellularApnUser: '',
  CellularApnPassword: 'sfsdfsdf',
  CellularPinCode: '',
  MobileSignalStrength: '',
  Latitude: '0.000000',
  Longitude: '0.000000',
  LastBootTimestamp: '1710434430650',
  SSID: 'SWTCH',
  BSSID: '',
  WiFiPassword: 'Swtch456!',
  WiFiSecurity: 'AUTO',
  HideWiFiAPSSID: '0',
  WiFiSignalStrength: '83',
  LEDBrightness: '1',
  ChargePointId: '1A31-1-YYWW-XXXXX',
  ProtocolName: 'ocpp1.6J',
  CentralSystemURL: 'ws://deployment.ocpp.swtchenergy.com/v16',
  MessageTransportLayer: 'WS',
  BootNotificationInterval: '60',
  BootNotificationRetries: '-1',
  PDUTimeout: '30',
  DownloadFirmwareInterval: '300',
  DownloadFirmwareRetries: '3',
  UploadDiagnosticsInterval: '300',
  UploadDiagnosticsRetries: '3',
  UnitPrice: '0.00',
  ParkingFee: '0.00',
  ParkingFeeCharging: '0.00',
  DefaultPrice: 'NULL',
  SetUserPrice: 'NULL',
  RunningCost: 'NULL',
  FinalCost: 'NULL',
  CustomDisplayCostAndPrice: '1',
  ActivationFee: '0.00',
  NotificationAfterSoftReset: '0',
  OutputPowerType: 'AC',
  PowerPhaseConnected: '1',
  MaxAmperageHWSetting: '32.0',
  MaxAmperageFWSetting: '32.0',
  PWMAmperage: '0.00',
  RealAmperage: '0.00',
  ColdLoadPickupMaxDelay: '720',
  OfflinePlugAndChargeToggle: 'false',
  PlugAndChargeId: '',
  ReservationSupported: 'true',
  ResumeCharging: 'false',
  rfidAvailability: 'true',
  VentilationArea: 'false',
  SkipHeartBeat: 'true',
  RestartTime: '900',
  RestartCounter: '50',
  TemperatureLow: '-30',
  TemperatureHigh: '88',
  VoltageLow: '170',
  VoltageHigh: '270',
  OperationMode: 'Direct',
  GatewaySerialNumber: '',
  UseExternalGateway: 'false',
  LlmEnable: 'false',
  LlmGroupId: '',
  LlmNumberOfChargers: '1',
  LlmGridMaxAmperage: '100',
  LlmChargingPolicy: 'UD',
  FallbackAmperage: '6.00',
  AllowOfflineTxForUnknownId: 'false',
  AuthorizationCacheEnabled: 'true',
  AuthorizeRemoteTxRequests: 'false',
  BlinkRepeat: '30',
  ClockAlignedDataInterval: '0',
  ConnectionTimeOut: '120',
  GetConfigurationMaxKeys: '128',
  HeartbeatInterval: '10',
  LightIntensity: '100',
  LocalAuthorizeOffline: 'true',
  LocalPreAuthorize: 'true',
  MaxEnergyOnInvalidId: '7680',
  MeterValuesAlignedData:
    'Current.Import,Current.Offered,Energy.Active.Import.Register,Energy.Active.Import.Interval,Power.Offered,Temperature,Voltage',
  MeterValuesAlignedDataMaxLength: '7',
  MeterValuesSampledData:
    'Current.Import,Current.Offered,Energy.Active.Import.Register,Power.Offered,Temperature,Voltage',
  MeterValuesSampledDataMaxLength: '6',
  MeterValueSampleInterval: '300',
  MinimumStatusDuration: '0',
  NumberOfConnectors: '1',
  ResetRetries: '-1',
  ConnectorPhaseRotation: 'NotApplicable',
  ConnectorPhaseRotationMaxLength: '1',
  StopTransactionOnEVSideDisconnect: 'true',
  StopTransactionOnInvalidId: 'true',
  StopTxnAlignedData: 'Energy.Active.Import.Register,Energy.Active.Import.Interval',
  StopTxnAlignedDataMaxLength: '5',
  StopTxnSampledData: 'Energy.Active.Import.Register',
  StopTxnSampledDataMaxLength: '4',
  SupportedFeatureProfiles:
    'Core,Firmware Management,Local Auth List Management,Reservation,Smart Charging,Remote Trigger',
  SupportedFeatureProfilesMaxLength: '6',
  TransactionMessageAttempts: '10',
  TransactionMessageRetryInterval: '10',
  UnlockConnectorOnEVSideDisconnect: 'false',
  WebSocketPingInterval: '180',
  LocalAuthListEnabled: 'true',
  LocalAuthListMaxLength: '1000',
  SendLocalListMaxLength: '1000',
  ReserveConnectorZeroSupported: 'true',
}
