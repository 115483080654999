import React from 'react'
import moment from 'moment'
import momentTz from 'moment-timezone'

interface props {
  date: moment.Moment | string // The date value to be displayed
  timezone?: string // The timezone for formatting the date
  direction?: 'row' | 'below' // The direction of the flex container
}

// export const DateTime: React.FC<props> = ({ date, timezone }) => {
//   // Convert the input date to a moment object if it's provided as a string
//   if (typeof date === 'string') {
//     date = moment(date)
//   }

//   // Define the format for displaying the date and time
//   const format = 'YYYY-MM-DD, HH:mm:ss'
//   return (
//     <span>
//       {date.format(format)}
//       <small> {momentTz.tz(date, timezone).format('(Z)')}</small>
//     </span>
//   )
// }

export const DateTimeV2: React.FC<props> = ({ date, timezone = 'America/Toronto', direction = 'below' }) => {
  // Parse the UTC timestamp

  const torontoDate = momentTz.tz(moment.utc(date), timezone)

  // Convert to Toronto time (Eastern Time Zone)

  // Define the format for date and time display
  const formatDate = 'YYYY-MM-DD' // Example format for date
  const formatTime = 'HH:mm:ss (z)' // Example format for time

  return (
    <div className={`flex-and-gap-${direction}`}>
      <span>{torontoDate.format(formatDate)}</span>
      <span className="paragraph-04-light opacity-06"> {torontoDate.format(formatTime)}</span>
    </div>
  )
}
