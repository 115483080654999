import React from 'react'
import { MainPageWrapper } from '../../atom/load-management/load-management'
import { withAuthenticatedLayout } from '../../components/layouts/layout'
import { SwtchControl } from './swtch-control/swtch-control'

const TenantsBasePage: React.FC = () => {
  return (
    <MainPageWrapper>
      <SwtchControl />
    </MainPageWrapper>
  )
}

export const TenantsPage = withAuthenticatedLayout(TenantsBasePage)
