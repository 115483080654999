import { useState } from 'react'
import { FileImageOutlined, UploadOutlined } from '@ant-design/icons'
import { Form, Select, Button, Input, Avatar, message, Upload, Row } from 'antd'

import { buildApiCihBaseUrl } from '../../helpers/url'
import { getSession } from '../../state/session-store'
import configProvider from '../../config'
import { styleDisplay } from '../../atom/style/display'
import useGetImages from '../../hooks/useGetImages'

interface ImageUploaderProps {
  setImagePath: (path: string) => void
  setImageFileName: (name: string) => void
}

const ImageUploader = ({ setImagePath, setImageFileName }: ImageUploaderProps) => {
  const [file, setFile] = useState<any>(null)
  const [imageUploading, setImageUploading] = useState(false)
  const [imageSource, setImageSource] = useState<string | null>(null)

  const { images, loading } = useGetImages()

  const session = getSession()

  const checkFileFormat = (file: any) => {
    const isPNG = file.type === 'image/png'
    if (!isPNG) {
      setFile(null)
      message.error(`${file.name} is not a png file`)
    }
    return isPNG || Upload.LIST_IGNORE
  }

  const checkFileSize = (file: any) => {
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      setFile(null)
      message.error('Image must be smaller than 2MB!')
      return setFile(null)
    }
  }

  const handleFileChange = (e: any) => {
    if (e === undefined || e === null || e === '' || e.target.files.length === 0) {
      setImagePath('')
      setImageFileName('')
      setFile(null)
      setImageSource(null)
      return
    }
    const selectedFile = e.target.files[0]
    if (!selectedFile) {
      setFile(null)
      return
    }
    if (selectedFile) {
      checkFileFormat(selectedFile)
      checkFileSize(selectedFile)
      setFile(selectedFile)
    } else {
      setFile(null)
    }
  }

  const handleImageSubmit = async () => {
    if (!file) {
      message.error('Please select a file to upload.')
      return
    }

    const formData = new FormData()
    formData.append('file', file)

    try {
      setImageUploading(true)
      const response = await fetch(`${buildApiCihBaseUrl()}/commissioning/api/v1/upload/charger-model-image`, {
        method: 'POST',
        body: formData,
        headers: {
          Api_key: configProvider.config.apiKey,
          Authorization: `Bearer ${session?.accessToken}`,
        },
      })

      if (!response.ok) {
        if (response.status === 400) {
          message.error('Bad Request: Error parsing the request body.')
        } else if (response.status === 422) {
          message.error('Unprocessable Content: The server cannot process the file.')
        } else {
          message.error(`Upload failed with status ${response.status}.`)
        }
        return
      }

      const result = await response.json()
      message.success('File uploaded successfully!')
      setImagePath(result.data.image_path)
      setImageFileName(result.data.image_file_name)
    } catch (error) {
      message.error('Upload failed.')
      console.error('Error:', error)
    }
    setImageUploading(false)
  }

  const handleExistingImageSelect = (value: string) => {
    setImageSource('existing')
    handleExistingImageChange(value)
  }

  const handleFileSelect = (e: any) => {
    setImageSource('upload')
    handleFileChange(e)
  }

  const handleExistingImageChange = (value: any) => {
    if (value === undefined || value === null || value === '') {
      setImagePath('')
      setImageFileName('')
      setImageSource(null)
      return
    }
    const selectedImage = images.find((image) => image.imageFileName === value)
    const imagePath = selectedImage ? selectedImage.imagePath : ''
    setImagePath(imagePath)
    setImageFileName(value)
  }

  const handleExistingImageHelp = () => {
    if (images.length === 0) {
      return 'Sorry, no existing image to select'
    }
    return null
  }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
    //change "return e && e.fileList" for
    // return e && e.fileList.slice(-1)
  }

  return (
    <>
      <h4>Choose either to upload or select an existing image</h4>
      <Row justify="space-between">
        <Form.Item
          name="image_path"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          validateStatus={images.length === 0 ? 'error' : imageSource === 'upload' ? 'warning' : 'success'}
          help={handleExistingImageHelp()}
          style={styleDisplay(49)}
        >
          <Select
            loading={loading}
            disabled={loading || images.length === 0 || imageSource === 'upload'}
            placement="topLeft"
            placeholder="Select existing image"
            dropdownMatchSelectWidth={false}
            allowClear
            onChange={handleExistingImageSelect}
          >
            {images.map((image) => (
              <Select.Option key={image.thumbUrl} value={image.imageFileName}>
                <Avatar src={image.thumbUrl} gap={4} size="small" icon={<FileImageOutlined />} /> {image.imageFileName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <span style={{ display: 'flex', alignItems: 'normal', marginTop: '0.6rem' }}>OR</span>
        <Form.Item
          name="image_path"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          validateStatus={imageSource === 'existing' ? 'warning' : 'success'}
          style={styleDisplay(46)}
        >
          <Input
            type="file"
            onChange={handleFileSelect}
            allowClear
            disabled={imageSource === 'existing'}
            style={{
              maxWidth: '70%',
              padding: '0.3rem 1px',
              marginRight: '1rem',
              borderRadius: '48px',
              verticalAlign: 'top',
            }}
            className="custom-input"
          />
          <Button icon={<UploadOutlined />} disabled={!file} loading={imageUploading} onClick={handleImageSubmit}>
            Upload
          </Button>
        </Form.Item>
      </Row>
    </>
  )
}

export default ImageUploader
