import { styled } from '../../theme'

interface styleProps {
  status?: string
}

export const ChargerTableWrapper = styled.div`
  font-family: ${(props) => props.theme.fontFamily.regular};

  .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th:first-child {
    width: 349px;
    min-width: 349px;
    max-width: 349px;
  }
  .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th:last-child {
    width: 443px;
  }
  .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th {
    width: 207px;
    border-right: 1px solid #dedede;
    border-bottom: 1px solid #dedede;
  }

  .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table {
    margin-top: -1px;
    border-top: 1px solid #dedede;
  }
  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    padding: 10px 20px;
  }
  .ant-table-tbody > tr > td > form > .ant-form-item {
    margin-bottom: 0px;
    display: flex;
  }
  .ant-table-tbody > tr > td {
    border-right: 1px solid #dedede !important;
    border-bottom: 1px solid #dedede !important;
  }
  .ant-table-tbody > tr > td > .fallback-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .ant-table-tbody > tr > td > .fallback-row > div > .ant-form-item {
    margin-bottom: 0px;
  }

  .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th {
    background: #fafafa;
    padding: 10px 20px;
  }

  .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th {
    background: #fafafa;
    padding: 10px 20px;
  }

  .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table.ant-table-bordered
    > .ant-table-container {
    border-left: 1px solid #dedede;
  }

  .ant-table-tbody > tr:hover > td {
    background-color: transparent;
  }
`

export const DefaultRowWrapper = styled.div`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #242e2c;
  opacity: 0.30000001192092896;
  font-family: ${(props) => props.theme.fontFamily.regular};
`

export const RemoveChargerLineBreakWrapper = styled.div`
  border-left: 1px solid #dedede;
  height: 100%;
  position: absolute;
`
export const RemoveChargerWrapper = styled.div`
  padding-left: 20px;
  cursor: pointer;

  svg {
    margin-top: 5px;
  }
`

export const ChargerNameWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 11px;
  }

  .ant-select > .ant-select-selector {
    border: none;
    align-items: center;
    padding-left: 0px;
  }

  .ant-select > .ant-select-selector > .ant-select-selection-search {
    margin-left: -11px;
  }

  .ant-select > .ant-select-selector > .ant-select-selection-search > input {
    border-radius: 0 !important;
    padding-top: 7px;
  }

  .ant-select {
    width: 90%;
  }

  .ant-select-selection-item, .ant-select-multiple .ant-select-selection-item { 
    background-color: transparent;
  }

  .ant-select-selection-item > div {
    margin-top: 30px;
  }
`

export const ErrorMessageWrapper = styled.div<styleProps>`
  color: ${(props) => (props.status === 'error' ? '#ec4747' : '#faad14')};
  font-family: ${(props) => props.theme.fontFamily.regular};
  margin-top: 5px !important;
  transform: translateY(-1000px);
  animation-name: from-top;
  animation-fill-mode: forwards;
  animation-duration: 0.2s;
  animation-timing-function: ease-out;
  @keyframes from-top {
    0% {
      transform: translateY(-8px);
    }
    100% {
      transform: translateY(0);
    }
  }
`
