import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Space, Tooltip } from 'antd'
import { List } from 'antd-mobile'
import styled from 'styled-components'
import MobileListView, { MobileListViewI } from '../../../atom/mobile-list-view'
import { PaginationMeta } from '../../../models/pagination'
import { Template } from '../../../models/template'
import { theme } from '../../../theme'

const { Item } = List

const MobileTemplateListView = MobileListView as MobileListViewI<Template>

interface props {
  templates: Template[]
  onClickEditBtn: (record: Template) => void
  onClickDeleteBtn: (record: Template) => void
  onClickViewTemplatesBtn: (record: Template) => void
  onPaginationChange?: (page: number, pageSize?: number) => void
  pagination?: PaginationMeta | undefined
  loading: boolean
}

const CustomFlexBasis = styled.div`
  .am-list-item .am-list-line .am-list-extra {
    flex-basis: 70%;
  }
`

export const TemplatesMobileView: React.FC<props> = ({
  templates,
  loading,
  onClickEditBtn,
  onClickDeleteBtn,
  onClickViewTemplatesBtn,
}) => {
  const renderListItem = (template: Template) => {
    return (
      <CustomFlexBasis>
        <List renderHeader={`${template.name}`}>
          <Item extra={template.model}>Model </Item>
          <Item extra={template.name}>Name </Item>
          <Item extra={template.vendor}>Vendor </Item>
          <Item extra={template.updatedAt}>Updated At </Item>
          <Item extra={template.updatedBy}>Updated By </Item>
          <Item
            extra={
              <Button type="primary" onClick={() => onClickViewTemplatesBtn(template)}>
                View Templates
              </Button>
            }
          >
            Templates
          </Item>
          <Item
            extra={
              <Space size="large">
                <Tooltip title="Edit Template">
                  <Button
                    shape="circle"
                    className="edit-btn"
                    color={theme.colors.red}
                    icon={<FontAwesomeIcon icon={faPen} size="lg" color={theme.colors.yellow} />}
                    onClick={() => {
                      //setTemplateRecord(record)
                      //setTemplateID(_id)
                      // setEditTemplateModalVisible(true)
                      onClickEditBtn(template)
                    }}
                  />
                </Tooltip>

                <Tooltip title="Delete Template">
                  <Button
                    shape="circle"
                    className="delete-btn"
                    color={theme.colors.red}
                    icon={<FontAwesomeIcon icon={faTrash} size="lg" color={theme.colors.red} />}
                    onClick={() => onClickDeleteBtn(template)}
                  />
                </Tooltip>
              </Space>
            }
          >
            Actions
          </Item>
        </List>
      </CustomFlexBasis>
    )
  }
  return <MobileTemplateListView data={templates} renderItem={renderListItem} loading={loading} />
}
