import { Form, Input, Row, Select } from 'antd'

import { requiredRule } from '../../helpers/rules'
import { Environment } from '../../models/environment'
import { Template } from '../../models/template'

interface props {
  environments: Environment[]
  environmentLoading: boolean
  configLoading: boolean
  configTemplates: Template[]
  onOCPPEnvironmentNameChange: (value: string) => void
}

const ConfigTemplateAndEnvironmentInput: React.FC<props> = ({
  environments,
  environmentLoading,
  configLoading,
  configTemplates,
  onOCPPEnvironmentNameChange,
}) => {
  return (
    <Row justify="space-between">
      <Form.Item
        name="config_template_id"
        label="Config. Template"
        style={{ display: 'inline-block', width: '49%', marginBottom: '2.5rem' }}
        rules={[requiredRule('config template')]}
      >
        <Select
          placeholder="LITE-ON GENERIC"
          loading={configLoading}
          dropdownMatchSelectWidth={false}
          disabled={configLoading || configTemplates.length === 0}
        >
          {configTemplates.map((config) => (
            <Select.Option key={config._id} value={config._id}>
              {config.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="environment_id"
        label="Environment"
        style={{ display: 'inline-block', width: '49%', marginBottom: '2.5rem' }}
      >
        <Select
          loading={environmentLoading}
          placeholder="Production"
          dropdownMatchSelectWidth={false}
          disabled={environmentLoading || environments.length === 0}
          onChange={onOCPPEnvironmentNameChange}
        >
          {environments.map((environment) => (
            <Select.Option key={environment._id} value={environment._id}>
              {environment.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="ocppEnvironment" label="Environment" hidden>
        <Input />
      </Form.Item>
    </Row>
  )
}

export default ConfigTemplateAndEnvironmentInput
