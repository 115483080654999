import configProvider from '../../config'
import { chargerQuery } from '../../helpers/charger'
import { addQueryString } from '../../helpers/url'
import { log } from '../../logger'
import {
  mockChargerResponse,
  mockCommissioningChargerResponse,
  mockDeleteChargerResponse,
  mockUpdateAndRestartChargerResponse,
} from '../../mock/charger'
import { Charger } from '../../models/charger'
import { HomeFilter } from '../../models/filter'

import { Message } from '../../models/message'
import { UpdateBase } from '../../models/template'

import { apiClient } from './client'

export async function GetAllChargers(filter: HomeFilter): Promise<Charger[]> {
  let url = '/chargers'
  log('fetching all chargers', { url })

  const queryParams = chargerQuery(filter)

  url = addQueryString(url, queryParams)

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockChargerResponse(filter.charger_serial_numbers, filter.status))
  }

  return apiClient(url, {})
}

export async function DeleteCharger(serialNumber: string): Promise<Message> {
  const url = `/chargers/${serialNumber}`
  log('deleting environment', { url })

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockDeleteChargerResponse(serialNumber))
  }

  return apiClient(url, {
    method: 'DELETE',
  })
}

export async function UpdateAndRestartCharger(serialNumbers: UpdateBase[]): Promise<Message> {
  const url = `/charger_deployment/update`
  log('updaing and restarting charger', { url: url })

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockUpdateAndRestartChargerResponse)
  }

  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      data: serialNumbers,
    }),
  })
}

export async function VerifyCharger(serialNumbers: UpdateBase[]): Promise<Message> {
  const url = `/charger_deployment/verify`
  log('verifying charger', { url: url })

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockUpdateAndRestartChargerResponse)
  }

  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      data: serialNumbers,
    }),
  })
}

export async function CommissionCharger(serialNumbers: UpdateBase[]): Promise<Message> {
  const url = `/charger_deployment/commission`
  log('Commissioning charger', { url: url })

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockCommissioningChargerResponse)
  }

  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      data: serialNumbers,
    }),
  })
}
