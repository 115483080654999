import { InputNumber } from 'antd'
import { styled } from '../theme'

export type AllStatus = 'Enabled' | 'Disabled'

// Define the prop types for the component
interface props {
  defaultValue?: any
  value: any
  ampFormat?: boolean
  onChange: (value: number) => void
}

// Styled component for customizing the appearance of InputNumber
export const InputNumberWrapper = styled.div`
  display: flex;

  .ant-input-number {
    width: 120px;
    height: 38px;
    border-radius: 20px;
  }

  .ant-input-number:hover {
    border-color: #30d7ba;
  }

  .ant-input-number-focused {
    box-shadow: none;
    border-color: #30d7ba;
  }

  .ant-input-number > .ant-input-number-input-wrap > .ant-input-number-input {
    padding: 5px 14px 0px 14px;
  }

  .ant-input-number > .ant-input-number-handler-wrap {
    border-left: none;
    margin-right: 15px;
    opacity: 1;
  }

  .ant-input-number > .ant-input-number-handler-wrap > .ant-input-number-handler {
    border-left: none;
    overflow: initial;
  }

  .ant-input-number > .ant-input-number-handler-wrap > .ant-input-number-handler:active {
    background: transparent;
  }

  .ant-input-number > .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
  .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
    font-size: 10px;
    font-family: ${(props) => props.theme.fontFamily.regular};
  }

  .ant-input-number > .ant-input-number-handler-wrap > .ant-input-number-handler > .ant-input-number-handler-up-inner {
    top: 0;
    margin-top: 6px;
  }

  .ant-input-number
    > .ant-input-number-handler-wrap
    > .ant-input-number-handler
    > .ant-input-number-handler-down-inner {
    top: 0;
    bottom: 5px;
    transform: none;
  }

  .ant-input-number
    > .ant-input-number-handler-wrap
    > .ant-input-number-handler
    > .ant-input-number-handler-down-inner:hover {
    top: 0;
    bottom: 5px;
    transform: none;
  }

  .ant-input-number > .ant-input-number-handler-wrap > .ant-input-number-handler:hover {
    transition: none;
    transform: none;
  }

  .ant-input-number > .ant-input-number-handler-wrap > .ant-input-number-handler-down {
    border-top: none;
    top: 17px;
    position: absolute;
  }
`

// Custom InputNumber component
export const CustomInputNumber: React.FC<props> = ({ value, ampFormat, defaultValue, onChange }) => {
  return (
    <InputNumberWrapper>
      <InputNumber
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        controls={true}
        formatter={ampFormat ? (value) => `${value} amp` : (value) => `${value}`}
        parser={
          ampFormat
            ? (value: string | undefined) => +value!.replace('amp', '')
            : (value: string | undefined) => +value!.replace('', '')
        }
      />
    </InputNumberWrapper>
  )
}
