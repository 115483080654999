import { Environment } from '../models/environment'
import { Message } from '../models/message'

export const mockAllChargerEnvironments: Environment[] = [
  {
    name: 'Production',
    ocppUrl: 'ws://ocpp.swtchev.com',
    updatedAt: '2023-06-13T00:56:00.631000',
    updatedBy: 'Alton',
    createdAt: '2023-06-13T00:56:00.631000',
    deletedAt: null,
    _id: '6487bea061dfd24a73dc7129',
  },
  {
    name: 'Pre Prod',
    ocppUrl: 'ws://preprod.ocpp.swtchenergy.com',
    updatedAt: '2023-06-13T00:56:10.889000',
    updatedBy: 'Alton',
    createdAt: '2023-06-13T00:56:10.889000',
    deletedAt: null,
    _id: '6487beaa61dfd24a73dc712b',
  },
  {
    name: 'Staging',
    ocppUrl: 'ws://staging.ocpp.swtchenergy.com',
    updatedAt: '2023-06-13T00:56:26.573000',
    updatedBy: 'Alton',
    createdAt: '2023-06-13T00:56:21.248000',
    deletedAt: null,
    _id: '6487beb561dfd24a73dc712d',
  },
]

export const mockCreateNewChargerEnvironment: Message = {
  message: 'Environment Preproduction SWTCH created',
  errors: [],
  warnings: [],
}

export const mockEditChargerEnvironment: Message = {
  message: 'Environment Preproduction SWTCH updated',
  errors: [],
  warnings: [],
}

export const mockDeleteEnvironment: Message = {
  message: 'Environment Preproduction SWTCH deleted',
  errors: [],
  warnings: [],
}

export const mockCreateEnvironment: Message = {
  message: 'Environment Preproduction SWTCH created',
  errors: [],
  warnings: [],
}
