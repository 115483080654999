import { Button, Col, Form, Input, message, Modal, Row, Space } from 'antd'
import { useState } from 'react'

import { formLayout, tailLayout } from '../../../atom/form/page-layout'
import { AlertError } from '../../../components/error'
import { AlertWarning } from '../../../components/warning'
import { isJson } from '../../../helpers/charger'
import { log } from '../../../logger'
import { ApiError } from '../../../models/error'
import { Template } from '../../../models/template'
import { CreateNewTemplate } from '../../../services/data-provider/template'

interface props {
  visible: boolean
  onCancel(): void
  onSubmit(): void
}

export const CreateNewTemplateModal: React.FC<props> = ({ visible, onCancel, onSubmit }) => {
  const [loading, setLoading] = useState(false)
  const [CreateNewTemplateForm] = Form.useForm()
  const [error, setError] = useState<ApiError>()

  const handleOk = (values: any) => {
    setLoading(true)

    const { template } = values

    const templateObj: { [key: string]: string } = JSON.parse(`{ ${template} }`)

    const createNewTemplateParams: Omit<Template, '_id' | 'updatedAt' | 'createdAt' | 'deletedAt'> = {
      vendor: values['vendor'],
      name: values['name'],
      model: values['model'],
      updatedBy: values['updatedBy'],
      template: templateObj,
    }

    CreateNewTemplate(createNewTemplateParams)
      .then((resp) => {
        log(`Created new template ${resp.message}`)
        message.success(resp.message)
        if (
          (Array.isArray(resp.errors) && resp.errors.length > 0) ||
          (Array.isArray(resp.warnings) && resp.warnings.length > 0)
        ) {
          setError(
            new ApiError(
              'Something went wrong with this request. Please contact customer support if this issue persists.',
              resp.errors,
              resp.warnings,
            ),
          )
        }
        onSubmit()
        CreateNewTemplateForm.resetFields()
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleCancel = () => {
    CreateNewTemplateForm.resetFields()
    onCancel()
  }

  return (
    <>
      <AlertError error={error} closable={true} />
      <AlertWarning error={error} closable={true} />
      <Row gutter={[8, 8]}>
        <Col md={24} lg={24} xl={24} xxl={24} style={{ textAlign: 'end' }}>
          <Modal
            title="Create New Template"
            visible={visible}
            confirmLoading={loading}
            footer={null}
            onCancel={handleCancel}
            width={1024}
          >
            <Form {...formLayout} form={CreateNewTemplateForm} onFinish={handleOk} style={{ marginTop: '2rem' }}>
              <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Name is required!' }]}>
                <Input />
              </Form.Item>
              <Form.Item label="Model" name="model">
                <Input />
              </Form.Item>
              <Form.Item label="Vendor" name="vendor">
                <Input />
              </Form.Item>
              <Form.Item
                label="Updated By"
                name="updatedBy"
                rules={[{ required: true, message: 'Updated By is required!' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Template"
                name="template"
                rules={[
                  {
                    required: true,
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.reject('Template is required!')
                      }
                      if (isJson(value)) {
                        return Promise.resolve()
                      } else {
                        return Promise.reject(
                          <>
                            <p>Template is required in the correct format:</p>
                            <p>"key1":"value1",</p>
                            <p>"key2":"value2"</p>
                          </>,
                        )
                      }
                    },
                  },
                ]}
              >
                <Input.TextArea rows={20} />
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Space>
                  <Button onClick={handleCancel}>Cancel</Button>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Modal>
        </Col>
      </Row>
    </>
  )
}
