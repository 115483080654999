import { useEffect, useState, useRef } from 'react'
import { LatLng } from 'leaflet'
import { Checkbox, Form, FormInstance, message } from 'antd'

import { useNotifications } from '../hooks/useNotifications'
import { CreateTenant, FindTenants } from '../services/data-provider/tenants'

import { ApiError, SwtchError } from '../models/error'
import { GeocodingSuccess, SwtchAddress } from '../models/geocoding'

import TimeZone from '../services/data-provider/timezone'
import { getLocationFromAddress } from '../services/data-provider/geocoding'
import { TenantRef } from '../models/tenant'

import { twentyFourSevenWeeklyScheduleValues } from '../helpers/weeklySchedule'

interface UseCreateTenantResult {
  error?: ApiError
  loading: boolean
  searchingAddress: boolean
  geocodingError?: SwtchError
  geocodedLocation?: LatLng
  timeZone: string
  form: FormInstance
  tenantsNames: Set<string>
  addressForGeocoding?: SwtchAddress
  nameInputRef: React.RefObject<any>
  handleAddressChange: (evt: React.ChangeEvent<HTMLInputElement>) => void
  handleCityChange: (evt: React.ChangeEvent<HTMLInputElement>) => void
  checkAndSubmit: () => void
}

export const useCreateTenant = (
  onOk: () => void,
  onTenantCreated?: (tenant: TenantRef) => void,
): UseCreateTenantResult => {
  const [loading, setLoading] = useState(false)
  const [searchingAddress, setSearchingAddress] = useState(false)
  const [existingTenants, setExistingTenants] = useState<TenantRef[]>([])
  const [addressForGeocoding, setAddressForGeocoding] = useState<SwtchAddress>()
  const [geocodedLocation, setGeocodedLocation] = useState<LatLng | undefined>(undefined)
  const [geocodingError, setGeocodingError] = useState<SwtchError>()
  const [timeZone, setTimeZone] = useState<string>('America/Toronto')
  const [error, setError] = useState<ApiError>()

  const { openSuccessNotification } = useNotifications()
  const [form] = Form.useForm()

  const nameInputRef = useRef<any>(null)

  useEffect(() => {
    FindTenants()
      .then((tenants) => setExistingTenants(tenants))
      .catch((err: any) => {
        setExistingTenants([])
        message.error(err.description)
        setError(err)
      })
  }, [])

  useEffect(() => {
    if (addressForGeocoding) {
      geocodeAddress(addressForGeocoding)
    }
  }, [addressForGeocoding])

  const tenantsNames = new Set(existingTenants.map((tenant) => tenant.name))

  const initializeTimeZone = async (lat: number, long: number) => {
    const result = await TimeZone(lat, long)
    if (result.found) {
      form.setFieldsValue({ timezone: result.timeZoneId })
      setTimeZone(result.timeZoneId)
    }
  }

  const geocodeAddress = (address: SwtchAddress) => {
    setGeocodingError(undefined)

    const geocodingResultAdmissible = (result: GeocodingSuccess): boolean => {
      if (address.province && address.country) {
        return (
          !!result.province &&
          !!result.country &&
          result.province === address.province &&
          result.country === address.country
        )
      } else return true
    }

    getLocationFromAddress(address).then((result) => {
      if (result.found) {
        if (!geocodingResultAdmissible(result)) {
          setGeocodedLocation(undefined)
          setGeocodingError(new SwtchError(`${geocodingError} ${address.province}`))
        } else {
          setTimeout(() => {
            setSearchingAddress(false)
          }, 2000)
          const { numberAndStreet, city, postalCode, country, province } = result
          form.setFieldsValue({
            address: numberAndStreet || '',
            city: city || '',
            postalCode: postalCode || '',
            province: [country, province],
          })

          setTimeout(() => {
            setGeocodedLocation(new LatLng(result.lat, result.lng))
            initializeTimeZone(result.lat, result.lng)
            setGeocodingError(undefined)
          }, 2000)
        }
      } else {
        setTimeout(() => {
          setSearchingAddress(false)
          setGeocodedLocation(undefined)
          setGeocodingError(new SwtchError(`${result.message}`, [`${result.code}: ${result.message}`]))
        }, 2000)
      }
    })
  }

  const handleAddressChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    const inputtedAddress = evt.target.value
    if (inputtedAddress.length >= 5) {
      setAddressForGeocoding({ ...addressForGeocoding, numberAndStreet: evt.target.value })
      return setSearchingAddress(true)
    }
  }

  const handleCityChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setAddressForGeocoding({ ...addressForGeocoding, city: evt.target.value })
  }

  const checkAndSubmit = () => {
    setError(undefined)

    form
      .validateFields()
      .then((values) => {
        values = {
          accessCode: values['accessCode'],
          name: values['name'].trim(),
          displayName: undefined,
          location: {
            address: values.address,
            city: values.city,
            postalCode: values.postalCode,
            province: values.province[1],
            country: values.province[0],
            latitude: geocodedLocation?.lat,
            longitude: geocodedLocation?.lng,
            listingTimezone: timeZone,
          },
          publishToOcpi: values.publishToOcpi,
          weeklySchedules: twentyFourSevenWeeklyScheduleValues,
        }
        setLoading(true)
        CreateTenant(values)
          .then((resp) => {
            form.resetFields()
            openSuccessNotification(
              <>
                <Checkbox checked={true} style={{ marginRight: '10px' }} />A site has been created: <b>{resp.name}</b>
              </>,
              { width: '100%' },
            )
            onOk()
            // Call the onTenantCreated callback if it exists
            onTenantCreated && onTenantCreated(resp)
          })
          .catch((error) => setError(error))
          .finally(() => setLoading(false))
      })
      .catch((info) => console.log('Validate Failed:', info))
  }

  return {
    loading,
    error,
    searchingAddress,
    geocodingError,
    geocodedLocation,
    timeZone,
    form,
    tenantsNames,
    nameInputRef,
    addressForGeocoding,
    handleAddressChange,
    handleCityChange,
    checkAndSubmit,
  }
}
