import { Col, Form, Input, Row } from 'antd'
import React from 'react'

import { noSpecialCharacters, requiredRule } from '../../helpers/rules'
import { getRandomLetter } from '../../helpers/letter'
import { Charger } from '../../models/charger'
import { ChargerModelTemplate } from '../../models/charger-model-template'

interface ChargerConnectorListingInputProps {
  chargers: Charger[]
  selectedChargerModelTemplate?: ChargerModelTemplate
}

const ChargerConnectorListingInput: React.FC<ChargerConnectorListingInputProps> = ({
  chargers,
  selectedChargerModelTemplate,
}) => {
  return (
    <>
      {chargers.map((charger, chargerIndex) => (
        <Row wrap key={charger.serialNumber}>
          {[...Array(selectedChargerModelTemplate?.total_connectors || 1)].map((_, connectorIndex) => (
            <React.Fragment key={connectorIndex}>
              <Col flex="auto">
                <Form.Item
                  label="Charger S/N"
                  name={['chargers', chargerIndex, 'serial_number']}
                  initialValue={charger.serialNumber}
                  style={{ marginRight: '1rem' }}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="auto">
                <Form.Item
                  label="Listing Title"
                  name={['chargers', chargerIndex, 'connectors', connectorIndex, 'listing', 'title']}
                  rules={[requiredRule('listing title'), noSpecialCharacters]}
                  style={{ marginRight: '1rem' }}
                >
                  <Input placeholder={`${getRandomLetter()}R0${connectorIndex + 1}`} />
                </Form.Item>
              </Col>
              <Col flex="auto">
                <Form.Item
                  label="Connector ID"
                  name={['chargers', chargerIndex, 'connectors', connectorIndex, 'connector_id']}
                  initialValue={connectorIndex + 1}
                  style={{ marginRight: '1rem' }}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </React.Fragment>
          ))}
        </Row>
      ))}
    </>
  )
}

export default ChargerConnectorListingInput
