import React from 'react'
// import AuthenticatedLayout from "./authenticated"
import UnauthenticatedLayout from './unauthenticated'
import { AuthenticatedLayout } from './authenticated'

/**
 * Higher-order component that wraps the provided Component with an AuthenticatedLayout
 */
export function withAuthenticatedLayout<T extends React.ComponentType<any>>(
  Component: T,
): React.FC<React.ComponentProps<T>> {
  return function withAuthenticatedLayout2(props: React.ComponentProps<T>) {
    return (
      <AuthenticatedLayout>
        <Component {...props} />
      </AuthenticatedLayout>
    )
  }
}

/**
 * Higher-order component that wraps the provided Component with an UnauthenticatedLayout
 */
export function withUnauthenticatedLayout<T extends React.ComponentType<any>>(
  Component: T,
): React.FC<React.ComponentProps<T>> {
  return function withUnauthenticatedLayout2(props: React.ComponentProps<T>) {
    return (
      <UnauthenticatedLayout>
        <Component {...props} />
      </UnauthenticatedLayout>
    )
  }
}
