import { Form, Input, InputNumber, Row, Select } from 'antd'
import { MustBeDecimalNumber, MustBePositiveIntegers } from '../../components/rules/rules'
import { requiredRule } from '../../helpers/rules'
import { styleDisplay } from '../../atom/style/display'
import { ChargerModelMetadata } from '../../models/charger-model-template'

const styleFullWidth = { width: '100%', borderRadius: '48px' }

interface PhysicalSpecFormProps {
  loading: boolean
  metadata?: ChargerModelMetadata
  onSupportPOSChange: (value: any) => void
}

const PhysicalSpecForm: React.FC<PhysicalSpecFormProps> = ({ loading, metadata, onSupportPOSChange }) => {
  return (
    <Row justify="space-between">
      <Form.Item
        name="cable_length"
        label="Cable Length (ft)"
        rules={[requiredRule('cable length'), MustBeDecimalNumber]}
        style={styleDisplay(24)}
      >
        <InputNumber placeholder="1" style={styleFullWidth} />
      </Form.Item>
      <Form.Item
        name="total_connectors"
        label="No. of Connectors"
        style={styleDisplay(24)}
        rules={[MustBePositiveIntegers]}
      >
        <InputNumber placeholder="1" style={styleFullWidth} />
      </Form.Item>
      <Form.Item name="display_size" label="Display" style={styleDisplay(24)}>
        <Select
          placeholder="LED Strip"
          loading={loading}
          disabled={loading}
          dropdownMatchSelectWidth
          allowClear
          showSearch
        >
          {metadata?.display_size.map((size) => (
            <Select.Option key={size} label={size} value={size}>
              {size || 'None'}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="support_pos_id" label="Support POS" style={styleDisplay(24)}>
        <Select
          placeholder="Nayax"
          loading={loading}
          disabled={loading}
          dropdownMatchSelectWidth
          allowClear
          showSearch
          onChange={onSupportPOSChange}
        >
          {metadata?.support_pos.map((pos) => (
            <Select.Option key={pos.id} label={pos.name} value={pos.id}>
              {pos.name || 'None'}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="support_pos" label="Support POS Name" hidden>
        <Input />
      </Form.Item>
    </Row>
  )
}

export default PhysicalSpecForm
