import { ChargerDetails } from './charger'

export const circuitType = ['Dedicated', 'Mixed']
export type CircuitType = 'Dedicated' | 'Mixed'

export const PanelType = {
  dedicated: 'Dedicated',
  mixed: 'Mixed',
}

export interface SessionsResponse {
  data: [AllCircuitData[]]
  message: string
  code: number
}

export interface Panel {
  id: string
  name: string
}

export interface UpdateBy {
  name: string | undefined
  date: string | undefined
}

export interface CTClamp {
  ip_address?: string | null
  port?: number | null
  id: string
}
export interface AllLoadManagements {
  tenant_id: string
  tenant_name: string
  status: boolean
  panel_type: string
  panel: Panel
  existing_load: string | number
  total_evse: number
  ct_clamp: CTClamp
  instance: Instance
  updated_by: UpdateBy
}
export interface AllCircuitData {
  tenant_id: string
  tenant_name: string
  circuits: Circuit[]
  updated_by?: string
  updated_at?: string
}

export interface CircuitRef {
  id: number
  circuit_id: number
  name: string
}

export interface CircuitDetails extends CircuitRef {
  existing_load: number
  circuit_size: number
  note: string
}

export interface Circuit {
  id: string
  circuit_id: string
  name: string
  load_management: boolean
  type: string | CircuitType
  ct_clamp: CtClamp
  existing_load: number
  circuit_size: number
  note: string
  branches: Branch[]
  session?: Session
  two_stage: boolean
}

export interface CtClamp {
  id: string
  enabled: boolean
  ip_address: string | null
  port: number | null
}

export interface Branch {
  id: string
  name: string
  load_management: boolean
  breaker_size: number
  end_point: null
  chargers: ChargerDetails[]
  session?: Session
}

export interface BranchData {
  branch_name: string
  load_management: boolean
  breaker_size: number
  end_point: string | null
}
export interface Session {
  ssh_username: string
  ssh_instance: string
  region_name: string
  reverse_proxy_port: string
  ocpp_url: string
  instance_port: number
  evidect_ip: string
  evidect_port: string
}

export interface EditedCircuit extends Circuit {
  edited?: boolean
}

export interface CircuitBranchProps extends ChargerDetails {
  circuitId: number
  branchId: number
  circuitIndex: number
  branchIndex: number
  circuitName: string
  branchName: string
  circuitBranch: string[]
}

export interface EditedBranch extends Branch {
  edited?: boolean
}

export interface EndPoint {
  [key: string]: {
    circuit: string
    branch: string
    url: string
  }
}

export interface EndPointData {
  circuit: string
  branch: string
  url: string
}

export interface Instance {
  instanceIp: string
  instancePort: string
  instanceNumber: string
}
