import { ChargerErrorType, ConfigurationState, ConfigurationTemplate } from './charger-config-template'
import { ConfigurationStateType } from './configuration'
import { ListingRef } from './listing'
import { TenantRef } from './tenant'

export type ChargePointStatusType =
  | 'Unknown'
  | 'Available'
  | 'Preparing'
  | 'Charging'
  | 'SuspendedEVSE'
  | 'SuspendedEV'
  | 'Finishing'
  | 'Reserved'
  | 'Unavailable'
  | 'Faulted'

export type OcppVersion = 'ocpp1.6' | 'ocpp2.0.1'
export const phaseType = ['AB', 'AC', 'BC']
export type PhaseType = (typeof phaseType)[number]

export type ChargerModelType = 'Level 2' | 'DC'
export const ChargingType = ['Level 1', 'Level 2', 'DC', 'L2&DCFC']

export const OCPPEnvironment = ['production', 'deployment', 'development', 'preprod']
export type OCPPEnvironmentType = (typeof OCPPEnvironment)[number]

export interface Charger {
  _id: string
  chargerConfigurations: []
  serialNumber: string
  createdAt: string
  deletedAt: string | null
  chargePointStatus: ChargePointStatus
  connectionStatus: ConnectionStatus
  configurationState: ConfigurationState
  configurationTemplate: ConfigurationTemplate
  info: ChargerInfo
  listings: ListingRef[]
  errors: ChargerErrorType[]
}

export interface ChargerInfo {
  vendor: string
  model: string
  firmwareVersion: string | null
  ocppVersion: OcppVersion
  ocppEnvironment: OCPPEnvironmentType
  updatedAt: string
  connectors: ConnectorAndListing[]
}

export interface ChargerOcppStatus {
  serialNumber: string
  mrid: string
  status: ChargePointStatusType
}

export interface ChargePointStatus {
  status: ChargePointStatusType
  previousStatus: ChargePointStatusType
  updatedAt: string
}

export interface ConnectionEnvironment {
  environment: 'production' | 'deployment' | 'development' | 'preprod' | 'staging'
}

export interface ConnectionStatus extends ConnectionEnvironment {
  status: string
  updatedAt: string
}

// export interface ConfigurationState {
//   state: ConfigurationStateType
//   updatedAt: string
// }

// export interface ConfigurationTemplate extends ConfigurationTemplateBase {
//   autoRun: string | null
//   updatedAt: string
//   updatedBy: string | null
// }

export interface ChargerInfo {
  vendor: string
  model: string
  firmwareVersion: string | null
  ocppVersion: OcppVersion
  updatedAt: string
}

export interface ConfigurationTemplateBase {
  tenant: string | null
  environment: string | null
  template: string | null
}

// export interface Charger {
//   chargerConfigurations: []
//   serialNumber: string
//   createdAt: string
//   deletedAt: string | null
//   chargePointStatus: ChargePointStatus
//   connectionStatus: ConnectionStatus
//   configurationState: ConfigurationState
//   configurationTemplate: ConfigurationTemplate
//   info: ChargerInfo
//   errors: chargerErrorType[]
// }

export interface SettingsBase {
  name: string
  updatedAt: string
  updatedBy: string
  createdAt: string
  deletedAt: string | null
  _id: string
}
export interface Template extends SettingsBase {
  vendor: string
  model: string
  template: UpdateBase
}

export interface Environment extends SettingsBase {
  ocppUrl: string
}

export interface EnvironmentParams {
  name: string
  ocppUrl: string
  updatedBy: string
}

export interface Message {
  message: string
  errors: string[]
  warnings: string[]
}

export type UpdateBase = {
  [key: string]: string
}

export type chargerErrorType = {
  updatedAt: string
  deletedAt: string | null
  status: ConfigurationStateType
  message: string
}

export interface ChargerDetails {
  id: string
  cp_serial_number: string
  phases: PhaseType
  ampere_max: number
  ampere_min: number
  ampere_fallback: number
}

export interface ChargerRef {
  id: number
  chargePointSerialNumber: string
  serialNumber?: string
}

export interface ChargersListingsLoadMgmt {
  [key: string]: {
    tenant_id: number
    tenant_name: string
    chargers: {}
  }
}

export interface ChargerInfoLoadMgmt {
  [key: string]: {
    charger_id: number
    chargepoint_serial_number: string
    charging_type: string
    service_status: string
    connectors: {
      [key: string]: {
        connector_id: number
        listing_id: number
        listing_title: number
      }
    }
  }
}

export interface LoadManagementCharger {
  chargepoint_serial_number: string
  charger_id: number
  charging_type: string
  connectors: Connector[]
  service_status: string
}

export interface Connector {
  connector_id: number
  listing_id: number
  listing_title: string
}

export interface ConnectorRef {
  id: number
  connectorId: number
  // connectorType: string
}

export interface ConnectorAndListing extends ConnectorRef {
  listing: ListingRef | null
}

export interface Connection {
  status: string
  updatedAt: string
}

export interface Configuration {
  autoRun?: string | null
  updatedAt: string
  environment?: string
  template?: string
  updatedBy?: string
  state: ConfigurationStateType
}

export interface ChargerV2 {
  id: number
  serialNumber: string
  vendor: string
  model: string
  firmwareVersion: string
  ocppVersion: OcppVersion
}

export interface ChargerResponseV2 {
  _id: string
  charger: ChargerV2
  connection: Connection
  tenant: TenantRef
  listings: ListingRef[]
  configuration: Configuration
  errors: ChargerErrorType[]
}
