interface InfoMessageProps {
  loading: boolean
  totalEntries: number
  text: string
  showSearchQuery: boolean
}

const InfoMessage = ({ text, loading, totalEntries, showSearchQuery }: InfoMessageProps) => {
  const singularOrPluralFoundText = (totalEntries: number) => {
    return totalEntries >= 2 || totalEntries === 0 ? 'are' : 'is'
  }

  const entireSentence = (totalEntries: number, text: string) => (
    <>
      {totalEntries} {text}
      {totalEntries >= 2 ? 's ' : ' '}
      {singularOrPluralFoundText(totalEntries)} found
    </>
  )

  return (
    <>
      {/* sample of expected outcome: 7 listings are found */}
      {showSearchQuery && !loading && totalEntries ? (
        <div className="paragraph-01-regular-soft-black">{entireSentence(totalEntries, text)}</div>
      ) : null}
    </>
  )
}

export default InfoMessage
