import { WebAuth } from 'auth0-js'
import jwt_decode from 'jwt-decode'

import React, { createContext, useContext, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Paths } from '../components/routes/paths'
import configProvider from '../config'
import { buildAppUrl } from '../helpers/url'
import { log } from '../logger'
import { JWT, JWT2, JWTIssue, JWTIssuev2 } from '../models/auth'
import { UserBase, UserSession } from '../models/user'
import { clearSession, getSession, setRailsToken, setSession as setSessionStore } from './session-store'

export interface StateContextType {
  auth0: WebAuth

  hydrateSession(): UserSession | undefined

  setCurrentUser(user: UserBase): void

  currentUser: UserBase | undefined

  isAuthenticated(): boolean

  login(jwt: JWTIssue): void

  login2(jwt: JWTIssuev2): void

  logout(): void

  IsMobile(): boolean

  IsTablet(): boolean

  IsLaptop(): boolean

  IsDesktop(): boolean

  IsMonitor(): boolean
}

export const StateContext = createContext<StateContextType>(null!)

const decodeJWT = (token: string): UserSession => {
  const decoded = jwt_decode<JWT>(token)
  const session = {
    id: decoded.uid,
    a: decoded.a,
    token: token,
    expiresAt: decoded.exp,
  } as UserSession
  return session
}

export const decodeJWT2 = (jWTIssuev2: JWTIssuev2): UserSession => {
  const decoded = jwt_decode<JWT2>(jWTIssuev2.accessToken)
  const session = {
    token: jWTIssuev2.token,
    expiresAt: decoded.exp,
    accessToken: jWTIssuev2.accessToken,
    permissions: decoded.permissions,
  } as UserSession

  return session
}

export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [session, setSession] = useState<UserSession>()
  const [currentUser, setCurrentUser] = useState<UserBase>()

  if (!configProvider.config.auth0.clientId) {
    throw new Error('Could not initialize WebAuth, Auth0 config has not been fetched')
  }

  const auth0 = new WebAuth({
    clientID: configProvider.config.auth0.clientId,
    domain: configProvider.config.auth0.domain,
    redirectUri: buildAppUrl(Paths.auth0Callback),
    responseType: 'token id_token',
    scope: configProvider.config.auth0.scope,
    audience: configProvider.config.auth0.audience,
  })

  const hydrateSession: StateContextType['hydrateSession'] = (): UserSession | undefined => {
    let sess = getSession()
    log('hydrating sessions via local store', {
      sess: sess,
    })

    if (!sess) {
      return undefined
    }

    setSession(sess)
    return sess
  }

  const setCurrentUserFunc: StateContextType['setCurrentUser'] = (user: UserBase) => {
    setCurrentUser(user)
  }

  const login: StateContextType['login'] = (jwt: JWTIssue) => {
    log('logging in with rails jwt %o ', jwt)
    const s = decodeJWT(jwt.token)
    log('rails jwt decoded', { session: s })
    setRailsToken(jwt.token)
  }

  const login2: StateContextType['login2'] = (jwt: JWTIssuev2) => {
    log('logging in with jwt %o ', jwt)
    const s = decodeJWT2(jwt)
    log('jwt decoded', { session: s })
    setSession(s)
    setSessionStore(s)
  }

  const logout: StateContextType['logout'] = () => {
    clearSession()
    setSession(undefined)
    auth0.logout({})
  }

  const isAuthenticated: StateContextType['isAuthenticated'] = (): boolean => {
    var sess: UserSession | undefined = session
    if (!sess) {
      log("local session isn't define attempt to manually fetching")
      sess = getSession()
      log('retrieved session %o', sess)

      if (!sess) {
        log('no local session found')
        return false
      }
    }

    // if (!sess.expiresAt) {
    //   log("session does not have an expiry")
    //   return [false, sess]
    // }
    //
    // const expired = new Date().getTime() / 1000 < sess.expiresAt
    // log("session expiration check", {
    //   expired: expired,
    // })
    return true
  }

  const IsMobile: StateContextType['IsMobile'] = (): boolean => useMediaQuery({ maxWidth: 576 })

  const IsTablet: StateContextType['IsTablet'] = (): boolean => useMediaQuery({ maxWidth: 992, minWidth: 576 })

  const IsLaptop: StateContextType['IsLaptop'] = (): boolean => useMediaQuery({ maxWidth: 1299, minWidth: 993 })

  const IsDesktop: StateContextType['IsDesktop'] = (): boolean => useMediaQuery({ maxWidth: 1599, minWidth: 1300 })

  const IsMonitor: StateContextType['IsMonitor'] = (): boolean => useMediaQuery({ minWidth: 1600 })

  return (
    <StateContext.Provider
      value={{
        auth0,
        login,
        login2,
        logout,
        setCurrentUser: setCurrentUserFunc,
        currentUser,
        hydrateSession,
        isAuthenticated,
        IsMobile,
        IsTablet,
        IsLaptop,
        IsDesktop,
        IsMonitor,
      }}
    >
      {props.children}
    </StateContext.Provider>
  )
}

export function useAppState() {
  const context = useContext(StateContext)
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider')
  }
  return context
}

export function useCurrentUser(): UserBase {
  const context = useContext(StateContext)
  if (!context) {
    throw new Error('useUserSession must be used within the AppStateProvider')
  }

  if (!context.currentUser) {
    throw new Error('useCurrentUser must be called within an authenticated component')
  }

  return context.currentUser
}
