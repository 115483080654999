import { Button, Table } from 'antd'
import { useState } from 'react'
import { ConfigurationItem } from '../../models/charger-config-template'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'

interface ShowChargerConfigTableProps {
  chargerConfig: ConfigurationItem
}

interface ConfigRecord {
  key: number
  setting: string
  value: string | JSX.Element[]
  isSensitive: boolean
}

const ShowChargerConfigTable: React.FC<ShowChargerConfigTableProps> = ({ chargerConfig }) => {
  const [visibility, setVisibility] = useState<{ [key: string]: boolean }>({})

  const toggleVisibility = (key: string) => {
    setVisibility((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }))
  }

  function parseConfigValue(value: string, key: string): string | JSX.Element[] {
    if (value.length > 0 && key.includes('Password') && !visibility[key]) {
      return '******'
    } else if (value.includes(',')) {
      return value
        .split(',')
        .map((val) => val.trim())
        .join(', ')
    } else {
      return value
    }
  }

  const data: ConfigRecord[] = Object.entries(chargerConfig).map(([key, value], index) => ({
    key: index,
    setting: key,
    value: parseConfigValue(value, key),
    isSensitive: key.includes('Password') && value.length > 0,
  }))

  const columns: any = [
    {
      title: 'Setting',
      dataIndex: 'setting',
      key: 'setting',
      filters: [
        { text: 'Cellular', value: 'Cellular' },
        { text: 'ChargePoint', value: 'ChargePoint' },
        { text: 'Meter', value: 'Meter' },
        { text: 'Version', value: 'Version' },
        { text: 'WiFi', value: 'WiFi' },
      ],
      onFilter: (value: string, record: any) => record.setting.includes(value),
      filterSearch: true,
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      render: (text: string | JSX.Element[], record: any) => (
        <span>
          {Array.isArray(text) ? text : text}
          {record.isSensitive && (
            <Button
              type="link"
              icon={visibility[record.setting] ? <EyeInvisibleOutlined /> : <EyeOutlined />}
              onClick={() => toggleVisibility(record.setting)}
            />
          )}
        </span>
      ),
    },
  ]

  return <Table dataSource={data} columns={columns} pagination={false} bordered />
}

export default ShowChargerConfigTable
