import { MapContainer, TileLayer, Marker, Popup, MarkerProps, useMap } from 'react-leaflet'
import L, { LatLng } from 'leaflet'
import 'leaflet/dist/leaflet.css'

import icon from 'leaflet/dist/images/marker-icon.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
})

L.Marker.prototype.options.icon = DefaultIcon

const CenterForcingMarker: React.FC<MarkerProps> = (props) => {
  const map = useMap()

  if (props.position) map.flyTo(props.position)

  return <Marker {...props} />
}

interface props {
  defaultCenter: LatLng
  defaultZoom: number
  value?: LatLng
  style?: React.CSSProperties
}

/**
 * A map widget that displays a location.
 *
 * @param props
 * @return
 */
export const LocationView: React.FC<props> = ({ defaultCenter, defaultZoom, value, style }) => (
  <MapContainer center={defaultCenter} zoom={defaultZoom} style={style}>
    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
    {!value || (
      <CenterForcingMarker position={value}>
        <Popup>This is where your tenant is, ma'am ¯\_(ツ)_/¯</Popup>
      </CenterForcingMarker>
    )}
  </MapContainer>
)

// https://github.com/PaulLeCam/react-leaflet/issues/453#issuecomment-787888924
