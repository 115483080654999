import { styled } from '../theme'

interface IProps {
  collapsed: boolean
  isDesktop: boolean
}

// Wrapper styled component for main container
export const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;

  img {
    margin-top: 25px !important;
    width: 36% !important;
  }
`

// NavigationMenuWrapper styled component for navigation menu
export const NavigationMenuWrapper = styled.div<IProps>`
  ${({ isDesktop, collapsed }) =>
    !isDesktop &&
    !collapsed &&
    `
    z-index: 1000;
    `}

  .swtch-icon {
    margin: 2.1rem 2.28rem auto 2.6rem;
  }

  svg:not(:root) {
    overflow: visible;
    height: 18px;
    width: 18px;
    margin-left: 1px;
    margin-bottom: 16px;
    margin-top: -1px;
  }

  .ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-dark.ant-menu-inline-collapsed li.ant-menu-item {
    width: 50px;
    height: 50px;
    border-radius: 30px;
    margin: 0 auto 0.3rem;
    padding: 16px;
  }

  .ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-dark.ant-menu-inline-collapsed li.ant-menu-item > svg {
    display: flex;
  }

  li.ant-menu-item:not(.ant-menu-item-selected) svg {
    path,
    circle,
    ellipse {
      opacity: 0.5;
    }
  }

  .ant-menu-dark .ant-menu-item > span > a {
    color: #fff;
  }

  .ant-menu-dark .ant-menu-item:not(.ant-menu-item-selected):hover {
    background-color: #0e1412;
    .ant-menu-title-content {
      a {
        color: #fff;
        font-family: ${(props) => props.theme.fontFamily.regular};
      }
    }
  }

  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #242e2c;
  }

  .ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-dark.ant-menu-inline-collapsed .ant-menu-title-content,
  .ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark .ant-menu-title-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 6px;
  }

  .ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark
    li.ant-menu-item:last-child.ant-menu-item-only-child
    .ant-menu-title-content {
    justify-content: end;
  }

  .ant-menu-title-content a {
    margin-left: 10px;
  }

  .ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed .ant-menu-item a {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }

  .ant-menu-item.ant-menu-item-selected svg {
    path,
    circle,
    ellipse {
      fill: #30d7ba;
    }
  }

  .ant-tooltip-inner > svg {
    display: none !important;
  }

  .toggleNavigation {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0 auto;
    background-color: rgb(0, 0, 0);
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
  }

  .toggleNavigation.expanded {
    right: 0;
  }

  .toggleNavigation svg {
    transform: translateX(-50%);
    margin: 2rem auto;
  }

  .toggleNavigation.expanded svg {
    position: absolute;
    bottom: 0;
    right: 2.9rem;
    transform: rotate(180deg) translateY(-25%);
  }

  .ant-menu-item {
    border-radius: 46px;
  }

  .ant-menu-dark.ant-menu-inline .ant-menu-item {
    width: 100%;
    margin: 4px auto;
    padding-left: 16.5px !important;
  }

  .ant-menu-dark.ant-menu-inline .ant-menu-item > svg {
    margin-bottom: 0;
  }

  .ant-menu-dark.ant-menu-inline .ant-menu-item > span > svg {
    margin-bottom: 0;
  }

  .ant-menu-dark .ant-menu-item-selected > span > a {
    color: #30d7ba;
    font-family: ${(props) => props.theme.fontFamily.regular};
  }

  .toggleNavigation::before {
    content: '';
    width: 100%;
    height: 1px;
    background-color: #ffff;
    left: 0;
    bottom: 5.7rem;
    position: absolute;
    display: block;
    opacity: 0.3;
  }

  .ant-menu.ant-menu-root {
    padding: 0;
    height: 100%;
    margin-top: 25px;
  }
`
