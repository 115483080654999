import { Button, Checkbox, Form, Select, Table } from 'antd'
import { Branch, Circuit, PanelType } from '../../../../models/circuit'
import { plusSVG } from '../../../../assets/svg/plus'
import { trashCanSVG } from '../../../../assets/svg/trashCan'
import { useEffect, useState } from 'react'
import { useCircuits } from '../../../../hooks/useCircuits'
import { ChargerDetails, LoadManagementCharger, phaseType } from '../../../../models/charger'
import { CustomInputNumber } from '../../../../components/input-number'
import { useNotifications } from '../../../../hooks/useNotifications'
import { BranchHeaderWrapper, EditBranchButtonWrapper } from '../../../../atom/load-management/load-management'
import {
  ChargerTableWrapper,
  DefaultRowWrapper,
  ErrorMessageWrapper,
  RemoveChargerLineBreakWrapper,
  RemoveChargerWrapper,
} from '../../../../atom/load-management/charger-table'
import { ChargerSelect } from '../../../../components/charger-select'
import { useSwtchControlPermissions } from '../../../panel-overview/useSwtchControlPermissions'

interface props {
  chargers: ChargerDetails[]
  branch: Branch
  circuit: Circuit
  circuits: Circuit[]
  verifiedChargers: LoadManagementCharger[]
  saveClicked: boolean
  setVerifiedChargers: (chargers: LoadManagementCharger[]) => void
  setCircuits: (circuits: Circuit[]) => void
  setBreakerLimitWarning: (breakerLimitWarning: boolean) => void
  setDisableSaveData: (disableSaveData: boolean) => void
}

export const ChargerTable: React.FC<props> = ({
  chargers,
  branch,
  circuit,
  circuits,
  verifiedChargers,
  saveClicked,
  setVerifiedChargers,
  setCircuits,
  setDisableSaveData,
  setBreakerLimitWarning,
}) => {
  const [chargerData, setChargerData] = useState<any[]>([])
  const [fallbackBranchBreakerSizeLimit, setFallbackBranchBreakerSizeLimit] = useState(0)
  const [maxCapBranchBreakerSizeLimit, setMaxCapBranchBreakerSizeLimit] = useState(0)
  const [validateFallbackStatus, setValidateFallbackStatus] = useState({})
  const [validateMaxStatus, setValidateMaxStatus] = useState({})
  const [validateMinStatus, setValidateMinStatus] = useState({})
  const [validateFallbackMessage, setValidateFallbackMessage] = useState({})
  const [validateMaxMessage, setValidateMaxMessage] = useState({})
  const [validateMinMessage, setValidateMinMessage] = useState({})
  const [disableSaveFallbackData, setDisableSaveFallbackData] = useState({})
  const [disableSaveMaxData, setDisableSaveMaxData] = useState({})
  const [disableSaveMinData, setDisableSaveMinData] = useState({})
  const { openErrorNotification } = useNotifications()
  const { chargerInitialValues, limitBranchBreakerSize } = useCircuits()
  const [form] = Form.useForm()
  const { canUpdateSwtchControl, canCreateSwtchControl } = useSwtchControlPermissions()

  useEffect(() => {
    settingChargersData(chargers)
  }, [circuits, chargers])

  useEffect(() => {
    const fallbackArr = Object.values(disableSaveFallbackData)
    const maxArr = Object.values(disableSaveMaxData)
    const minArr = Object.values(disableSaveMinData)
    let disable = false
    fallbackArr.map((item) => {
      if (item === true) {
        disable = true
        setDisableSaveData(true)
      }
      return disable
    })
    maxArr.map((item) => {
      if (item === true) {
        disable = true
        setDisableSaveData(true)
      }
      return disable
    })

    minArr.map((item) => {
      if (item === true) {
        disable = true
        setDisableSaveData(true)
      }
      return disable
    })
    if (disable === false) return setDisableSaveData(false)
  }, [disableSaveFallbackData, disableSaveMaxData, disableSaveMinData])

  const settingChargersData = (chargers: ChargerDetails[]) => {
    let chargersArr: any = []
    if (chargers.length > 0) {
      chargers.map((charger, index) => {
        const tempCharger = {
          key: index,
          charger_serial_number: charger.cp_serial_number,
          phases: charger.phases,
          min_cap: charger.ampere_min,
          max_cap: charger.ampere_max,
          fallback: charger.ampere_fallback,
        }
        form.setFieldsValue({
          [`ampere_fallback_${index}`]: charger.ampere_fallback,
          [`ampere_min_${index}`]: charger.ampere_min,
          [`ampere_max_${index}`]: charger.ampere_max,
        })
        return chargersArr.push(tempCharger)
      })
    }

    setChargerData(chargersArr)
  }

  const onChangeCharger = (e: any, circuit: Circuit, branch: any, charger: any, key?: string) => {
    if (canUpdateSwtchControl) {
      const singleCircuit = { ...circuit }
      const singleBranch = { ...branch }
      const singleCharger = { ...charger }

      if ((e === null || !e.target) && key) {
        if (e === null) {
          singleCharger[key] = 0
        } else if (key === 'phases' || key === 'cp_serial_number') {
          singleCharger[key] = e
        } else {
          singleCharger[key] = +e
        }
        const tempChargers = singleBranch.chargers.map((charger: any) => {
          if (charger.id === singleCharger.id) return singleCharger
          return charger
        })
        settingChargersData(chargers)
        const tempBranch = { ...singleBranch, chargers: tempChargers }

        // setting new breaker limit with change of breaker size
        if (key === 'ampere_fallback') {
          const breakerLimit = limitBranchBreakerSize(tempBranch, 'ampere_fallback')
          setFallbackBranchBreakerSizeLimit(breakerLimit)
        }
        if (key === 'ampere_max') {
          const breakerLimit = limitBranchBreakerSize(tempBranch, 'ampere_max')
          setMaxCapBranchBreakerSizeLimit(breakerLimit)
        }

        const tempBranches = singleCircuit.branches.map((branch) => {
          if (branch.id === tempBranch.id) return tempBranch
          return branch
        })
        const tempCircuit = { ...singleCircuit, branches: tempBranches }
        const tempCircuits = circuits.map((dedicatedCircuit) => {
          if (dedicatedCircuit.id === tempCircuit.id) return tempCircuit
          return dedicatedCircuit
        })
        setCircuits([...tempCircuits])
      } else {
        const name = e.target.name
        singleCharger[name] = e.target.value
        const tempChargers = singleBranch.chargers.map((charger: any) => {
          if (charger.id === singleCharger.id) return singleCharger
          return charger
        })
        const tempBranch = { ...singleBranch, chargers: tempChargers }
        const tempBranches = singleCircuit.branches.map((branch) => {
          if (branch.id === tempBranch.id) return tempBranch
          return branch
        })
        const tempCircuit = { ...singleCircuit, branches: tempBranches }
        const tempCircuits = circuits.map((dedicatedCircuit) => {
          if (dedicatedCircuit.id === tempCircuit.id) return tempCircuit
          return dedicatedCircuit
        })
        setCircuits([...tempCircuits])
      }
    }
  }

  const checkChargerLimit = (newValue: string, circuit: Circuit, branch: Branch) => {
    if (circuit.type === PanelType.mixed) {
      if (branch?.chargers.length === 1 && branch?.chargers[0].cp_serial_number) {
        const message = (
          <>
            <Checkbox checked={true} style={{ marginRight: '10px' }} />
            Error: Limit for chargers reached.
          </>
        )
        openErrorNotification(message)
      } else {
        const tempChargersArr = verifiedChargers.filter((charger) => charger.chargepoint_serial_number !== newValue)
        setVerifiedChargers(tempChargersArr)
        addCharger(circuit, branch, newValue, 'Mixed')
      }
    } else {
      const tempChargersArr = verifiedChargers.filter((charger) => charger.chargepoint_serial_number !== newValue)
      setVerifiedChargers(tempChargersArr)
      addCharger(circuit, branch, newValue, 'Dedicated')
    }
  }

  // Function to check if the new ID already exists
  const checkIfIdExists = (array: any[], newId: number) => {
    return array.some((object) => Number(object.id) === newId)
  }

  // Function to generate a random ID
  const generateRandomId = () => {
    return Math.floor(Math.random() * 100) + 1
  }

  // Function to get a unique ID
  const getUniqueId = (array: any[]) => {
    let newId
    do {
      newId = generateRandomId()
    } while (checkIfIdExists(array, newId))
    return newId
  }

  const addCharger = (circuit: Circuit, branch: Branch, chargerName: string, type: string) => {
    const singleCircuit = { ...circuit }
    const singleBranch = { ...branch }
    const singleCharger = chargerInitialValues
    let tempChargerId = getUniqueId(branch.chargers)

    verifiedChargers.map((charger) => {
      if (charger.chargepoint_serial_number === chargerName) {
        tempChargerId = charger.charger_id
      }
      return charger
    })
    singleCharger.cp_serial_number = chargerName
    singleCharger.id = tempChargerId.toString()
    const tempChargers = [...branch.chargers, singleCharger]
    settingChargersData(tempChargers)
    const tempBranch = { ...singleBranch, chargers: tempChargers }

    const tempBranches = singleCircuit.branches.map((branch) => {
      if (branch.id === tempBranch.id) return tempBranch
      return branch
    })
    const tempCircuit = { ...singleCircuit, branches: tempBranches }
    const tempCircuits = circuits.map((circuit) => {
      if (circuit.id === tempCircuit.id) return tempCircuit
      return circuit
    })
    setCircuits(tempCircuits)
  }

  const removeCharger = (charger: any, circuit: Circuit, branch: Branch) => {
    const circuitsArr = [...circuits]
    const singleCircuit = { ...circuit }
    const singleBranch = { ...branch }
    const singleCharger = { ...charger }
    const tempChargers = singleBranch.chargers.filter((charger) => charger.id !== singleCharger.id)
    settingChargersData(tempChargers)
    singleBranch.chargers = tempChargers

    const tempBranches = singleCircuit.branches.map((branch) => {
      if (branch.id === singleBranch.id) return singleBranch
      return branch
    })
    singleCircuit.branches = tempBranches

    const tempCircuits = circuitsArr.map((panel) => {
      if (panel.id === singleCircuit.id) return singleCircuit
      return panel
    })
    setCircuits(tempCircuits)
  }

  const columns = [
    {
      title: (
        <BranchHeaderWrapper>
          <div>Charger</div>{' '}
          {canCreateSwtchControl && (
            <EditBranchButtonWrapper>
              <Button shape="circle" className="add-button" onClick={() => checkChargerLimit('', circuit, branch)}>
                {plusSVG}
              </Button>
            </EditBranchButtonWrapper>
          )}
        </BranchHeaderWrapper>
      ),
      dataIndex: 'charger_serial_number',
      key: 'charger_serial_number',
      render: (charger_name: string, record: any, index: number) => {
        return (
          <>
            <ChargerSelect
              verifiedChargers={verifiedChargers}
              circuit={circuit}
              branch={branch}
              chargers={chargers}
              index={index}
              chargerName={charger_name}
              onChangeCharger={onChangeCharger}
            />
          </>
        )
      },
    },
    {
      title: <div>Phases</div>,
      dataIndex: 'phases',
      render: (phases: string, record: any, index: number) => {
        return (
          <Form>
            <Form.Item rules={[{ required: true }]}>
              <Select
                value={chargers[index]?.phases ? chargers[index]?.phases : '-'}
                onChange={(value: any) => {
                  onChangeCharger(value, circuit, branch, chargers[index], 'phases')
                }}
                status={!phases && saveClicked ? 'error' : ''}
              >
                {phaseType.map((item, index) => (
                  <Select.Option key={index} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        )
      },
    },
    {
      title: <div>Min Capacity (amp)</div>,
      dataIndex: 'min_cap',
      key: 'min_cap',
      render: (min_cap: string, record: any, index: number) => {
        return (
          <Form form={form}>
            <Form.Item
              name={`ampere_min_${index}`}
              validateStatus={validateMinStatus[index as keyof typeof validateMinStatus]}
              rules={[
                {
                  type: 'number',
                  min: 6,
                  max: chargers[index]?.ampere_max,
                  message: `Cannot be less than 6 A`,
                  validator: (_, value) => {
                    if (value < 6) {
                      setValidateMinStatus({ ...validateMinStatus, [index]: 'error' })
                      setValidateMinMessage({ ...validateMinMessage, [index]: 'Cannot be less than 6 A' })
                      setDisableSaveMinData({ ...disableSaveMinData, [index]: true })
                      return Promise.reject('Template is required!')
                    }
                    if (value > chargers[index]?.ampere_max) {
                      setValidateMinStatus({ ...validateMinStatus, [index]: 'error' })
                      setValidateMinMessage({ ...validateMinMessage, [index]: 'Cannot be greater than Max Cap' })
                      setDisableSaveMinData({ ...disableSaveMinData, [index]: true })
                      return Promise.reject('Template is required!')
                    }
                    setValidateMinStatus({ ...validateMinStatus, [index]: '' })
                    setDisableSaveMinData({ ...disableSaveMinData, [index]: false })
                    return Promise.resolve()
                  },
                },
              ]}
            >
              <CustomInputNumber
                defaultValue={chargers[index]?.ampere_min}
                value={chargers[index]?.ampere_min}
                onChange={(value) => onChangeCharger(value, circuit, branch, chargers[index], 'ampere_min')}
                // ampFormat={true}
              />
            </Form.Item>
            {disableSaveMinData[index as keyof typeof disableSaveMinData] &&
              validateMinStatus[index as keyof typeof validateMinStatus] && (
                <ErrorMessageWrapper status={validateMinStatus[index as keyof typeof validateMinStatus]}>
                  {validateMinMessage[index as keyof typeof validateMinMessage]}
                </ErrorMessageWrapper>
              )}
          </Form>
        )
      },
    },
    {
      title: <div>Max Capacity (amp)</div>,
      dataIndex: 'max_cap',
      key: 'max_cap',
      render: (max_cap: string, record: any, index: number) => {
        return (
          <Form form={form}>
            <Form.Item
              name={`ampere_max_${index}`}
              validateStatus={validateMaxStatus[index as keyof typeof validateMaxStatus]}
              rules={[
                {
                  type: 'number',
                  max: maxCapBranchBreakerSizeLimit + chargers[index]?.ampere_max,
                  message: `Breaker size limit reached`,
                  warningOnly: true,
                  validator: (_, value) => {
                    if (value > maxCapBranchBreakerSizeLimit + chargers[index]?.ampere_max) {
                      setValidateMaxStatus({ ...validateMaxStatus, [index]: 'warning' })
                      setValidateMaxMessage({ ...validateMaxMessage, [index]: 'Breaker size limit reached' })
                      setBreakerLimitWarning(true)
                      setDisableSaveMaxData({ ...disableSaveMaxData, [index]: false })
                      return Promise.reject('Template is required!')
                    }
                    if (value < chargers[index]?.ampere_min || value < 6) {
                      console.log('error?')
                      setValidateMaxStatus({ ...validateMaxStatus, [index]: 'error' })
                      setValidateMaxMessage({ ...validateMaxMessage, [index]: 'Cannot be less than Min Cap' })
                      setDisableSaveMaxData({ ...disableSaveMaxData, [index]: true })
                      return Promise.reject('Template is required!')
                    }
                    console.log('noerror')
                    setDisableSaveMaxData({ ...disableSaveMaxData, [index]: false })
                    setValidateMaxStatus({ ...validateMaxStatus, [index]: '' })
                    setBreakerLimitWarning(false)
                    return Promise.resolve()
                  },
                },
              ]}
            >
              <CustomInputNumber
                defaultValue={chargers[index]?.ampere_max}
                value={chargers[index]?.ampere_max}
                onChange={(value) => onChangeCharger(value, circuit, branch, chargers[index], 'ampere_max')}
                // ampFormat={true}
              />
            </Form.Item>
            {validateMaxStatus[index as keyof typeof validateMaxStatus] && (
              <ErrorMessageWrapper status={validateMaxStatus[index as keyof typeof validateMaxStatus]}>
                {validateMaxMessage[index as keyof typeof validateMaxMessage]}
              </ErrorMessageWrapper>
            )}
          </Form>
        )
      },
    },
    {
      title: <div>Fallback (amp)</div>,
      dataIndex: 'fallback',
      key: 'fallback',
      render: (fallback: string, record: any, index: number) => {
        return (
          <Form form={form} className="fallback-row">
            <div>
              <Form.Item
                validateStatus={validateFallbackStatus[index as keyof typeof validateFallbackStatus]}
                name={[`ampere_fallback_${index}`]}
                rules={[
                  {
                    type: 'number',
                    max: fallbackBranchBreakerSizeLimit + chargers[index]?.ampere_fallback,
                    message: `Breaker size limit reached`,
                    validator: (_, value) => {
                      if (value > fallbackBranchBreakerSizeLimit + chargers[index]?.ampere_fallback) {
                        setDisableSaveFallbackData({ ...disableSaveFallbackData, [index]: true })
                        setValidateFallbackMessage({
                          ...validateFallbackMessage,
                          [index]: 'Breaker size limit reached',
                        })
                        setValidateFallbackStatus({ ...validateFallbackStatus, [index]: 'error' })
                        return Promise.reject('Template is required!')
                      }
                      if (value > chargers[index]?.ampere_max || value < chargers[index]?.ampere_min) {
                        setDisableSaveFallbackData({ ...disableSaveFallbackData, [index]: true })
                        setValidateFallbackMessage({
                          ...validateFallbackMessage,
                          [index]: `Must be between ${chargers[index]?.ampere_min} A and ${chargers[index]?.ampere_max} A`,
                        })
                        setValidateFallbackStatus({ ...validateFallbackStatus, [index]: 'error' })
                        return Promise.reject('Template is required!')
                      }
                      if (value < 6) {
                        setValidateFallbackStatus({ ...validateFallbackStatus, [index]: 'error' })
                        setValidateFallbackMessage({ ...validateFallbackMessage, [index]: 'Cannot be less than 6 A' })
                        setDisableSaveFallbackData({ ...disableSaveFallbackData, [index]: true })
                        return Promise.reject('Template is required!')
                      }
                      setDisableSaveFallbackData({ ...disableSaveFallbackData, [index]: false })
                      setValidateFallbackStatus({ ...validateFallbackStatus, [index]: '' })
                      return Promise.resolve()
                    },
                  },
                ]}
              >
                <CustomInputNumber
                  defaultValue={chargers[index]?.ampere_fallback}
                  value={chargers[index]?.ampere_fallback}
                  onChange={(value) => onChangeCharger(value, circuit, branch, chargers[index], 'ampere_fallback')}
                  // ampFormat={true}
                />
              </Form.Item>
              {disableSaveFallbackData[index as keyof typeof disableSaveFallbackData] &&
                validateFallbackStatus[index as keyof typeof validateFallbackStatus] && (
                  <ErrorMessageWrapper status={validateFallbackStatus[index as keyof typeof validateFallbackStatus]}>
                    {validateFallbackMessage[index as keyof typeof validateFallbackMessage]}
                  </ErrorMessageWrapper>
                )}
            </div>
            {chargers[index]?.ampere_fallback === 6 &&
            chargers[index]?.ampere_min === 6 &&
            chargers[index]?.ampere_max === 6 ? (
              <DefaultRowWrapper>Default</DefaultRowWrapper>
            ) : (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <RemoveChargerLineBreakWrapper></RemoveChargerLineBreakWrapper>
                <RemoveChargerWrapper onClick={() => removeCharger(chargers[index], circuit, branch)}>
                  {trashCanSVG}
                </RemoveChargerWrapper>
              </div>
            )}
          </Form>
        )
      },
    },
  ]

  return (
    <ChargerTableWrapper>
      <Table columns={columns} dataSource={chargerData} pagination={false} bordered />
    </ChargerTableWrapper>
  )
}
