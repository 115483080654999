import { getSession } from '../../state/session-store'

export const useSwtchControlPermissions = () => {
  const session = getSession()

  const hasPermission = session && session.permissions && session.permissions.length > 0

  const canUpdateSwtchControl = session && hasPermission && session.permissions.includes('swtch_control:all.update')

  const canDeleteSwtchControl = session && hasPermission && session.permissions.includes('swtch_control:all.delete')

  const canCreateSwtchControl = session && hasPermission && session.permissions.includes('swtch_control:all.create')

  const canReadSwtchControl = session && hasPermission && session.permissions.includes('swtch_control:all.read')

  return {
    hasPermission,
    canUpdateSwtchControl,
    canDeleteSwtchControl,
    canCreateSwtchControl,
    canReadSwtchControl,
  }
}
