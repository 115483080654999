import { getSession } from '../../state/session-store'

export const useDashboardPermissions = () => {
  const session = getSession()

  const hasPermission = session && session.permissions && session.permissions.length > 0
  const canUpdateChargerEntries =
    session && hasPermission && session.permissions.includes('deployment:charger_entries.update')
  const canDeleteChargerEntries =
    session && hasPermission && session.permissions.includes('deployment:charger_entries.delete')
  const canUpdateCharger = session && hasPermission && session.permissions.includes('deployment:charger.update')
  const canVerifyCharger = session && hasPermission && session.permissions.includes('deployment:charger.verify')
  const canCommissionCharger = session && hasPermission && session.permissions.includes('deployment:charger.commission')
  const canCreateEnvironment =
    session && hasPermission && session.permissions.includes('deployment:environments.create')

  const canReadChargerEntries =
    session && hasPermission && session.permissions.includes('deployment:charger_entries.read')
  const canReadEnvironments = session && hasPermission && session.permissions.includes('deployment:environments.read')
  const canUpdateEnvironments =
    session && hasPermission && session.permissions.includes('deployment:environments.update')
  const canDeleteEnvironments =
    session && hasPermission && session.permissions.includes('deployment:environments.delete')
  const canUpdateTemplates = session && hasPermission && session.permissions.includes('deployment:templates.update')
  const canReadTemplates = session && hasPermission && session.permissions.includes('deployment:templates.read')
  const canCreateTemplates = session && hasPermission && session.permissions.includes('deployment:templates.create')
  const canDeleteTemplates = session && hasPermission && session.permissions.includes('deployment:templates.delete')

  return {
    hasPermission,
    canUpdateChargerEntries,
    canDeleteChargerEntries,
    canUpdateCharger,
    canVerifyCharger,
    canCommissionCharger,
    canCreateEnvironment,
    canReadChargerEntries,
    canReadEnvironments,
    canUpdateEnvironments,
    canDeleteEnvironments,
    canUpdateTemplates,
    canReadTemplates,
    canCreateTemplates,
    canDeleteTemplates,
  }
}