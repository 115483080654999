// Defines an SVG component for a mixed panel icon
export const mixedPanelSVG = (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="13" cy="13" r="13" fill="#FF9900" />
    <path
      d="M16.224 13.52H12.064M16.4736 8.1952C16.4736 8.9304 17.0696 9.5264 17.8048 9.5264C18.54 9.5264 19.136 8.9304 19.136 8.1952C19.136 7.46 18.54 6.864 17.8048 6.864C17.0696 6.864 16.4736 7.46 16.4736 8.1952ZM16.4736 8.1952L12.6464 8.1952C12.2788 8.1952 11.9808 8.4932 11.9808 8.8608V18.1792C11.9808 18.5468 12.2788 18.8448 12.6464 18.8448L16.4736 18.8448M16.4736 18.8448C16.4736 19.58 17.0696 20.176 17.8048 20.176C18.54 20.176 19.136 19.58 19.136 18.8448C19.136 18.1096 18.54 17.5136 17.8048 17.5136C17.0696 17.5136 16.4736 18.1096 16.4736 18.8448ZM19.136 13.52C19.136 14.2552 18.54 14.8512 17.8048 14.8512C17.0696 14.8512 16.4736 14.2552 16.4736 13.52C16.4736 12.7848 17.0696 12.1888 17.8048 12.1888C18.54 12.1888 19.136 12.7848 19.136 13.52ZM9.15197 17.68V9.36C9.15197 8.9005 8.77947 8.528 8.31997 8.528H6.65597C6.19647 8.528 5.82397 8.9005 5.82397 9.36L5.82397 17.68C5.82397 18.1395 6.19647 18.512 6.65597 18.512H8.31997C8.77947 18.512 9.15197 18.1395 9.15197 17.68Z"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
)
