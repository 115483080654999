import { useEffect, useState } from 'react'
import { Button, Form, Input, Modal } from 'antd'
import { Circuit, PanelType } from '../../../../models/circuit'
import { MustBeRequired, MustBeIpAddress, MustBeNumerical } from '../../../../components/rules/rules'
import { useCircuits } from '../../../../hooks/useCircuits'
import {
  AddPanelButtonWrapper,
  HeadingWrapper,
  LastRowInputsWrapper,
  RowInputsWrapper,
  TitleWrapper,
} from '../../../../atom/load-management/modals'
import { dedicatedPanelSVG } from '../../../../assets/svg/dedicatedPanel'
import { mixedPanelSVG } from '../../../../assets/svg/mixedPanel'
import { CustomInputNumber } from '../../../../components/input-number'
import { plusSVG } from '../../../../assets/svg/plus'
import { useHistory } from 'react-router-dom'
import { ButtonLink } from '../../../../atom/button'

interface props {
  visible: boolean
  panelTypeModal: string
  circuitsFromUi: Circuit[]
  circuits: Circuit[]
  addNewCircuitId?: string
  onCancel(): void
  onSubmit(): void
  setPanelTypeModal(type: string): void
  setCircuitsFromUi: (circuit: Circuit[]) => void
  setCircuits: (circuit: Circuit[]) => void
  setAddNewCircuitId?: (addNewCircuitId: string) => void
}

const input = { borderRadius: '5px' }
const { TextArea } = Input

export const AddPanelModal: React.FC<props> = ({
  visible,
  panelTypeModal,
  circuitsFromUi,
  circuits,
  addNewCircuitId,
  onCancel,
  onSubmit,
  setPanelTypeModal,
  setCircuitsFromUi,
  setCircuits,
  setAddNewCircuitId,
}) => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const [type, setType] = useState(panelTypeModal)
  const [panelId, setPanelId] = useState('0')
  const { dedicatedPanelInitialValues, mixedPanelInitialValues, findMissingIndexes, IPtoNum } = useCircuits()
  const [newCircuit, setNewCircuit] = useState({ ...dedicatedPanelInitialValues, type })
  const navigate = useHistory()
  const disabled =
    newCircuit.type === PanelType.dedicated
      ? newCircuit.name === '' || newCircuit.circuit_size === 0
      : newCircuit.name === '' ||
        newCircuit.circuit_size === 0 ||
        newCircuit.ct_clamp.ip_address === '' ||
        newCircuit.ct_clamp.port === 0

  useEffect(() => {
    findPanelId(circuits)
    if (panelTypeModal === PanelType.dedicated) {
      setType(PanelType.dedicated)
      setNewCircuit({ ...dedicatedPanelInitialValues, type })
    } else if (panelTypeModal === PanelType.mixed) {
      setType(PanelType.mixed)
      setNewCircuit({ ...mixedPanelInitialValues, type })
    }
  }, [panelTypeModal])

  const afterClose = () => {
    form.resetFields()
    setPanelTypeModal('')
    if (setAddNewCircuitId) setAddNewCircuitId('')
  }

  const modalTitle = () => {
    if (panelTypeModal === PanelType.dedicated)
      return <TitleWrapper>{dedicatedPanelSVG} Add Dedicated EV Panel Information</TitleWrapper>
    return <TitleWrapper>{mixedPanelSVG} Add Mixed EV Panel Information</TitleWrapper>
  }

  const onChange = (e: any, key?: string) => {
    if (key === 'port') {
      setNewCircuit({
        ...newCircuit,
        ct_clamp: {
          enabled: newCircuit.ct_clamp.enabled,
          id: newCircuit.ct_clamp.id,
          ip_address: newCircuit.ct_clamp.ip_address,
          port: e,
        },
      })
    } else if (key === 'existing_load') {
      setNewCircuit({ ...newCircuit, existing_load: +e })
    } else if (key === 'circuit_size') {
      setNewCircuit({ ...newCircuit, circuit_size: +e })
    } else if (e.target.name === 'ip_address') {
      setNewCircuit({
        ...newCircuit,
        ct_clamp: {
          enabled: newCircuit.ct_clamp.enabled,
          id: newCircuit.ct_clamp.id,
          ip_address: e.target.value,
          port: newCircuit.ct_clamp.port,
        },
      })
    } else if (e.target.name === 'ct_clamp_id') {
      setNewCircuit({
        ...newCircuit,
        ct_clamp: {
          enabled: newCircuit.ct_clamp.enabled,
          id: e.target.value,
          ip_address: newCircuit.ct_clamp.ip_address,
          port: newCircuit.ct_clamp.port,
        },
      })
    } else {
      setNewCircuit({ ...newCircuit, [e.target.name]: e.target.value })
    }
  }

  const findPanelId = (circuits: Circuit[]) => {
    let tempPanelId = 0
    if (circuits.length === 0) {
      tempPanelId = 1
    } else {
      let ids = circuits.map((circuit) => +circuit.id)
      const maximum = Math.max(...ids)
      const missingIndexArr = findMissingIndexes(ids)

      if (missingIndexArr.length === 0) {
        tempPanelId = maximum + 1
      } else {
        tempPanelId = missingIndexArr[0]
      }
    }
    setPanelId(tempPanelId.toString())
  }

  const submit = (e: any) => {
    setLoading(true)
    e.preventDefault()
    if (!addNewCircuitId) {
      if (newCircuit.circuit_id === '') {
        setNewCircuit({ ...newCircuit, id: panelId, circuit_id: panelId })
        const tempCircuit = { ...newCircuit, id: panelId, circuit_id: panelId }
        setCircuitsFromUi([...circuitsFromUi, tempCircuit])
        setCircuits([...circuits, tempCircuit])
      } else {
        setNewCircuit({ ...newCircuit, id: panelId })
        const tempCircuit = { ...newCircuit, id: panelId }
        setCircuitsFromUi([...circuitsFromUi, tempCircuit])
        setCircuits([...circuits, tempCircuit])
      }
    } else {
      let tempCircuit = { ...newCircuit }
      if (newCircuit.circuit_id === '') {
        setNewCircuit({ ...newCircuit, id: panelId, circuit_id: panelId })
        tempCircuit = { ...newCircuit, id: panelId, circuit_id: panelId }
      } else {
        setNewCircuit({ ...newCircuit, id: panelId })
        tempCircuit = { ...newCircuit, id: panelId }
      }
      setCircuitsFromUi([...circuitsFromUi, tempCircuit])
      setCircuits([...circuits, tempCircuit])
      navigate.push({
        pathname: `/panel-overview/${addNewCircuitId}`,
        state: { circuits: [...circuits, tempCircuit], circuitsFromUi: [...circuitsFromUi, tempCircuit] },
      })
    }
    onSubmit()
    setLoading(false)
  }

  return (
    <Modal
      title={null}
      visible={visible}
      confirmLoading={loading}
      footer={
        <AddPanelButtonWrapper>
          {addNewCircuitId ? (
            <div className="link-button">
              <ButtonLink icon={plusSVG} text="ADD PANEL" onClick={submit} />
            </div>
          ) : (
            <Button
              style={{ width: '100%' }}
              size="large"
              key="invite-btn"
              type="primary"
              loading={loading}
              onClick={submit}
              disabled={disabled}
            >
              {plusSVG} ADD PANEL
            </Button>
          )}
        </AddPanelButtonWrapper>
      }
      closable={true}
      width={471}
      afterClose={afterClose}
      onCancel={onCancel}
    >
      <Form form={form}>
        {modalTitle()}
        <Form.Item name="panelId">
          <HeadingWrapper>
            <div>Panel ID</div>
            <Input
              placeholder="Enter Panel ID"
              style={input}
              name="circuit_id"
              value={newCircuit.circuit_id}
              onChange={(value) => onChange(value)}
            />
          </HeadingWrapper>
        </Form.Item>
        <Form.Item name="panelName">
          <HeadingWrapper>
            <div>Panel Name</div>
            <Input
              name="name"
              placeholder="Enter Name"
              style={input}
              value={newCircuit.name}
              onChange={(value) => onChange(value)}
            />
          </HeadingWrapper>
        </Form.Item>
        {panelTypeModal === PanelType.mixed && (
          <RowInputsWrapper>
            <Form.Item name="existingLoad" className="row-input">
              <HeadingWrapper>
                <div>Existing Load (amp)</div>
                <CustomInputNumber
                  value={newCircuit.existing_load}
                  onChange={(value) => onChange(value, 'existing_load')}
                  // ampFormat={true}
                />
              </HeadingWrapper>
            </Form.Item>
            <Form.Item name="panelSize" className="row-input">
              <HeadingWrapper>
                <div>Panel Size (amp)</div>
                <CustomInputNumber
                  value={newCircuit.circuit_size}
                  onChange={(value) => onChange(value, 'circuit_size')}
                  // ampFormat={true}
                />
              </HeadingWrapper>
            </Form.Item>
          </RowInputsWrapper>
        )}
        {panelTypeModal === PanelType.dedicated && (
          <Form.Item name="panelSize">
            <HeadingWrapper>
              <div>Panel Size (amp)</div>
              <div className="panel-size-row">
                <CustomInputNumber
                  value={newCircuit.circuit_size}
                  onChange={(value) => onChange(value, 'circuit_size')}
                  // ampFormat={true}
                />
              </div>
            </HeadingWrapper>
          </Form.Item>
        )}
        <Form.Item name="panelNote">
          <HeadingWrapper>
            <div>Panel Note</div>
            <TextArea
              style={{ borderRadius: '20px', height: '105px' }}
              name="note"
              value={newCircuit.note}
              placeholder="Panel Note"
              onChange={(value) => onChange(value)}
              autoSize={{ minRows: 5, maxRows: 8 }}
            />
          </HeadingWrapper>
        </Form.Item>
        {panelTypeModal === PanelType.mixed && (
          <>
            <hr
              style={{
                border: 0,
                borderTop: '1px solid #f0f0f0',
                margin: '49px -24px 16px',
              }}
            />
            <TitleWrapper>CT Clamp Configuration for Mixed Panel</TitleWrapper>
            <LastRowInputsWrapper>
              <Form.Item
                className="row-input"
                name="ip_address"
                validateTrigger={['onChange', 'onBlur', 'onSubmit']}
                rules={[
                  MustBeRequired('Please enter a valid IP Address'),
                  MustBeIpAddress('Enter a valid IP Address'),
                  {
                    type: 'string',
                    message: `IP address cannot be higher than 255.255.255.255`,
                    validator: (_, value) => {
                      IPtoNum(value)
                      if (IPtoNum('000.000.000.000') <= IPtoNum(value) && IPtoNum('255.255.255.255') >= IPtoNum(value))
                        return Promise.resolve()
                      return Promise.reject('Template is required!')
                    },
                  },
                  {
                    type: 'string',
                    message: `A valid IP address has three decimal numbers separated by periods`,
                    validator: (_, value) => {
                      const ip = [...value]
                      let count = 0
                      ip.map((char) => {
                        if (char === '.') {
                          count = count + 1
                        }
                        return char
                      })
                      if (count === 3) return Promise.resolve()
                      return Promise.reject('Template is required!')
                    },
                  },
                ]}
              >
                <HeadingWrapper>
                  <div>IP</div>
                  <div>
                    <Input
                      style={input}
                      name="ip_address"
                      placeholder="IP Address"
                      value={newCircuit.ct_clamp.ip_address}
                      onChange={(value) => onChange(value)}
                    />
                  </div>
                </HeadingWrapper>
              </Form.Item>
              <Form.Item
                name="port"
                className="row-input"
                rules={[
                  MustBeRequired('Please enter a valid port'),
                  MustBeNumerical,
                  {
                    type: 'number',
                    max: 65535,
                    min: 1000,
                    message: `Port must be within 1000 and 65,535`,
                    validator: (_, value) => {
                      if (value > 65535 || value < 1000) {
                        return Promise.reject('Template is required!')
                      } else {
                        return Promise.resolve()
                      }
                    },
                  },
                ]}
              >
                <HeadingWrapper>
                  <div>Port</div>
                  <CustomInputNumber value={newCircuit.ct_clamp.port} onChange={(value) => onChange(value, 'port')} />
                </HeadingWrapper>
              </Form.Item>
              <Form.Item name="ctClampId" className="row-input">
                <HeadingWrapper>
                  <div>CT Clamp ID</div>
                  <Input
                    name="ct_clamp_id"
                    placeholder="CT Clamp ID"
                    style={input}
                    value={newCircuit.ct_clamp.id}
                    onChange={(value) => onChange(value)}
                  />
                </HeadingWrapper>
              </Form.Item>
            </LastRowInputsWrapper>
          </>
        )}
      </Form>
    </Modal>
  )
}
