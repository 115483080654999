import { Checkbox, Select } from 'antd'
import { ReactElement, useState, MouseEvent } from 'react'
import { tenantSVG } from '../assets/svg/tenant'
import { LoadManagementTenantRef } from '../models/tenant'
import { MultiTenantSelectorContainer } from '../atom/select'

const { Option } = Select

interface props {
  defaultTenant?: LoadManagementTenantRef
  onOptionsChange: (options: LoadManagementTenantRef[]) => void
  tenants: LoadManagementTenantRef[]
  loading?: boolean
  isCombineRight?: boolean
}

export const MultiTenantSelector: React.FC<props> = ({
  onOptionsChange,
  tenants,
  loading,
  isCombineRight,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([''])
  const [dirty, setDirty] = useState(false)

  const renderOption = (tenant: LoadManagementTenantRef): ReactElement => {
    return (
      <Option key={tenant.tenant_id} value={tenant.tenant_name}>
        <Checkbox
          onClick={(e) => {
            // console.log('-----------')
            // console.log(e)
            if (dirty) {
              e.stopPropagation()
            }
            setDirty(false)
            // console.log('Check Clicked')
          }}
          checked={selectedOptions.includes(tenant.tenant_name)}
        >
          {tenant.tenant_name}
        </Checkbox>
      </Option>
    )
  }

  const handleChange = (value: string[]) => {
    setSelectedOptions(value)
    setDirty(true)
    // console.log('Select Changed')
    // console.log(value)
    onOptionsChange(tenants.filter((t) => value.includes(t.tenant_name)))
  }

  const handleOnClear = () => {
    // console.log('-----selected options----')
    // console.log(selectedOptions)
    setSelectedOptions([''])
    // onClear()
  }
  const canCombineBorder = isCombineRight

  const renderTenantLists = (tenants: LoadManagementTenantRef[]) => {
    const final = tenants
      .map((v) => renderOption(v))
      .sort((a: ReactElement, b: ReactElement) => {
        return b.props.children.props.checked - a.props.children.props.checked
      })
    return final
  }

  const handlePlaceholder = () => {
    return selectedOptions.length === 1 ? selectedOptions[0] : `${selectedOptions.length} Tenants Selected`
  }

  const handleMouseDown = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    setDirty(false)
    //console.log('Select Clicked')
    e.stopPropagation()
  }

  return (
    <MultiTenantSelectorContainer isCombineRight={canCombineBorder}>
      {tenantSVG}
      <Select
        disabled={loading}
        showSearch
        mode="multiple"
        allowClear
        style={{ minWidth: 156, width: '100%', lineHeight: '34px' }}
        placeholder="Tenant (s)"
        onClear={handleOnClear}
        onChange={handleChange}
        onMouseDown={(e) => handleMouseDown(e)}
        dropdownMatchSelectWidth={false}
        maxTagCount={0}
        maxTagPlaceholder={handlePlaceholder}
      >
        {renderTenantLists(tenants)}
      </Select>
    </MultiTenantSelectorContainer>
  )
}
