import { Form, Input, Modal, Select, SelectProps, Spin } from 'antd'
import { useState } from 'react'

import { Charger } from '../../../models/charger'
import { Environment } from '../../../models/environment'
import { Template } from '../../../models/template'

interface props {
  visible: boolean
  onCancel(): void
  onSubmit(): void
  record: Charger
  environments: Environment[]
  templates: Template[]
}

export const EditChargerConfigurationTemplateModal: React.FC<props> = ({
  visible,
  onCancel,
  onSubmit,
  record,
  environments,
  templates,
}) => {
  const [loading, setLoading] = useState(false)
  const [EditEnvironmentForm] = Form.useForm()
  const [options, setOptions] = useState<SelectProps<object>['options']>([])

  const { configurationTemplate } = record

  const initialValues = {
    tenant: configurationTemplate.tenant,
    environment: configurationTemplate.environment,
    template: configurationTemplate.template,
    updatedBy: configurationTemplate.updatedBy,
  }

  const handleCancel = () => {
    EditEnvironmentForm.resetFields()
    onCancel()
  }

  return (
    <Spin spinning={loading}>
      <Modal
        forceRender
        title={`Charger ${record.serialNumber}'s Configuration Template`}
        visible={visible}
        confirmLoading={loading}
        footer={null}
        onCancel={handleCancel}
      >
        <Form layout="vertical" form={EditEnvironmentForm} initialValues={initialValues} requiredMark={false}>
          <Form.Item label="Tenant" name="tenant" rules={[{ required: true, message: 'Tenant is required!' }]}>
            <Select
              disabled
              allowClear
              showArrow={false}
              style={{ width: '100%' }}
              options={options}
              placeholder="Please select"
              showSearch
              filterOption={(input, option: any) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            />
          </Form.Item>
          <Form.Item
            label="Environment"
            name="environment"
            rules={[{ required: true, message: 'Environment is required!' }]}
          >
            <Select disabled showArrow={false}>
              {environments.map((environment) => (
                <Select.Option value={environment._id} key={environment._id}>
                  {environment.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Template" name="template" rules={[{ required: true, message: 'Template is required!' }]}>
            <Select disabled showArrow={false}>
              {templates.length > 0 &&
                templates.map((template) => (
                  <Select.Option key={template.name} value={template._id}>
                    {template.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Updated By"
            name="updatedBy"
            rules={[{ required: true, message: 'Updated By is required!' }]}
          >
            <Input disabled />
          </Form.Item>
        </Form>
      </Modal>
    </Spin>
  )
}
