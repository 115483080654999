import { LoadingOutlined } from '@ant-design/icons'
import { Button, Col, Result, Row, Spin } from 'antd'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { Redirect, useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import { Box } from '../../atom/box'
import { withAuthenticatedLayout } from '../../components/layouts/layout'
import { Links } from '../../components/routes/paths'
import { log } from '../../logger'
import { SwtchError } from '../../models/error'
import { GetSelf } from '../../services/data-provider/auth'
import { useAppState } from '../../state'
import { getSession } from '../../state/session-store'

export const DeploymentBasePage: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const { redirect_path } = queryString.parse(location.search)
  const { currentUser, setCurrentUser, logout } = useAppState()
  const [error, setError] = useState<SwtchError>()
  const session = getSession()
  const currentDate = Math.floor(Date.now() / 1000)

  useEffect(() => {
    if (currentUser) {
      return
    }
    log('onboard page is loading, current user is not defined so attempting to retrieve it')
    GetSelf()
      .then((user) => {
        log('found signed in user', user)
        setCurrentUser(user)
      })
      .catch((err) => {
        setError(err)
      })
  }, [currentUser])

  if (error && session?.expiresAt && currentDate > session.expiresAt) {
    return (
      <>
        <Result
          status="500"
          icon={<LoadingOutlined />}
          title="Session Expired"
          subTitle={`Redirecting back to the login page!`}
        />
        <Spin size="large">
          {log('logging out the user and redirecting to the auth login page')}
          {setTimeout(() => {
            logout()
            history.push(Links.auth0login())
          }, 5000)}
        </Spin>
      </>
    )
  }

  if (error) {
    return (
      <Result
        status="500"
        title="Oops something went wrong"
        subTitle={`${error.description}. Try again.`}
        extra={
          <Button
            type="primary"
            onClick={() => {
              logout()
              history.push(Links.auth0login())
            }}
          >
            Back to login
          </Button>
        }
      />
    )
  }

  if (!currentUser) {
    return (
      <Row>
        <Col span={24}>
          <Box>
            <Result icon={<LoadingOutlined />} title="Loading your profile" />
          </Box>
        </Col>
      </Row>
    )
  }

  let redirectUri = Links.dashboard()
  if (typeof redirect_path === 'string') {
    redirectUri = redirect_path as string
  }

  return <Redirect to={redirectUri} />
}

export const DeploymentPage = withAuthenticatedLayout(DeploymentBasePage)
