import { styled } from '../theme'
import React from 'react'
import { Spin } from 'antd'
import { SpinSize } from 'antd/es/spin'

// Styled wrapper component for centering content.
const Wrapper = styled.div`
  margin: auto auto;
  text-align: center;
  padding-top: 60px;
`

// Props for the Loading component, with an optional size property.
interface props {
  size?: SpinSize
}

// Loading component that displays a spinning indicator.
export const Loading: React.FC<props> = ({ size }) => {
  return (
    <Wrapper>
      <Spin size={size || 'large'} />
    </Wrapper>
  )
}
