import { styled } from '../../theme'

// BranchTableWrapper styled component
export const BranchTableWrapper = styled.div`
  font-family: ${(props) => props.theme.fontFamily.regular};

  .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th:first-child {
    width: 349px;
    min-width: 349px;
    max-width: 349px;
    border-top-left-radius: 5px;
  }
  .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th:last-child {
    width: 443px;
    border-top-right-radius: 5px;
  }
  .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th {
    width: 310px;
    border-right: 1px solid #dedede;
    border-bottom: 1px solid #dedede;
  }

  .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table {
    border-top: 1px solid #dedede;
    border-radius: 5px 5px 0px 0px;
  }
  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    padding: 10px 20px;
  }
  .ant-table-tbody > tr > td > form > .ant-form-item {
    margin-bottom: 0px;
  }
  .ant-table-tbody > tr > td {
    border-right: 1px solid #dedede !important;
    border-bottom: 1px solid #dedede !important;
  }

  .ant-table-tbody > tr:hover > td {
    background-color: transparent;
  }

  .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th {
    background: #fafafa;
    padding: 10px 20px;
  }

  .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th {
    background: #fafafa;
    padding: 10px 20px;
  }

  .ant-table-wrapper > .ant-spin-nested-loading > .ant-spin-container > .ant-table {
    border-radius: 5px 5px 0px 0px;
  }

  .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table.ant-table-bordered
    > .ant-table-container {
    border-left: 1px solid #dedede;
    border-radius: 5px 0px 0px 0px;
  }
`

// RemoveButtonWrapper styled component
export const RemoveButtonWrapper = styled.div`
  float: right;
  margin-top: -40px;
  font-family: ${(props) => props.theme.fontFamily.regular};

  .ant-btn-link:hover {
    color: #242e2c;
  }

  button {
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 16px;
    color: #242e2c;
    font-family: ${(props) => props.theme.fontFamily.regular};
  }
`
