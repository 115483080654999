import { Modal, Table } from 'antd'

import { Charger } from '../../../models/charger'
import { DateTimeV2 } from '../../../components/date-time'
import { useEffect, useState } from 'react'

interface props {
  visible: boolean
  charger: Charger
  onCancel(): void
}

export const ChargerErrorsModal: React.FC<props> = ({ visible, charger, onCancel }) => {
  const [modalWidth, setModalWidth] = useState(700)
  useEffect(() => {
    charger.errors.forEach((error) => {
      if (error.message.length > 100) {
        setModalWidth(1100)
      } else {
        setModalWidth(700)
      }
    })
  }, [charger.errors])

  const columns = [
    {
      title: 'Reason for Failure',
      dataIndex: 'message',
    },
    {
      title: 'Date/Time',
      dataIndex: 'updatedAt',
      width: '17%',
      render: (updatedAt: string) => <DateTimeV2 date={updatedAt} />,
    },
  ]

  return (
    <Modal title={charger.serialNumber} visible={visible} footer={null} onCancel={onCancel} width={modalWidth}>
      <Table columns={columns} dataSource={charger.errors} rowKey="updatedAt" pagination={false} />
    </Modal>
  )
}
