import React, { useEffect, useState } from 'react'
import { Button, Input, PageHeader, Row } from 'antd'
import { withAuthenticatedLayout } from '../../components/layouts/layout'
import { ResponsiveTable } from '../../atom/responsive-table'
import { searchSVG } from '../../assets/svg/search'
import { magnifyBlackSVG } from '../../assets/svg/magnify'
import { ButtonIcon } from '../../atom/button'
import { MultiTenantSelector } from '../../components/multi-tenants-selector'
import {
  ButtonWrapper,
  LineBreak,
  MultiTenantStyling,
  NoSearchIcon,
  SearchButtonWrapper,
  TableStyling,
  UserSelectorStyling,
} from '../../atom/panel-overview'
import { AlertError } from '../../components/error'
import { panelOverviewColumns } from './panel-overview-columns'
import { useSessions } from '../../hooks/useSessions'
import { plusSVG } from '../../assets/svg/plus'
import { AddNewPanelModal } from '../tenants/swtch-control/modal/add-new-panel'
import { AddPanelModal } from '../tenants/swtch-control/modal/add-panel'
import { useCircuits } from '../../hooks/useCircuits'
import { Circuit } from '../../models/circuit'
import { LoadManagementTenantRef } from '../../models/tenant'
import { useSwtchControlPermissions } from './useSwtchControlPermissions'
import UnauthorizedResult from '../../components/result/UnauthorizedResult'
import { GreyBackground } from '../../atom/grey-background'
const { Search } = Input

const PanelOverviewBasePage: React.FC = () => {
  const [addNewPanelModal, setAddNewPanelModal] = useState(false)
  const [addPanelModalVisible, setAddPanelModalVisible] = useState(false)
  const [circuitsFromUi, setCircuitsFromUi] = useState<Circuit[]>([])
  const [circuits, setCircuits] = useState<Circuit[]>([])
  const [addNewCircuitId, setAddNewCircuitId] = useState('')
  const [tenantsToSearch, setTenantsToSearch] = useState<LoadManagementTenantRef[]>([])
  const {
    loadManagements,
    error,
    loading,
    searchTenantId,
    allTenants,
    onChange,
    searchForTenants,
    onPaginationChange,
  } = useSessions()
  const { panelTypeModal, setPanelTypeModal } = useCircuits()
  const { canUpdateSwtchControl, canCreateSwtchControl, canReadSwtchControl } = useSwtchControlPermissions()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const cancelAdd = () => {
    setAddPanelModalVisible(false)
    setPanelTypeModal('')
    if (setAddNewCircuitId) setAddNewCircuitId('')
  }

  const handleAddPanelSubmit = () => {
    setAddPanelModalVisible(false)
    setPanelTypeModal('')
  }

  const renderTitle = () => {
    return (
      <Row
        gutter={[8, 8]}
        style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#fafafa', padding: '8px' }}
      >
        <div style={{ display: 'flex' }}>
          <MultiTenantStyling>
            <MultiTenantSelector
              isCombineRight
              onOptionsChange={(tenants: any) => setTenantsToSearch(tenants)}
              tenants={allTenants}
            />
          </MultiTenantStyling>
          <LineBreak />
          <UserSelectorStyling>
            {searchSVG}
            <div style={{ display: 'flex' }}>
              <NoSearchIcon>
                <Search
                  placeholder="Tenant ID (s)"
                  value={searchTenantId}
                  onChange={onChange}
                  onSearch={(v) => {
                    searchForTenants(v, tenantsToSearch)
                  }}
                />
              </NoSearchIcon>
              <SearchButtonWrapper>
                <ButtonIcon
                  icon={magnifyBlackSVG}
                  loading={loading}
                  fetchData={() => searchForTenants(searchTenantId, tenantsToSearch)}
                />
              </SearchButtonWrapper>
            </div>
          </UserSelectorStyling>
        </div>
      </Row>
    )
  }

  const newSwtchControlButton = (
    <ButtonWrapper>
      <Button
        size="large"
        key="invite-btn"
        type="primary"
        icon={plusSVG}
        loading={loading}
        onClick={() => setAddNewPanelModal(true)}
      >
        NEW SWTCH CONTROL
      </Button>
    </ButtonWrapper>
  )

  return (
    <GreyBackground>
      {canReadSwtchControl ? (
        <>
          <AlertError error={error} />
          <TableStyling>
            <PageHeader title={renderTitle()} extra={canCreateSwtchControl ? newSwtchControlButton : null} />
            <ResponsiveTable
              loading={loading}
              size="small"
              pagination={{
                position: ['bottomCenter'],
                showSizeChanger: false,
                onChange: onPaginationChange,
              }}
              dataSource={loadManagements}
              columns={panelOverviewColumns(canUpdateSwtchControl)}
              rowKey="id"
            />
          </TableStyling>
          {addNewPanelModal && (
            <AddNewPanelModal
              showCircuitIdInput={true}
              addNewCircuitId={addNewCircuitId}
              setAddNewCircuitId={setAddNewCircuitId}
              setPanelTypeModal={setPanelTypeModal}
              setAddNewPanelModal={setAddNewPanelModal}
              setAddPanelModalVisible={setAddPanelModalVisible}
            />
          )}
          {addPanelModalVisible && (
            <AddPanelModal
              addNewCircuitId={addNewCircuitId}
              visible={addPanelModalVisible}
              onCancel={cancelAdd}
              onSubmit={handleAddPanelSubmit}
              panelTypeModal={panelTypeModal}
              setPanelTypeModal={setPanelTypeModal}
              circuitsFromUi={circuitsFromUi}
              setCircuitsFromUi={setCircuitsFromUi}
              circuits={circuits}
              setCircuits={setCircuits}
            />
          )}
        </>
      ) : (
        <UnauthorizedResult />
      )}
    </GreyBackground>
  )
}

export const PanelOverviewPage = withAuthenticatedLayout(PanelOverviewBasePage)
