import { Button, Modal, Skeleton } from 'antd'

import { Charger } from '../../models/charger'
import { useGetChargerConfig } from '../../hooks/useGetChargerConfig'
import { AlertError } from '../../components/error'
import ShowChargerConfigTable from '../table/ShowChargerConfigTable'

interface ShowChargerConfigModalProps {
  charger: Charger
  onClose: () => void
}

const ShowChargerConfigModal: React.FC<ShowChargerConfigModalProps> = ({ charger, onClose }) => {
  const { serialNumber } = charger
  const { loading, error, chargerConfig } = useGetChargerConfig({ serialNumber })

  return (
    <>
      <AlertError error={error} />
      <Modal
        title={<h3>Charger Configuration for {charger.serialNumber}</h3>}
        visible={!!charger}
        onCancel={onClose}
        onOk={onClose}
        width={700}
        style={{ top: 10 }}
        className="fixed-title-modal"
        footer={[
          <Button key="submit" type="primary" onClick={onClose}>
            Close
          </Button>,
        ]}
      >
        {loading && <Skeleton active />}
        {chargerConfig && typeof chargerConfig !== 'undefined' && (
          <ShowChargerConfigTable chargerConfig={chargerConfig} />
        )}

        {/* One day, the data will come back in sections */}
        {/* <br />
          <Table
            dataSource={chargerConfig.charger}
            columns={columns}
            pagination={false}
            bordered
            style={{ padding: 0 }}
            title={() => <h2>Hardware</h2>}
          />
          <br />
          <Table
            dataSource={chargerConfig.meter}
            columns={columns}
            pagination={false}
            title={() => <h2>Meter</h2>}
            bordered
            style={{ padding: 0 }}
          /> */}
      </Modal>
    </>
  )
}

export default ShowChargerConfigModal
