import { styled } from '../theme'

// Styles for the container of the app header.
export const AppHeaderContainer = styled.div`
  font-family: ${(props) => props.theme.fontFamily.regular};
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.lightGray};

  h1 {
    color: ${(props) => props.theme.colors.softBlack};
  }

  .ant-layout-header,
  .sub-menu {
    background-color: ${(props) => props.theme.colors.white};
  }

  .ant-menu-submenu-title {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-menu-overflow.ant-menu.ant-menu-root.ant-menu-horizontal.ant-menu-light {
    display: flex;
    justify-content: right;
    align-items: center;
  }

  .ant-menu-overflow-item.ant-menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 0px;
    padding-top: 18px;
  }

  .logout-btn svg {
    margin-left: 10px;
  }

  .lng-option img {
    display: none !important;
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
  .ant-menu-horizontal > .ant-menu-item::after,
  .ant-menu-horizontal > .ant-menu-submenu::after,
  .ant-menu-horizontal {
    border: 0;
  }

  .language:hover svg path,
  .logout-btn:hover svg path {
    fill: ${(props) => props.theme.colors.neonGreen};
  }

  .ant-menu-submenu-disabled,
  .ant-menu-overflow-item.ant-menu-item.ant-menu-item-disabled.logout-btn {
    pointer-events: none;
  }

  .ant-menu-submenu-disabled svg path {
    fill: ${(props) => props.theme.colors.lightGray};
  }

  .language:hover .ant-menu-title-content,
  .logout-btn:hover .ant-menu-title-content {
    color: ${(props) => props.theme.colors.neonGreen};
  }

  .language:hover + .logout-btn .right-arrow {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    transition: all 500ms ease;
  }

  .ant-menu-light .ant-menu-submenu-active {
    color: ${(props) => props.theme.colors.black};
  }

  .ant-menu-overflow span {
    font-family: 'Akkurat LL Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
      'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    font-style: normal;
  }

  .arrow-icon {
    position: absolute;
    bottom: 3px;
    right: -15px;
    transition: all 500ms ease;
  }

  .globe {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .language:hover .arrow-icon,
  .language.ant-menu-submenu-open.ant-menu-submenu-active .arrow-icon {
    transform: rotate(90deg);
    transition: all 500ms ease;
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    color: #000;
  }
`

// Styles for the heading wrapper that holds the header title.
export const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
`
