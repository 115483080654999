// Defines an SVG component for a dedicated panel icon
export const dedicatedPanelSVG = (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="13" cy="13" r="12.5" fill="#30D7BA" stroke="#30D7BA" />
    <path
      d="M7.44633 13.52C7.44633 14.2552 6.85034 14.8512 6.11514 14.8512C5.37993 14.8512 4.78394 14.2552 4.78394 13.52C4.78394 12.7848 5.37993 12.1888 6.11514 12.1888C6.85034 12.1888 7.44633 12.7848 7.44633 13.52ZM7.44633 13.52H15.4335M15.4335 13.52C15.4335 14.2552 16.0295 14.8512 16.7647 14.8512C17.4999 14.8512 18.0959 14.2552 18.0959 13.52C18.0959 12.7848 17.4999 12.1888 16.7647 12.1888C16.0295 12.1888 15.4335 12.7848 15.4335 13.52ZM15.4335 8.1952C15.4335 8.9304 16.0295 9.5264 16.7647 9.5264C17.4999 9.5264 18.0959 8.9304 18.0959 8.1952C18.0959 7.46 17.4999 6.864 16.7647 6.864C16.0295 6.864 15.4335 7.46 15.4335 8.1952ZM15.4335 8.1952H11.6063C11.2387 8.1952 10.9407 8.4932 10.9407 8.8608V18.1792C10.9407 18.5468 11.2387 18.8448 11.6063 18.8448H15.4335M15.4335 18.8448C15.4335 19.58 16.0295 20.176 16.7647 20.176C17.4999 20.176 18.0959 19.58 18.0959 18.8448C18.0959 18.1096 17.4999 17.5136 16.7647 17.5136C16.0295 17.5136 15.4335 18.1096 15.4335 18.8448Z"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
)
