import React from 'react'
import { Notifier } from '@airbrake/browser'
import configProvider from '../../../config'
import { Result } from 'antd'

class ErrorBoundary extends React.Component {
  state: { hasError: boolean }
  private airbrake: Notifier | undefined

  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
    this.airbrake = undefined

    if (configProvider.config.airbrake) {
      this.airbrake = new Notifier({
        projectId: configProvider.config.airbrake.projectId,
        projectKey: `${configProvider.config.airbrake.projectKey}`,
      })
      this.airbrake.addFilter((notice) => {
        notice.context.environment = configProvider.config.airbrake?.env
        return notice
      })
    }
  }

  componentDidCatch(error: any, info: any) {
    // Display fallback UI
    this.setState({ hasError: true })
    // Send error to Airbrake
    if (this.airbrake) {
      this.airbrake.notify({
        error: error,
        params: {
          info: info,
        },
      })
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <Result status="500" title="500" subTitle="Sorry, something went wrong." />
    }
    return this.props.children
  }
}

export default ErrorBoundary
