import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Drawer, Layout, Menu, Row } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import { useNetwork } from '../../../context/network'
import { useAppState } from '../../../state'
import { Links, Paths } from '../../routes/paths'
import { FileTextFilled, PieChartFilled, ProfileFilled } from '@ant-design/icons'
import { navigationArrowSVG } from '../../../assets/svg/navigationArrow'
import { NavigationMenuWrapper, Wrapper } from '../../../atom/navigation'

const { Sider } = Layout
const NAVIGATION_COLLAPSE_SIZE = 70

// Navigation component
interface props {
  collapsed: boolean
  skeleton?: boolean
  handleToggleMenu(): void
}

export const Navigation: React.FC<props> = ({ collapsed, skeleton, handleToggleMenu }) => {
  const { IsMobile, IsDesktop } = useAppState()
  const location = useLocation()
  const network = useNetwork()
  const displaySkeleton = skeleton ? skeleton : false
  const isMobile = IsMobile()
  const isDesktop = IsDesktop()

  // JSX for the menu items
  const MenuSelection = (
    <>
      <NavigationMenuWrapper
        className={`toggleNavigation ${collapsed ? '' : 'expanded'}`}
        collapsed={collapsed}
        isDesktop={isDesktop}
      >
        <Menu defaultSelectedKeys={[location.pathname]} mode="inline" theme="dark">
          <Menu.Item
            key={Paths.dashboard}
            icon={<FontAwesomeIcon style={{ height: '18px', width: '18px' }} icon={faHome} />}
            disabled={displaySkeleton}
          >
            <Link to={Links.dashboard()}>Dashboard</Link>
          </Menu.Item>
          <Menu.Item key={Paths.panelOverview} icon={<PieChartFilled />} disabled={displaySkeleton}>
            <Link to={Links.panelOverview()}>Panel Overview</Link>
          </Menu.Item>
          <Menu.Item key={Paths.template} icon={<FileTextFilled />} disabled={displaySkeleton}>
            <Link to={Links.template()}>Template</Link>
          </Menu.Item>
          <Menu.Item key={Paths.chargerModels} icon={<ProfileFilled />} disabled={displaySkeleton}>
            <Link to={Links.chargerModels()}>Charger Models</Link>
          </Menu.Item>
        </Menu>
        <div className={`toggleNavigation ${collapsed ? '' : 'expanded'}`} onClick={handleToggleMenu}>
          {navigationArrowSVG}
        </div>
      </NavigationMenuWrapper>
    </>
  )

  // Return JSX for the Navigation component
  return (
    <>
      {
        <Sider
          collapsed={collapsed}
          collapsedWidth={isMobile && collapsed ? 60 : NAVIGATION_COLLAPSE_SIZE}
          style={{ backgroundColor: '#000' }}
        >
          <Wrapper>
            {collapsed && network.icon}
            {!collapsed && (
              <Row justify="center">
                <Col span={24}>{network.logo}</Col>
              </Row>
            )}

            {MenuSelection}
          </Wrapper>
        </Sider>
      }
      {isMobile && (
        <Drawer
          closable={false}
          placement="left"
          // visible={collapsed}
          onClose={handleToggleMenu}
          width="54vw"
          drawerStyle={{ backgroundColor: '#000' }}
        >
          <p>{network.logo}</p>
          {MenuSelection}
        </Drawer>
      )}
    </>
  )
}
