import { styled } from "../theme"

export const ButtonWrapper = styled.div`
  .ant-btn-primary:hover {
    background-color: #00b799;
    border-color: #00b799;
  }
  .ant-btn-primary > a:hover {
    height: 17px;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #000000;
    text-shadow: none;
    font-family: ${(props) => props.theme.fontFamily.regular};
  }
  .ant-btn {
    border-radius: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    gap: 8px;
    width: 206px;
    height: 38px;
    background: #30d7ba;
    box-shadow: none;
    border: none;
    font-family: ${(props) => props.theme.fontFamily.regular};
  }

  .ant-btn > svg {
    margin-bottom: 2px;
  }

  .ant-btn > span {
    height: 16.8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #000000;
    text-shadow: none;
    font-family: ${(props) => props.theme.fontFamily.regular};
  }

  .ant-btn-general {
    border-radius: 20px;
    text-transform: uppercase;
  }
`

export const TableStyling = styled.div`
  padding: 24px 20px 20px 20px;
  background-color: #fafafa;

  .ant-page-header {
    background-color: #fafafa;
    padding: 0px 0px 20px 0px;
  }

  .ant-page-header-heading-left {
    overflow: inherit;
  }

  .ant-page-header-heading-title {
    overflow: inherit;
  }

  .ant-page-header-heading {
    align-items: center;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background: none;
  }

  .ant-table.ant-table-small .ant-table-title {
    padding: 0;
  }

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    text-align: left;
  }

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    text-align: left;
    font-size: 13px;
    background-color: white;
    padding: 10px !important;
    font-weight: 400;
    line-height: 16px;
    font-family: ${(props) => props.theme.fontFamily.regular};
  }

  .ant-table-small .ant-table-thead > tr > th {
    padding: 16px 10px !important;
    font-weight: 700;
    font-family: ${(props) => props.theme.fontFamily.regular};
  }

  .ant-spin-container {
    display: flex;
    flex-direction: column;
    min-height: 83vh;
    justify-content: space-between;
    background-color: white;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
  }

  .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-pagination
    > ant-pagination-next
    > .ant-pagination-item-link
    > .anticon,
  .anticon-right {
    display: inline !important;
  }

  .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-pagination
    > ant-pagination-next
    > .ant-pagination-item-link
    > .anticon,
  .anticon-right {
    display: inline !important;
  }

  .ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
    background-color: #fafafa;
    border: 1px #f1f1f1 solid;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 36px;
  }

  .ant-pagination-jump-next,
  .ant-pagination-jump-prev,
  .ant-pagination-prev {
    margin-right: 8px !important;
  }

  .ant-pagination-jump-next,
  .ant-pagination-jump-prev,
  .ant-pagination-next,
  .ant-pagination-prev {
    margin-left: 8px !important;
  }

  .ant-table-pagination.ant-pagination {
    margin-bottom: 40px;
  }

  .ant-pagination-item {
    border-radius: 20px;
    background: #30d7ba;
    border-color: #30d7ba;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px !important;
    width: 36px;
  }

  .ant-pagination-item a {
    color: #000000;
    font-family: ${(props) => props.theme.fontFamily.regular};
  }
`

export const SvgSpacing = styled.span`
  svg {
    margin-right: 10px;
  }
`

export const SecondaryDataWrapper = styled.div`
  color: #242e2c;
  opacity: 0.6;
  font-weight: 300;
  font-size: 11px;
  line-height: 16px;
  margin-top: 13px;
  font-family: ${(props) => props.theme.fontFamily.light};
`

export const EditButtonWrapper = styled.div`
  width: 100%;

  .ant-btn {
    font-size: 13px;
    letter-spacing: 0.05em;
    color: #30d7ba;
    font-family: ${(props) => props.theme.fontFamily.regular};
  }
`

export const MultiTenantStyling = styled.div`
  div > .ant-select > .ant-select-selector {
    width: 321px;
    border-radius: 48px 0px 0px 48px !important;
    border-right-width: 0px;
    z-index: 1;
    padding-left: 33px;
  }
  div > .ant-select > .ant-select-selector:hover {
    border-right-width: 1px;
  }

  div
    > .ant-select
    > .ant-select-selector
    > .ant-select-selection-overflow
    > .ant-select-selection-overflow-item
    > .ant-select-selection-item {
    border-radius: 17px;
    margin-left: 5px;
  }

  div > .ant-select > .ant-select-selector > .ant-select-selection-overflow {
    margin-left: 2px;
  }
`

export const LineBreak = styled.span`
  border-right: 1px solid #d0d5dd;
  height: 24px;
  z-index: 1;
  position: absolute;
  left: 324.5px;
  top: 20px;
`

export const UserSelectorStyling = styled.div`
  width: 210px;

  .ant-input-group-wrapper > .ant-input-wrapper > .ant-input {
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    padding-left: 30px;
    width: 230px;
    line-height: 26px;
    border-radius: 0px 48px 48px 0px !important;
    border-left-width: 1px;
    border-left-color: transparent;
    height: 39px;
    margin-left: -1px;
  }

  .ant-input-group-wrapper > .ant-input-wrapper > .ant-input:hover {
    border-left-width: 1px;
    border-color: #30D7BA;
  }

  .ant-input-group-wrapper > .ant-input-wrapper > .ant-input:focus {
    border-left-width: 1px;
    border-color: #30D7BA;
  }

  svg.usersSelectorIcon {
    z-index: 2;
    position: absolute;
    top: 31px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: all 0.3s;
    margin-left: 8px;
  }
`

export const NoSearchIcon = styled.div`
  .ant-input-group-addon {
    display: none;
  }
  ${(props) => props.theme.breakpoints.down('lg')} {
    .ant-select {
      width: 100%;
    }
  }
`

export const SearchButtonWrapper = styled.div`
  .ant-btn-primary:hover {
    background-color: #00b799;
    border-color: #00b799;
  }
  margin-top: 2.5px;

  button > svg {
    margin-top: 3px;
  }
`
