import React from 'react'
import { ChargerNameWrapper } from '../atom/load-management/charger-table'
import { chargerSVG } from '../assets/svg/charger'
import { Select } from 'antd'
import { ChargerDetails, LoadManagementCharger } from '../models/charger'
import { ChargerOptionWrapper, ListingLabelColourWrapper } from '../atom/load-management/load-management'
import { WarningOutlined } from '@ant-design/icons'
import { Branch, Circuit } from '../models/circuit'

interface props {
  verifiedChargers: LoadManagementCharger[]
  circuit: Circuit
  branch: Branch
  chargers: ChargerDetails[]
  index: number
  chargerName: string
  onChangeCharger: (e: any, circuit: Circuit, branch: any, charger: any, key?: string) => void
}

export const ChargerSelect: React.FC<props> = ({
  verifiedChargers,
  circuit,
  branch,
  chargers,
  index,
  chargerName,
  onChangeCharger,
}) => {
  return (
    <ChargerNameWrapper>
      {chargerSVG}
      <Select
        showSearch
        value={chargerName || undefined}
        defaultValue={chargerName || undefined}
        placeholder="Type Serial Number"
        defaultActiveFirstOption={false}
        showArrow={false}
        onChange={(value) => onChangeCharger(value, circuit, branch, chargers[index], 'cp_serial_number')}
        notFoundContent={null}
        optionLabelProp="children"
      >
        {verifiedChargers.map((charger) => (
          <Select.Option value={charger.chargepoint_serial_number} key={charger.chargepoint_serial_number}>
            <ChargerOptionWrapper>
              {charger.service_status === 'in_maintenance' && <WarningOutlined />}
              {charger.chargepoint_serial_number}
              {Object.values(charger.connectors).map((connector, connectorIndex) =>
                connector.listing_title ? (
                  <ListingLabelColourWrapper key={connectorIndex}>{connector.listing_title}</ListingLabelColourWrapper>
                ) : null,
              )}
            </ChargerOptionWrapper>
          </Select.Option>
        ))}
      </Select>
    </ChargerNameWrapper>
  )
}
