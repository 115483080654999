import { useState } from 'react'
import { Drawer, Button, Select, Spin, Space } from 'antd'
import { FilterOutlined } from '@ant-design/icons'

import { HomeFilter } from '../../models/filter'
import { connectionStatus } from '../../helpers/connectivity'

interface props {
  loading: boolean
  filter: HomeFilter
  handleMaxTagPlaceholder: (value: string) => string
  setFilter: (filter: any) => void
  getData: () => void
}

const HomeSelectors: React.FC<props> = ({ loading, filter, setFilter, getData, handleMaxTagPlaceholder }) => {
  const [visible, setVisible] = useState(false)

  const showDrawer = () => {
    setVisible(true)
  }

  const closeDrawer = () => {
    setVisible(false)
  }

  return (
    <div>
      <Button type="primary" icon={<FilterOutlined />} onClick={showDrawer}>
        Filter
      </Button>
      <Drawer title="Filter Chargers" placement="left" onClose={closeDrawer} visible={visible}>
        <Space direction="vertical">
          <Select
            mode="tags"
            allowClear
            disabled={loading}
            placeholder="Serial number (s)"
            onChange={(value) => setFilter({ ...filter, charger_serial_numbers: value })}
            onClear={() => setFilter({ ...filter, charger_serial_numbers: [] })}
            dropdownMatchSelectWidth={false}
            style={{ minWidth: 330, width: '100%' }}
            // options={[{ label: 'SWTCH-0002', value: 'SWTCH-0002' }]}
            tokenSeparators={[',']}
            maxTagCount={0}
            maxTagPlaceholder={handleMaxTagPlaceholder}
            notFoundContent={loading ? <Spin size="small" /> : 'Start typing to input'}
          />
          <Select
            allowClear
            showSearch
            disabled={loading}
            placeholder="Status"
            // style={{ minWidth: '213px', width: '100%', lineHeight: '26px' }}
            style={{ minWidth: 300, width: '100%', lineHeight: '34px' }}
            dropdownMatchSelectWidth={false}
            options={connectionStatus}
            onChange={(value) => setFilter({ ...filter, status: value })}
          />

          <Button
            type="primary"
            loading={loading}
            onClick={() => {
              getData()
              closeDrawer()
            }}
            style={{ width: '100%' }}
          >
            Apply
          </Button>
        </Space>
      </Drawer>
    </div>
  )
}

export default HomeSelectors
