import { Button, Col, Form, Input, message, Modal, Row, Space, Spin } from 'antd'
import { useEffect, useState } from 'react'

import { formLayout, tailLayout } from '../../../atom/form/page-layout'
import { AlertError } from '../../../components/error'
import { AlertWarning } from '../../../components/warning'
import { isJson } from '../../../helpers/charger'
import { ApiError } from '../../../models/error'
import { Template } from '../../../models/template'
import { EditChargerTemplate } from '../../../services/data-provider/template'

interface props {
  visible: boolean
  onCancel(): void
  onSubmit(): void
  record: Template
  templateID: string
}

export const EditTemplateModal: React.FC<props> = ({ visible, onCancel, onSubmit, record, templateID }) => {
  const [loading, setLoading] = useState(false)
  const [EditTemplateForm] = Form.useForm()
  const [error, setError] = useState<ApiError>()

  const { template } = record

  const initialValues = {
    ...record,
    template: JSON.stringify(template, null, 2).replace(/[{}]/g, ''),
  }

  useEffect(() => {
    EditTemplateForm.resetFields()
  }, [record])

  const handleOk = (values: any) => {
    setLoading(true)

    const { template } = values

    const templateObj: {
      [key: string]: string
    } = JSON.parse(`{ ${template} }`)

    const templateToUpdateParams: Omit<Template, 'updatedAt' | 'deletedAt' | 'createdAt'> = {
      _id: record._id,
      name: values['name'],
      vendor: values['vendor'],
      model: values['model'],
      template: templateObj,
      updatedBy: values['updatedBy'],
    }

    EditChargerTemplate(templateToUpdateParams)
      .then((resp) => {
        message.success(resp.message)
        if (
          (Array.isArray(resp.errors) && resp.errors.length > 0) ||
          (Array.isArray(resp.warnings) && resp.warnings.length > 0)
        ) {
          setError(
            new ApiError(
              'Something went wrong with this request. Please contact customer support if this issue persists.',
              resp.errors,
              resp.warnings,
            ),
          )
        }
        onSubmit()
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleCancel = () => {
    EditTemplateForm.resetFields()
    onCancel()
  }

  return (
    <>
      <AlertError error={error} closable={true} />
      <AlertWarning error={error} closable={true} />
      <Spin spinning={loading}>
        <Row gutter={[8, 8]}>
          <Col md={24} lg={24} xl={24} xxl={24} style={{ textAlign: 'end' }}>
            <Modal
              title="Edit Template"
              visible={visible}
              confirmLoading={loading}
              footer={null}
              onCancel={handleCancel}
              width={1024}
            >
              <Form
                {...formLayout}
                form={EditTemplateForm}
                onFinish={handleOk}
                style={{ marginTop: '2rem' }}
                initialValues={initialValues}
              >
                <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Name is required!' }]}>
                  <Input />
                </Form.Item>
                <Form.Item label="Model" name="model">
                  <Input />
                </Form.Item>
                <Form.Item label="Vendor" name="vendor">
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Updated By"
                  name="updatedBy"
                  rules={[{ required: true, message: 'Updated By is required!' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Template"
                  name="template"
                  rules={[
                    {
                      required: true,
                      validator: (_, value) => {
                        if (!value) {
                          return Promise.reject('Template is required!')
                        }
                        if (isJson(value)) {
                          return Promise.resolve()
                        } else {
                          return Promise.reject(
                            <>
                              <p>Template is required in the correct format:</p>
                              <p>"key1":"value1",</p>
                              <p>"key2":"value2"</p>
                            </>,
                          )
                        }
                      },
                    },
                  ]}
                >
                  <Input.TextArea rows={20} />
                </Form.Item>
                <Form.Item {...tailLayout}>
                  <Space size="large">
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button type="primary" htmlType="submit">
                      Update
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Modal>
          </Col>
        </Row>
      </Spin>
    </>
  )
}
