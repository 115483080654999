import { Form, Row, Select } from 'antd'

import { ChargerModelTemplate } from '../../models/charger-model-template'

interface props {
  chargerModelTemplateLoading: boolean
  chargerModelTemplates: ChargerModelTemplate[]
  chargerModelTemplateSelected?: ChargerModelTemplate | null
  onCommissionFields: (template: ChargerModelTemplate | null) => void
}

const CommissionChargerModelTemplate: React.FC<props> = ({
  chargerModelTemplateSelected,
  chargerModelTemplateLoading,
  chargerModelTemplates,
  onCommissionFields,
}) => {
  const handleChange = (value: string) => {
    const selectedTemplate = chargerModelTemplates.find((template) => template.id === value)
    if (selectedTemplate) {
      onCommissionFields(selectedTemplate)
    } else {
      onCommissionFields(null)
    }
  }

  const filterOption = (input: string, option: any) => {
    return option.children.toLowerCase().includes(input.toLowerCase())
  }
  return (
    <Row justify="space-between">
      <Form.Item
        name="charger_model_template_id"
        label="Charger Model Template"
        help={chargerModelTemplateSelected ? '' : 'Please select a charger model template before commissioning'}
        style={{ display: 'inline-block', width: chargerModelTemplateSelected ? '50%' : '100%' }}
      >
        <Select
          loading={chargerModelTemplateLoading}
          disabled={chargerModelTemplateLoading || chargerModelTemplates.length === 0}
          placeholder="ABB_32A_EX-1762-5SW1-SWTCH"
          dropdownMatchSelectWidth={false}
          allowClear
          showSearch
          onChange={handleChange}
          filterOption={filterOption}
        >
          {chargerModelTemplates.map((data) => (
            <Select.Option key={data.id} value={data.id}>
              {data.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Row>
  )
}

export default CommissionChargerModelTemplate
