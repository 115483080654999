import { theme } from '../../theme'

export const networkSVG = (
  <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.80005 15.7V14.1M6.60005 15.7V10.1M11.4 15.7V6.09999M16.2 15.7V1.29999"
      stroke={theme.colors.darkGray}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
