import { useState } from 'react'
import { Button, Form, Input, Modal, Radio, Space } from 'antd'
import { FooterLayout, ModalTitleWrapper, RadioGroupWrapper } from '../../../../atom/load-management/modals'
import { dedicatedPanelSVG } from '../../../../assets/svg/dedicatedPanel'
import { mixedPanelSVG } from '../../../../assets/svg/mixedPanel'
import { PanelType } from '../../../../models/circuit'

interface props {
  addNewCircuitId?: string
  showCircuitIdInput?: boolean
  setAddNewCircuitId?: (addNewCircuitId: string) => void
  setAddNewPanelModal: (addNewPanel: boolean) => void
  setAddPanelModalVisible: (addPanelModalVisible: boolean) => void
  setPanelTypeModal: (panelTypeModal: string) => void
}

export const AddNewPanelModal: React.FC<props> = ({
  addNewCircuitId,
  showCircuitIdInput,
  setAddNewCircuitId,
  setAddNewPanelModal,
  setAddPanelModalVisible,
  setPanelTypeModal,
}) => {
  const [panelTypeSelected, setPanelTypeSelected] = useState(false)
  const disabled =
    (showCircuitIdInput && addNewCircuitId === '') ||
    (showCircuitIdInput && !panelTypeSelected) ||
    (!showCircuitIdInput && !panelTypeSelected)
      ? true
      : false

  const afterClose = () => {
    setAddNewPanelModal(false)
    setPanelTypeModal('')
    if (setAddNewCircuitId) setAddNewCircuitId('')
  }

  const onCancel = () => {
    setAddNewPanelModal(false)
    setPanelTypeModal('')
    if (setAddNewCircuitId) setAddNewCircuitId('')
  }

  const handleAddPanelSubmit = () => {
    setAddPanelModalVisible(true)
    setAddNewPanelModal(false)
  }

  return (
    <Modal
      title={<ModalTitleWrapper>Add SWTCH Control</ModalTitleWrapper>}
      closable={true}
      visible={true}
      afterClose={afterClose}
      onCancel={onCancel}
      footer={
        <FooterLayout>
          <Button style={{ width: '240px' }} onClick={onCancel}>
            <span className="paragraph-02-regular">CANCEL</span>
          </Button>
          <Button style={{ width: '240px' }} onClick={handleAddPanelSubmit} disabled={disabled}>
            <span className="paragraph-02-regular">ADD</span>
          </Button>
        </FooterLayout>
      }
      width={470}
    >
      <Form>
        {showCircuitIdInput && setAddNewCircuitId && (
          <>
            <Input
              placeholder="Tenant ID"
              value={addNewCircuitId}
              onChange={(e) => setAddNewCircuitId(e.target.value)}
            />
            <hr
              style={{
                border: 0,
                borderTop: '1px solid #f0f0f0',
                margin: '25px -24px 25px',
              }}
            />
          </>
        )}
        <RadioGroupWrapper>
          <Radio.Group
            onChange={(e) => {
              setPanelTypeModal(e.target.value)
              setPanelTypeSelected(true)
            }}
          >
            <Space direction="vertical">
              <Radio value={PanelType.dedicated}>{dedicatedPanelSVG} Add Dedicated EV Panel</Radio>
              <Radio value={PanelType.mixed}>{mixedPanelSVG} Add Mixed EV Panel</Radio>
            </Space>
          </Radio.Group>
        </RadioGroupWrapper>
      </Form>
    </Modal>
  )
}
