import { WebAuth } from 'auth0-js'
import { Paths } from '../../components/routes/paths'
import { buildAppUrl } from '../../helpers/url'
import configProvider from '../../config'

export default new WebAuth({
  clientID: configProvider.config.auth0.clientId,
  domain: configProvider.config.auth0.domain,
  redirectUri: buildAppUrl(Paths.auth0Callback),
  responseType: 'token id_token',
  scope: configProvider.config.auth0.scope,
  audience: configProvider.config.auth0.audience,
})
