type NotificationStatus = 'success' | 'warning' | 'fail' | 'partial'

export interface ErrorChargerMessage {
  [key: string]: {
    error_msg: string
  }
}

export interface CommissionNewListingData {
  total_request_chargers: number
  total_processing_chargers: number
  total_error_chargers: number
  error_chargers: ErrorChargerMessage | {}
}

export interface CommissionNewListingResponse {
  status: NotificationStatus
  message: string
  data: CommissionNewListingData
}

export const mockCommissionNewListingSuccess = {
  status: 'success' as NotificationStatus,
  message: 'charger commissioning started',
  data: {
    total_request_chargers: 2,
    total_processing_chargers: 2,
    total_error_chargers: 0,
    error_chargers: {},
  },
}

export const mockCommissionNewListingFailed = {
  status: 'fail' as NotificationStatus,
  message: 'Commission new listing failed',
  data: {
    total_request_chargers: 2,
    total_processing_chargers: 0,
    total_error_chargers: 2,
    error_chargers: {
      '1EC3-1-1917-00010': {
        error_msg:
          'charger 1EC3-1-1917-00010, connector 1, listing title MMY01 is duplicate. charger 1EC3-1-1917-00010 cannot be commisioned',
      },
      SWT00000102: {
        error_msg:
          'charger SWT00000102, connector 1, listing title MMY01 is duplicate. charger SWT00000102 cannot be commisioned',
      },
    },
  },
}

export const mockCommissionNewListingPartialFailed = {
  status: 'warning' as NotificationStatus,
  message: 'charger commissioning started but some chargers failed',
  data: {
    total_request_chargers: 2,
    total_processing_chargers: 1,
    total_error_chargers: 1,
    error_chargers: {
      '1EC3-1-1917-00010': {
        error_msg:
          'charger 1EC3-1-1917-00010, connector 1, listing title FR03 already exists in rails. charger 1EC3-1-1917-00010 cannot be commisioned',
      },
    },
  },
}
