import { Button, Form, Modal } from 'antd'

import { Charger } from '../../models/charger'
import { Environment } from '../../models/environment'

import CommissionNewListingsForm from './CommissionNewListingsForm'
import { AlertError } from '../../components/error'
import { pluralize } from '../../helpers/letter'
import { useCreateCommissionNewListings } from '../../hooks/useCreateCommissionNewListings'
import CommissionError from './CommissionError'
import { useState } from 'react'
import { ChargerModelTemplate } from '../../models/charger-model-template'
import CommissionLoading from './CommissionLoading'

interface CommissionNewListingsModalProps {
  onCancel: () => void
  isModalOpen: boolean
  onOk: () => void
  chargers: Charger[]
  environments: Environment[]
}

const CommissionNewListingsModal: React.FC<CommissionNewListingsModalProps> = ({
  onCancel,
  isModalOpen,
  onOk,
  chargers,
  environments,
}) => {
  const [form] = Form.useForm()

  const [chargerModelTemplateSelected, setChargerModelTemplateSelected] = useState<ChargerModelTemplate | null>(null)

  const {
    loading,
    error,
    commissionLoading,
    isModalVisible,
    onFinish,
    handleCommissionModalOk,
    handleCommissionModalCancel,
    handleCommissionLoadingModalOk,
    commissionNewListingError,
  } = useCreateCommissionNewListings({ form, onOk })

  const buttonProps = {
    loading,
    disabled: loading,
    style: { width: '49%' },
  }

  const handlePluralTitle = (chargers: Charger[]) => `Commission New ${pluralize('Listing', chargers.length)}`

  const footer = [
    <Button key="back" onClick={onCancel} {...buttonProps}>
      Cancel
    </Button>,
    <Button key="submit" type="primary" onClick={onFinish} {...buttonProps}>
      {`Commission New ${pluralize('Listing', chargers.length)}`}
    </Button>,
  ]

  const handleCommissioningFields = (template: ChargerModelTemplate | null) => {
    setChargerModelTemplateSelected(template)
  }

  return (
    <>
      <Modal
        width={chargerModelTemplateSelected ? 975 : 520}
        onOk={onOk}
        onCancel={onCancel}
        style={{
          top: 10,
          transition: chargerModelTemplateSelected ? 'all 0.2s ease-in-out' : 'width 0.2s ease-in-out',
        }}
        visible={isModalOpen}
        title={handlePluralTitle(chargers)}
        footer={chargerModelTemplateSelected ? footer : null}
      >
        <AlertError error={error} />
        <CommissionNewListingsForm
          form={form}
          chargers={chargers}
          environments={environments}
          chargerModelTemplateSelected={chargerModelTemplateSelected}
          onCommissionFields={handleCommissioningFields}
        />
      </Modal>
      <CommissionError
        isCommissionErrorModalVisible={isModalVisible}
        onOk={handleCommissionModalOk}
        onCancel={handleCommissionModalCancel}
        response={commissionNewListingError}
      />
      <CommissionLoading isModalVisible={commissionLoading} onOk={handleCommissionLoadingModalOk} />
    </>
  )
}

export default CommissionNewListingsModal
