import { useState } from 'react'
import { Checkbox, FormInstance } from 'antd'

import { ApiError } from '../models/error'
import { useNotifications } from './useNotifications'
import { CreateNewListingsViaCommissioning } from '../services/data-provider/commission'
import { CommissionNewListingResponse } from '../models/http'
import pluralize from 'pluralize'

interface CreateChargerModelProps {
  form: FormInstance<any>
  onOk: () => void
}

const initialState = {
  status: 'success',
  message: 'charger commisioning started',
  data: {
    total_request_chargers: 0,
    total_processing_chargers: 0,
    total_error_chargers: 0,
    error_chargers: {},
  },
}

export const useCreateCommissionNewListings = ({ form, onOk }: CreateChargerModelProps) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ApiError>()
  const [commissionLoading, setCommissionLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [commissionNewListingError, setCommissionNewListingError] = useState<CommissionNewListingResponse>(initialState)

  const { openSuccessNotification, openErrorNotification, openWarningNotification } = useNotifications()

  const notifyUser = (response: CommissionNewListingResponse, environment: string) => {
    const { status, message, data } = response

    if (response.status === 'success' && response?.data?.total_error_chargers === 0) {
      openSuccessNotification(
        <>
          <Checkbox checked={true} style={{ marginRight: '10px' }} />
          <b>The system will now:</b>
          <ul>
            <li>Update and verify the config template</li>
            <li>{`Update ${response.data.total_request_chargers} ${pluralize(
              'charger',
              response.data.total_request_chargers,
            )} to the ${environment} OCPP server`}</li>
            <li>Create the listing(s) and assign the charger(s) to the listing(s)</li>
            <li>Set the status as "Commissioned"</li>
          </ul>
        </>,
        { width: '50em' },
        'top',
      )
    } else if (status === 'fail' && data.total_error_chargers > 0) {
      setCommissionNewListingError(response)
      setIsModalVisible(true)
      openErrorNotification(
        <>
          Failed! <b>{message}</b>
        </>,
      )
    } else if (status === 'partial' && data.total_error_chargers > 0 && data.total_processing_chargers > 0) {
      setCommissionNewListingError(response)
      setIsModalVisible(true)
    } else {
      openWarningNotification(
        <>
          <Checkbox checked={true} style={{ marginRight: '10px' }} />
          <b>{message}</b>
        </>,
      )
    }
  }

  const handleCommissionLoadingModalOk = () => {
    setIsModalVisible(false)
  }

  const handleCommissionModalOk = () => {
    setIsModalVisible(false)
  }

  const handleCommissionModalCancel = () => {
    setIsModalVisible(false)
  }

  const onFinish = () => {
    form.validateFields().then((values) => createCommissionNewListings(values))
  }

  const createCommissionNewListings = async (values: any) => {
    setLoading(true)
    setCommissionLoading(true)
    setError(undefined)
    setCommissionNewListingError(initialState)
    // do not pass the ocppEnvironment to the API
    const modifiedData = { ...values }
    const environment = modifiedData.ocppEnvironment
    delete values.ocppEnvironment

    setTimeout(() => {
      CreateNewListingsViaCommissioning(values)
        .then((response) => {
          notifyUser(response, environment)
          if (response.status === 'success') {
            onOk()
          }
        })
        .catch((err) => setError(err))
        .finally(() => {
          setLoading(false)
          setCommissionLoading(false)
        })
    }, 2000)
  }
  return {
    loading,
    error,
    commissionNewListingError,
    commissionLoading,
    isModalVisible,
    onFinish,
    handleCommissionModalOk,
    handleCommissionModalCancel,
    handleCommissionLoadingModalOk,
  }
}
