import { Button, Card, Col, Modal, Row, Statistic, Table } from 'antd'
import { CommissionNewListingResponse } from '../../models/http'

interface props {
  isCommissionErrorModalVisible: boolean
  onOk: () => void
  onCancel: () => void
  response: CommissionNewListingResponse
}

interface ErrorCharger {
  key: string
  chargerId: string
  errorMsg: string
}

const columns = [
  {
    title: 'Charger ID',
    dataIndex: 'chargerId',
    width: '20%',
  },
  {
    title: 'Error Message',
    dataIndex: 'errorMsg',
  },
]

const CommissionError: React.FC<props> = ({ isCommissionErrorModalVisible, onOk, onCancel, response }) => {
  const transformErrorChargers = (errorChargers: { [key: string]: { error_msg: string } }): ErrorCharger[] => {
    return Object.keys(errorChargers).map((chargerId) => ({
      key: chargerId, // The key is used by Ant Design for unique identification
      chargerId, // This will be displayed in the 'Charger ID' column
      errorMsg: errorChargers[chargerId].error_msg, // This will be displayed in the 'Error Message' column
    }))
  }

  const dataSource = transformErrorChargers(response.data.error_chargers)

  return (
    <Modal
      width={900}
      title={<h3>{response.message}</h3>}
      visible={isCommissionErrorModalVisible}
      onOk={onOk}
      onCancel={onCancel}
      footer={[
        <Button type="primary" onClick={onOk}>
          Go Back to Commissioning
        </Button>,
      ]}
    >
      <h3>Overview</h3>
      <Row gutter={16}>
        <Col span={8}>
          <Card>
            <Statistic title="Total Requested" value={response.data.total_request_chargers} />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic title="Total Error" value={response.data.total_error_chargers} />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic title="Total Processing " value={response.data.total_processing_chargers} />
          </Card>
        </Col>
      </Row>
      <br />
      <h3>Error Descriptions</h3>
      <Table bordered columns={columns} dataSource={dataSource} rowKey="id" pagination={false} />
      <br />
      <h4>Please go back and remove the listings above from the charger and/or site and try to commission again!</h4>
    </Modal>
  )
}

export default CommissionError
