import { useEffect, useState } from 'react'
import { Breadcrumb, Button, Checkbox, PageHeader, Spin } from 'antd'
import { styled } from '../../../theme'
import { PanelView } from './panel/panel-view'
import { AddPanelModal } from './modal/add-panel'
import { useCircuits } from '../../../hooks/useCircuits'
import {
  CreateNewTenant,
  GetCircuits,
  GetEndPoints,
  GetLoadManagementChargers,
  UpdateCircuits,
} from '../../../services/data-provider/circuit'
import { ApiError } from '../../../models/error'
import { ColumnWrapper, Padding15Wrapper } from '../../../atom/load-management/load-management'
import { Branch, Circuit, EndPointData } from '../../../models/circuit'
import { ChargerDetails, LoadManagementCharger } from '../../../models/charger'
import { useNotifications } from '../../../hooks/useNotifications'
import { AlertError } from '../../../components/error'
import { Prompt, useLocation, useParams } from 'react-router-dom'
import moment from 'moment'
import { useAppState } from '../../../state'
import { formatDate } from '../../../helpers/charger'
import { LoadManagementEndPointResponse } from '../../../models/http'
import { plusSVG } from '../../../assets/svg/plus'
import { breadcrumbRightArrowSVG } from '../../../assets/svg/breadcrumbRightArrow'
import { AddNewPanelModal } from './modal/add-new-panel'
import { useSwtchControlPermissions } from '../../panel-overview/useSwtchControlPermissions'
import UnauthorizedResult from '../../../components/result/UnauthorizedResult'

const SecondaryButtonWrapper = styled.div`
  button {
    border-radius: 20px;
    border: 1px solid #dedede;
    background: #ffffff;
    box-shadow: none;
  }
  button:hover {
    border: 1px solid #c1c3d0;
  }
  button:focus {
    border-color: #dedede;
  }
  button > span {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #000000;
    font-family: ${(props) => props.theme.fontFamily.regular};
  }
`
const CircleButtonWrapper = styled.div`
  .ant-btn-group > .ant-btn:first-child:not(:last-child),
  .ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
    display: none;
  }
  .ant-btn-group > .ant-btn:last-child:not(:first-child),
  .ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
    border-radius: 38px;
  }
  button {
    background: #30d7ba;
    box-shadow: none;
    border: none;
  }
  button:hover {
    background: #00b799;
  }
  button:focus {
    background: #30d7ba;
  }
`
const BreadcrumbWrapper = styled.div`
  .bolded {
    color: #000000;
  }

  .grey {
    color: #7d838a;
  }

  .ant-breadcrumb {
    display: flex;
  }
  .ant-breadcrumb > span {
    display: flex;
    align-items: flex-start;
  }
`
const TenantHeaderWrapper = styled.span`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  font-family: ${(props) => props.theme.fontFamily.regular};
  div {
    margin-bottom: 8px;
  }

  .title {
    color: #7d838a;
    font-family: ${(props) => props.theme.fontFamily.regular};
  }
`
interface locationStateProps {
  circuits: Circuit[]
  circuitsFromUi: Circuit[]
}
export const SwtchControl: React.FC = () => {
  const [tenantId, setTenantId] = useState('')
  const [tenantName, setTenantName] = useState('')
  const [circuits, setCircuits] = useState<Circuit[]>([])
  const [verifiedChargers, setVerifiedChargers] = useState<LoadManagementCharger[]>([])
  const [allVerifiedChargers, setAllVerifiedChargers] = useState<LoadManagementCharger[]>([])
  const [addPanelModalVisible, setAddPanelModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ApiError>()
  const [index, setIndex] = useState<number>(0)
  const [disableSaveData, setDisableSaveData] = useState(false)
  const [circuitsFromUi, setCircuitsFromUi] = useState<Circuit[]>([])
  const [endPoints, setEndPoints] = useState<EndPointData[]>([])
  const [breakerLimitWarning, setBreakerLimitWarning] = useState(false)
  const [createNewTenant, setCreateNewTenant] = useState(false)
  const { currentUser } = useAppState()
  const currentDate = formatDate(moment().format('LL'))
  const [updatedBy, setUpdatedBy] = useState<{}>({ updated_by: '', updated_at: '' })
  const [addNewPanelModal, setAddNewPanelModal] = useState(false)
  const [unsavedData, setUnsavedData] = useState(false)
  const [saveClicked, setSaveClicked] = useState(false)
  const {
    panelTypeModal,
    circuitInitialValues,
    setChargerId,
    setCtClampId,
    setPanelTypeModal,
    findMissingIndexes,
  } = useCircuits()
  const { openWarningNotification, openErrorNotification, openSuccessNotification } = useNotifications()
  const { selectedTenantId }: any = useParams()
  const location = useLocation()
  const { canUpdateSwtchControl, canCreateSwtchControl, canReadSwtchControl } = useSwtchControlPermissions()

  useEffect(() => {
    setUpdatedBy({ updated_by: currentUser?.name, updated_at: currentDate })
    setLoading(true)
    Promise.all([
      GetLoadManagementChargers(selectedTenantId).then((resp) => {
        let chargersArr: any
        chargersArr = Object.values(resp[tenantId as keyof typeof resp].chargers)
        setVerifiedChargers(chargersArr)
        setAllVerifiedChargers(chargersArr)
      }),
    ])
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
    getCircuitData(selectedTenantId, false)
    setUnsavedData(false)
  }, [])

  useEffect(() => {
    setUnsavedData(true)
    setSaveClicked(false)
    setChargerId(Math.floor(Math.random() * 100).toString())
    setCtClampId(Math.floor(Math.random() * 100).toString())
  }, [circuits])

  const getCircuitData = (tenantId: string, updatedPanel: boolean) => {
    setLoading(true)
    let tempCircuits: Circuit[] = []

    Promise.all([
      // getting panel data
      GetCircuits(tenantId)
        .then((resp) => {
          if (resp.message === 'Panel data retrieved successfully') {
            if (location.state && !updatedPanel) {
              const data: locationStateProps = location.state as locationStateProps
              tempCircuits = [...resp.data[0]?.circuits, ...data.circuits]
              setCircuitsFromUi(data.circuits)
            } else {
              tempCircuits = [...resp.data[0]?.circuits]
            }
            setCircuits(tempCircuits)
            setTenantId(resp.data[0]?.tenant_id)
            setTenantName(resp.data[0]?.tenant_name)
            setCreateNewTenant(false)
          } else if (resp.message === 'Empty list returned') {
            if (location.state) {
              const data: locationStateProps = location.state as locationStateProps
              tempCircuits = [...data.circuits]
              setCircuitsFromUi(data.circuits)
            }
            setCircuits(tempCircuits)
            setTenantId(tenantId)
            setCreateNewTenant(true)
          }
          setUnsavedData(false)
        })
        .catch((error) => setError(error)),
      // getting charger data
      GetLoadManagementChargers(tenantId).then((resp) => {
        let chargersArr: any[]
        chargersArr = Object.values(resp[tenantId as keyof typeof resp].chargers)
        setVerifiedChargers(chargersArr)
        setAllVerifiedChargers(chargersArr)
        setTenantName(resp[tenantId as keyof typeof resp].tenant_name)
      }),
      // getting end point data
      GetEndPoints(tenantId).then((resp: LoadManagementEndPointResponse) => {
        let endPointsArr: any
        endPointsArr = Object.values(resp.data[0])
        setEndPoints(endPointsArr)
      }),
    ])
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }

  const checkBranchLimit = (breakerSizeLimit: number, initialBreakerSizeLimit: boolean) => {
    if (initialBreakerSizeLimit) {
      if (breakerSizeLimit <= 0) {
        setBreakerLimitWarning(true)
      } else {
        setBreakerLimitWarning(false)
      }
    } else {
      setBreakerLimitWarning(false)
    }
  }

  const addBranch = (circuit: Circuit, type: string, breakerLimitWarning: boolean) => {
    if (breakerLimitWarning) {
      const message = (
        <>
          <Checkbox checked={true} style={{ marginRight: '10px' }} />
          Warning: Limit of circuit size reached.
        </>
      )
      openWarningNotification(message)
    }
    let tempBranchId = 0
    let ids = circuit.branches.map((branch) => +branch.id)
    let maximum = 0
    if (ids.length > 0) {
      maximum = Math.max(...ids)
    }
    const missingIndexArr = findMissingIndexes(ids)

    if (missingIndexArr.length === 0) {
      tempBranchId = maximum + 1
    } else {
      tempBranchId = missingIndexArr[0]
    }

    const circuitsArr = [...circuits]
    const singleCircuit = { ...circuit }
    singleCircuit.branches.push({ ...circuitInitialValues, id: tempBranchId.toString() })
    setIndex(index + 1)
    const tempCircuitsArr = circuitsArr.map((panel) => {
      if (panel.id === singleCircuit.id) return singleCircuit
      return panel
    })
    setCircuits(tempCircuitsArr)
    setBreakerLimitWarning(false)
  }

  const deleteBranch = async (circuit: Circuit, branch: Branch, type: string) => {
    const circuitsArr = [...circuits]
    const singleCircuit = { ...circuit }
    const singleBranch = { ...branch }
    const tempBranches = singleCircuit.branches.filter((branch) => branch.id !== singleBranch.id)
    singleCircuit.branches = tempBranches

    const tempCircuits = circuitsArr.map((panel) => {
      if (panel.id === singleCircuit.id) return singleCircuit
      return panel
    })
    setCircuits(tempCircuits)
  }

  const deleteCharger = (circuit: Circuit, branch: Branch, charger: ChargerDetails, type: string) => {
    const singleCircuit = { ...circuit }
    const singleBranch = { ...branch }
    const singleCharger = { ...charger }

    const tempChargers = branch.chargers.filter(
      (selectedCharger) => selectedCharger.cp_serial_number !== singleCharger.cp_serial_number,
    )
    singleBranch.chargers = tempChargers
    const tempFilteredChargers = tempChargers.filter((charger) => charger.cp_serial_number)
    const tempBranch = { ...singleBranch, chargers: tempFilteredChargers }
    const tempBranches = singleCircuit.branches.map((branch) => {
      if (branch.id === tempBranch.id) return tempBranch
      return branch
    })
    const tempCircuit = { ...singleCircuit, branches: tempBranches }
    const tempCircuits = circuits.map((circuit) => {
      if (circuit.id === tempCircuit.id) return tempCircuit
      return circuit
    })
    setCircuits(tempCircuits)

    const tempVerifiedChargersArr = verifiedChargers
    allVerifiedChargers.filter((singleCharger) => {
      if (charger.cp_serial_number === singleCharger.chargepoint_serial_number) {
        tempVerifiedChargersArr.push(singleCharger)
      }
      return singleCharger
    })
    setVerifiedChargers(tempVerifiedChargersArr)
  }

  const saveCircuits = () => {
    setError(undefined)
    setLoading(true)
    setSaveClicked(true)

    const tenantRef = {
      id: tenantId,
      name: tenantName,
    }
    const circuitData = [...circuits]

    const missingField = circuits.some((circuit) =>
      circuit.branches.some((branch) => branch.chargers.some((charger) => charger.phases === '')),
    )

    if (disableSaveData || missingField) {
      setLoading(false)
      const message = (
        <>
          <Checkbox checked={true} style={{ marginRight: '10px' }} />
          Error: Unable to save data
        </>
      )
      openErrorNotification(message)
    } else {
      if (breakerLimitWarning) {
        const message = (
          <>
            <Checkbox checked={true} style={{ marginRight: '10px' }} />
            Warning: Limit of circuit size reached.
          </>
        )
        openWarningNotification(message)
      }
      if (createNewTenant) {
        // api call to create new site and then settimeout to update circuit
        const newTenant = {
          id: tenantId,
          name: tenantName,
        }

        Promise.all([
          CreateNewTenant(newTenant)
            .then((resp) => {
              setTimeout(() => {
                Promise.all([
                  UpdateCircuits(tenantRef, circuitData, updatedBy)
                    .then((resp) => {
                      if (resp.code === 404) {
                        openErrorNotification(
                          <>
                            <Checkbox checked={true} style={{ marginRight: '10px' }} />
                            {resp.message}
                          </>,
                        )
                      } else {
                        openSuccessNotification(
                          <>
                            <Checkbox checked={true} style={{ marginRight: '10px' }} />
                            {resp.message}
                          </>,
                        )
                        setTimeout(() => {
                          getCircuitData(tenantId, true)
                          GetEndPoints(tenantId)
                            .then((resp: LoadManagementEndPointResponse) => {
                              let endPointsArr: any
                              endPointsArr = Object.values(resp.data[0])
                              setEndPoints(endPointsArr)
                            })
                            .catch((err) => setError(err))
                            .finally(() => setLoading(false))
                        }, 2000)
                      }
                    })
                    .catch((error) => {
                      console.log('UpdateCircuit:', error)
                      setError(error)
                      const message = (
                        <>
                          <Checkbox checked={true} style={{ marginRight: '10px' }} />
                          Error: Unable to save data
                        </>
                      )
                      openErrorNotification(message)
                    }),
                ]).finally(() => setLoading(false))
              }, 2000)
              setCreateNewTenant(false)
              // return openMessageSuccessNotification(<><Checkbox checked={true} style={{marginRight: '10px'}} />{resp.message}</>)
              openSuccessNotification(
                <>
                  <Checkbox checked={true} style={{ marginRight: '10px' }} />
                  {resp.message}
                </>,
              )
            })
            .catch((error) => {
              console.log('UpdateCircuit:', error)
              setError(error)
            })
            .finally(() => setLoading(false)),
        ])
      } else {
        // api save
        Promise.all([
          UpdateCircuits(tenantRef, circuitData, updatedBy)
            .then((resp) => {
              if (resp.code === 404) {
                openErrorNotification(
                  <>
                    <Checkbox checked={true} style={{ marginRight: '10px' }} />
                    {resp.message}
                  </>,
                )
              } else {
                openSuccessNotification(
                  <>
                    <Checkbox checked={true} style={{ marginRight: '10px' }} />
                    {resp.message}
                  </>,
                )

                setTimeout(() => {
                  getCircuitData(tenantId, true)
                  GetEndPoints(tenantId)
                    .then((resp: LoadManagementEndPointResponse) => {
                      let endPointsArr: any
                      endPointsArr = Object.values(resp.data[0])
                      setEndPoints(endPointsArr)
                    })
                    .catch((err) => setError(err))
                    .finally(() => setLoading(false))
                }, 2000)
              }
            })
            .catch((error) => {
              console.log('UpdateCircuit:', error)
              setError(error)
              const message = (
                <>
                  <Checkbox checked={true} style={{ marginRight: '10px' }} />
                  Error: Unable to save data
                </>
              )
              openErrorNotification(message)
            }),
        ]).finally(() => setLoading(false))
      }
      setUnsavedData(false)
    }
  }

  const handleAddPanelSubmit = () => {
    setAddPanelModalVisible(false)
    setPanelTypeModal('')
  }

  const cancelAdd = () => {
    setAddPanelModalVisible(false)
    setPanelTypeModal('')
  }

  const breadcrumb = (
    <BreadcrumbWrapper>
      <Breadcrumb separator={breadcrumbRightArrowSVG}>
        <Breadcrumb.Item className="bolded paragraph-04-regular">Panel Overview</Breadcrumb.Item>
        <Breadcrumb.Item className="grey paragraph-04-regular">#{tenantId}</Breadcrumb.Item>
      </Breadcrumb>
    </BreadcrumbWrapper>
  )

  const headingButtons = (
    <>
      <SecondaryButtonWrapper>
        <Button size="large" key="invite-btn" type="default" loading={loading} onClick={saveCircuits}>
          SAVE CONFIGURATION
        </Button>
      </SecondaryButtonWrapper>
      <CircleButtonWrapper>
        <Button
          size="large"
          key="invite-btn"
          type="primary"
          loading={loading}
          icon={plusSVG}
          shape="circle"
          onClick={() => setAddNewPanelModal(true)}
        ></Button>
      </CircleButtonWrapper>
    </>
  )

  return (
    <>
      {canReadSwtchControl ? (
        <>
          <AlertError error={error} />
          <Spin spinning={loading}>
            <Padding15Wrapper>
              <PageHeader
                title={breadcrumb}
                extra={canCreateSwtchControl && canUpdateSwtchControl ? headingButtons : null}
              />
              <TenantHeaderWrapper>
                <div>
                  <span className="title">Tenant Id:</span> {tenantId} <span className="title">| OCPP Name:</span>{' '}
                  {tenantName}
                </div>
              </TenantHeaderWrapper>
              <ColumnWrapper>
                <div>
                  <PanelView
                    circuits={circuits}
                    verifiedChargers={verifiedChargers}
                    circuitsFromUi={circuitsFromUi}
                    endPoints={endPoints}
                    saveClicked={saveClicked}
                    setCircuits={setCircuits}
                    addBranch={addBranch}
                    deleteBranch={deleteBranch}
                    checkBranchLimit={checkBranchLimit}
                    setDisableSaveData={setDisableSaveData}
                    setCircuitsFromUi={setCircuitsFromUi}
                    setBreakerLimitWarning={setBreakerLimitWarning}
                    setVerifiedChargers={setVerifiedChargers}
                    deleteCharger={deleteCharger}
                    setAddPanelModalVisible={setAddPanelModalVisible}
                  />
                </div>
              </ColumnWrapper>
              {panelTypeModal && (
                <AddPanelModal
                  visible={addPanelModalVisible}
                  onCancel={cancelAdd}
                  onSubmit={handleAddPanelSubmit}
                  panelTypeModal={panelTypeModal}
                  setPanelTypeModal={setPanelTypeModal}
                  circuitsFromUi={circuitsFromUi}
                  setCircuitsFromUi={setCircuitsFromUi}
                  circuits={circuits}
                  setCircuits={setCircuits}
                />
              )}
              {addNewPanelModal && (
                <AddNewPanelModal
                  setPanelTypeModal={setPanelTypeModal}
                  setAddNewPanelModal={setAddNewPanelModal}
                  setAddPanelModalVisible={setAddPanelModalVisible}
                />
              )}
            </Padding15Wrapper>
          </Spin>
          <Prompt when={unsavedData} message="You have unsaved data. Are you sure you want to leave?" />
        </>
      ) : (
        <UnauthorizedResult />
      )}
    </>
  )
}
