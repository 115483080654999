import { useEffect, useState } from 'react'

import { ApiError } from '../models/error'
import { Environment } from '../models/environment'

import { GetAllChargerEnvironments } from '../services/data-provider/environment'

export const useGetEnvironments = () => {
  const [environmentLoading, setEnvironmentLoading] = useState(false)
  const [environments, setEnvironments] = useState<Environment[]>([])
  const [environmentError, setEnvironmentError] = useState<ApiError>()

  useEffect(() => {
    setEnvironmentLoading(true)
    GetAllChargerEnvironments()
      .then((res) => setEnvironments(res))
      .catch((err) => setEnvironmentError(err))
      .finally(() => setEnvironmentLoading(false))
  }, [])

  return { environmentLoading, environments, environmentError }
}
