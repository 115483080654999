import { theme } from '../theme'
import { Charger } from '../models/charger'
import { HomeFilter } from '../models/filter'

export const renderStatusColor = (charger: Charger): string => {
  let color = ''
  switch (charger.connectionStatus.status) {
    case 'connected':
      color = theme.colors.green
      break
    case 'disconnected':
      color = theme.colors.red
      break
    default:
      color = theme.colors.gray
  }
  return color
}

export const isJson = (str: any) => {
  try {
    JSON.parse('{' + str + '}')
  } catch (e) {
    return false
  }
  return true
}

export const formatDate = (dateStr: string) => {
  let d = new Date(dateStr)
  let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
  let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d)
  let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
  let hh = d.getHours() < 10 ? `0${d.getHours()}` : d.getHours()
  let mm = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes()
  let ss = d.getSeconds() < 10 ? `0${d.getSeconds()}` : d.getSeconds()
  const formattedDate = `${ye}-${mo}-${da} - ${hh}:${mm}:${ss}`
  return formattedDate
}

export const chargerQuery = (f: HomeFilter): { [key: string]: string } => {
  let queryParams: { [key: string]: string } = {}

  if (f.charger_serial_numbers && f.charger_serial_numbers.length > 0) {
    queryParams['charger_serial_numbers'] = `${f.charger_serial_numbers}`
  }

  if (f.status) {
    queryParams['status'] = f.status
  }

  return queryParams
}
