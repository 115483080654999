// Import a helper function from a separate file
import { capitalizeFirstLetter } from '../../helpers/users'

// Returns a validation rule object that enforces the field to be required.
// The optional `message` parameter can be provided for a custom error message.
export const MustBeRequired = (message?: string) => {
  return { required: true, message }
}

// Returns a validation rule object that checks if the field value matches the pattern of an IP address.
// The optional `message` parameter can be provided for a custom error message.
export const MustBeIpAddress = (message?: string) => {
  return { pattern: new RegExp('^[0-9]+.[0-9]+.[0-9]+.[0-9]+.[0-9]?[0-9]$'), message }
}

// Returns a validation rule object that checks if the field value is a positive integer (including zero).
export const MustBePositiveIntegers = {
  min: 0,
  message: 'Please enter a positive number',
  pattern: new RegExp('^[0-9]*$'),
}

export const MustBeDecimalNumber = {
  pattern: new RegExp('^[0-9]+(.[0-9]+)?$'),
  message: 'Please enter a valid number',
}

// Returns a validation rule object that checks if the field value has a specified minimum number of digits.
// The `subject` parameter is used to construct a custom error message.
export const MustHaveNAmountOfNum = (subject: string, num: number) => {
  return {
    pattern: new RegExp('^[0-9]{4,}$'),
    message: `${capitalizeFirstLetter(subject)} must have ${num} or more numbers`,
  }
}

// Returns a validation rule object that checks if the field value is a valid integer (positive, negative, or zero).
export const MustBeNumerical = { pattern: new RegExp('^([-]?[1-9][0-9]*|0)$'), message: 'Must be a integer' }
