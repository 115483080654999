// Trash can icon
export const trashCanSVG = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 3.47059H15M6.25 11.7059V6.76471M9.75 11.7059V6.76471M11.5 15H4.5C3.5335 15 2.75 14.2626 2.75 13.3529V4.29412C2.75 3.83929 3.14175 3.47059 3.625 3.47059H12.375C12.8582 3.47059 13.25 3.83929 13.25 4.29412V13.3529C13.25 14.2626 12.4665 15 11.5 15ZM6.25 3.47059H9.75C10.2332 3.47059 10.625 3.10188 10.625 2.64706V1.82353C10.625 1.36871 10.2332 1 9.75 1H6.25C5.76675 1 5.375 1.36871 5.375 1.82353V2.64706C5.375 3.10188 5.76675 3.47059 6.25 3.47059Z"
      stroke="#7D838A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
