import { ChargerModelTemplate } from '../models/charger-model-template'
import { ChargerModelMetadataResponse, ChargerModelTemplateResponse } from '../models/http'
import { Template } from '../models/template'

export const mockAllChargersConfigTemplates: Template[] = [
  {
    vendor: null,
    model: null,
    name: 'LiteOn 80A WIFI Generic',
    template: {
      MeterValueSampleInterval: '300',
      MeterValuesAlignedData:
        'Current.Import,Current.Offered,Energy.Active.Import.Register,Energy.Active.Import.Interval,Power.Offered,Temperature,Voltage',
      MeterValuesAlignedDataMaxLength: '7',
      MeterValuesSampledData:
        'Current.Import,Current.Offered,Energy.Active.Import.Register,Power.Offered,Temperature,Voltage',
      MeterValuesSampledDataMaxLength: '6',
      BootNotificationInterval: '60',
      SSID: 'SWTCH',
      WiFiPassword: 'Swtch456!',
      AllowOfflineTxForUnknownId: 'false',
      OperationMode: 'Direct',
      ResetRetries: '-1',
      rfidAvailability: 'true',
      ConnectionTimeOut: '120',
      PlugAndChargeId: '',
      ReservationSupported: 'true',
    },
    updatedAt: '2023-07-05T16:58:36.498000',
    updatedBy: 'King',
    createdAt: '2023-06-13T05:19:14.774000',
    deletedAt: null,
    _id: '6487fc5261dfd24a73dc7137',
  },
  {
    vendor: 'LITEON',
    model: 'LITEON-32AMP',
    name: 'LITE-ON Plug and Play',
    template: {
      HeartbeatInterval: '10',
      MeterValueSampleInterval: '300',
      MeterValuesAlignedData:
        'Current.Import,Current.Offered,Energy.Active.Import.Register,Energy.Active.Import.Interval,Power.Offered,Temperature,Voltage',
      MeterValuesAlignedDataMaxLength: '7',
      MeterValuesSampledData:
        'Current.Import,Current.Offered,Energy.Active.Import.Register,Power.Offered,Temperature,Voltage',
      MeterValuesSampledDataMaxLength: '6',
      BootNotificationInterval: '60',
      SSID: 'SWTCH',
      WiFiPassword: 'Swtch456!',
      AllowOfflineTxForUnknownId: 'true',
      OperationMode: 'Direct',
      Connectivity: 'Wi-Fi',
      RestartTime: '1200',
      RestartCounter: '50',
      ResetRetries: '-1',
      rfidAvailability: 'true',
      ConnectionTimeOut: '120',
      MaxAmperageConnector: '32.0',
      MaxAmperageHWSetting: '32.0',
      MaxAmperageFWSetting: '32',
      PlugAndChargeId: '12345678',
      ReservationSupported: 'true',
      MaxEnergyOnInvalidId: '200000',
    },
    updatedAt: '2023-08-29T17:01:48.464000',
    updatedBy: 'Kush',
    createdAt: '2023-06-13T05:19:38.465000',
    deletedAt: null,
    _id: '6487fc6a61dfd24a73dc7139',
  },
  {
    vendor: null,
    model: null,
    name: 'LITE-ON 40A WiFi Generic',
    template: {
      HeartbeatInterval: '10',
      MeterValueSampleInterval: '300',
      MeterValuesAlignedData:
        'Current.Import,Current.Offered,Energy.Active.Import.Register,Energy.Active.Import.Interval,Power.Offered,Temperature,Voltage',
      MeterValuesAlignedDataMaxLength: '7',
      MeterValuesSampledData:
        'Current.Import,Current.Offered,Energy.Active.Import.Register,Power.Offered,Temperature,Voltage',
      MeterValuesSampledDataMaxLength: '6',
      BootNotificationInterval: '60',
      SSID: 'SWTCH',
      WiFiPassword: 'Swtch456!',
      AllowOfflineTxForUnknownId: 'false',
      OperationMode: 'Direct',
      Connectivity: 'Wi-Fi',
      RestartTime: '1200',
      RestartCounter: '50',
      ResetRetries: '-1',
      rfidAvailability: 'true',
      ConnectionTimeOut: '120',
      MaxAmperageConnector: '40.0',
      MaxAmperageHWSetting: '40.0',
      MaxAmperageFWSetting: '40',
      PlugAndChargeId: '',
      ReservationSupported: 'true',
    },
    updatedAt: '2023-07-26T19:16:59.735000',
    updatedBy: 'King',
    createdAt: '2023-07-26T19:16:59.735000',
    deletedAt: null,
    _id: '64c1712b61dfd24a73dc76f6',
  },
  {
    vendor: 'Lite-on',
    model: 'Liteon 32 Amp',
    name: 'Commissioning 32 amp Liteon Template',
    template: {
      MeterValueSampleInterval: '300',
      MeterValuesAlignedData:
        'Current.Import,Current.Offered,Energy.Active.Import.Register,Energy.Active.Import.Interval,Power.Offered,Temperature,Voltage',
      MeterValuesAlignedDataMaxLength: '7',
      MeterValuesSampledData:
        'Current.Import,Current.Offered,Energy.Active.Import.Register,Power.Offered,Temperature,Voltage',
      MeterValuesSampledDataMaxLength: '6',
      BootNotificationInterval: '60',
      AllowOfflineTxForUnknownId: 'false',
      OperationMode: 'Direct',
      ResetRetries: '-1',
      rfidAvailability: 'true',
      ConnectionTimeOut: '120',
      MaxAmperageConnector: '32.0',
      MaxAmperageHWSetting: '32.0',
      MaxAmperageFWSetting: '32',
      PlugAndChargeId: '',
      ReservationSupported: 'true',
    },
    updatedAt: '2024-05-22T14:12:31.030000',
    updatedBy: 'Dylan',
    createdAt: '2024-05-21T16:57:04.635000',
    deletedAt: null,
    _id: '664cd26082bee94cf5414084',
  },
]

export const mockChargerModelMetadata = (): ChargerModelMetadataResponse => {
  return {
    status: 'success',
    data: {
      make: [
        'ABB',
        'ATG',
        'Autel',
        'BTC',
        'CMI',
        'Current',
        'Delta Electronics',
        'Eaton',
        'Elmec',
        'EV-BOX',
        'Freewire',
        'Guanke',
        'IES',
        'Joint',
        'JuiceBar',
        'LGE',
        'LITE-ON',
        'Phihong',
        'Siemens',
        'telluspower',
        'The New Motion BV',
        'Topstar',
        'Tritium',
        'UnitedChargers',
        'WallBox',
        'XCHARGE',
        'Zerova',
      ],
      charging_type: ['Level 2', 'DC', 'L2&DCFC', 'Level 1'],
      CPO: ['SWTCH', 'ATG', 'Itselectric', 'Lakeland', 'PowerCharge'],
      display_size: ['', 'LED Screen', 'LED Screen < 55in', 'LED Screen > 55in'],
      support_pos: [
        { id: 1, name: '' },
        { id: 2, name: 'Nayax' },
        { id: 3, name: 'Payter' },
        { id: 4, name: 'Nayax & Payter' },
      ],
      connector_type: [
        { id: 1, name: 'J1772' },
        { id: 2, name: 'J3400' },
        { id: 3, name: 'CHADeMO' },
        { id: 4, name: 'Mix of J1772 & J3400' },
        { id: 5, name: 'Mix of J1772 & CHADeMO' },
        { id: 6, name: 'Mix of J3400 & CHADeMO' },
      ],
    },
  }
}

export const mockChargerModelTemplates: ChargerModelTemplate[] = [
  {
    name: 'LITE-ON_32A_EX-1762-5SW1_SWTCH',
    make: 'LITE-ON',
    model: 'EX-1762-5SW1',
    cpo: 'SWTCH',
    rated_power: 100.0,
    rated_power_unit: 'kW',
    rated_current: 32,
    rated_current_unit: 'A',
    charging_type: 'Level 2',
    min_input_voltage: 20.0,
    max_input_voltage: 110.0,
    voltage_unit: 'V',
    min_operating_temperature: 40.0,
    max_operating_temperature: 100.0,
    temperature_unit: 'C',
    connector_type_id: 4,
    total_connectors: 1,
    max_connector_plug_cycles: 10000,
    cable_length: 5.5,
    cable_length_unit: 'FT',
    display_size: 'LED Screen',
    has_built_in_holster: true,
    support_RFID: true,
    support_pos: 'Nayax & Payter',
    support_ISO15118_2: true,
    support_ISO15118_20: false,
    support_wifi: true,
    support_4G: true,
    support_WiSun: false,
    imagePath: 'https://swtch-garden-bucket-dev.s3.amazonaws.com/test/1715532892057',
    imageFileName: 'ic3_80ab05_426057.png',
    creator: 'mike',
    created_at: '2024-05-12T17:33:33Z',
    updated_at: '2024-05-12T17:33:33Z',
    deleted_at: null,
    imageUrl:
      'https://swtch-garden-bucket-dev.s3.amazonaws.com/test/1719517344569/original/LiteonSWTCHwhite660x440px.jpg',
    thumbUrl: 'https://swtch-garden-bucket-dev.s3.amazonaws.com/test/1719517344569/thumb/LiteonSWTCHwhite660x440px.jpg',
    id: '6655d7f6a16f325f744bfe12',
    creatorEmail: 'lan2@swtchenergy.com',
  },
  {
    name: 'LGE_32A_EVW011SK-SN_SWTCH',
    make: 'LGE',
    model: 'EVW011SK-SN',
    cpo: 'ATG',
    rated_power: 78.0,
    rated_power_unit: 'kW',
    rated_current: 32,
    rated_current_unit: 'A',
    charging_type: 'DC',
    min_input_voltage: 20.0,
    max_input_voltage: 110.0,
    voltage_unit: 'V',
    min_operating_temperature: 40.0,
    max_operating_temperature: 100.0,
    temperature_unit: 'C',
    connector_type_id: 2,
    total_connectors: 2,
    max_connector_plug_cycles: 10000,
    cable_length: 1.0,
    cable_length_unit: 'FT',
    display_size: 'LED Screen > 55in',
    has_built_in_holster: true,
    support_RFID: true,
    support_pos: 'Payter',
    support_ISO15118_2: true,
    support_ISO15118_20: true,
    support_wifi: true,
    support_4G: true,
    support_WiSun: true,
    imagePath: 'https://swtch-garden-bucket-dev.s3.amazonaws.com/test/1715532892057',
    imageFileName: 'ic3_80ab05_426056.png',
    creator: 'mike',
    created_at: '2024-05-12T17:33:33Z',
    updated_at: '2024-05-12T17:33:33Z',
    deleted_at: null,
    imageUrl:
      'https://swtch-garden-bucket-dev.s3.amazonaws.com/test/1719517344569/original/LiteonSWTCHwhite660x440px.jpg',
    thumbUrl: 'https://swtch-garden-bucket-dev.s3.amazonaws.com/test/1719517344569/thumb/LiteonSWTCHwhite660x440px.jpg',
    id: '6655d7f6a16f325f744bfe1a',
    creatorEmail: 'lan@swtchenergy.com',
  },
  {
    name: 'LGE_48A_WAF0SK_ATG',
    make: 'LGE',
    model: 'WAF0SK',
    cpo: 'ATG',
    rated_power: 100.0,
    rated_power_unit: 'kW',
    rated_current: 32,
    rated_current_unit: 'A',
    charging_type: 'DC',
    min_input_voltage: 20.0,
    max_input_voltage: 110.0,
    voltage_unit: 'V',
    min_operating_temperature: 40.0,
    max_operating_temperature: 100.0,
    temperature_unit: 'C',
    connector_type_id: 3,
    total_connectors: 2,
    max_connector_plug_cycles: 10000,
    cable_length: 2.8,
    cable_length_unit: 'FT',
    display_size: 'LED Screen > 55in',
    has_built_in_holster: true,
    support_RFID: false,
    support_pos: '-',
    support_ISO15118_2: true,
    support_ISO15118_20: true,
    support_wifi: true,
    support_4G: true,
    support_WiSun: true,
    imagePath: 'https://swtch-garden-bucket-dev.s3.amazonaws.com/test/1715532892057',
    imageFileName: 'ic3_80ab05_426056.png',
    creator: 'mike',
    created_at: '2024-05-12T17:33:33Z',
    updated_at: '2024-05-12T17:33:33Z',
    deleted_at: null,
    imageUrl:
      'https://swtch-garden-bucket-dev.s3.amazonaws.com/test/1719517344569/original/LiteonSWTCHwhite660x440px.jpg',
    thumbUrl: 'https://swtch-garden-bucket-dev.s3.amazonaws.com/test/1719517344569/thumb/LiteonSWTCHwhite660x440px.jpg',
    id: '6655d7f6a16f325f744bfe1a',
    creatorEmail: 'lan@swtchenergy.com',
  },
  {
    name: 'BTC_78A_LPT011SK-SN_Power_charge',
    make: 'BTC',
    model: 'EVW011SK-SN',
    cpo: 'Power Charge',
    rated_power: 100.0,
    rated_power_unit: 'kW',
    rated_current: 32,
    rated_current_unit: 'A',
    charging_type: 'Level 1',
    min_input_voltage: 20.0,
    max_input_voltage: 110.0,
    voltage_unit: 'V',
    min_operating_temperature: 40.0,
    max_operating_temperature: 100.0,
    temperature_unit: 'C',
    connector_type_id: 4,
    total_connectors: 2,
    max_connector_plug_cycles: 10000,
    cable_length: 4.5,
    cable_length_unit: 'FT',
    display_size: 'LED Screen < 55in',
    has_built_in_holster: false,
    support_RFID: false,
    support_pos: 'Nayax',
    support_ISO15118_2: true,
    support_ISO15118_20: false,
    support_wifi: false,
    support_4G: false,
    support_WiSun: false,
    imagePath: 'https://swtch-garden-bucket-dev.s3.amazonaws.com/test/1715532892057',
    imageFileName: 'ic3_80ab05_426056.png',
    creator: 'mike',
    created_at: '2024-05-12T17:33:33Z',
    updated_at: '2024-05-12T17:33:33Z',
    deleted_at: null,
    imageUrl:
      'https://swtch-garden-bucket-dev.s3.amazonaws.com/test/1719517344569/original/LiteonSWTCHwhite660x440px.jpg',
    thumbUrl: 'https://swtch-garden-bucket-dev.s3.amazonaws.com/test/1719517344569/thumb/LiteonSWTCHwhite660x440px.jpg',
    id: '6655d7f6a16f325f744bfe1a',
    creatorEmail: 'lan@swtchenergy.com',
  },
  {
    name: 'LGE_32A_EVW011SK-SN_lakeland',
    make: 'LGE',
    model: 'EVW011SK-SN',
    cpo: 'lakeland',
    rated_power: 100.0,
    rated_power_unit: 'kW',
    rated_current: 32,
    rated_current_unit: 'A',
    charging_type: 'Level 2',
    min_input_voltage: 20.0,
    max_input_voltage: 110.0,
    voltage_unit: 'V',
    min_operating_temperature: 40.0,
    max_operating_temperature: 100.0,
    temperature_unit: 'C',
    connector_type_id: 1,
    total_connectors: 2,
    max_connector_plug_cycles: 10000,
    cable_length: 4.5,
    cable_length_unit: 'FT',
    display_size: 'LED Screen',
    has_built_in_holster: true,
    support_RFID: false,
    support_pos: 'Nayax',
    support_ISO15118_2: false,
    support_ISO15118_20: false,
    support_wifi: true,
    support_4G: true,
    support_WiSun: true,
    imagePath: 'https://swtch-garden-bucket-dev.s3.amazonaws.com/test/1715532892057',
    imageFileName: 'ic3_80ab05_426056.png',
    creator: 'mike',
    created_at: '2024-05-12T17:33:33Z',
    updated_at: '2024-05-12T17:33:33Z',
    deleted_at: null,
    imageUrl:
      'https://swtch-garden-bucket-dev.s3.amazonaws.com/test/1719517344569/original/LiteonSWTCHwhite660x440px.jpg',
    thumbUrl: 'https://swtch-garden-bucket-dev.s3.amazonaws.com/test/1719517344569/thumb/LiteonSWTCHwhite660x440px.jpg',
    id: '6655d7f6a16f325f744bfe1a',
    creatorEmail: 'lan@swtchenergy.com',
  },
  {
    name: 'LGE_32A_EVW011SK-SN_itElectric',
    make: 'LGE',
    model: 'EVW011SK-SN',
    cpo: 'itsElectric',
    rated_power: 100.0,
    rated_power_unit: 'kW',
    rated_current: 32,
    rated_current_unit: 'A',
    charging_type: 'L2&DCFC',
    min_input_voltage: 20.0,
    max_input_voltage: 110.0,
    voltage_unit: 'V',
    min_operating_temperature: 40.0,
    max_operating_temperature: 100.0,
    temperature_unit: 'C',
    connector_type_id: 3,
    total_connectors: 2,
    max_connector_plug_cycles: 10000,
    cable_length: 4.5,
    cable_length_unit: 'FT',
    display_size: '-',
    has_built_in_holster: true,
    support_RFID: false,
    support_pos: 'Nayax',
    support_ISO15118_2: false,
    support_ISO15118_20: true,
    support_wifi: true,
    support_4G: false,
    support_WiSun: true,
    imagePath: 'https://swtch-garden-bucket-dev.s3.amazonaws.com/test/1715532892057',
    imageFileName: 'ic3_80ab05_426056.png',
    creator: 'mike',
    created_at: '2024-05-12T17:33:33Z',
    updated_at: '2024-05-12T17:33:33Z',
    deleted_at: null,
    imageUrl:
      'https://swtch-garden-bucket-dev.s3.amazonaws.com/test/1719517344569/original/LiteonSWTCHwhite660x440px.jpg',
    thumbUrl: 'https://swtch-garden-bucket-dev.s3.amazonaws.com/test/1719517344569/thumb/LiteonSWTCHwhite660x440px.jpg',
    id: '6655d7f6a16f325f744bfe1a',
    creatorEmail: 'lan@swtchenergy.com',
  },
]

export const mockChargerModelTemplate = (page?: number): ChargerModelTemplateResponse => {
  return {
    pagination: {
      current_page: page || 1,
      per_page: 3,
      total_entries: mockChargerModelTemplates.length,
    },
    status: 'success',
    data: mockChargerModelTemplates,
  }
}
