import React, { useContext } from 'react'
import swtchBanner from '../assets/images/swtch-banner.png'
import swtchIcon from '../assets/images/swtch-icon-80x80.png'
import { Network } from '../models/network'

// Define the type for the NetworkContext
interface NetworkContextType {
  network: Network
}

// Create a NetworkContext using React's createContext
const NetworkContext = React.createContext<NetworkContextType>(null!)

// Styling for the logo image
const logoStyle = {
  margin: '2px 13px',
  filter: 'invert(1)',
  width: '75%',
}

// Styling for the icon image
const iconStyle = {
  margin: '5px 20px',
  width: '53%',
  filter: 'invert(1)',
}

// NetworkProvider component to provide network-related context
export function NetworkProvider(props: React.PropsWithChildren<{}>) {
  let logo = <img src={swtchBanner} alt="Swtch Energy Logo" style={logoStyle} />
  let icon = <img src={swtchIcon} alt="Swtch Energy Icon" style={iconStyle} />
  let name = 'swtch'
  let title = 'Swtch'

  // Provide the network context value using NetworkContext.Provider
  return (
    <NetworkContext.Provider
      value={{
        network: { logo, name, title, icon },
      }}
    >
      {props.children}
    </NetworkContext.Provider>
  )
}

// Custom hook to consume the network context using useContext
export const useNetwork = (): Network => {
  const context = useContext(NetworkContext)
  if (!context) {
    throw new Error('useNetwork must be used within the <NetworkProvider>..</NetworkProvider>')
  }
  return context.network
}
