import { Alert } from 'antd'
import React from 'react'
import { AlertBoxprops, AlertMessageProps } from '../models/error'

// This component displays an error message using the Ant Design Alert component.
// It shows a list of error messages if available.
export const AlertError: React.FC<AlertBoxprops> = ({ error, closable }) => {
  if (!error) {
    return <></>
  }

  // Generate a list of error messages as JSX elements
  const description = () => {
    return (
      <ul>
        {error.errors.map((err, index) => {
          return <li key={index}>{err}</li>
        })}
      </ul>
    )
  }

  // Render the Ant Design Alert component if there are errors to display
  return (
    <>
      {error && error.errors && error.errors.length > 0 && (
        <Alert closable={closable || false} showIcon message={error.message} description={description()} type="error" />
      )}
    </>
  )
}

export const AlertMessage: React.FC<AlertMessageProps> = ({ error, closable }) => {
  if (!error) {
    return <></>
  }

  return <Alert closable={closable || false} showIcon message={error.message} type="error" />
}
