import { useEffect, useState } from 'react'
import { Button, Form, Input, Modal } from 'antd'
import { Circuit, PanelType } from '../../../../models/circuit'
import { MustBeRequired, MustBeIpAddress, MustBeNumerical } from '../../../../components/rules/rules'
import {
  AddPanelButtonWrapper,
  HeadingWrapper,
  LastRowInputsWrapper,
  RowInputsWrapper,
  TitleWrapper,
} from '../../../../atom/load-management/modals'
import { useCircuits } from '../../../../hooks/useCircuits'
import { dedicatedPanelSVG } from '../../../../assets/svg/dedicatedPanel'
import { mixedPanelSVG } from '../../../../assets/svg/mixedPanel'
import { CustomInputNumber } from '../../../../components/input-number'

interface props {
  visible: boolean
  panelTypeModal: string
  circuitData: Circuit
  circuits: Circuit[]
  onCancel(): void
  onSubmit(): void
  setPanelTypeModal(type: string): void
  setCircuits(circuit: any): void
}

const input = { borderRadius: '5px' }
const { TextArea } = Input

export const EditPanelModal: React.FC<props> = ({
  visible,
  panelTypeModal,
  circuitData,
  circuits,
  onCancel,
  onSubmit,
  setPanelTypeModal,
  setCircuits,
}) => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const [dedicated, setDedicated] = useState(false)
  const [mixed, setMixed] = useState(false)
  const [circuit, setCircuit] = useState(circuitData)
  const { IPtoNum } = useCircuits()

  useEffect(() => {
    if (panelTypeModal === PanelType.dedicated) {
      setDedicated(true)
    } else if (panelTypeModal === PanelType.mixed) {
      setMixed(true)
    }
  }, [panelTypeModal])

  useEffect(() => {
    if (panelTypeModal === PanelType.dedicated) {
      setDedicated(true)
    } else if (panelTypeModal === PanelType.mixed) {
      setMixed(true)
    }
  }, [])

  const afterClose = () => {
    form.resetFields()
    setDedicated(false)
    setMixed(false)
    setPanelTypeModal('')
  }

  const modalTitle = () => {
    if (panelTypeModal === PanelType.dedicated)
      return <TitleWrapper>{dedicatedPanelSVG} Edit Dedicated EV Panel Information</TitleWrapper>
    return <TitleWrapper>{mixedPanelSVG} Edit Mixed EV Panel Information</TitleWrapper>
  }

  const onChange = (e: any, key?: string) => {
    if (key === 'port') {
      setCircuit({
        ...circuit,
        ct_clamp: {
          enabled: circuit.ct_clamp.enabled,
          id: circuit.ct_clamp.id,
          ip_address: circuit.ct_clamp.ip_address,
          port: +e,
        },
      })
    } else if (key === 'circuit_size') {
      setCircuit({ ...circuit, circuit_size: +e })
    } else if (key === 'existing_load') {
      setCircuit({ ...circuit, existing_load: +e })
    } else if (e.target.name === 'ip_address') {
      setCircuit({
        ...circuit,
        ct_clamp: {
          enabled: circuit.ct_clamp.enabled,
          id: circuit.ct_clamp.id,
          ip_address: e.target.value,
          port: circuit.ct_clamp.port,
        },
      })
    } else if (e.target.name === 'ct_clamp_id') {
      setCircuit({
        ...circuit,
        ct_clamp: {
          enabled: circuit.ct_clamp.enabled,
          id: e.target.value,
          ip_address: circuit.ct_clamp.ip_address,
          port: circuit.ct_clamp.port,
        },
      })
    } else {
      setCircuit({ ...circuit, [e.target.name]: e.target.value })
    }
  }

  const submit = (e: any) => {
    setLoading(true)
    e.preventDefault()
    let tempCircuit = circuit
    if (circuit.circuit_id === '') {
      tempCircuit = { ...circuit, circuit_id: circuit.id }
    }
    const tempCircuits = circuits.map((panel) => {
      if (panel.id === tempCircuit.id) {
        return tempCircuit
      } else {
        return panel
      }
    })
    setCircuits([...tempCircuits])

    const tempAllCircuits = []
    tempAllCircuits.push(...tempCircuits)
    tempAllCircuits.push(...circuits)
    onSubmit()
    setLoading(false)
  }

  return (
    <Modal
      title={null}
      visible={visible}
      confirmLoading={loading}
      footer={
        <AddPanelButtonWrapper>
          <Button
            style={{ width: '100%' }}
            size="large"
            key="invite-btn"
            type="primary"
            loading={loading}
            onClick={submit}
          >
            SAVE
          </Button>
        </AddPanelButtonWrapper>
      }
      closable={true}
      width={467}
      afterClose={afterClose}
      onCancel={onCancel}
    >
      <Form form={form}>
        {modalTitle()}
        <Form.Item>
          <HeadingWrapper>
            <div>Panel ID</div>
            <Input style={input} name="circuit_id" value={circuit.circuit_id} onChange={(value) => onChange(value)} />
          </HeadingWrapper>
        </Form.Item>
        <Form.Item>
          <HeadingWrapper>
            <div>Panel Name</div>
            <Input
              placeholder="Enter Panel ID"
              name="name"
              style={input}
              value={circuit.name}
              onChange={(value) => onChange(value)}
            />
          </HeadingWrapper>
        </Form.Item>
        {(!dedicated || mixed) && (
          <RowInputsWrapper>
            <Form.Item name="existing_load" className="row-input">
              <HeadingWrapper>
                <div>Existing Load (amp)</div>
                <CustomInputNumber
                  value={circuit.existing_load}
                  onChange={(value) => onChange(value, 'existing_load')}
                  // ampFormat={true}
                />
              </HeadingWrapper>
            </Form.Item>
            <Form.Item name="panelSize" className="row-input">
              <HeadingWrapper>
                <div>Panel Size (amp)</div>
                <CustomInputNumber
                  value={circuit.circuit_size}
                  onChange={(value) => onChange(value, 'circuit_size')}
                  // ampFormat={true}
                />
              </HeadingWrapper>
            </Form.Item>
          </RowInputsWrapper>
        )}
        {(dedicated || !mixed) && (
          <Form.Item name="circuit_size">
            <HeadingWrapper>
              <div>Panel Size (amp)</div>
              <div className="panel-size-row">
                <CustomInputNumber
                  value={circuit.circuit_size}
                  onChange={(value) => onChange(value, 'circuit_size')}
                  // ampFormat={true}
                />
              </div>
            </HeadingWrapper>
          </Form.Item>
        )}
        <Form.Item name="note">
          <HeadingWrapper>
            <div>Panel Note</div>
            <TextArea
              style={{ borderRadius: '20px', height: '105px' }}
              placeholder="Panel Note"
              name="note"
              value={circuit.note}
              onChange={(value) => onChange(value)}
              autoSize={{ minRows: 5, maxRows: 8 }}
            />
          </HeadingWrapper>
        </Form.Item>
        {(!dedicated || mixed) && (
          <>
            <hr
              style={{
                border: 0,
                borderTop: '1px solid #f0f0f0',
                margin: '49px -24px 16px',
              }}
            />
            <TitleWrapper>CT Clamp Configuration for Mixed Panel</TitleWrapper>
            <LastRowInputsWrapper>
              <Form.Item
                name="ip_address"
                className="row-input"
                validateTrigger={['onChange', 'onBlur', 'onSubmit']}
                rules={[
                  MustBeRequired('Please enter a valid IP Address'),
                  MustBeIpAddress('Enter a valid IP Address'),
                  {
                    type: 'string',
                    message: `IP address cannot be higher than 255.255.255.255`,
                    validator: (_, value) => {
                      IPtoNum(value)
                      if (IPtoNum('000.000.000.000') <= IPtoNum(value) && IPtoNum('255.255.255.255') >= IPtoNum(value))
                        return Promise.resolve()
                      return Promise.reject('Template is required!')
                    },
                  },
                  {
                    type: 'string',
                    message: `A valid IP address has three decimal numbers separated by periods`,
                    validator: (_, value) => {
                      const ip = [...value]
                      let count = 0
                      ip.map((char) => {
                        if (char === '.') {
                          count = count + 1
                        }
                        return char
                      })
                      if (count === 3) return Promise.resolve()
                      return Promise.reject('Template is required!')
                    },
                  },
                ]}
              >
                <HeadingWrapper>
                  <div>IP</div>
                  <div>
                    <Input
                      style={input}
                      name="ip_address"
                      value={circuit.ct_clamp.ip_address || ''}
                      onChange={(value) => onChange(value)}
                    />
                  </div>
                </HeadingWrapper>
              </Form.Item>
              <Form.Item
                name="port"
                className="row-input"
                rules={[
                  MustBeRequired('Please enter a valid port'),
                  MustBeNumerical,
                  {
                    type: 'number',
                    max: 65535,
                    min: 1000,
                    message: `Port must be within 1000 and 65,535`,
                    validator: (_, value) => {
                      if (value > 65535 || value < 1000) return Promise.reject('Template is required!')
                      return Promise.resolve()
                    },
                  },
                ]}
              >
                <HeadingWrapper>
                  <div>Port</div>
                  <CustomInputNumber
                    value={circuit.ct_clamp.port || ''}
                    onChange={(value) => onChange(value, 'port')}
                  />
                </HeadingWrapper>
              </Form.Item>
              <Form.Item name="ctClampId" className="row-input">
                <HeadingWrapper>
                  <div>CT Clamp ID</div>
                  <Input
                    name="ct_clamp_id"
                    placeholder="CT Clamp ID"
                    style={input}
                    value={circuit.ct_clamp.id}
                    onChange={(value) => onChange(value)}
                  />
                </HeadingWrapper>
              </Form.Item>
            </LastRowInputsWrapper>
          </>
        )}
      </Form>
    </Modal>
  )
}
