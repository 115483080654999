import { Form, Input, Row, Select } from 'antd'
import { noSpecialCharacters, requiredRule } from '../../helpers/rules'
import { styleDisplay } from '../../atom/style/display'
import { ChargerModelMetadata } from '../../models/charger-model-template'

interface ModelChargerFormProps {
  loading: boolean
  metadata?: ChargerModelMetadata
}

const ModelChargerForm: React.FC<ModelChargerFormProps> = ({ loading, metadata }) => {
  return (
    <Row justify="space-between">
      <Form.Item name="make" label="Make" rules={[requiredRule('make')]} style={styleDisplay(33)}>
        <Select placeholder="Select Make" loading={loading} disabled={loading} showSearch>
          {metadata?.make.map((make) => (
            <Select.Option key={make} label={make} value={make}>
              {make}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="model"
        label="Model"
        rules={[requiredRule('model'), noSpecialCharacters]}
        style={styleDisplay(33)}
      >
        <Input placeholder="EX-1762-5SW1" />
      </Form.Item>
      <Form.Item name="cpo" label="CPO" rules={[requiredRule('CPO')]} style={styleDisplay(33)}>
        <Select placeholder="SWTCH" loading={loading} disabled={loading} dropdownMatchSelectWidth showSearch>
          {metadata?.CPO.map((cpo) => (
            <Select.Option key={cpo} label={cpo} value={cpo}>
              {cpo}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Row>
  )
}

export default ModelChargerForm
