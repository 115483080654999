import { useEffect, useState } from 'react'

import { ApiError } from '../models/error'
import { GetChargerModelTemplateMetadata } from '../services/data-provider/template'
import { ChargerModelMetadata } from '../models/charger-model-template'

export const useGetChargerModelMetadata = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ApiError>()
  const [metadata, setMetadata] = useState<ChargerModelMetadata>()
  const [status, setStatus] = useState('success')

  useEffect(() => {
    setLoading(true)
    GetChargerModelTemplateMetadata()
      .then((response) => {
        setMetadata(response.data)
        setStatus(response.status)
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }, [])

  return { loading, error, status, metadata }
}
