import { Filter } from '../../models/filter'

export const chargerModelTemplateQuery = (f: Filter): { [key: string]: string } => {
  let queryParams: { [key: string]: string } = {}
  if (f.page) {
    queryParams['page'] = `${f.page}`
  }

  return queryParams
}
