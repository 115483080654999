import styledComponents, { createGlobalStyle, ThemedStyledInterface } from 'styled-components'
import { Breakpoints, createBreakpoints } from './breakpoints'

export const styled = styledComponents as ThemedStyledInterface<Theme>

// **Important** Most fit colors defined in `<root>/config/antd-theme.js`, always keep both
//               files in sync. Restart server to see changes being applied to `antd-theme.js` file.
export type Theme = typeof theme

const borderWidths = ['0', '1px', '2px', '4px', '8px']

// Typographic Scale (numbers are converted to px values)
const fontSizes = ['10px', '12px', '14px', '16px', '20px', '24px', '32px', '40px', '48px', '64px', '72px']
const lineHeights = ['18px', '22px', '26px', '34px', '40px', '50px']

const fontFamily = {
  regular: 'AkkuratLLWeb-Regular',
  black: 'AkkuratLLWeb-Black',
  light: 'AkkuratLLWeb-Light',
}

const radii = ['0', '1px', '2px', '4px', '8px']

// Spacing Scale (used for margin and padding)
const space = ['0px', '4px', '8px', '16px', '32px', '64px', '128px', '256px', '512px']

const colors = {
  primary: '#30D7BA',
  yellow: '#F0AD4E',
  orange: '#FF951E',
  blue: '#5bc0de',
  red: '#d9534f',
  gray: '#777777',
  darkGray: '#7D838A',
  neonGreen: '#30D7BA',
  black: '#000000',
  darkBlue: '#1C84C6',
  white: '#fff',
  lightGray: '#dedede',
  softBlack: '#242e2c',
  green: '#44D333',
  green100: '#e7f5da',
  green200: '#60a902',
  transparancy: 'rgba(255,255,255,0)',
  greenTransparancy: 'rgba(100, 199, 221, 0.15)',
  dangerTransparancy: 'rgba(222, 84, 84, 0.1)',
  ternary25: '#fafafa',
  ternary50: '#fafbfc',
  ternary100: '#f6f8f9',
  ternary200: '#f0f3f5',
  ternary250: '#e4eaef',
  ternary300: '#d8e1e9',
  ternary400: '#bbc7d3',
  ternary500: '#9fadbc',
  ternary600: '#8294a6',
  ternary700: '#657a90',
  ternary800: '#49617a',
  ternary900: '#2c4863',
  ternary950: '#203d5a',
  ternary1000: '#0f2e4d',
  ternary1100: '#0c243b',
  ternary1200: '#081929',
  danger: '#EC4747',
  link400: '#707bdb',
  link500: '#6673E5',
  link700: '#5a5ab4',

  highlight1: '#61d8c8',
  highlight2: '#34cfbd',

  alert1000: '#fbab0b',

  grey1: '#f8f8fa',
  grey2: '#dcdde2',
  grey3: '#c9cacf',
  grey4: '#b2b2b9',
  grey5: '#9a9ba3',
  grey6: '#7a7c84',
  grey7: '#5a5d65',
  grey8: '#3a3e47',
  grey9: '#1a1f28',
  grey10: '#000',
  grey11: '#f3f3f4',
  grayBackground: '#FAFAFA',
  black05: '#0000000d',
  buttonHovering: '#00B799',
  cultered: '#F1F1F1',
  magicMint: '#C8F9E8',
  viridian: '#09846E',
  orangePeel15: 'rgba(255, 153, 0, 0.15)',
  warningPrimary: '#ff9900',
  underRepair: 'rgba(222, 84, 84, 0.1)',
  dangerPrimary: '#ec4747',
  lightBlue: '#c3ecfa',
  subtitle: 'rgba(0, 0, 0, 0.45)',
}
const breakpoints: Breakpoints = createBreakpoints()
export const theme = {
  breakpoints,
  colors,
  fontSizes,
  fontFamily,
  borderWidths,
  lineHeights,
  radii,
  space,
}

export const GlobalStyle = createGlobalStyle`
html,body{
  background: #f3f3f4;
  font-size: 12px;
}

.ant-page-header {
  background-color: #ffffff;
}
.am-list-item .am-list-line .am-list-content,
.am-calendar .week-panel .cell,
.am-calendar .single-month .row .cell .date-wrapper .date,
.am-calendar .shortcut-panel .item,
.am-calendar .confirm-panel .button,
.am-list-item .am-list-line .am-list-extra,
.ant-list-item .ant-list-item-main,
.ant-list-item .ant-list-item-extra,
.am-button.am-button-inline,
.am-card-body,
.am-card-header-content    {
    font-size: 12px;
    font-family: sans-serif;
}

.delete-btn:hover {
	background-color: #d9534f;
  border-color: #d9534f;
}
.delete-btn:hover > [data-icon="trash"],
.edit-btn:hover > [data-icon="pen"],
.edit-charger-info-btn:hover > [data-icon="file"],
.edit-charger-status-btn:hover > [data-icon="charging-station"],
.copy-btn:hover > [data-icon="copy"],
.charger-error-btn:hover > [data-icon="exclamation-triangle"],
.create-btn:hover > [data-icon="plus-circle"] {
  color: #fff;
}

.create-btn:hover {
  background-color: #30D7BA;
  border-color: #30D7BA;
}

.edit-btn:hover {
    background-color: #F0AD4E;
    border-color: #F0AD4E;
}

.edit-charger-info-btn:hover {
  background-color: #FF951E;
  border-color: #FF951E;
}

.edit-charger-status-btn:hover {
  background-color: #1C84C6;
  border-color: #1C84C6;
}

.copy-btn:hover {
  background-color: #777777;
  border-color: #777777;
}

.charger-error-btn:hover {
   background-color: #FF951E;
  border-color: #FF951E;
}

`
