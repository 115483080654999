import configProvider from '../../config'
import { log } from "../../logger"
import { mockLoadMgmtAllResp, mockLoadMgmtChargerResp, mockLoadMgmtEndPointResp, mockLoadMgmtResp, mockLoadMgmtUpdatedResp, mockLoadMgmtUpdatedTenant } from '../../mock/panels'
import { ChargersListingsLoadMgmt } from '../../models/charger'
import { LoadManagementAllResponse, LoadManagementCreateNewTenantResponse, LoadManagementEndPointResponse, LoadManagementResponse, LoadManagementUpdatedResponse } from "../../models/http"
import { TenantRef } from '../../models/tenant'
import { apiLoadMgmtChargerClient, apiLoadMgmtClient } from './client'

export async function GetCircuits(tenantId: string): Promise<LoadManagementResponse> {
  const url = `/panel/${tenantId}`

  log(`getting all panels for tenant id: ${tenantId}`, { url })
  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockLoadMgmtResp)
  }

  return apiLoadMgmtClient(url, {
    method: 'GET',
    headers: {
      'api-key': configProvider.config.loadManagementApiKey,
      'Access-Control-Allow-Origin': configProvider.config.loadManagement,
      Referer: configProvider.config.loadManagement,
      Origin: configProvider.config.loadManagement,
    },
  })
}

export async function GetAllCircuitInfo(currentPage?: number): Promise<LoadManagementAllResponse> {
  let url = `/sessions/`

  log(`getting all panels`, { url })
  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockLoadMgmtAllResp)
  }

  return apiLoadMgmtClient(url, {
    method: 'GET',
    headers: {
      'api-key': configProvider.config.loadManagementApiKey,
      'Access-Control-Allow-Origin': configProvider.config.loadManagement,
      Referer: configProvider.config.loadManagement,
      Origin: configProvider.config.loadManagement,
    },
  })
}


export async function UpdateCircuits(tenant: TenantRef, data: any, updatedBy: any): Promise<LoadManagementUpdatedResponse> {
  const url = `/panel/${tenant.id}`

  log(`updated panels for tenant id: ${tenant.id}`, { url })
  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockLoadMgmtUpdatedResp)
  }

  return apiLoadMgmtClient(url, {
    method: 'PUT',
    body: JSON.stringify({
      tenant_id: tenant.id,
      tenant_name: tenant.name,
      circuits: data,
      updated_by: updatedBy.updated_by,
      updated_at: updatedBy.updated_at
    }),
    headers: {
      'api-key': configProvider.config.loadManagementApiKey,
      'Access-Control-Allow-Origin': configProvider.config.loadManagement,
      'Content-Type': 'application/json',
      Referer: configProvider.config.loadManagement,
      Origin: configProvider.config.loadManagement,
    },
  })
}

export async function GetEndPoints(tenantId: string): Promise<LoadManagementEndPointResponse> {
  const url = `/endpoint/${tenantId}`

  log(`getting all endpoints for tenant id: ${tenantId}`, { url })
  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockLoadMgmtEndPointResp)
  }

  return apiLoadMgmtClient(url, {
    method: 'GET',
    headers: {
      'api-key': configProvider.config.loadManagementApiKey,
      'Access-Control-Allow-Origin': configProvider.config.loadManagement,
      Referer: configProvider.config.loadManagement,
      Origin: configProvider.config.loadManagement,
    },
  })
}

export async function GetLoadManagementChargers(tenantId: string): Promise<ChargersListingsLoadMgmt> {
  const url = `/tenants?tenants_id=${tenantId}`

  log(`getting all panels for tenant id: ${tenantId}`, { url })
  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockLoadMgmtChargerResp)
  }
  
  return apiLoadMgmtChargerClient(url, {
    method: 'GET',
    headers: {
      'api-key': configProvider.config.loadManagementApiKey,
      'Access-Control-Allow-Origin': configProvider.config.loadManagementCharger,
      Referer: configProvider.config.loadManagementCharger,
      Origin: configProvider.config.loadManagementCharger,
    },
  })
}


export async function CreateNewTenant(tenant: TenantRef): Promise<LoadManagementCreateNewTenantResponse> {
  const url = `/tenant/`

  log(`created new tenant for tenant id: ${tenant.id}`, { url })
  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockLoadMgmtUpdatedTenant)
  }

  return apiLoadMgmtClient(url, {
    method: 'POST',
    body: JSON.stringify({
      tenant_id: tenant.id,
      tenant_name: tenant.name,
    }),
    headers: {
      'api-key': configProvider.config.loadManagementApiKey,
      'Access-Control-Allow-Origin': configProvider.config.loadManagement,
      'Content-Type': 'application/json',
      Referer: configProvider.config.loadManagement,
      Origin: configProvider.config.loadManagement,
    },
  })
}