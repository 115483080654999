import { styled, theme } from '../theme'

export const GreyBackground = styled.div`
  color: ${theme.colors.ternary25};
  margin: -8px;
`

export const BackgroundContainer = styled.div`
  .ant-page-header {
    background-color: rgb(250, 250, 250);
    padding: 16px 0 4px 0;
  }
`
