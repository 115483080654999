import { Button, Form, Modal } from 'antd'
import { useState } from 'react'

import { useCreateChargerModel } from '../../hooks/useCreateChargerModel'
import { AlertError } from '../../components/error'
import CreateChargerModelForm from '../create-charger-model/CreateChargerModelForm'

interface CreateChargerModelModalProps {
  isModalOpen: boolean
  onCancel: () => void
  onOk: () => void
}

const CreateChargerModelModal: React.FC<CreateChargerModelModalProps> = ({ isModalOpen, onCancel, onOk }) => {
  // State to hold the selected image file
  const [imagePath, setImagePath] = useState('')
  const [imageFileName, setImageFileName] = useState('')
  const [templateName, setTemplateName] = useState('')
  const [form] = Form.useForm()

  const { loading, error, onFinish } = useCreateChargerModel({ form, onOk, imagePath, imageFileName, templateName })

  const buttonProps = {
    loading,
    disabled: loading,
    style: { width: '49%' },
  }

  return (
    <Modal
      title="Create Charger Model Template"
      visible={isModalOpen}
      onCancel={onCancel}
      width={800}
      onOk={onOk}
      style={{ top: 5 }}
      footer={[
        <Button key="back" onClick={onCancel} {...buttonProps}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={onFinish} {...buttonProps}>
          Create Charger Model Template
        </Button>,
      ]}
    >
      <AlertError error={error} />
      <CreateChargerModelForm
        form={form}
        setImagePath={setImagePath}
        setImageFileName={setImageFileName}
        setTemplateName={setTemplateName}
      />
    </Modal>
  )
}

export default CreateChargerModelModal
