import { useEffect, useState } from 'react'

import { ApiError } from '../models/error'
import { GetChargerConfig } from '../services/data-provider/configuration'
import { ConfigurationItem } from '../models/charger-config-template'

interface ChargerConfigProps {
  serialNumber: string
}

export const useGetChargerConfig = ({ serialNumber }: ChargerConfigProps) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ApiError>()
  const [chargerConfig, setChargerConfig] = useState<ConfigurationItem>()

  useEffect(() => {
    setLoading(true)
    GetChargerConfig(serialNumber)
      .then((response) => {
        if (typeof response === 'string') {
          // ! "Charger with serial number 5SW1-1-2147-00081 not found"
          return setError(response as ApiError)
        }
        setChargerConfig(response)
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }, [])

  return { loading, error, chargerConfig }
}
