import { useEffect, useState } from 'react'

import { Template } from '../models/template'
import { ApiError } from '../models/error'

import { GetAllChargerConfigTemplates } from '../services/data-provider/template'

export const useGetConfigTemplates = () => {
  const [configLoading, setConfigLoading] = useState(false)
  const [configTemplates, setConfigTemplates] = useState<Template[]>([])
  const [configError, setConfigError] = useState<ApiError>()

  useEffect(() => {
    setConfigLoading(true)
    GetAllChargerConfigTemplates()
      .then((res) => setConfigTemplates(res))
      .catch((err) => setConfigError(err))
      .finally(() => setConfigLoading(false))
  }, [])

  return { configLoading, configTemplates, configError }
}
