import { useState } from 'react'
import { ChargerDetails } from '../models/charger'
import { Branch, Circuit, CircuitType, circuitType } from '../models/circuit'

export const useCircuits = () => {
  const [circuitModalData, setCircuitsModalData] = useState<Circuit[]>([])
  const [panelId, setPanelId] = useState(Math.floor(Math.random() * 100).toString())
  const [chargerId, setChargerId] = useState(Math.floor(Math.random() * 100).toString())
  const [ctClampId, setCtClampId] = useState(Math.floor(Math.random() * 100).toString())
  const [branchId, setBranchId] = useState('1')
  const [panelTypeModal, setPanelTypeModal] = useState('')
  const [editPanelModalVisible, setEditPanelModalVisible] = useState(false)
  const [modalData, setModalData] = useState<Circuit>(circuitModalData[0])
  const [dedicatedPanelInitialValues, setDedicatedPanelInitialValues] = useState({
    id: panelId,
    circuit_id: '',
    name: '',
    load_management: false,
    type: circuitType[0],
    ct_clamp: {
      id: ctClampId,
      enabled: false,
      ip_address: '',
      port: 0,
    },
    existing_load: 0,
    circuit_size: 0,
    note: '',
    two_stage: false,
    branches: [
      {
        id: branchId,
        name: '',
        load_management: true,
        breaker_size: 0,
        end_point: null,
        chargers: [
          {
            id: chargerId,
            cp_serial_number: '',
            phases: '',
            ampere_max: 6,
            ampere_min: 6,
            ampere_fallback: 6,
          },
        ],
      },
    ],
  })

  const [mixedPanelInitialValues, setMixedPanelInitialValues] = useState({
    id: panelId,
    circuit_id: '',
    name: '',
    load_management: true,
    type: circuitType[1],
    ct_clamp: {
      id: ctClampId,
      enabled: true,
      ip_address: '',
      port: 0,
    },
    existing_load: 0,
    circuit_size: 0,
    note: '',
    two_stage: false,
    branches: [
      {
        id: branchId,
        name: '',
        load_management: false,
        breaker_size: 0,
        end_point: null,
        chargers: [
          {
            id: chargerId,
            cp_serial_number: '',
            phases: '',
            ampere_max: 6,
            ampere_min: 6,
            ampere_fallback: 6,
          },
        ],
      },
    ],
  })

  const circuitInitialValues = {
    id: '',
    name: '',
    load_management: false,
    breaker_size: 0,
    end_point: null,
    chargers: [
      {
        id: chargerId,
        cp_serial_number: '',
        phases: '',
        ampere_max: 6,
        ampere_min: 6,
        ampere_fallback: 6,
      },
    ],
  }

  const chargerInitialValues = {
    id: chargerId,
    cp_serial_number: '',
    phases: '',
    ampere_max: 6,
    ampere_min: 6,
    ampere_fallback: 6,
  }

  const handleEditPanelSubmit = () => {
    setEditPanelModalVisible(false)
    setPanelTypeModal('')
  }

  const editPanel = (type: string | CircuitType, circuit: Circuit) => {
    setPanelTypeModal(type)
    setEditPanelModalVisible(true)
    setModalData(circuit)
  }

  const cancelEdit = () => {
    setEditPanelModalVisible(false)
    setPanelTypeModal('')
  }

  const limitBreakerSizeDedicated = (circuit: Circuit) => {
    const part1 = circuit.circuit_size * 0.8
    const part2 = calTotalBreakerSize(circuit)
    return part1 - part2
  }

  const limitBreakerSizeMixed = (circuit: Circuit) => {
    const part1 = circuit.circuit_size * 0.8 - circuit.existing_load
    const part2 = calTotalBreakerSize(circuit)
    return part1 - part2
  }

  const calTotalBreakerSize = (circuit: Circuit) => {
    const final = circuit.branches.reduce((acc: number, branch: Branch) => {
      return acc + branch.breaker_size
    }, 0)
    return final
  }

  const getTotalBreakerSize = (circuit: Circuit) => {
    if (circuit.branches.length === 0 || circuit.branches.length === 1) {
      return circuit.circuit_size
    }
    return calTotalBreakerSize(circuit)
  }

  const limitBranchBreakerSize = (branch: Branch, type: string) => {
    const part1 = branch.breaker_size * 0.8
    if (type === 'ampere_fallback') {
      const part2 = calFallbackTotalBranchBreakerSize(branch)
      return part1 - part2
    } else {
      const part2 = calMaxCapTotalBranchBreakerSize(branch)
      return part1 - part2
    }
  }

  const calFallbackTotalBranchBreakerSize = (branch: Branch) => {
    const final = branch.chargers.reduce((acc: number, charger: ChargerDetails) => {
      return acc + charger.ampere_fallback
    }, 0)
    return final
  }

  const calMaxCapTotalBranchBreakerSize = (branch: Branch) => {
    const final = branch.chargers.reduce((acc: number, charger: ChargerDetails) => {
      return acc + charger.ampere_max
    }, 0)
    return final
  }

  const findMissingIndexes = (arr: any) => {
    arr = arr.slice(0).sort((a: any, b: any) => {
      return a - b
    })
    let next = 1
    let missing = []
    for (let i = 0; i < arr.length; i++) {
      while (next < arr[i]) {
        missing.push(next)
        next++
      }
      next++
    }
    return missing
  }

  const IPtoNum = (ip: string) => {
    return Number(
      ip
        .split('.')
        .map((d) => ('000' + d).substr(-3))
        .join(''),
    )
  }

  return {
    panelTypeModal,
    editPanelModalVisible,
    modalData,
    dedicatedPanelInitialValues,
    mixedPanelInitialValues,
    chargerId,
    circuitInitialValues,
    chargerInitialValues,
    setModalData,
    handleEditPanelSubmit,
    editPanel,
    cancelEdit,
    setPanelTypeModal,
    setPanelId,
    setChargerId,
    setCtClampId,
    setBranchId,
    setCircuitsModalData,
    limitBreakerSizeDedicated,
    limitBreakerSizeMixed,
    getTotalBreakerSize,
    limitBranchBreakerSize,
    findMissingIndexes,
    setDedicatedPanelInitialValues,
    setMixedPanelInitialValues,
    IPtoNum,
  }
}
