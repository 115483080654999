import { Button, Card, Col, Descriptions, Row } from 'antd'
import { DeleteTwoTone, ExclamationCircleTwoTone, FileTextTwoTone, SettingTwoTone } from '@ant-design/icons'
import momentTz from 'moment-timezone'
import moment from 'moment'
import React from 'react'

import { handleConfigurationStatus, handleConnectivityBadge } from '../../helpers/connectivity'
import { theme } from '../../theme'
import { Charger } from '../../models/charger'

interface props {
  chargers: Charger[]
  selectedChargers: Charger[]
  onSerialNumberIndices: (index: number, record: Charger) => string
  onClickErrorsBtn: (record: Charger) => void
  onClickEditBtn: (record: Charger) => void
  onClickGetConfigurationBtn: (record: Charger) => void
  onClickDeleteBtn: (record: Charger) => void
  onSelectedChargers: (record: Charger) => void
}

const labelStyle = { flex: '0 0 30%', fontWeight: 700 }
const centerStyle = { display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '4px' }

const { gray, red, dangerPrimary, yellow } = theme.colors

const ChargerCardView: React.FC<props> = ({
  chargers,
  selectedChargers,
  onSerialNumberIndices,
  onClickErrorsBtn,
  onClickEditBtn,
  onClickGetConfigurationBtn,
  onClickDeleteBtn,
  onSelectedChargers,
}) => {
  const sendNumberIndices = (chargerIndex: number, charger: Charger) => {
    onSerialNumberIndices(chargerIndex, charger)
    return null
  }

  const canGetConfiguration = (charger: Charger): boolean => {
    return charger.connectionStatus.status === 'disconnected' || charger.connectionStatus.status === 'unknown'
  }

  return (
    <Row gutter={[16, 16]}>
      {chargers.map((charger, chargerIndex) => (
        <>
          {sendNumberIndices(chargerIndex, charger)}
          <Col span={24} key={charger._id}>
            <Card
              title={
                <Card.Meta
                  // avatar={
                  //   <Avatar
                  //     alt="connectivity"
                  //     style={{ backgroundColor: 'white' }}
                  //     icon={handleConnectivity(charger.connectionStatus, charger.info, true, false)}
                  //   />
                  // }
                  title={
                    <>
                      {handleConnectivityBadge(charger.connectionStatus.status)}
                      {charger.serialNumber}
                    </>
                  }
                  description={handleConfigurationStatus(charger.configurationState.state)}
                  style={{ height: '100%' }}
                />
              }
              extra={
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '6px',
                    alignItems: 'center',
                  }}
                >
                  <div className="flex-and-gap-below-right">
                    <span>
                      {charger.configurationTemplate.updatedBy === null
                        ? null
                        : `Commissioned by ${charger.configurationTemplate.updatedBy}`}
                    </span>
                    <span className="paragraph-04-light opacity-06">
                      Updated on{' '}
                      {moment
                        .utc(charger.configurationTemplate.updatedAt)
                        .tz('America/Toronto')
                        .format('YYYY/MM/DD - HH:mm:ss (z)')}
                    </span>
                  </div>
                  <Button
                    type="primary"
                    disabled={charger.connectionStatus.status !== 'connected'}
                    onClick={() => onSelectedChargers(charger)}
                  >
                    {selectedChargers.find((selectedCharger) => selectedCharger._id === charger._id)
                      ? 'Selected'
                      : 'Select'}
                  </Button>
                </div>
              }
              actions={[
                charger.errors.length > 0 && (
                  <div onClick={() => onClickErrorsBtn(charger)} style={centerStyle}>
                    <ExclamationCircleTwoTone
                      key="get_configuration"
                      twoToneColor={dangerPrimary}
                      style={{ fontSize: '16px' }}
                    />
                    <span style={{ color: dangerPrimary }}>Error</span>
                  </div>
                ),
                <div onClick={() => onClickEditBtn(charger)} style={centerStyle}>
                  <FileTextTwoTone key="setting" twoToneColor={yellow} style={{ fontSize: '16px' }} />
                  <span style={{ color: yellow }}>Config. Template</span>
                </div>,

                <div
                  aria-disabled={canGetConfiguration(charger)}
                  onClick={canGetConfiguration(charger) ? undefined : () => onClickGetConfigurationBtn(charger)}
                  style={{
                    cursor: canGetConfiguration(charger) ? 'not-allowed' : 'pointer',
                    opacity: canGetConfiguration(charger) ? 0.5 : 1,
                    ...centerStyle,
                  }}
                >
                  <SettingTwoTone key="edit" twoToneColor={gray} style={{ fontSize: '16px' }} />
                  <span style={{ color: gray }}>Get Configuration</span>
                </div>,

                <div onClick={() => onClickDeleteBtn(charger)} style={centerStyle}>
                  <DeleteTwoTone key="delete" twoToneColor={red} style={{ fontSize: '16px' }} />
                  <span style={{ color: red }}>Delete</span>
                </div>,
              ].filter(Boolean)} // Remove falsy values from the array
            >
              <>
                <Descriptions
                  column={1}
                  colon={false}
                  // title={`${charger.info.vendor} - ${charger.info.model}`}
                >
                  <Descriptions.Item label="Make" labelStyle={labelStyle}>
                    {charger.info.vendor}
                  </Descriptions.Item>
                  <Descriptions.Item label="Model" labelStyle={labelStyle}>
                    {charger.info.model}
                  </Descriptions.Item>

                  <Descriptions.Item label="Connected Date" labelStyle={labelStyle}>
                    {momentTz
                      .tz(moment.utc(charger.info.updatedAt), 'America/Toronto')
                      .format('YYYY/MM/DD - HH:mm:ss (z)')}
                  </Descriptions.Item>

                  <Descriptions.Item label="Site" labelStyle={labelStyle}>
                    {charger.configurationTemplate.tenant}
                  </Descriptions.Item>
                </Descriptions>
              </>
            </Card>
          </Col>
        </>
      ))}
    </Row>
  )
}

export default ChargerCardView
