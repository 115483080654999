import { Form, InputNumber, Row } from 'antd'
import { styleDisplay } from '../../atom/style/display'
import { requiredRule } from '../../helpers/rules'
import { MustBeNumerical, MustBePositiveIntegers } from '../../components/rules/rules'

const styleFullWidth = { width: '100%', borderRadius: '48px' }

const TemperatureOptionsForm: React.FC = () => {
  return (
    <Row justify="space-between">
      <Form.Item
        name="min_operating_temperature"
        label="Min. Operating Temperature (&#176;C)"
        rules={[requiredRule('min. operating temperature'), MustBeNumerical]}
        style={styleDisplay(50)}
      >
        <InputNumber placeholder="1" style={styleFullWidth} />
      </Form.Item>
      <Form.Item
        name="max_operating_temperature"
        label="Max. Operating Temperature (&#176;C)"
        rules={[requiredRule('max. operating temperature'), MustBePositiveIntegers]}
        style={styleDisplay(49)}
      >
        <InputNumber placeholder="1" style={styleFullWidth} />
      </Form.Item>
    </Row>
  )
}

export default TemperatureOptionsForm
