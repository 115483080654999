import { useState } from 'react'
import { Checkbox, FormInstance } from 'antd'

import { ApiError } from '../models/error'
import { CreateChargerModelTemplate } from '../services/data-provider/template'
import { useNotifications } from './useNotifications'
import { useAppState } from '../state'

interface CreateChargerModelProps {
  form: FormInstance<any>
  onOk: () => void
  imagePath: string
  imageFileName: string
  templateName: string
}

export const useCreateChargerModel = ({
  form,
  onOk,
  imagePath,
  imageFileName,
  templateName,
}: CreateChargerModelProps) => {
  const { currentUser } = useAppState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ApiError>()

  const { openSuccessNotification, openErrorNotification, openWarningNotification } = useNotifications()

  const parseResponse = (res: any) => {
    if (res.status === 'success') {
      openSuccessNotification(
        <>
          <Checkbox checked={true} style={{ marginRight: '10px' }} />
          This template has been created: <b>{res.data.charger_model_template.name}</b>
        </>,
        { width: '100%' },
      )
      onOk()
    } else if (res.status === 'fail') {
      openErrorNotification(
        <>
          Failed! <b>{res.message}</b>
        </>,
      )
    } else {
      openWarningNotification(
        <>
          <Checkbox checked={false} style={{ marginRight: '10px' }} />
          <b>{res.message}</b>
        </>,
      )
    }
  }

  const onFinish = () => {
    setLoading(true)
    setError(undefined)

    form
      .validateFields()
      .then((values) => {
        // Check if imagePath and imageFileName are present
        if (!imagePath || !imageFileName) {
          setError(new ApiError('Form is incomplete due to a missing property', ['An image is required'], []))
          setLoading(false)
          return
        }
        // Include the selected image file when performing the final action
        const dataToSend = {
          ...values,
          image_path: imagePath,
          image_file_name: imageFileName,
          creator: currentUser?.name,
          creator_auth0_uid: currentUser?.auth0_uid,
          creator_email: currentUser?.email,
          name: templateName,
        }

        createChargerModel(dataToSend)
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }

  const createChargerModel = async (values: any) => {
    CreateChargerModelTemplate(values)
      .then((res) => parseResponse(res))
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }
  return { loading, error, onFinish }
}
