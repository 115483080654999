import { Link } from 'react-router-dom'
import { Button } from 'antd'
import { styled } from '../theme'

type ButtonSize = 'large' | 'middle' | 'small'
type ButtonType = 'link' | 'text' | 'default' | 'ghost' | 'primary' | 'dashed'

// Props for the ButtonIcon component.
interface props {
  loading?: boolean
  icon: JSX.Element
  fetchData?: () => void
  text?: JSX.Element | string
  size?: ButtonSize
  type?: ButtonType
  withinSearchBar?: boolean
  withInSuffix?: boolean
  reusableSelectorSuffix?: boolean
  disabled?: boolean
}

// Props for the ButtonLink component, extending the common props.
interface linkProps extends props {
  url?: string
  hash?: string
  onClick?: (e: any) => void
}

// Styled button component using styled-components.
const StyledButton = styled.button<{
  loading?: boolean
  disabled?: boolean
  withinSearchBar: boolean
  withInSuffix: boolean
  reusableSelectorSuffix?: boolean
}>`
  height: ${(props) => (props.withinSearchBar || props.withInSuffix ? '37px' : '35px')};
  width: 44px;
  border: 0;
  border-radius: 40px;
  z-index: 1;

  svg {
    margin-top: ${(props) => (props.reusableSelectorSuffix ? '0px' : '4px')};
  }

  margin-left: ${(props) => (props.withinSearchBar ? '-45px' : 0)};
  position: relative;

  cursor: ${({ disabled, loading }) => (disabled || loading ? 'not-allowed' : 'pointer')};
  transition: all 0.2s ease-in-out;

  background-color: ${({ disabled, loading, theme }) =>
    disabled || loading ? theme.colors.lightGray : theme.colors.primary};
  &:hover {
    background-color: ${({ disabled, loading, theme }) =>
      disabled || loading ? theme.colors.lightGray : theme.colors.buttonHovering};
  }
`

// ButtonIcon component that displays a button with an icon.
export const ButtonIcon: React.FC<props> = ({
  loading,
  disabled,
  icon,
  fetchData,
  withinSearchBar = false,
  withInSuffix = false,
  reusableSelectorSuffix,
}) => {
  return (
    <StyledButton
      disabled={disabled}
      loading={loading}
      onClick={fetchData}
      withinSearchBar={withinSearchBar}
      withInSuffix={withInSuffix}
      reusableSelectorSuffix={reusableSelectorSuffix}
    >
      {icon}
    </StyledButton>
  )
}

// ButtonLink component that displays a button or a link button with an icon and optional text.
export const ButtonLink: React.FC<linkProps> = ({ icon, text, url, hash, onClick }) => {
  if (url) {
    // If there's a URL provided, wrap the content in a Link component.
    return (
      <Link to={{ pathname: url, hash: hash ? hash : '' }} onClick={onClick}>
        <Button size="large" type="link" icon={icon}>
          <span>{text}</span>
        </Button>
      </Link>
    )
  } else {
    // If no URL is provided, render a regular Button with an icon and optional text.
    return (
      <Button size="large" type="link" icon={icon} onClick={onClick}>
        <span>{text}</span>
      </Button>
    )
  }
}
