import React, { useState } from 'react'
import { Navigation } from './navigation'
import { AppHeader } from './app-header'
import { AppFooter } from './app-footer'

import { Layout } from 'antd'

const { Content } = Layout

// AuthenticatedLayout component
export const AuthenticatedLayout: React.FC = ({ children }) => {
  const [collapsed, setCollapsed] = useState(true)
  // Return JSX for the AuthenticatedLayout component
  return (
    <Layout>
      <Navigation collapsed={collapsed} handleToggleMenu={() => setCollapsed(!collapsed)} />
      <Layout>
        <AppHeader collapsed={collapsed} handleToggleMenu={() => setCollapsed(!collapsed)} />
        <Content style={{ padding: '8px' }}>{children}</Content>
        <AppFooter />
      </Layout>
    </Layout>
  )
}
