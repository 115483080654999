import { useState } from 'react'
import { Form, Row, Typography } from 'antd'
import { FormInstance } from 'antd'

import { useGetChargerModelMetadata } from '../../hooks/useGetChargerModelMetadata'
import { useGetChargerModelTemplate } from '../../hooks/useGetChargerModelTemplate'

import { styleDisplay } from '../../atom/style/display'

import { AlertError } from '../../components/error'
import CreatorForm from '../../components/form/CreatorForm'

import ImageUploader from '../image-uploader/ImageUploader'
import SupportOptionsForm from './SupportOptionsForm'
import RatedVoltagePowerForm from './RatedVoltageAndPowerForm'
import TemperatureOptionsForm from './TemperatureOptionsForm'
import PhysicalSpecForm from './PhysicalSpecForm'
import ChargerAndConnectorTypeForm from './ChargerAndConnectorTypeForm'
import ModelChargerForm from './ModelMakeForm'

interface CreateChargerModelProps {
  form: FormInstance<any>
  setImagePath: (path: string) => void
  setImageFileName: (name: string) => void
  setTemplateName: (templateName: string) => void
}

const CreateChargerModelForm = ({ form, setImagePath, setImageFileName, setTemplateName }: CreateChargerModelProps) => {
  const [combinedValue, setCombinedValue] = useState('')

  const { loading, error, metadata } = useGetChargerModelMetadata()
  const { chargerModelTemplateError } = useGetChargerModelTemplate(false)

  const handleFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  // Function to update combined value whenever 'make', 'model', or 'power' changes
  const updateCombinedValue = () => {
    const values = form.getFieldsValue(['make', 'model', 'rated_current', 'cpo'])
    const combined = `${values.make}_${values.rated_current}A_${values.model}_${values.cpo}`
    setCombinedValue(combined)
    setTemplateName(combined)
  }

  // Callback for form field changes
  const handleFormChange = () => {
    updateCombinedValue()
  }

  const handleSupportPOSChange = (value: any) => {
    // Find the corresponding support_pos name based on the selected id
    const selectedPos = metadata?.support_pos.find((pos) => pos.id === value)
    const supportPosName = selectedPos ? selectedPos.name : ''

    // Set the value of support_pos in the form
    form.setFieldsValue({ support_pos: supportPosName })
  }

  return (
    <Form
      form={form}
      name="create_charger_model_form"
      requiredMark="optional"
      onFinishFailed={handleFinishFailed}
      scrollToFirstError
      colon={false}
      initialValues={{ ...initialFormValues }}
      onFieldsChange={handleFormChange} // Listen for form field changes
    >
      <Row>
        <AlertError error={error || chargerModelTemplateError} />
      </Row>

      <ModelChargerForm loading={loading} metadata={metadata} />

      <ChargerAndConnectorTypeForm loading={loading} metadata={metadata} />

      <RatedVoltagePowerForm styleDisplay={styleDisplay} styleFullWidth={styleFullWidth} />

      <PhysicalSpecForm loading={loading} metadata={metadata} onSupportPOSChange={handleSupportPOSChange} />

      <TemperatureOptionsForm />

      <SupportOptionsForm />

      <ImageUploader setImageFileName={setImageFileName} setImagePath={setImagePath} />

      <Row>
        <CreatorForm />
        <Typography.Text>
          The template will be created as: <b>{combinedValue}</b>
        </Typography.Text>
      </Row>
    </Form>
  )
}

export default CreateChargerModelForm

const initialFormValues = {
  cpo: 'SWTCH',
  charging_type: 'Level 2',
  connector_type_id: 1, // J1772
  cable_length: 18,
  has_built_in_holster: true,
  rated_current: 32,
  rated_power: 6.6,
  support_wifi: true,
  support_RFID: true,
  support_ISO15118_2: false,
  support_ISO15118_20: false,
  support_WiSun: false,
  support_4G: false,
  total_connectors: 1,
  support_pos: 'none',
  support_pos_id: 1,
  display_size: 'none',
  min_input_voltage: 208,
  max_input_voltage: 240,
  min_operating_temperature: -30,
  max_operating_temperature: 55,
}

const styleFullWidth = { width: '100%', borderRadius: '48px' }
