import { Badge } from 'antd'
import { theme } from '../theme'

// Define possible status values
export type AllStatus = 'Enabled' | 'Disabled'

// Define prop types for the component
interface props {
  state: AllStatus
}

// ServiceStatusBadge component
export const ServiceStatusBadge: React.FC<props> = ({ state }) => {
  const getColorStatus = (status: AllStatus): string[] => {
    switch (status) {
      case 'Disabled':
        return [status, theme.colors.dangerTransparancy, theme.colors.danger]
      case 'Enabled':
        return [status, theme.colors.greenTransparancy, theme.colors.neonGreen]
      default:
        return ['Unknown', theme.colors.gray]
    }
  }
  const [status, backgroundColor, fontColor] = getColorStatus(state)
  return <Badge count={status} style={{ backgroundColor, color: fontColor, fontSize: '11px' }} />
}
