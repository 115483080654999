import { Form, Switch } from 'antd'
import { useRef, useState } from 'react'

import { useGetEnvironments } from '../../hooks/useGetEnvironment'
import { useGetConfigTemplates } from '../../hooks/useGetConfigTemplates'

import { AlertError } from '../../components/error'
import CreatorForm from '../../components/form/CreatorForm'
import ChargerConnectorListingInput from './ChargerConnectorListingInput'
import ConfigTemplateAndEnvironmentInput from './ConfigTemplateAndEnvironmentInput'

import { Charger } from '../../models/charger'
import { TenantRef } from '../../models/tenant'
import { Environment } from '../../models/environment'
import CreateTenantModal from '../modal/CreateTenantModal'

import SearchOrCreateTenant from './SearchOrCreateTenant'
import { useAppState } from '../../state'
import { useGetChargerModelTemplate } from '../../hooks/useGetChargerModelTemplate'
import { ChargerModelTemplate } from '../../models/charger-model-template'
import CommissionChargerModelTemplate from './CommissionChargerModelTemplate'

interface CommissionNewListingsFormProps {
  form: any
  chargers: Charger[]
  environments: Environment[]
  chargerModelTemplateSelected?: ChargerModelTemplate | null
  onCommissionFields: (template: ChargerModelTemplate | null) => void
}

const CommissionNewListingsForm: React.FC<CommissionNewListingsFormProps> = ({
  form,
  chargers,
  environments,
  chargerModelTemplateSelected,
  onCommissionFields,
}) => {
  const { currentUser } = useAppState()
  const [isCreateTenantModalVisible, setIsCreateTenantModalVisible] = useState(false)

  const tenantRef = useRef<TenantRef>()

  const { configLoading, configTemplates, configError } = useGetConfigTemplates()
  const { environmentLoading, environmentError } = useGetEnvironments()
  const { chargerModelTemplates, chargerModelTemplateLoading } = useGetChargerModelTemplate()

  const handleCreateTenantModalOpen = () => {
    setIsCreateTenantModalVisible(true)
  }

  const handleCreateTenantModalClose = () => {
    setIsCreateTenantModalVisible(false)
  }

  const handleFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  const handleTenantCreated = (tenant: TenantRef) => {
    tenantRef.current = tenant
    setIsCreateTenantModalVisible(false)
    form.setFieldsValue({
      tenant_id: tenant.id,
      tenant_name: tenant.name,
    })
  }

  const handleOCPPEnvironmentNameChange = (value: string) => {
    form.setFieldsValue({
      ocppEnvironment: environments.find((env) => env._id === value)?.name.toLowerCase(),
    })
  }

  return (
    <>
      <AlertError error={configError || environmentError} />
      <Form
        form={form}
        name="commission_new_listings_form"
        colon={false}
        requiredMark={false}
        scrollToFirstError
        onFinishFailed={handleFinishFailed}
        initialValues={{
          environment_id: environments[0]._id,
          ocppEnvironment: environments[0].name.toLowerCase(),
          creator: currentUser?.name,
          creator_auth0_uid: currentUser?.auth0_uid,
          creator_email: currentUser?.email,
          allow_cloud_load_management: false,
        }}
      >
        <CommissionChargerModelTemplate
          chargerModelTemplateSelected={chargerModelTemplateSelected}
          chargerModelTemplateLoading={chargerModelTemplateLoading}
          chargerModelTemplates={chargerModelTemplates}
          onCommissionFields={onCommissionFields}
        />

        {chargerModelTemplateSelected ? (
          <>
            <ChargerConnectorListingInput
              chargers={chargers}
              selectedChargerModelTemplate={chargerModelTemplateSelected}
            />

            <ConfigTemplateAndEnvironmentInput
              environments={environments}
              environmentLoading={environmentLoading}
              configLoading={configLoading}
              configTemplates={configTemplates}
              onOCPPEnvironmentNameChange={handleOCPPEnvironmentNameChange}
            />

            <SearchOrCreateTenant
              onCreateTenantModalOpen={handleCreateTenantModalOpen}
              onTenantSelected={handleTenantCreated}
            />

            <Form.Item
              name="allow_cloud_load_management"
              label="Allow Cloud Load Management"
              style={{ marginBottom: 0 }}
            >
              <Switch />
            </Form.Item>

            <CreatorForm />
          </>
        ) : null}
      </Form>

      <CreateTenantModal
        onOk={handleCreateTenantModalClose}
        onCancel={handleCreateTenantModalClose}
        isModalOpen={isCreateTenantModalVisible}
        onTenantCreated={handleTenantCreated}
      />
    </>
  )
}

export default CommissionNewListingsForm
