import { LoadingOutlined } from '@ant-design/icons'
import { Result } from 'antd'

// SessionExpiredResult component
const SessionExpiredResult: React.FC = () => {
  // Renders a Result component with status "403" (Forbidden), an icon, title, and subTitle
  return (
    <Result
      status="403"
      icon={<LoadingOutlined />}
      title="Session Expired"
      subTitle={`Redirecting back to the login page!`}
    />
  )
}

export default SessionExpiredResult
