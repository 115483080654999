import React from 'react'

// Custom error type that includes the isCanceled property
interface CanceledError extends Error {
  isCanceled?: boolean
}

// Utility function to create a cancelable promise
function makeCancelable<T>(worker: Promise<T>, id?: string): CancelablePromise<T> {
  let hasCanceled = false

  const wrappedPromise = new Promise<T>((resolve, reject) => {
    // Handle resolve and reject based on cancelation status
    worker.then(
      (val: T) => {
        return hasCanceled ? reject({ isCanceled: true } as CanceledError) : resolve(val)
      },
      (error: Error) => {
        return hasCanceled ? reject({ isCanceled: true } as CanceledError) : reject(error)
      },
    )
  })

  return {
    promise: wrappedPromise,
    // Method to mark the promise as canceled
    cancel() {
      hasCanceled = true
    },
  }
}

// Props interface for the AsyncComponent
interface Props<T, E = any> {
  id?: string
  worker: () => Promise<T>
  onLoading?: React.ReactNode | LoadingCallback
  onSuccess?: React.ReactNode | SuccessCallback<T>
  onError?: React.ReactNode | ErrorCallback<E>
}

// Type aliases for callback functions
type LoadingCallback = () => React.ReactNode
type SuccessCallback<T> = (result: T) => React.ReactNode
type ErrorCallback<E> = (error?: E) => React.ReactNode

// State interface for the AsyncComponent
interface State<T, E = any> {
  success?: boolean
  result?: T
  error?: E
}

// Main AsyncComponent class
export class AsyncComponent<T, E = any> extends React.Component<Props<T, E>, State<T, E>> {
  cancelablePromise?: CancelablePromise<T>
  state: State<T, E> = {}

  async componentDidMount() {
    const { id } = this.props
    this.cancelablePromise = makeCancelable(this.props.worker(), id)
    try {
      const result = await this.cancelablePromise.promise
      this.setState({ result, success: true })
    } catch (error) {
      const canceledError = error as CanceledError
      if (error === undefined || (canceledError !== null && canceledError.isCanceled !== true)) {
        this.setState({ error: (canceledError as unknown) as E, success: false })
      }
    }
  }

  componentWillUnmount() {
    if (this.cancelablePromise) {
      this.cancelablePromise.cancel()
      this.cancelablePromise = undefined
    }
  }

  render() {
    const { onSuccess, onError, onLoading } = this.props

    if (this.state.success === true) {
      return typeof onSuccess === 'function' ? (onSuccess as SuccessCallback<T>)(this.state.result!) : onSuccess || null
    }

    if (this.state.success === false) {
      return typeof onError === 'function' ? (onError as ErrorCallback<E>)(this.state.error!) : onError || null
    }

    // Render loading content
    return typeof onLoading === 'function' ? (onLoading as LoadingCallback)() : onLoading || null
  }
}

// Interface for a cancelable promise
interface CancelablePromise<T> {
  promise: Promise<T>

  cancel(): void
}
