// Tenant icon
export const tenantSVG = (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg" className='tenantSelectorIcon'>
      <path
        d="M16.3636 15.9H18V17.5H0V15.9H1.63636V1.3C1.63636 1.08783 1.72256 0.884344 1.876 0.734315C2.02944 0.584285 2.23755 0.5 2.45455 0.5H15.5455C15.7624 0.5 15.9706 0.584285 16.124 0.734315C16.2774 0.884344 16.3636 1.08783 16.3636 1.3V15.9ZM5.72727 8.7V10.3H8.18182V8.7H5.72727ZM5.72727 5.5V7.1H8.18182V5.5H5.72727ZM5.72727 11.9V13.5H8.18182V11.9H5.72727ZM9.81818 11.9V13.5H12.2727V11.9H9.81818ZM9.81818 8.7V10.3H12.2727V8.7H9.81818ZM9.81818 5.5V7.1H12.2727V5.5H9.81818Z"
        fill="#30D7BA"
      />
    </svg>
  )
  
