import { Badge } from 'antd'

interface CoreBadgeProps {
  text: string
  backgroundColor: string
  color: string
}

const CoreBadge: React.FC<CoreBadgeProps> = ({ text, backgroundColor, color }) => {
  return (
    <Badge
      key={text}
      count={text}
      style={{
        backgroundColor,
        color,
        boxShadow: `${backgroundColor} 0px 0px 0px 0.5px`,
      }}
    />
  )
}

export default CoreBadge
