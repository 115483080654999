// Defines an SVG component for a logout icon
export const logoutSVG = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.32967 1.25H3.25624C2.79046 1.25 2.34375 1.43437 2.01439 1.76256C1.68503 2.09075 1.5 2.53587 1.5 3V13.5C1.5 13.9641 1.68503 14.4092 2.01439 14.7374C2.34375 15.0656 2.79046 15.25 3.25624 15.25H6.32967M6.54795 8.25H14.5M14.5 8.25L12 5.25M14.5 8.25L12 11.25"
      stroke="#242E2C"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
