// Basic layout structure for a form field.
export const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 12,
  },
}

// Layout configuration for the "tail" part of a form, with responsive offsets and spans.
export const tailLayout = {
  wrapperCol: {
    xs: {
      offset: 8,
      span: 24,
    },
    sm: {
      offset: 9,
      span: 16,
    },
    md: {
      offset: 10,
      span: 16,
    },
    lg: {
      offset: 10,
      span: 16,
    },
    xl: {
      offset: 8,
      span: 16,
    },
    xxl: {
      offset: 8,
      span: 16,
    },
  },
}

// Layout configuration for a form with responsive label and wrapper columns.
export const formLayout = {
  labelCol: {
    md: {
      span: 7,
    },
    lg: {
      span: 7,
    },
    xl: {
      span: 8,
    },
    xxl: {
      span: 6,
    },
  },
  wrapperCol: {
    md: {
      span: 11,
    },
    lg: {
      span: 10,
    },
    xl: {
      span: 10,
    },
    xxl: {
      span: 12,
    },
  },
}
