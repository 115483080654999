import { log } from './logger'

export interface Auth0Config {
  domain: string
  clientId: string
  scope: string
  audience: string
}

export type AirbrakeConfigEnv = 'development' | 'staging' | 'preproduction' | 'production'
export interface AirbrakeConfig {
  projectId: number
  projectKey: string
  env: AirbrakeConfigEnv
}

export interface Config {
  mockEnabled: boolean
  network: string
  apiDomain: string
  auth0: Auth0Config
  airbrake: AirbrakeConfig | undefined
  googleMapsApiKey: string
  googleTimeZoneApiKey: string
  apiKey: string
  loadManagement: string
  loadManagementApiKey: string
  loadManagementCharger: string
  railsApiDomain: string
  cihApiDomain: string
}

export class ConfigProvider {
  containsBackendProps = false
  config: Config

  constructor() {
    const {
      REACT_APP_API_DOMAIN, // i,e: cahrger
      REACT_APP_NETWORK, // i,e: swtch, powercharge
      REACT_APP_AUTH0_DOMAIN,
      REACT_APP_AUTH0_CLIENT_ID,
      REACT_APP_MOCK,
      REACT_APP_GOOGLE_MAPS_API_KEY,
      REACT_APP_GOOGLE_TIME_ZONE_API_KEY,
      REACT_APP_CIH_API_KEY,
      REACT_APP_API_AUDIENCE_DOMAIN,
      REACT_APP_LOAD_MANAGEMENT,
      REACT_APP_LOAD_MANAGEMENT_API_KEY,
      REACT_APP_LOAD_MANAGEMENT_CHARGER,
      REACT_APP_RAILS_API_DOMAIN,
      REACT_APP_CIH_API_DOMAIN,
    } = process.env

    this.config = {
      mockEnabled: REACT_APP_MOCK === 'true',
      airbrake: undefined,
      apiDomain: REACT_APP_API_DOMAIN || '18.191.151.206:8000',
      network: REACT_APP_NETWORK || 'swtch',
      googleMapsApiKey: REACT_APP_GOOGLE_MAPS_API_KEY || 'AIzaSyAZK3vUG-tNGwqyaxBPGYEYnAVREeqW9to',
      googleTimeZoneApiKey: REACT_APP_GOOGLE_TIME_ZONE_API_KEY || 'AIzaSyBDvKljLv54znE_ZhNqd9GyB3vKYDIPVYg',
      auth0: {
        domain: REACT_APP_AUTH0_DOMAIN || '',
        clientId: REACT_APP_AUTH0_CLIENT_ID || '',
        scope: 'openid profile email',
        audience: REACT_APP_API_AUDIENCE_DOMAIN || 'https://staging.commissioning.swtchenergy.com/',
      },
      apiKey: REACT_APP_CIH_API_KEY || 'QBKDUJVLKPLWJWGOCUFKKORNHNMCMMS',
      loadManagement: REACT_APP_LOAD_MANAGEMENT || '',
      loadManagementApiKey:
        REACT_APP_LOAD_MANAGEMENT_API_KEY || 'g2A/OvRh1HyJT=fbAowqjhAwGav068YUbeK2u-!zq2e//h0AXkEuYJclKejpgQpe',
      loadManagementCharger: REACT_APP_LOAD_MANAGEMENT_CHARGER || '',
      railsApiDomain: REACT_APP_RAILS_API_DOMAIN || 'staging.swtchenergy.com',
      cihApiDomain: REACT_APP_CIH_API_DOMAIN || 'staging.cih.api.swtchenergy.com',
    }

    log('loaded application config %o', this.config)
  }
}

export default new ConfigProvider()
