import { Message } from '../models/message'

export const mockCreateTemplate: Message = {
  message: 'Template Template 1 created',
  errors: [],
  warnings: [],
}

export const mockDeleteTemplate: Message = {
  message: 'Template Template 1 deleted',
  errors: [],
  warnings: [],
}

export const mockTemplateCreatedResponse: any = {
  status: 'success',
  data: { charger_model_template: { name: 'LITE-ON_32A_EX-1762-5SW1_SWTCH' } },
}

export const mockCreateChargerModelTemplateResponse = {
  status: 'fail',
  message: 'Charger model template already exists!',
}

export const mockTemplateEditedResponse: Message = {
  message: 'Template Lite-On Edited Successfully',
  errors: [],
  warnings: [],
}
