// Defines an SVG component for a navigation arrow icon
export const navigationArrowSVG = (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="#fff" xmlns="http:www.w3.org/2000/svg">
    <path
      d="M17.1722 12L12.2222 7.05L13.6362 5.636L20.0002 12L13.6362 18.364L12.2222 16.95L17.1722 12Z"
      fill="white"
    />
    <path
      d="M10.1722 12L5.22217 7.05L6.63617 5.636L13.0002 12L6.63617 18.364L5.22217 16.95L10.1722 12Z"
      fill="white"
    />
  </svg>
  )
  
