import { useEffect, useState } from 'react'

import { ApiError } from '../models/error'
import { GetImages } from '../services/data-provider/image'
import { Image } from '../models/image'

export const useGetImages = () => {
  const [images, setImages] = useState<Image[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ApiError>()

  useEffect(() => {
    fetchImages()
  }, [])

  const fetchImages = () => {
    setError(undefined)
    setLoading(true)
    GetImages()
      .then((response) => setImages(response.data))
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }
  return { images, loading, error, fetchImages }
}

export default useGetImages
