export interface Address {
  numberAndStreet?: string
  city?: string
  province?: string
  postalCode?: string
  country?: string
}
export type SwtchAddress = Address

export interface GoogleGeocodeLatLng {
  lat: number
  lng: number
}

export interface GoogleGeocodeGeometry {
  location: GoogleGeocodeLatLng
}

export interface GoogleGeocodeAddressComponent {
  long_name: string
  short_name: string
  types: string[]
}

export interface GoogleGeocodeResult {
  geometry: GoogleGeocodeGeometry
  address_components: GoogleGeocodeAddressComponent[]
  formatted_address: string
  partial_match: boolean
  place_id: string
  types: string[]
}

export interface GoogleGeocodeResponse {
  results: GoogleGeocodeResult[]
  status: string
  error_message?: string
}

export const googleGeocodeBaseUrl = 'https://maps.googleapis.com/maps/api/geocode/json'

export type GeocodingSuccess = {
  found: true
  lat: number
  lng: number
  numberAndStreet?: string
  address?: string
  province?: string
  country?: string
  city?: string
  postalCode?: string
}

export type GeocodingFailure = {
  found: false
  code: string
  message: string
}

export type GeocodingResult = GeocodingSuccess | GeocodingFailure
