import { styled } from '../../theme'

export const Padding20Wrapper = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`

export const TitleWrapper = styled.div`
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 25px;
  display: flex;
  gap: 11px;
  align-items: center;
  font-family: ${(props) => props.theme.fontFamily.regular};
`

export const HeadingWrapper = styled.div`
  color: #242e2c;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: -8px;
  font-family: ${(props) => props.theme.fontFamily.regular};

  textarea {
    margin-bottom: -14px;
  }
  .panel-size-row {
    margin-bottom: 0px;
  }
  .panel-size-row > div {
    margin-bottom: -16px;
    width: 100%;
  }
  div {
    margin-bottom: 8px;
  }
  div > .ant-input-number {
    width: 100%;
  }
  div > div {
    width: 50%;
  }
`

export const RowInputsWrapper = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-between;
  margin-bottom: 8px;

  .row-input {
    width: 50%;
    margin-bottom: 0px;
  }

  .row-input
    > .ant-col
    > .ant-form-item-control-input
    > .ant-form-item-control-input-content
    > div
    > div
    > .ant-input-number {
    width: 100%;
  }
`

export const LastRowInputsWrapper = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-between;

  .row-input {
    width: 50%;
    margin-bottom: 0px;
  }

  .row-input
    > .ant-col
    > .ant-form-item-control-input
    > .ant-form-item-control-input-content
    > div
    > div
    > .ant-input-number {
    width: 100%;
    margin-bottom: 2px;
  }
`

export const AmpInputWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const AmpLabelWrapper = styled.span`
  margin-left: 10px;
  font-size: 10px;
  font-family: ${(props) => props.theme.fontFamily.regular};
`

export const BoldWrapper = styled.div`
  font-weight: bold;
`

export const ModalTitleWrapper = styled.div`
  font-size: 18px;
  font-family: ${(props) => props.theme.fontFamily.regular};
`

export const RadioGroupWrapper = styled.div`
  display: flex;
  .ant-radio-group {
    width: 100%;
  }
  .ant-radio-group > .ant-space {
    width: 100%;
  }

  .ant-radio-group > .ant-space > .ant-space-item:focus {
    border: 1px solid #30d7ba;
  }
  .ant-radio-group > .ant-space > .ant-space-item > .ant-radio-wrapper-checked > .ant-radio-checked > .ant-radio-inner {
    border-color: #30d7ba;
  }
  .ant-radio-group
    > .ant-space
    > .ant-space-item
    > .ant-radio-wrapper-checked
    > .ant-radio-checked
    > .ant-radio-inner::after {
    background-color: #30d7ba;
  }
  .ant-radio-group > .ant-space > .ant-space-item > .ant-radio-wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    font-size: 13px;
    color: #242e2c;
    line-height: 16px;
    margin-right: 0px;
    border: 1px solid #dedede;
    border-radius: 41px;
    padding: 11px 13px;
    font-family: ${(props) => props.theme.fontFamily.regular};
  }
  .ant-radio-group > .ant-space > .ant-space-item > .ant-radio-wrapper-checked {
    box-shadow: 0 0 0 3px var(--brand-colors-neon-green-10, rgba(48, 215, 186, 0.1));
    border: 1px solid var(--brand-colors-neon-green, #30d7ba);
  }
  .ant-radio-group > .ant-space > .ant-space-item > .ant-radio-wrapper > span {
    font-size: 13px;
    color: #242e2c;
    line-height: 16px;
    display: flex;
    align-items: center;
    gap: 9px;
    padding: 0px;
    font-family: ${(props) => props.theme.fontFamily.regular};
  }
  .ant-radio-group > .ant-space > .ant-space-item > .ant-radio-wrapper > .ant-radio {
    top: -5px;
  }
  .ant-radio-group > .ant-space > .ant-space-item > .ant-radio-wrapper::after {
    content: none;
  }
`

export const FooterLayout = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 9px;

  .ant-btn-default {
    background-color: #ffffff;
    color: #000000;
    border-color: #dedede;
    box-shadow: none;
    text-shadow: none;
    height: 38px;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.05em;
    text-align: center;
    border-radius: 40px;
    font-family: ${(props) => props.theme.fontFamily.regular};
  }

  .ant-btn-primary {
    box-shadow: none;
    text-shadow: none;
    height: 38px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-align: center;
    font-family: ${(props) => props.theme.fontFamily.regular};
  }

  .ant-btn-default:hover {
    background-color: #ffffff;
    border-color: #dedede;
  }

  .ant-btn-primary:hover {
    background-color: #00b799;
    border-color: #00b799;
  }
`

export const AddPanelButtonWrapper = styled.div`
  padding: 10px;
  .ant-btn-primary:hover {
    background-color: #00b799;
    border-color: #00b799;
  }

  .ant-btn-primary:disabled,
  .ant-btn-primary[disabled] {
    background-color: #f1f1f1;
  }
  .ant-btn-primary:disabled,
  .ant-btn-primary[disabled] > svg {
    stroke-opacity: 0.15000000596046448;
  }
  .ant-btn-primary:disabled,
  .ant-btn-primary[disabled] > span {
    color: #cdcdcd;
    font-family: ${(props) => props.theme.fontFamily.regular};
  }

  .link-button > .ant-btn {
    width: 100%;
  }
  .ant-btn {
    border-radius: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    gap: 5px;
    width: 203px;
    height: 38px;
    background: #30d7ba;
    box-shadow: none;
    border: none;
  }

  .ant-btn > svg {
    margin-bottom: 2px;
  }

  .ant-btn > span {
    height: 17px;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #000000;
    text-shadow: none;
    font-family: ${(props) => props.theme.fontFamily.regular};
  }

  .ant-btn-general {
    border-radius: 20px;
    text-transform: uppercase;
  }
`