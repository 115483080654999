import { Form, Switch, Row, Col } from 'antd'

const SupportOptionsForm: React.FC = () => {
  return (
    <Row>
      <Col span={8}>
        <Form.Item name="support_RFID" label="RFID Enabled" valuePropName="checked" style={{ margin: 0 }}>
          <Switch />
        </Form.Item>
        <Form.Item name="support_ISO15118_2" label="ISO15118-2 Enabled" valuePropName="checked" style={{ margin: 0 }}>
          <Switch />
        </Form.Item>
        <Form.Item name="support_ISO15118_20" label="ISO15118-20 Enabled" valuePropName="checked">
          <Switch />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item name="support_wifi" label="Wifi Enabled" valuePropName="checked" style={{ margin: 0 }}>
          <Switch />
        </Form.Item>
        <Form.Item name="support_4G" label="4G Enabled" valuePropName="checked" style={{ margin: 0 }}>
          <Switch />
        </Form.Item>
        <Form.Item name="support_WiSun" label="WiSun Enabled" valuePropName="checked">
          <Switch />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item name="has_built_in_holster" label="Has Built-in Holster" valuePropName="checked">
          <Switch />
        </Form.Item>
      </Col>
    </Row>
  )
}

export default SupportOptionsForm
