import { Form, Row, Select } from 'antd'

import { requiredRule } from '../../helpers/rules'
import { styleDisplay } from '../../atom/style/display'
import { ChargerModelMetadata } from '../../models/charger-model-template'

interface ChargerAndConnectorTypeFormProps {
  loading: boolean
  metadata?: ChargerModelMetadata
}

const ChargerAndConnectorTypeForm: React.FC<ChargerAndConnectorTypeFormProps> = ({ loading, metadata }) => {
  return (
    <Row justify="space-between">
      <Form.Item
        name="charging_type"
        label="Charging Type"
        rules={[requiredRule('charging type')]}
        style={styleDisplay(50)}
      >
        <Select placeholder="Level 2" loading={loading} disabled={loading} showSearch>
          {metadata?.charging_type.map((type) => (
            <Select.Option key={type} value={type} label={type}>
              {type}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="connector_type_id"
        label="Connector Type"
        rules={[requiredRule('connector type')]}
        style={styleDisplay(49)}
      >
        <Select placeholder="J1772" loading={loading} disabled={loading}>
          {metadata?.connector_type.map((type) => (
            <Select.Option key={type.id} value={type.id} label={type.name}>
              {type.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Row>
  )
}

export default ChargerAndConnectorTypeForm
