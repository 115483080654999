import { styled, theme } from '../theme'

// Styled component for a generic box with white background, margin, and padding.
export const Box = styled.div`
  background-color: white;
  margin: 8px 0;
  padding: 10px;
`

// Styled component for a section with margin.
export const Section = styled.div`
  margin: 10px 0;
`

// Styled component for a grey background container with padding and border-radius.
export const GreyBackground = styled.div`
  /* background-color: ${theme.colors.grey11}; */
  padding: '12px';
  border-radius: '12px;
`
