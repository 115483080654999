import { Select, Space, Spin } from 'antd'

import { ReusableSelectorContainer } from '../../atom/select'
import { connectionStatus } from '../../helpers/connectivity'
import { HomeFilter } from '../../models/filter'
import { LineBreak } from '../../atom/line-break'
import { ButtonIcon } from '../../atom/button'
import { magnifyBlackSVG } from '../../assets/svg/magnify'

interface props {
  loading: boolean
  filter: HomeFilter
  handleMaxTagPlaceholder: (value: string) => string
  setFilter: (filter: any) => void
  getData: () => void
}

const ChargerSelectors: React.FC<props> = ({ loading, filter, setFilter, getData, handleMaxTagPlaceholder }) => {
  return (
    <Space wrap size={[0, 6]}>
      <ReusableSelectorContainer isCombineRight={true} removeSVG={true}>
        <Select
          mode="tags"
          allowClear
          disabled={loading}
          placeholder="Serial number (s)"
          onChange={(value) => setFilter({ ...filter, charger_serial_numbers: value })}
          onClear={() => setFilter({ ...filter, charger_serial_numbers: [] })}
          dropdownMatchSelectWidth={false}
          style={{ minWidth: 300, width: '100%' }}
          // options={[{ label: 'SWTCH-0002', value: 'SWTCH-0002' }]}
          tokenSeparators={[',']}
          maxTagCount={0}
          maxTagPlaceholder={handleMaxTagPlaceholder}
          notFoundContent={loading ? <Spin size="small" /> : 'Start typing to input'}
        />
      </ReusableSelectorContainer>
      <LineBreak />
      <ReusableSelectorContainer isCombineLeft={true} removeSVG={true}>
        <Select
          allowClear
          showSearch
          disabled={loading}
          placeholder="Status"
          // style={{ minWidth: '213px', width: '100%', lineHeight: '26px' }}
          style={{ minWidth: 200, width: '100%', lineHeight: '34px' }}
          dropdownMatchSelectWidth={false}
          options={connectionStatus}
          onChange={(value) => setFilter({ ...filter, status: value })}
        />
      </ReusableSelectorContainer>
      <ButtonIcon
        icon={magnifyBlackSVG}
        loading={loading}
        disabled={loading}
        fetchData={getData}
        withinSearchBar
        withInSuffix
      />
    </Space>
  )
}

export default ChargerSelectors
