import { useEffect, useState } from 'react'
import { Checkbox, Timeline } from 'antd'
import { Branch, Circuit, EndPointData, PanelType } from '../../../../models/circuit'
import { ChargerDetails, LoadManagementCharger } from '../../../../models/charger'
import { TimelineWrapper } from '../../../../atom/load-management/load-management'
import { useNotifications } from '../../../../hooks/useNotifications'
import { PanelHeading } from './panel-heading'
import { BranchTable } from './branch-table'
import { dedicatedPanelSVG } from '../../../../assets/svg/dedicatedPanel'
import { mixedPanelSVG } from '../../../../assets/svg/mixedPanel'
import { dedicatedPanelBranchSVG } from '../../../../assets/svg/dedicatedPanelBranch'
import { mixedPanelBranchSVG } from '../../../../assets/svg/mixedPanelBranch'
import { useLocation } from 'react-router'

interface props {
  circuits: Circuit[]
  verifiedChargers: LoadManagementCharger[]
  circuitsFromUi: Circuit[]
  endPoints: EndPointData[]
  saveClicked: boolean
  setCircuits: (circuits: Circuit[]) => void
  addBranch: (circuit: Circuit, type: string, breakerLimitWarning: boolean) => void
  deleteBranch: (circuit: Circuit, branch: Branch, type: string) => void
  checkBranchLimit: (breakerSizeLimit: number, initialBreakerSizeLimit: boolean) => void
  setDisableSaveData: (disable: boolean) => void
  setCircuitsFromUi: (circuits: Circuit[]) => void
  setBreakerLimitWarning: (breakerLimitWarning: boolean) => void
  setVerifiedChargers: (chargers: LoadManagementCharger[]) => void
  deleteCharger: (circuit: Circuit, branch: Branch, charger: ChargerDetails, type: string) => void
  setAddPanelModalVisible: (addPanelModalVisible: boolean) => void
}

export const PanelView: React.FC<props> = ({
  circuits,
  verifiedChargers,
  circuitsFromUi,
  endPoints,
  saveClicked,
  setCircuits,
  addBranch,
  deleteBranch,
  checkBranchLimit,
  setDisableSaveData,
  setCircuitsFromUi,
  setBreakerLimitWarning,
  setVerifiedChargers,
}) => {
  const [breakerSizeLimit, setBreakerSizeLimit] = useState(0)
  const [initialBreakerSizeLimit, setInitialBreakerSizeLimit] = useState(false)
  const { openWarningNotification } = useNotifications()
  const { hash } = useLocation()
  const circuitId = hash.substring(1)
  const circuitSection = document.getElementById(circuitId)

  useEffect(() => {
    if (circuitSection) {
      circuitSection.scrollIntoView()
    }
  }, [circuitSection])

  useEffect(() => {
    checkBranchLimit(breakerSizeLimit, initialBreakerSizeLimit)
  }, [breakerSizeLimit, initialBreakerSizeLimit])

  useEffect(() => {
    const tempSelectedChargersArr: any = []
    circuits.map((circuit) => {
      circuit.branches.map((branch) => {
        branch.chargers.map((charger) => {
          if (charger.cp_serial_number !== '') {
            tempSelectedChargersArr.push(charger.cp_serial_number)
          }
          return charger
        })
        return branch
      })
      return circuit
    })
    const notSelectedChargersArr: any = []
    verifiedChargers.filter((charger) => {
      if (!tempSelectedChargersArr.includes(charger.chargepoint_serial_number)) {
        notSelectedChargersArr.push(charger)
      }
      return charger
    })
    setVerifiedChargers(notSelectedChargersArr)
  }, [])

  const removePanel = (type: string, circuit: Circuit) => {
    let deleted = false
    if (circuitsFromUi.length === 0) {
      const message = (
        <>
          <Checkbox checked={true} style={{ marginRight: '10px' }} />
          Warning: You can not remove a panel once it is saved. Contact the technical team for assistance.
        </>
      )
      openWarningNotification(message)
    } else {
      circuitsFromUi.forEach((circuitFromUi) => {
        if (circuitFromUi.id === circuit.id && circuitFromUi.circuit_id === circuit.circuit_id) {
          const tempCircuits = circuits.filter(
            (circuit) => circuitFromUi.id !== circuit.id && circuitFromUi.circuit_id !== circuit.circuit_id,
          )
          const tempCircuitsFromUi = circuitsFromUi.filter(
            (circuit) => circuitFromUi.id !== circuit.id && circuitFromUi.circuit_id !== circuit.circuit_id,
          )
          setCircuits([...tempCircuits])
          setCircuitsFromUi([...tempCircuitsFromUi])
          deleted = true
        }
      })
      if (!deleted) {
        const message = (
          <>
            <Checkbox checked={true} style={{ marginRight: '10px' }} />
            Warning: You can not remove a panel once it is saved. Contact the technical team for assistance.
          </>
        )
        openWarningNotification(message)
      }
    }
  }

  return (
    <>
      {circuits.length >= 1 &&
        circuits.map((circuit, panelIndexKey) => {
          return (
            <>
              <TimelineWrapper
                id={circuit.circuit_id}
                isDedicated={circuit.type === PanelType.dedicated ? true : false}
              >
                <Timeline>
                  <Timeline.Item
                    style={{ paddingTop: '0px' }}
                    dot={circuit.type === PanelType.dedicated ? dedicatedPanelSVG : mixedPanelSVG}
                    color="#30D7BA"
                  >
                    <PanelHeading
                      panelIndexKey={panelIndexKey}
                      circuit={circuit}
                      circuits={circuits}
                      initialBreakerSizeLimit={initialBreakerSizeLimit}
                      setCircuits={setCircuits}
                      removePanel={removePanel}
                      setBreakerSizeLimit={setBreakerSizeLimit}
                      setBreakerLimitWarning={setBreakerLimitWarning}
                      addBranch={addBranch}
                    />
                  </Timeline.Item>
                  {circuit?.branches.map((branch, branchIndex) => {
                    return (
                      <Timeline.Item
                        dot={circuit.type === PanelType.dedicated ? dedicatedPanelBranchSVG : mixedPanelBranchSVG}
                        color="#30D7BA"
                      >
                        <BranchTable
                          branch={branch}
                          circuit={circuit}
                          circuits={circuits}
                          breakerSizeLimit={breakerSizeLimit}
                          verifiedChargers={verifiedChargers}
                          endPoints={endPoints}
                          saveClicked={saveClicked}
                          setVerifiedChargers={setVerifiedChargers}
                          setCircuits={setCircuits}
                          setBreakerSizeLimit={setBreakerSizeLimit}
                          setInitialBreakerSizeLimit={setInitialBreakerSizeLimit}
                          setBreakerLimitWarning={setBreakerLimitWarning}
                          deleteBranch={deleteBranch}
                          setDisableSaveData={setDisableSaveData}
                        />
                      </Timeline.Item>
                    )
                  })}
                </Timeline>
              </TimelineWrapper>
            </>
          )
        })}
    </>
  )
}
