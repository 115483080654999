// Plus icon with black stroke
export const plusSVG = (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 1L6 11M11 6L1 6" stroke="black" stroke-width="2" stroke-linecap="round" />
  </svg>
)

// Plus icon with grey stroke
export const greyPlusSVG = (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 1L6 11M11 6L1 6" stroke="#7D838A" stroke-width="2" stroke-linecap="round" />
  </svg>
)

export const plusSignSVG = (
  <svg width="18" height="18" viewBox="0 0 18 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 1L6 11M11 6L1 6" stroke="black" stroke-width="2" stroke-linecap="round" />
  </svg>
)
