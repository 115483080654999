import moment from 'moment'
import { WeeklySchedule } from '../models/weeklySchedule'

export const weekdays: Array<[number, string]> = Array.from(
  ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].entries(),
)

export const weeklySchedulesFromFormValues = (values: any): WeeklySchedule[] =>
  weekdays.map(([weekdayNumber, weekDayName]) => {
    const [openingTime, closingTime] = values[weekDayName.toLowerCase()]
    return {
      dayOfWeek: weekdayNumber,
      startHour: openingTime.format('HH:mm:ss'),
      endHour: closingTime.format('HH:mm:ss'),
    }
  })

export const isTwentyFourSeven = (schedules: WeeklySchedule[]): boolean =>
  schedules.every((s) => s['startHour'] === '00:00:00' && s['endHour'] >= '23:45:00')

export const parseWeeklyScheduleTime = (time: string): moment.Moment => moment(time, 'HH:mm:ss')

export const weeklySchedulesToFormValues = (weeklySchedules: WeeklySchedule[]): any => {
  return Object.fromEntries(
    weekdays.flatMap(([dayNumber, dayName]) => {
      const weeklySchedule = weeklySchedules.find((s) => s.dayOfWeek === dayNumber)

      if (weeklySchedule) {
        const openingTime = parseWeeklyScheduleTime(weeklySchedule['startHour'])
        const closingTime = parseWeeklyScheduleTime(weeklySchedule['endHour'])
        return [[dayName.toLowerCase(), [openingTime, closingTime]]]
      } else {
        return []
      }
    }),
  )
}

export const twentyFourSevenWeeklyScheduleValues: WeeklySchedule[] = weekdays.map(([dayNumber, _]) => {
  return { dayOfWeek: dayNumber, startHour: '00:00:00', endHour: '23:45:00' }
})
