import configProvider from '../../config'
import { chargerModelTemplateQuery } from '../../helpers/query/chargerModelTemplateQuery'
import { addQueryString } from '../../helpers/url'
import { log } from '../../logger'
import { mockChargerUpdatedResponse } from '../../mock/charger'
import {
  mockAllChargersConfigTemplates,
  mockChargerModelMetadata,
  mockChargerModelTemplate,
} from '../../mock/charger-config-template'
import { mockDeleteTemplate, mockTemplateCreatedResponse, mockTemplateEditedResponse } from '../../mock/template'
import { ConfigurationTemplate } from '../../models/charger-config-template'
import { ChargerModelTemplate } from '../../models/charger-model-template'
import { ChargerModelMetadataResponse, ChargerModelTemplateResponse } from '../../models/http'
import { Message } from '../../models/message'
import { Template } from '../../models/template'
import { CaseConverter } from './case-converter'
import { apiCihClient, apiClient } from './client'
import { returnMock } from './mock'

export async function GetAllChargerConfigTemplates(): Promise<Template[]> {
  const url = '/templates'
  log('fetching all charger templates', { url: url })

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockAllChargersConfigTemplates)
  }

  return apiClient(url, {})
}

export async function ModifyChargerConfigTemplate(
  chargerInfoParams: ConfigurationTemplate,
  serialNumber: string,
): Promise<Message> {
  console.log('🚀 ~ serialNumber:', serialNumber)
  console.log('🚀 ~ chargerInfoParams:', chargerInfoParams)
  const url = `/chargers/assign_config/${serialNumber}`
  log('editing environment', { url: url })

  let caseConverter = new CaseConverter()
  const snakeCasedConfigurationTemplate = caseConverter.camelToSnakeCase(chargerInfoParams)
  console.log('🚀 ~ snakeCasedConfigurationTemplate:', snakeCasedConfigurationTemplate)

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockChargerUpdatedResponse(serialNumber))
  }

  return apiClient(url, {
    method: 'PUT',
    body: JSON.stringify({
      ...snakeCasedConfigurationTemplate,
    }),
  })
}

export async function DeleteChargerTemplate(templateID: String): Promise<Message> {
  const url = `/templates/${templateID}`
  log('deleting template', { url: url })

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockDeleteTemplate)
  }

  return apiClient(url, {
    method: 'DELETE',
  })
}

export async function CreateNewTemplate(
  templateObj: Omit<Template, '_id' | 'updatedAt' | 'createdAt' | 'deletedAt'>,
): Promise<Message> {
  const url = `/templates`
  log('creating new template', { url: url })

  let caseConverter = new CaseConverter()
  const snakeCaseCreateNewTemplate = caseConverter.camelToSnakeCase(templateObj)

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockTemplateCreatedResponse)
  }

  snakeCaseCreateNewTemplate.template = templateObj.template

  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      ...snakeCaseCreateNewTemplate,
    }),
  })
}

export async function EditChargerTemplate(
  templateObj: Omit<Template, 'updatedAt' | 'deletedAt' | 'createdAt'>,
): Promise<Message> {
  const url = `/templates/${templateObj._id}`
  log('Editing template', { url: url })

  let caseConverter = new CaseConverter()
  const snakeEditChargerTemplate = caseConverter.camelToSnakeCase(templateObj)

  snakeEditChargerTemplate.template = templateObj.template

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockTemplateEditedResponse)
  }

  return apiClient(url, {
    method: 'PUT',
    body: JSON.stringify({
      ...snakeEditChargerTemplate,
    }),
  })
}

export async function GetChargerModelTemplate(page?: number): Promise<ChargerModelTemplateResponse> {
  let url = '/commissioning/api/v1/charger-model-template'
  log('Fetching charger model template', { url })

  const filter = { page: page || undefined }

  const queryParams = chargerModelTemplateQuery(filter)

  url = addQueryString(url, queryParams)

  if (configProvider.config.mockEnabled) {
    return returnMock(mockChargerModelTemplate(page))
  }
  return apiCihClient(url, {})
}

export async function GetChargerModelTemplateMetadata(): Promise<ChargerModelMetadataResponse> {
  let url = '/commissioning/api/v1/charger-model-template/metadata'
  const productionURL = configProvider.config.cihApiDomain
  if (productionURL.includes('commissioning.swtchenergy.com')) {
    url = '/api/v1/charger-model-template/metadata'
  }
  log('Fetching charger model template', { url })

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockChargerModelMetadata())
  }
  return apiCihClient(url, {})
}

export async function CreateChargerModelTemplate(data: ChargerModelTemplate): Promise<any> {
  let url = '/commissioning/api/v1/charger-model-template'
  const productionURL = configProvider.config.cihApiDomain
  if (productionURL.includes('commissioning.swtchenergy.com')) {
    url = '/api/v1/charger-model-template'
  }
  log('Creating charger model template', { url })

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockTemplateCreatedResponse)
  }

  return apiCihClient(url, {
    method: 'POST',
    body: JSON.stringify(data),
  })
}
