import { stringify } from 'query-string'
import configProvider from '../config'

// i.e. this returns a base url i.e: https://admin.swtchenergy.com
export function buildBaseUrl(): string {
  const { protocol, host } = window.location

  return `${protocol}//${host}`
}

export function buildAppUrl(path: string): string {
  return `${buildBaseUrl()}${path}`
}

export function buildApiBaseUrl(): string {
  //const { protocol } = window.location
  let hostname = configProvider.config.apiDomain

  return `${hostname}/api/v1`
}

export function buildApiManageUrl(): string {
  return `${buildApiBaseUrl()}/manage`
}

export function addQueryString(
  path: string,
  queryParams: {
    [key: string]: string
  },
): string {
  const querystring = stringify(queryParams)
  return path + '?' + querystring
}

export function buildApiLoadMgmtBaseUrl(): string {
  return configProvider.config.loadManagement
}

export function buildApiLoadMgmtChargerBaseUrl(): string {
  return configProvider.config.loadManagementCharger
}

export function buildApiRailsBaseUrl(): string {
  const url = `https://${configProvider.config.railsApiDomain}/api/v2/manage`
  return url
}

export function buildApiCihBaseUrl(): string {
  return `https://${configProvider.config.cihApiDomain}`
}
